/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Alert, Box, Flex, useSnackbar } from 'pouncejs';
import Page404 from 'Pages/404';
import withSEO from 'Hoc/withSEO';
import useRouter from 'Hooks/useRouter';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { compose } from 'Helpers/compose';
import { LogPullerTypeEnum, Permission } from 'Generated/schema';
import Page403 from 'Pages/403';
import {
  checkIfSourceIsAuthorized,
  extractErrorMessage,
  generateIntegrationOAuthLink,
} from 'Helpers/utils';
import urls from 'Source/urls';
import DropboxLogSourceWizard, { DropboxLogSourceWizardValues } from '../DropboxLogSourceWizard';
import { useUpdateDropboxLogSource } from './graphql/updateDropboxLogSource.generated';
import { useGetDropboxLogSource } from './graphql/getDropboxLogSource.generated';

const EditDropboxLogSource: React.FC = () => {
  const { pushSnackbar } = useSnackbar();
  const { match: { params: { id } } } = useRouter<{ id: string }>(); // prettier-ignore
  const [updateDropboxLogSource] = useUpdateDropboxLogSource();

  const { data, error: getError } = useGetDropboxLogSource({
    variables: { id },
    onError: error => {
      pushSnackbar({
        title: extractErrorMessage(error) || 'An unknown error occurred',
        variant: 'error',
      });
    },
  });

  const initialValues: DropboxLogSourceWizardValues = React.useMemo(
    () => ({
      integrationId: id,
      integrationLabel: data?.getLogPullingIntegration.integrationLabel ?? 'Loading...',
      logTypes: data?.getLogPullingIntegration.logTypes ?? [],
      clientId: data?.getLogPullingIntegration.pullerConfig.dropbox.clientId,
      clientSecret: data?.getLogPullingIntegration.pullerConfig.dropbox.clientSecret,
    }),
    [id, data]
  );

  const handleSubmit = React.useCallback(
    async (values: DropboxLogSourceWizardValues) => {
      try {
        const res = await updateDropboxLogSource({
          variables: {
            input: {
              integrationId: values.integrationId,
              integrationLabel: values.integrationLabel,
              pullerConfig: {
                logTypes: values.logTypes,
                dropbox: {
                  clientId: values.clientId,
                  clientSecret: values.clientSecret,
                },
              },
            },
          },
        });
        return res.data.updateLogPullingIntegration;
      } catch (err) {
        return err as Error;
      }
    },
    [updateDropboxLogSource]
  );

  // we optimistically assume that an error in "get" is a 404. We don't have any other info
  if (getError) {
    return <Page404 />;
  }

  const shouldRenderAuthorizationBanner =
    !!data && !checkIfSourceIsAuthorized(data.getLogPullingIntegration);

  return (
    <Flex direction="column" spacing={4}>
      {shouldRenderAuthorizationBanner && (
        <Alert
          variant="error"
          title={[
            'Panther is not authorized to pulls logs from Dropbox. ',
            <Box
              key={1}
              as="a"
              textDecoration="underline"
              href={generateIntegrationOAuthLink(
                id,
                urls.integrations.logSources.authorize(id, LogPullerTypeEnum.Dropbox)
              )}
            >
              Click here to authorize
            </Box>,
          ]}
        />
      )}
      <DropboxLogSourceWizard initialValues={initialValues} onSubmit={handleSubmit} />
    </Flex>
  );
};

export default compose(
  withSEO({ title: 'Edit Dropbox Source' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LogSourceModify],
    fallback: <Page403 />,
  })
)(EditDropboxLogSource);

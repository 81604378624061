/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import useUrlParams, { UseUrlParamsProps } from 'Hooks/useUrlParams';

function useRequestParamsWithoutPagination<AvailableParams>(
  parsingOptions?: UseUrlParamsProps<AvailableParams>
) {
  const { urlParams, updateUrlParams, setUrlParams } = useUrlParams<Partial<AvailableParams>>(
    parsingOptions
  );

  // This is our typical function that updates the parameters
  const updateRequestParams = React.useCallback(
    (newParams: Partial<AvailableParams>) => {
      updateUrlParams({ ...urlParams, ...newParams });
    },
    // FIXME: look into hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [urlParams]
  );

  // This is a similar function like the above but instead of updating the existing params with the
  // new parameters, it clears all the parameters and just sets the parameters passed as an argument
  const setRequestParams = React.useCallback(
    (newParams: Partial<AvailableParams>) => {
      setUrlParams({ ...newParams });
    },
    // FIXME: look into hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [urlParams]
  );

  return React.useMemo(
    () => ({
      requestParams: urlParams,
      updateRequestParams,
      setRequestParams,
    }),
    // FIXME: look into hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [urlParams]
  );
}
export default useRequestParamsWithoutPagination;

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { LogPullerTypeEnum } from 'Generated/schema';
import { LogSourceType } from 'Source/constants';
import urls from 'Source/urls';

export const alertDestinationsUrls = [
  // current
  urls.configure.alertDestinations.create(),
  urls.configure.alertDestinations.edit(':id'),
  urls.configure.alertDestinations.list(),
  `${urls.configure.alertDestinations.list()}:id`,
  // deprecated
  urls.integrations.destinations.create(),
  urls.integrations.destinations.edit(':id'),
  urls.integrations.destinations.list(),
  `${urls.integrations.destinations.list()}:id`,
];

export const cloudAccountsUrls = [
  // current
  urls.configure.cloudAccounts.create(),
  urls.configure.cloudAccounts.edit(':id'),
  urls.configure.cloudAccounts.list(),
  `${urls.configure.cloudAccounts.list()}:id`,
  // deprecated
  urls.integrations.cloudAccounts.create(),
  urls.integrations.cloudAccounts.edit(':id'),
  urls.integrations.cloudAccounts.list(),
  `${urls.integrations.cloudAccounts.list()}:id`,
];

export const cloudResourcesUrls = [
  // current
  urls.configure.cloudResources.list(),
  urls.configure.cloudResources.details(':id'),
  // deprecated
  urls.data.home(),
  urls.data.resources.details(':id'),
  urls.data.resources.list(),
];

export const enrichmentProvidersRoutesUrls = [
  // current
  urls.configure.enrichmentProviders.details(':id'),
  urls.configure.enrichmentProviders.list(),
  // deprecated
  urls.enrichment.sources.details(':id'),
  urls.enrichment.sources.list(),
];

export const logSourcesUrls = [
  // current
  urls.configure.home(),
  urls.configure.logSources.authorize(':id', ':type' as LogPullerTypeEnum),
  urls.configure.logSources.create(),
  urls.configure.logSources.create(':type' as LogSourceType),
  urls.configure.logSources.details(':id', ':type' as LogSourceType),
  urls.configure.logSources.edit(':id', ':type' as LogSourceType),
  urls.configure.logSources.list(),
  `${urls.configure.logSources.list()}:type`,
  urls.configure.logSources.schemasManagement(':id').home(),
  // deprecated
  urls.integrations.home(),
  urls.integrations.logSources.authorize(':id', ':type' as LogPullerTypeEnum),
  urls.integrations.logSources.create(),
  urls.integrations.logSources.create(':type' as LogSourceType),
  urls.integrations.logSources.details(':id', ':type' as LogSourceType),
  urls.integrations.logSources.edit(':id', ':type' as LogSourceType),
  urls.integrations.logSources.list(),
  `${urls.integrations.logSources.list()}:type`,
  urls.integrations.logSources.schemasManagement(':id').home(),
];

export const lookupTablesUrls = [
  // current
  urls.configure.lookupTables.home(),
  urls.configure.lookupTables.list(),
  urls.configure.lookupTables.create(),
  urls.configure.lookupTables.edit(':id'),
  `${urls.configure.lookupTables.list()}:id`,
  // deprecated
  urls.enrichment.home(),
  urls.enrichment.lookupTables.list(),
  urls.enrichment.lookupTables.create(),
  urls.enrichment.lookupTables.edit(':id'),
  `${urls.enrichment.lookupTables.list()}:id`,
];

export const schemasUrls = [
  // current
  urls.configure.schemas.create(),
  urls.configure.schemas.details(':name'),
  urls.configure.schemas.edit(':name'),
  urls.configure.schemas.list(),
  // deprecated
  urls.data.schemas.create(),
  urls.data.schemas.details(':name'),
  urls.data.schemas.edit(':name'),
  urls.data.schemas.list(),
];

export const configureUrlsGroup = [
  ...alertDestinationsUrls,
  ...cloudAccountsUrls,
  ...cloudResourcesUrls,
  ...enrichmentProvidersRoutesUrls,
  ...logSourcesUrls,
  ...lookupTablesUrls,
  ...schemasUrls,
];

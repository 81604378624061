/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import useRouter from 'Hooks/useRouter';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import urls from 'Source/urls';
import CreateDataSchema from 'Pages/CreateDataSchema';
import DataSchemaDetailsPage from 'Pages/DataSchemaDetails';
import EditDataSchema from 'Pages/EditDataSchema';
import ListDataSchemasPage from 'Pages/ListDataSchemas';

const SchemasRoutes: React.FC = () => {
  const { location } = useRouter();

  return (
    <Switch>
      <Redirect
        exact
        from={urls.data.schemas.list()}
        to={{ pathname: urls.configure.schemas.list(), search: location.search }}
      />
      <Route exact path={urls.configure.schemas.list()} component={ListDataSchemasPage} />

      <Redirect
        exact
        from={urls.data.schemas.create()}
        to={{ pathname: urls.configure.schemas.create(), search: location.search }}
      />
      <Route exact path={urls.configure.schemas.create()} component={CreateDataSchema} />

      <Redirect
        exact
        from={urls.data.schemas.details(':name')}
        to={urls.configure.schemas.details(':name')}
      />
      <Route
        exact
        path={urls.configure.schemas.details(':name')}
        component={DataSchemaDetailsPage}
      />

      <Redirect
        exact
        from={urls.data.schemas.edit(':name')}
        to={urls.configure.schemas.edit(':name')}
      />
      <Route exact path={urls.configure.schemas.edit(':name')} component={EditDataSchema} />
    </Switch>
  );
};

export default SchemasRoutes;

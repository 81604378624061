/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Flex, Heading, Button, Text } from 'pouncejs';
import useModal from 'Hooks/useModal';

export const S3PrefixInformationModal = () => {
  const { hideModal } = useModal();

  return (
    <Flex direction="column" spacing={4} maxWidth={750}>
      <Heading as="h2" fontSize="x-large" fontWeight="medium">
        Prefixes & Exclusion Filters
      </Heading>
      <Text fontSize="medium">
        AWS S3 buckets contain a file structure that is defined by prefixes. With this field, you
        can define prefixes to stream data from in your S3 bucket or to exclude from streaming. Once
        the S3 Bucket is onboarded, Panther will only pull data from the prefixes you’ve defined.
      </Text>
      <Heading as="h2" fontSize="x-large" fontWeight="medium">
        Schemas
      </Heading>
      <Text fontSize="medium">
        Schemas are the mechanism that classifies your data as it comes into Panther. If you’ve
        already have a schema for the data type you’re onboarded, you can define that now. If you’d
        like to generate a schema from live data in the bucket you’re onboarding, you can skip this
        step for now and create a schema after data onboarding is completed.
      </Text>
      <Flex justify="center" mt={4}>
        <Button onClick={hideModal}>Ok, Got it!</Button>
      </Flex>
    </Flex>
  );
};

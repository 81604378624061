/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import withSEO from 'Hoc/withSEO';
import { compose } from 'Helpers/compose';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import useAvailableLogTypesForLogPuller from 'Hooks/useAvailableLogTypesForLogPuller';
import { Permission } from 'Generated/schema';
import Page403 from 'Pages/403';
import DropboxLogSourceWizard, { DropboxLogSourceWizardValues } from '../DropboxLogSourceWizard';
import { useAddDropboxLogSource } from './graphql/addDropboxLogSource.generated';

const CreateDropboxLogSource: React.FC = () => {
  const [addDropboxLogSource] = useAddDropboxLogSource();
  const { defaultLogTypes } = useAvailableLogTypesForLogPuller();
  const initialValues = React.useMemo(
    () => ({
      integrationLabel: '',
      logTypes: defaultLogTypes,
      clientId: '',
      clientSecret: '',
    }),
    [defaultLogTypes]
  );

  const handleSubmit = React.useCallback(
    async (values: DropboxLogSourceWizardValues) => {
      try {
        const { data } = await addDropboxLogSource({
          variables: {
            input: {
              integrationLabel: values.integrationLabel,
              pullerConfig: {
                logTypes: values.logTypes,
                dropbox: {
                  clientId: values.clientId,
                  clientSecret: values.clientSecret,
                },
              },
            },
          },
        });

        return data.addLogPullingIntegration;
      } catch (err) {
        return err as Error;
      }
    },
    [addDropboxLogSource]
  );

  return <DropboxLogSourceWizard initialValues={initialValues} onSubmit={handleSubmit} />;
};

export default compose(
  withSEO({ title: 'New Dropbox Source' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LogSourceModify],
    fallback: <Page403 />,
  })
)(CreateDropboxLogSource);

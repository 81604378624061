/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Alert, Box, Button, Card, FadeIn, Flex, Heading, Icon, Spinner, Tooltip } from 'pouncejs';
import JsonViewer, { EventNodeActions } from 'Components/JsonViewer';
import { TableControlsPagination as PaginationControls } from 'Components/utils/TableControls';
import ViewWithDataExplorerButton from 'Components/buttons/ViewWithDataExplorerButton';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import useNavigationKeys from 'Hooks/useNavigationKeys';
import dayjs from 'dayjs';
import { AlertType, Permission, AlertOriginRule } from 'Generated/schema';
import useUrlParams from 'Hooks/useUrlParams';
import { downloadData, extractErrorMessage, toPlural } from 'Helpers/utils';
import { DEFAULT_LARGE_PAGE_SIZE } from 'Source/constants';
import { AlertDetails } from '../../graphql/alertDetails.generated';
import { useGetRuleAlertEvents } from './graphql/getRuleAlertEvents.generated';
import { RuleAlertDetailsUrlParams } from '../RuleAlertDetails';

interface RuleAlertEventsProps {
  alert: AlertDetails['alert'];
}

const RuleAlertEvents: React.FC<RuleAlertEventsProps> = ({ alert }) => {
  const { urlParams } = useUrlParams<RuleAlertDetailsUrlParams>();
  // because we are going to use that in PaginationControls we are starting an indexing starting
  // from 1 instead of 0. That's why we are using `eventDisplayIndex - 1` when selecting the proper event.
  // Normally the `PaginationControls` are used for displaying pages so they are built with a
  // 1-based indexing in mind
  const [eventDisplayIndex, setEventDisplayIndex] = React.useState(1);

  const { data, loading, error, fetchMore } = useGetRuleAlertEvents({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      id: alert.id,
      eventsInput: {
        pageSize: DEFAULT_LARGE_PAGE_SIZE,
      },
    },
  });

  const isAlertFromError = [AlertType.RuleError, AlertType.ScheduledRuleError].includes(alert.type);
  const events = data?.alert.origin.events ?? null;
  React.useEffect(() => {
    if (!events) {
      return;
    }

    // We are pre-fetching the next page of events to avoid showing a spinner when users reach the end of current page
    if (eventDisplayIndex - 1 === events.items.length - DEFAULT_LARGE_PAGE_SIZE) {
      fetchMore({
        variables: {
          id: alert.id,
          eventsInput: {
            pageSize: DEFAULT_LARGE_PAGE_SIZE,
            exclusiveStartKey: events.lastEvaluatedKey,
          },
        },
      });
    }
    // FIXME: look into effect dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventDisplayIndex, events]);

  const goToPrevious = React.useCallback(() => {
    const nextIndex =
      eventDisplayIndex === 1 ? data.alert.origin.eventsMatched : eventDisplayIndex - 1;
    setEventDisplayIndex(nextIndex);
  }, [eventDisplayIndex, data?.alert?.origin.eventsMatched]);
  const goToNext = React.useCallback(() => {
    const nextIndex =
      data.alert.origin.eventsMatched === eventDisplayIndex ? 1 : eventDisplayIndex + 1;
    setEventDisplayIndex(nextIndex);
  }, [data?.alert?.origin.eventsMatched, eventDisplayIndex]);

  useNavigationKeys({
    // disabled when not on this tab since the window event triggers even when the tab is not active
    disabled: urlParams.section !== 'events',
    previousCallback: goToPrevious,
    nextCallback: goToNext,
  });

  if (!data && loading) {
    return (
      <Flex my={50} justify="center">
        <FadeIn delay={100}>
          <Spinner size="medium" />
        </FadeIn>
      </Flex>
    );
  }

  if (error) {
    return (
      <Alert
        variant="error"
        title="Couldn't load events"
        description={
          extractErrorMessage(error) ||
          "An unknown error occurred and we couldn't load the alert events from the server"
        }
        actions={
          <ViewWithDataExplorerButton
            size="medium"
            variantColor="pink-600"
            snippedId={isAlertFromError ? 'alertIdError' : 'alertId'}
            snippetParams={{ alertId: alert.id }}
          />
        }
      />
    );
  }

  const currentCount = events.items.length;
  const totalCount = (alert.origin as AlertOriginRule).eventsMatched;
  const isLastItem = eventDisplayIndex === currentCount;
  const alertEvent = events.items[eventDisplayIndex - 1];
  const firstEvent = events.items[0];

  const isAlertRecentlyCreated = dayjs().diff(alert.createdAt, 'second') < 60;
  if (!currentCount && isAlertRecentlyCreated) {
    return (
      <Alert
        variant="warning"
        title="No events to show"
        description={
          'This alert got created a few seconds ago & our systems are still processing the related event(s). Please try refreshing the page in a few seconds.'
        }
      />
    );
  }

  return (
    <Flex direction="column" spacing={6}>
      <Flex justify="space-between" align="center">
        <Flex align="center" spacing={2}>
          <Heading size="x-small">
            <b>{totalCount}</b> Triggered {toPlural('Event', totalCount)}
          </Heading>
          <Tooltip
            content={
              <Box maxWidth={300}>
                Each triggered event contains a set of extended metadata for a log entry that was
                matched against your rule
              </Box>
            }
          >
            <Icon type="info" size="medium" />
          </Tooltip>
        </Flex>
        <Box position="relative">
          <PaginationControls
            page={eventDisplayIndex}
            totalPages={totalCount}
            onPageChange={setEventDisplayIndex}
            disableNextPageNavigation={isLastItem}
          />
          {loading && isLastItem && currentCount < totalCount && (
            <Spinner
              position="absolute"
              right={-25}
              top="14px"
              transform="translateY(-50%)"
              size="small"
            />
          )}
        </Box>
        <Flex spacing={4}>
          <ViewWithDataExplorerButton
            snippedId={isAlertFromError ? 'alertIdError' : 'alertId'}
            snippetParams={{ alertId: alert.id }}
          />
          <Button
            onClick={() =>
              downloadData(JSON.stringify(alertEvent), `${alert.id}-${eventDisplayIndex}.json`)
            }
          >
            Download JSON
          </Button>
        </Flex>
      </Flex>
      <Card variant="dark" p={6}>
        <RoleRestrictedAccess
          allowedPermissions={[Permission.DataAnalyticsRead]}
          fallback={<JsonViewer data={alertEvent} />}
        >
          <JsonViewer
            data={alertEvent}
            actions={actionProps => (
              <EventNodeActions
                {...actionProps}
                // eslint-disable-next-line camelcase
                dateCreated={firstEvent?.p_event_time ?? undefined}
                dateEnded={alert.lastReceivedEventAt}
              />
            )}
          />
        </RoleRestrictedAccess>
      </Card>
    </Flex>
  );
};

export default React.memo(RuleAlertEvents);

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Link as RRLink } from 'react-router-dom';
import { Box, Text, Card, Flex, Heading, AbstractButton, Link } from 'pouncejs';
import useModal from 'Hooks/useModal';
import urls from 'Source/urls';
import SetS3PrefixesModal from 'Components/modals/SetS3PrefixesModal';
import { Permission, S3LogIntegration, UpdateS3LogIntegrationInput } from 'Generated/schema';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import { CREATE_CUSTOM_SCHEMA_DOC_URL, GENERATE_AND_TEST_SCHEMA_DOC_URL } from 'Source/constants';

const EmptyDataFallback = ({
  source,
  onSourceUpdate,
  loading,
  hasRawData,
  rawDataLoading,
  onRawDataRefresh,
}: {
  source: S3LogIntegration;
  onSourceUpdate: (source: Partial<UpdateS3LogIntegrationInput>) => void;
  loading: boolean;
  hasRawData: boolean;
  rawDataLoading: boolean;
  onRawDataRefresh: () => void;
}) => {
  const { showModal } = useModal();

  return (
    <Card variant="dark" px={100} py={6}>
      <RoleRestrictedAccess
        allowedPermissions={[Permission.LogSourceModify]}
        fallback={
          <Heading p={8} as="h3" size="small" fontWeight="medium" textAlign="center">
            No schemas or prefixes currently specified for this source
          </Heading>
        }
      >
        <Flex align="center" direction="column">
          <Heading mb={6} as="h3" size="small" fontWeight="medium" textAlign="center">
            No schemas or prefixes currently specified for this source
            <br />
            Choose one of the options below to start
          </Heading>

          <Flex
            width={1}
            px={6}
            py={4}
            mb={4}
            align="center"
            borderRadius="small"
            justify="space-between"
            backgroundColor="navyblue-350"
          >
            <Box>
              <Heading as="h4" fontSize="x-large" mb={2}>
                I already know schemas and prefixes
              </Heading>
              <Text color="gray-300" fontSize="small-medium">
                Choose this option if you already created a schema and you know the
                <br />
                S3 prefix you want Panther to read logs from{' '}
                <Link fontSize="small-medium" href={CREATE_CUSTOM_SCHEMA_DOC_URL} external mb={10}>
                  Read More
                </Link>
              </Text>
            </Box>
            <AbstractButton
              px={4}
              py={2}
              disabled={loading}
              borderRadius="medium"
              fontSize="large"
              backgroundColor="blue-300"
              aria-label="Open Schema configuration"
              onClick={() =>
                showModal(
                  <SetS3PrefixesModal
                    initialValues={{
                      s3PrefixLogTypes: source.s3PrefixLogTypes,
                    }}
                    onSuccess={values =>
                      onSourceUpdate({
                        s3PrefixLogTypes: values.s3PrefixLogTypes,
                      })
                    }
                    schemasManagementUrl={
                      hasRawData
                        ? urls.integrations.logSources
                            .schemasManagement(source.integrationId)
                            .home()
                        : null
                    }
                  />,
                  {
                    showCloseButton: true,
                    title: 'S3 Prefixes & Schemas',
                  }
                )
              }
            >
              Start
            </AbstractButton>
          </Flex>
          <RoleRestrictedAccess allowedPermissions={[Permission.LogSourceRawDataRead]}>
            <Flex
              px={6}
              py={4}
              mb={4}
              width={1}
              align="center"
              borderRadius="small"
              justify="space-between"
              backgroundColor="navyblue-350"
            >
              <Box>
                <Heading as="h4" fontSize="x-large" mb={2}>
                  I want to generate a schema from raw events
                </Heading>
                <Text color="gray-300" fontSize="small-medium">
                  Select this option to generate a schema from live data in this bucket
                  <br />
                  and define which prefixes you want Panther to read logs from.{' '}
                  <Link href={GENERATE_AND_TEST_SCHEMA_DOC_URL} external mb={10}>
                    Read More
                  </Link>
                  <br />
                  <Text as="span" color={!hasRawData ? 'yellow-300' : 'gray-300'}>
                    This option is made available when data is imported.{' '}
                  </Text>
                  {!hasRawData && (
                    <AbstractButton
                      color={rawDataLoading ? 'gray-200' : 'blue-200'}
                      _hover={{ color: 'blue-100' }}
                      onClick={onRawDataRefresh}
                      disabled={rawDataLoading}
                    >
                      {rawDataLoading ? 'Refreshing...' : 'Refresh'}
                    </AbstractButton>
                  )}
                </Text>
              </Box>
              <AbstractButton
                as={RRLink}
                px={4}
                py={2}
                borderRadius="medium"
                fontSize="large"
                opacity={loading || !hasRawData ? 0.4 : 1}
                pointerEvents={loading || !hasRawData ? 'none' : 'all'}
                backgroundColor="blue-300"
                aria-label="Open Schema configuration"
                to={urls.integrations.logSources.schemasManagement(source.integrationId).home()}
              >
                Start
              </AbstractButton>
            </Flex>
          </RoleRestrictedAccess>
          <Text textAlign="center" color="gray-300" fontSize="small-medium">
            You can also create your own schema from the <b>Schemas page</b> and return here to
            attach it
          </Text>
          <Link as={RRLink} to={urls.data.schemas.create()} fontSize="medium">
            Create new schema
          </Link>
        </Flex>
      </RoleRestrictedAccess>
    </Card>
  );
};

export default EmptyDataFallback;

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, Heading, Card } from 'pouncejs';
import UpdateAlertDropdown from 'Components/dropdowns/UpdateAlertDropdown';
import { AlertSummaryFull } from 'Source/graphql/fragments/AlertSummaryFull.generated';
import { ALERT_TYPE_COLOR_MAP } from 'Source/constants';
import { AlertDetails } from 'Pages/AlertDetails';
import { alertTypeToString } from 'Helpers/utils';

interface SystemErrorAlertDetailsBannerProps {
  alert: AlertDetails['alert'];
}

const SystemErrorAlertDetailsBanner: React.FC<SystemErrorAlertDetailsBannerProps> = ({ alert }) => {
  return (
    <Card as="article" p={6}>
      <Flex as="header" align="center">
        <Heading fontWeight="bold" wordBreak="break-word" flexShrink={1} mr={100}>
          {alert.title || alert.id}
        </Heading>
        <Flex flex={1} justifyContent="flex-end">
          <UpdateAlertDropdown
            variant="outline"
            alert={alert as AlertSummaryFull}
            dropdownFlexDirection="row"
          />
        </Flex>
      </Flex>
      <Flex as="dl" fontSize="small-medium" pt={5} spacing={8} wrap="wrap">
        <Flex>
          <Box color="navyblue-100" as="dt" pr={2}>
            Alert Type
          </Box>
          <Box as="dd" fontWeight="bold" color={ALERT_TYPE_COLOR_MAP[alert.type]}>
            {alertTypeToString(alert.type, { useSingular: true })}
          </Box>
        </Flex>
        <Flex>
          <Box color="navyblue-100" as="dt" pr={2}>
            Alert ID
          </Box>
          <Box as="dd" fontWeight="bold">
            {alert.id}
          </Box>
        </Flex>
      </Flex>
    </Card>
  );
};

export default SystemErrorAlertDetailsBanner;

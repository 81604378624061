/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useSnackbar, Flex, Box, Combobox, Tooltip } from 'pouncejs';
import { AlertStatus, Permission } from 'Generated/schema';
import {
  extractErrorMessage,
  formatDatetime,
  getActorDisplayName,
  capitalize,
} from 'Helpers/utils';
import { AlertSummaryFull } from 'Source/graphql/fragments/AlertSummaryFull.generated';
import { useUpdateAlertStatusById } from 'Source/graphql/queries';
import useCheckPermissions from 'Hooks/useCheckPermissions';
import AssigneeDropdown from './AssigneeDropdown';

interface UpdateAlertDropdownProps {
  alert: AlertSummaryFull;
  variant?: 'solid' | 'outline';
  dropdownFlexDirection?: 'column' | 'row';
}

const alertStatusValues = Object.values(AlertStatus);

const filterItemToString = (item: AlertStatus) =>
  capitalize(item === AlertStatus.Closed ? 'Invalid' : item.toLowerCase());

const UpdateAlertDropdown: React.FC<UpdateAlertDropdownProps> = ({
  alert,
  variant = 'solid',
  dropdownFlexDirection = 'column',
}) => {
  const { pushSnackbar } = useSnackbar();
  const canModifyAlert = useCheckPermissions(Permission.AlertModify);

  const [updateAlertStatusById] = useUpdateAlertStatusById({
    variables: {
      input: {
        status: alert.status,
        ids: [alert.id],
      },
    },
    // We want to simulate an instant change in the UI which will fallback if there's a failure
    optimisticResponse: data => ({
      updateAlertStatusById: {
        alerts: [
          {
            ...alert,
            status: data.input.status,
          },
        ],
      },
    }),
    onCompleted: data => {
      const { status } = data.updateAlertStatusById.alerts[0];
      pushSnackbar({
        variant: 'success',
        title: `Alert set to ${capitalize(
          (status === AlertStatus.Closed ? 'INVALID' : status).toLowerCase()
        )}`,
      });
    },
    onError: error => {
      pushSnackbar({
        variant: 'error',
        title: `Failed to set the alert status`,
        description: extractErrorMessage(error),
      });
    },
  });

  const AlertStatusDropdown = React.useMemo(
    () => (
      <Combobox
        label="Alert Status"
        aria-label="Change Alert Status"
        disabled={!canModifyAlert}
        variant={variant}
        showClearSelectionControl={false}
        data-tid="change-alert-status"
        value={alert.status}
        onChange={status =>
          updateAlertStatusById({
            variables: { input: { status, ids: [alert.id] } },
          })
        }
        items={alertStatusValues}
        itemToString={filterItemToString}
      />
    ),
    // FIXME: look into hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [alert]
  );

  return (
    <Box width={dropdownFlexDirection === 'column' ? '160px' : '320px'}>
      {alert.updatedBy?.id ? (
        <Tooltip
          content={
            <Flex spacing={1}>
              <Flex direction="column" spacing={1}>
                <Box id="user-name-label">By</Box>
                <Box id="updated-by-timestamp-label">At</Box>
              </Flex>
              <Flex direction="column" spacing={1} fontWeight="bold">
                <Box aria-labelledby="user-name-label">{getActorDisplayName(alert.updatedBy)}</Box>
                <Box aria-labelledby="updated-by-timestamp-label">
                  {formatDatetime(alert.updatedAt)}
                </Box>
              </Flex>
            </Flex>
          }
        >
          <Flex flexDirection={dropdownFlexDirection} spacing={4}>
            <Box>
              <AssigneeDropdown alert={alert} variant={variant} />
            </Box>
            <Box>{AlertStatusDropdown}</Box>
          </Flex>
        </Tooltip>
      ) : (
        <Flex flexDirection={dropdownFlexDirection} spacing={4}>
          <Box>
            <AssigneeDropdown alert={alert} variant={variant} />
          </Box>
          <Box>{AlertStatusDropdown}</Box>
        </Flex>
      )}
    </Box>
  );
};

export default UpdateAlertDropdown;

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import * as Yup from 'yup';
import { Link as RRLink } from 'react-router-dom';
import { Box, Flex, Link, SimpleGrid, Text } from 'pouncejs';
import useModal from 'Hooks/useModal';
import { S3PrefixLogTypesFormValues } from 'Pages/Integrations/LogSources/S3/SchemasManagement/SchemasManagement';
import useAvailableLogTypesForProvider from 'Hooks/useAvailableLogTypesForProvider';
import SubmitButton from 'Components/buttons/SubmitButton';
import S3PrefixSchemaFieldsList from 'Components/forms/common/S3PrefixLogTypesFields';
import { Formik } from 'formik';
import { yupS3PrefixLogTypesValidation } from 'Helpers/utils';

type FormValues = Pick<S3PrefixLogTypesFormValues, 's3PrefixLogTypes'>;

export interface SetS3PrefixesLogtypesModalProps {
  initialValues: FormValues;
  draftSchemaNames?: string[];
  onSuccess: (values: FormValues) => void;
  schemasManagementUrl?: string;
}

const SetS3PrefixesModal: React.FC<SetS3PrefixesLogtypesModalProps> = ({
  initialValues,
  draftSchemaNames = [],
  onSuccess,
  schemasManagementUrl,
}) => {
  const { hideModal } = useModal();
  const { availableLogTypes } = useAvailableLogTypesForProvider();
  const onSubmit = React.useCallback(
    (val: S3PrefixLogTypesFormValues) => {
      onSuccess(val);
      hideModal();
    },
    [hideModal, onSuccess]
  );

  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        s3PrefixLogTypes: yupS3PrefixLogTypesValidation(),
      })}
      onSubmit={onSubmit}
    >
      {({ values }) => (
        <SimpleGrid columns={1} spacing={6} width={857}>
          <Box textAlign="center">
            <Text fontSize="x-large" px={60}>
              Enter in the S3 prefix you would like Panther to read data from followed by the
              schema(s) that classify data as it comes into Panther. You can also add
              &quot;exclusion&quot; filter(s) which will exclude prefixes from being read.
            </Text>
            {schemasManagementUrl && (
              <Text fontSize="x-large">
                Alternatively you can{' '}
                <Link as={RRLink} to={schemasManagementUrl} onClick={hideModal}>
                  infer schemas from live data.
                </Link>
              </Text>
            )}
          </Box>
          <Box
            position="relative"
            overflowX="auto"
            maxHeight={600}
            px={80}
            pb={values.s3PrefixLogTypes?.length > 1 ? 200 : 80}
          >
            <S3PrefixSchemaFieldsList logTypes={[...availableLogTypes, ...draftSchemaNames]} />
          </Box>
          <Flex justify="center">
            <SubmitButton variantColor="blue-300">Apply Changes</SubmitButton>
          </Flex>
        </SimpleGrid>
      )}
    </Formik>
  );
};

export default SetS3PrefixesModal;

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetAsyncQueryDownloadUrlVariables = {
  input: Types.GetAsyncQueryDownloadUrlInput;
};

export type GetAsyncQueryDownloadUrl = {
  getAsyncQueryDownloadUrl: Pick<Types.GetAsyncQueryDownloadUrlOutput, 'url'> & {
    error?: Types.Maybe<Pick<Types.Error, 'message'>>;
  };
};

export const GetAsyncQueryDownloadUrlDocument = gql`
  query GetAsyncQueryDownloadUrl($input: GetAsyncQueryDownloadUrlInput!) {
    getAsyncQueryDownloadUrl(input: $input) {
      url
      error {
        message
      }
    }
  }
`;

/**
 * __useGetAsyncQueryDownloadUrl__
 *
 * To run a query within a React component, call `useGetAsyncQueryDownloadUrl` and pass it any options that fit your needs.
 * When your component renders, `useGetAsyncQueryDownloadUrl` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAsyncQueryDownloadUrl({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAsyncQueryDownloadUrl(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAsyncQueryDownloadUrl,
    GetAsyncQueryDownloadUrlVariables
  >
) {
  return ApolloReactHooks.useQuery<GetAsyncQueryDownloadUrl, GetAsyncQueryDownloadUrlVariables>(
    GetAsyncQueryDownloadUrlDocument,
    baseOptions
  );
}
export function useGetAsyncQueryDownloadUrlLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAsyncQueryDownloadUrl,
    GetAsyncQueryDownloadUrlVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetAsyncQueryDownloadUrl, GetAsyncQueryDownloadUrlVariables>(
    GetAsyncQueryDownloadUrlDocument,
    baseOptions
  );
}
export type GetAsyncQueryDownloadUrlHookResult = ReturnType<typeof useGetAsyncQueryDownloadUrl>;
export type GetAsyncQueryDownloadUrlLazyQueryHookResult = ReturnType<
  typeof useGetAsyncQueryDownloadUrlLazyQuery
>;
export type GetAsyncQueryDownloadUrlQueryResult = ApolloReactCommon.QueryResult<
  GetAsyncQueryDownloadUrl,
  GetAsyncQueryDownloadUrlVariables
>;
export function mockGetAsyncQueryDownloadUrl({
  data,
  variables,
  errors,
}: {
  data: GetAsyncQueryDownloadUrl;
  variables?: GetAsyncQueryDownloadUrlVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetAsyncQueryDownloadUrlDocument, variables },
    result: { data, errors },
  };
}

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Card, TabList, TabPanel, TabPanels, Tabs } from 'pouncejs';
import { Series, SeriesWithBreakdown } from 'Generated/schema';
import { BorderedTab, BorderTabDivider } from 'Components/BorderedTab';
import NoDataFound from 'Components/NoDataFound';
import DataQueriedTab from './DataQueriedTab';
import DataIngestionTab from './DataIngestionTab';

interface DataVolumeSectionProps {
  bytesIngestedPerSource: Series[];
  bytesQueriedPerSource: SeriesWithBreakdown[];
  totalBytesIngested: number;
  totalBytesQueried: number;
}

const DataVolumeSection: React.FC<DataVolumeSectionProps> = ({
  bytesIngestedPerSource,
  bytesQueriedPerSource,
  totalBytesIngested,
  totalBytesQueried,
}) => {
  return (
    <Card as="section">
      <Tabs>
        <Box position="relative" px={2}>
          <TabList>
            <BorderedTab>Volume of Data Stored</BorderedTab>
            <BorderedTab>Volume of Data Queried</BorderedTab>
          </TabList>
          <BorderTabDivider />
        </Box>
        <Box p={6}>
          <TabPanels>
            <TabPanel lazy>
              {!bytesIngestedPerSource?.length ? (
                <NoDataFound title="No data ingested yet" />
              ) : (
                <DataIngestionTab
                  bytesIngestedPerSource={bytesIngestedPerSource}
                  totalBytesIngested={totalBytesIngested}
                />
              )}
            </TabPanel>
            <TabPanel lazy>
              {!bytesQueriedPerSource?.length ? (
                <NoDataFound title="Nothing queried yet" />
              ) : (
                <DataQueriedTab
                  bytesQueriedPerSource={bytesQueriedPerSource}
                  totalBytesQueried={totalBytesQueried}
                />
              )}
            </TabPanel>
          </TabPanels>
        </Box>
      </Tabs>
    </Card>
  );
};

export default React.memo(DataVolumeSection);

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Button, Card, Flex, Heading, Alert, useSnackbar } from 'pouncejs';
import { Redirect } from 'react-router';
import useRouter from 'Hooks/useRouter';
import useTimer from 'Hooks/useTimer';
import Breadcrumbs from 'Components/Breadcrumbs';
import { useSchemasManagementContext } from 'Pages/Integrations/LogSources/S3/SchemasManagement/SchemasManagementContext';
import { extractErrorMessage } from 'Helpers/utils';
import { JobStatus } from 'Generated/schema';
import urls from 'Source/urls';
import { HoldingTankTestJobDetails } from 'Source/graphql/fragments/HoldingTankTestJobDetails.generated';
import S3PrefixesLogTypesDisplay from './S3PrefixesLogTypesDisplay';
import TestReportOverview from './TestReportOverview';
import { useCancelHoldingTankJob } from '../graphql/cancelHoldingTankJob.generated';

const titleText = {
  [JobStatus.Cancelled]: 'Test stopped - Elapsed Time',
  [JobStatus.Failed]: 'Test failed - Elapsed Time',
  [JobStatus.Succeeded]: 'Test finished - Elapsed Time',
  [JobStatus.Running]: 'Test is in progress for',
};

const TestReport = () => {
  const { schemaTestJob, setSchemaTestJob } = useSchemasManagementContext();
  const isTestRunning = schemaTestJob?.status === JobStatus.Running;

  const { timeElapsed, reset } = useTimer({
    lazy: !isTestRunning,
    from: schemaTestJob?.stats?.elapsedTimeSeconds * 1000,
  });

  const seconds = isTestRunning
    ? Math.floor(timeElapsed / 1000)
    : schemaTestJob?.stats?.elapsedTimeSeconds || 0;
  const minutesDisplay = Math.floor(seconds / 60).toString().padStart(2, '0'); // prettier-ignore
  const secondsDisplay = (seconds % 60).toString().padStart(2, '0');

  React.useEffect(() => {
    reset();
  }, [reset, schemaTestJob?.stats?.elapsedTimeSeconds]);

  const { match, history } = useRouter<{ id: string }>();
  const { pushSnackbar } = useSnackbar();

  const [cancelTask, { loading }] = useCancelHoldingTankJob({
    onError: cancelError => {
      pushSnackbar({
        variant: 'error',
        title: "Couldn't cancel schema testing operation",
        description: extractErrorMessage(cancelError),
      });
    },
    onCompleted: data => {
      setSchemaTestJob(data.cancelHoldingTankJob as HoldingTankTestJobDetails);
    },
  });

  const erroredPrefixes = schemaTestJob?.testResults
    .filter(t => t.errored > 0)
    .map(t => `“${t.prefix === '' ? '*' : t.prefix}”`)
    .join(' and ');

  if (!schemaTestJob) {
    return <Redirect to={urls.integrations.logSources.schemasManagement(match.params.id).home()} />;
  }

  return (
    <Card as="section" width={1}>
      <Flex
        align="center"
        justify="center"
        p={6}
        borderBottom="1px solid"
        borderColor={'navyblue-300'}
        spacing={4}
      >
        <Heading size="x-small" as="h4">
          <Flex>
            {titleText[schemaTestJob.status]}
            <Flex ml={2} as="time" align="center">
              {minutesDisplay}min {secondsDisplay}sec
            </Flex>
          </Flex>
        </Heading>
        {isTestRunning && (
          <Button
            loading={loading}
            disabled={loading}
            onClick={() =>
              cancelTask({
                variables: {
                  id: schemaTestJob.id,
                },
              })
            }
            variantColor="pink-700"
          >
            Stop Test
          </Button>
        )}
      </Flex>
      <Flex direction="column" spacing={4} p={6}>
        {Boolean(erroredPrefixes) && (
          <Alert
            variant="error"
            title={`Panther detected errors in ${erroredPrefixes} when testing data. This could be due to an incorrect stream type, unsupported data format, or access issues.`}
          />
        )}
        <TestReportOverview schemaTestJob={schemaTestJob} />
        <Box>
          {schemaTestJob.testParameters.s3PrefixLogTypes.map((s3PrefixLogTypesElement, i) => {
            return (
              <Box
                backgroundColor="navyblue-500"
                key={s3PrefixLogTypesElement.prefix}
                pb={4}
                mb={4}
              >
                <S3PrefixesLogTypesDisplay {...s3PrefixLogTypesElement} index={i} />
              </Box>
            );
          })}
        </Box>
      </Flex>
      <Breadcrumbs.Actions>
        <Flex spacing={4} justify="flex-end">
          <Button
            variantColor="blue-500"
            onClick={() =>
              history.replace(
                urls.integrations.logSources.schemasManagement(match.params.id).home()
              )
            }
            disabled={isTestRunning}
          >
            Back to Schemas
          </Button>
        </Flex>
      </Breadcrumbs.Actions>
    </Card>
  );
};

export default TestReport;

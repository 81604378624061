/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Card, Grid } from 'pouncejs';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import withAsyncQueryContext from 'Hoc/withAsyncQueryContext';
import { Permission } from 'Generated/schema';
import Page403 from 'Pages/403';
import { compose } from 'Helpers/compose';
import useAsyncQueryContext from 'Hooks/useAsyncQueryContext';
import Portal from 'Components/utils/Portal';
import withSEO from 'Hoc/withSEO';
import Breadcrumbs from 'Components/Breadcrumbs';
import SavedQueriesActions from './SavedQueriesActions';
import SQLEditor from './SQLEditor';
import Browser from './Browser';
import { withDataExplorerContext } from './DataExplorerContext';
import Results from './Results';

const DataExplorerPage: React.FC = () => {
  const {
    state: { queryId },
  } = useAsyncQueryContext();

  return (
    <React.Fragment>
      <Grid gap={4} templateColumns="1fr 3fr">
        <Browser />
        <Card p={6}>
          <SQLEditor />
        </Card>
      </Grid>
      <Portal selector="#secondary-page-content">
        <Box p={6} width="100%">
          {/**
           * Setting `key` to the `queryId` guarantees we unmount
           * any existing results when the query changes.
           */}
          <Results key={queryId} />
        </Box>
      </Portal>
      <Breadcrumbs.Actions>
        <SavedQueriesActions />
      </Breadcrumbs.Actions>
    </React.Fragment>
  );
};

export default compose(
  withSEO({ title: 'Data Explorer' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.DataAnalyticsRead],
    fallback: <Page403 />,
  }),
  withDataExplorerContext,
  withAsyncQueryContext({ storage: 'browserHistory' })
)(DataExplorerPage);

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { BorderedTab, BorderTabDivider } from 'Components/BorderedTab';
import ErrorBoundary from 'Components/ErrorBoundary';
import useAsyncQueryContext from 'Hooks/useAsyncQueryContext';
import usePrevious from 'Hooks/usePrevious';
import { Box, Card, TabList, Tabs } from 'pouncejs';
import React from 'react';
import { IS_SNOWFLAKE } from 'Source/constants';
import ResultsTab from './ResultsTab';
import ColumnSummaryTab from './ColumnSummaryTab';
import { ColumnSummaryProvider, useColumnSummary } from './ColumnSummaryContext';

const tabIndexMap = {
  results: 0,
  summary: 1,
};

/**
 * Displays a table of the results of a data explorer query as well as summaries of
 * the result set.
 */
const Results = () => {
  const [tabIndex, setTabIndex] = React.useState(tabIndexMap.results);
  const {
    state: { queryStatus },
  } = useAsyncQueryContext();
  const { status, meta } = useColumnSummary();

  const summarizeTabLabel = meta?.column ? `Summarize: ${meta.column}` : `Summarize`;

  // When going into a loading state, switch to the summary tab.
  // We want to check against the previous state to avoid hijacking the user's
  // choice if they decided to change the tab during loading. This way we only flip
  // it when `status` flips to loading instead of every time this effect runs.
  const prevStatus = usePrevious(status);
  React.useEffect(() => {
    if (status === 'loading' && prevStatus !== 'loading') {
      setTabIndex(tabIndexMap.summary);
    }
  }, [status, prevStatus]);

  if (IS_SNOWFLAKE) {
    return (
      <Tabs index={tabIndex} onChange={setTabIndex}>
        <Card as="section" position="relative">
          <Box px={2}>
            <TabList>
              <BorderedTab data-tid="data-explorer-results-tabs-results">Results</BorderedTab>
              <BorderedTab
                disabled={queryStatus !== 'succeeded'}
                data-tid="data-explorer-results-tabs-summarize"
              >
                {summarizeTabLabel}
              </BorderedTab>
            </TabList>
          </Box>
          <BorderTabDivider />

          {tabIndex === tabIndexMap.results && <ResultsTab />}
          {tabIndex === tabIndexMap.summary && (
            <ErrorBoundary>
              <ColumnSummaryTab />
            </ErrorBoundary>
          )}
        </Card>
      </Tabs>
    );
  }

  return (
    <Card as="section" position="relative">
      <ResultsTab />
    </Card>
  );
};

const ResultsSection = () => {
  return (
    <ColumnSummaryProvider>
      <Results />
    </ColumnSummaryProvider>
  );
};

export default ResultsSection;

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Alert, Box, Card, Flex } from 'pouncejs';
import { extractErrorMessage } from 'Helpers/utils';
import { DEFAULT_LARGE_PAGE_SIZE } from 'Source/constants';
import { Permission, DataLakeQueriesInput } from 'Generated/schema';
import { compose } from 'Helpers/compose';
import useInfiniteScroll from 'Hooks/useInfiniteScroll';
import TablePlaceholder from 'Components/TablePlaceholder';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import Page403 from 'Pages/403';
import withSEO from 'Hoc/withSEO';
import Panel from 'Components/Panel';
import useRequestParamsWithoutPagination from 'Hooks/useRequestParamsWithoutPagination';
import NoResultsFound from 'Components/NoResultsFound';
import { withSelectContext } from 'Components/utils/SelectContext';
import ErrorBoundary from 'Components/ErrorBoundary';
import QueryHistoryCard from 'Components/cards/QueryHistoryCard';
import QueryHistorySkeleton from './Skeleton';
import QueryHistoryFilters from './QueryHistoryFilters';
import QueryHistoryBreadcrumbFilters from './QueryHistoryBreadcrumbFilters';
import { useListDataLakeQueries } from './graphql/listDataLakeQueries.generated';

const QueryHistory: React.FC = () => {
  const { requestParams } = useRequestParamsWithoutPagination<DataLakeQueriesInput>({
    overrides: { contains: 'string' },
  });
  const { loading, error, previousData, data = previousData, fetchMore } = useListDataLakeQueries({
    // This keeps the data fresh when alternating between running queries and viewing the history.
    fetchPolicy: 'network-only',
    // Paginated requests can trigger an infinite fetching loop so we want to read from the cache
    // for any subsequent requests on this page.
    nextFetchPolicy: 'cache-first',
    variables: {
      input: {
        ...requestParams,
        pageSize: DEFAULT_LARGE_PAGE_SIZE,
      },
    },
  });

  const queries = data?.dataLakeQueries.edges.map(edge => edge.node) ?? [];
  const lastEvaluatedKey = data?.dataLakeQueries.pageInfo.endCursor ?? null;
  const hasNextPage = data?.dataLakeQueries.pageInfo.hasNextPage ?? false;

  const { sentinelRef } = useInfiniteScroll<HTMLDivElement>({
    loading,
    threshold: 500,
    onLoadMore: () => {
      fetchMore({
        variables: {
          input: {
            ...requestParams,
            pageSize: DEFAULT_LARGE_PAGE_SIZE,
            cursor: lastEvaluatedKey,
          },
        },
      });
    },
  });

  if (loading && !data) {
    return <QueryHistorySkeleton />;
  }

  const hasError = Boolean(error);

  return (
    <ErrorBoundary>
      {hasError && (
        <Box mb={6}>
          <Alert
            variant="error"
            title="Couldn't load your Query History items"
            description={
              extractErrorMessage(error) ||
              'There was an error when performing your request, please contact support@runpanther.io'
            }
          />
        </Box>
      )}
      <QueryHistoryBreadcrumbFilters />
      <Panel title="Query History" actions={<QueryHistoryFilters />}>
        <Card as="section" position="relative">
          <Box position="relative">
            <Flex direction="column" spacing={2}>
              {queries.length ? (
                queries.map(query => <QueryHistoryCard key={query.id} query={query} />)
              ) : (
                <Box my={8}>
                  <NoResultsFound title="Your history is empty" />
                </Box>
              )}
            </Flex>
            {hasNextPage && (
              <Box py={8} ref={sentinelRef}>
                <TablePlaceholder rowCount={10} />
              </Box>
            )}
          </Box>
        </Card>
      </Panel>
    </ErrorBoundary>
  );
};

export default compose(
  withSEO({ title: 'Query History' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.DataAnalyticsRead],
    fallback: <Page403 />,
  }),
  withSelectContext(),
  React.memo
)(QueryHistory);

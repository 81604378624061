/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import ReactDOM from 'react-dom';
import { Box, Flex, theme as Theme, ThemeProvider, useTheme } from 'pouncejs';
import dayjs from 'dayjs';
import { remToPx, capitalize } from 'Helpers/utils';
import { Scalars } from 'Generated/schema';
import type { EChartOption, ECharts } from 'echarts';
import mapKeys from 'lodash/mapKeys';
import { SEVERITY_COLOR_MAP } from 'Source/constants';
import { stringToPaleColor } from 'Helpers/colors';
import useMounted from 'Hooks/useMounted';
import ChartTooltip, { ChartTooltipProps, TooltipOptions } from './ChartTooltip';
import useChartOptions from './useChartOptions';
import ResetButton from '../ResetButton';
import ScaleControls from '../ScaleControls';

export type TimeSeries = {
  label: string;
  values: number[];
  color?: keyof typeof Theme['colors'];
};

export type TimeSeriesData = {
  timestamps: string[];
  series: TimeSeries[];
  metadata?: unknown[];
};

export type TimeSeriesPointData = {
  timestamp: string;
  value: number;
  metadata?: unknown;
};

export interface DateRange {
  startDateTimestamp: number;
  endDateTimestamp: number;
}

type DualAxisProps = {
  leftAxisColor: keyof typeof Theme['colors'];
  rightAxisColor: keyof typeof Theme['colors'];
};

interface TimeSeriesChartProps {
  /** The data for the time series */
  data: TimeSeriesData;

  /**
   * Whether the chart will allow zooming
   * @default false
   */
  zoomable?: boolean;

  /**
   * Callback triggered when the zoom is adjusted.
   * Dates are represented in Unix epoch timestamp format
   */
  onZoomChange?: (dateRange: DateRange) => void;

  /**
   * Whether the chart will allow to change scale type
   * @default true
   */
  scaleControls?: boolean;

  /**
   * If defined, the chart will be zoomable and will zoom up to a range specified in `ms` by this
   * value. This range will occupy the entirety of the X-axis (end-to-end).
   * For example, a value of 3600 * 1000 * 24 would allow the chart to zoom until the entirety
   * of the zoomed-in chart shows 1 full day.
   * @default 3600 * 1000 * 24
   */
  maxZoomPeriod?: number;

  /**
   * Whether to render chart as lines or bars
   * @default line
   */
  chartType?: 'line' | 'bar';

  /**
   * Whether to show label for series
   * @default true
   */
  hideSeriesLabels?: boolean;

  /**
   * Whether to hide legend
   * @default false
   */
  hideLegend?: boolean;

  /**
   * This parameter determines if we need to display the values with an appropriate suffix
   */
  units?: string;

  /**
   * This is an optional parameter that will render the text provided above legend if defined
   */
  title?: string;
  /**
   * If specified every timestamp greater than this date will be displayed as a projection.
   * Actual and forecast values are plotted as two data series.
   */
  projectionStartDate?: string;
  /**
   *
   * @default ChartTooltip
   */
  tooltipComponent?: React.FC<ChartTooltipProps>;

  /**
   * Boolean variable for displaying dates on charts, labels and tooltips as UTC
   * @default false
   */
  useUTC?: boolean;

  /**
   * Boolean variable for showing zero values in tooltip
   */
  tooltipOptions?: TooltipOptions;
  /**
   * Chart identifier
   */
  chartId: string;

  /**
   * Callback triggered when clicking on a Chart series Bar or Line Graph
   */
  onSeriesClick?: (data: TimeSeriesPointData) => void;

  /**
   * Multiaxis
   */
  dualAxis?: DualAxisProps;
}

const severityColors = mapKeys(SEVERITY_COLOR_MAP, (val, key) => capitalize(key.toLowerCase()));

function formatDateString(timestamp: Scalars['DateTime'], useUTC: boolean) {
  return `${(useUTC ? dayjs.utc(timestamp) : dayjs(timestamp)).format('HH:mm')}\n${dayjs(timestamp)
    .format('MMM DD')
    .toUpperCase()}`;
}

const TimeSeriesChart: React.FC<TimeSeriesChartProps> = ({
  data,
  zoomable = false,
  scaleControls = true,
  maxZoomPeriod = 3600 * 1000 * 24,
  chartType = 'line',
  hideLegend = false,
  tooltipOptions = {},
  hideSeriesLabels = true,
  units,
  title,
  tooltipComponent = ChartTooltip,
  useUTC = false,
  chartId,
  projectionStartDate,
  onZoomChange,
  onSeriesClick,
  dualAxis,
}) => {
  const [scaleType, setScaleType] = React.useState<EChartOption.BasicComponents.CartesianAxis.Type>(
    'value'
  );
  const theme = useTheme();
  const { getLegend, getYAxis } = useChartOptions();
  const timeSeriesChart = React.useRef<ECharts>(null);
  const container = React.useRef<HTMLDivElement>(null);
  const tooltip = React.useRef<HTMLDivElement>(document.createElement('div'));
  const isMounted = useMounted();
  /*
   * Defining ChartOptions
   */
  const chartOptions = React.useMemo(() => {
    /*
     *  Timestamps & Series are common for all series since everything has the same interval
     *  and the same time frame
     */
    const { series, timestamps, metadata } = data;
    // Sort timestamps in descending order and find the last timestamp that
    // contains actual data by searching the first timestamp that is before the projection
    // start date. Else the first descending timestamp (i.e. the timestamp further in the future)
    // should be used as a safe fallback
    const descendingTimestamps = [...timestamps].sort().reverse();
    const lastValidTimestamp = projectionStartDate
      ? descendingTimestamps.find(t => t <= projectionStartDate)
      : descendingTimestamps[0];

    const timeChartSeries = series.map(s => ({
      ...s,
      values: s.values.map((value, index) => ({
        timestamp: timestamps[index],
        value,
        metadata: metadata ? metadata[index] : null,
      })),
    }));
    /*
     * 'series' must be an array of objects that includes some graph options
     * like 'type', 'symbol' and 'itemStyle' and most importantly 'data' which
     * is an array of values for all datapoints
     * Must be ordered
     */
    const actualSeries = timeChartSeries.map(({ label, values, color }, i) => {
      return {
        // this assumes that there will be only 2 series
        yAxisIndex: dualAxis && (i % 2 === 0 ? 1 : 0),
        name: label,
        type: chartType,
        symbol: 'none',
        smooth: true,
        barMaxWidth: 24,
        itemStyle: {
          color: theme.colors[color || severityColors[label]] || stringToPaleColor(label),
        },
        emphasis: {
          label: {
            show: !hideSeriesLabels,
          },
        },
        label: {
          show: false,
          formatter: ({ value }) => value[1].toLocaleString(),
          position: 'top',
          fontSize: 11,
          fontWeight: 'bold',
          fontFamily: theme.fonts.primary,
          color: '#fff',
        },
        data: values
          .filter(v => v.timestamp <= lastValidTimestamp)
          .map(({ value, timestamp, metadata: meta }) => {
            return {
              name: label,
              value: [timestamp, value === 0 && scaleType === 'log' ? 0.0001 : value, meta],
            };
          }),
      };
    });

    const seriesProjections = projectionStartDate
      ? timeChartSeries.map(({ values, color, label }) => ({
          name: label,
          type: chartType,
          symbol: 'none',
          smooth: true,
          barMaxWidth: 24,
          data: values
            .filter(t =>
              // For line charts we want to connect the last actual value with the projected
              // values in order to maintain a continuous line
              chartType === 'line'
                ? t.timestamp >= lastValidTimestamp
                : t.timestamp > lastValidTimestamp
            )
            .map(({ value, timestamp, metadata: meta }) => {
              return {
                name: label,
                hideFromToolTip: timestamp === lastValidTimestamp,
                value: [timestamp, value === 0 && scaleType === 'log' ? 0.0001 : value, meta],
              };
            }),
          lineStyle: {
            width: 1.3,
            type: 'dashed',
          },
          itemStyle: {
            color: theme.colors[color || severityColors[label]] || stringToPaleColor(label),
            opacity: 0.4,
          },
        }))
      : [];

    const options: EChartOption = {
      useUTC,
      aria: {
        enabled: true,
      },
      grid: {
        left: hideLegend
          ? theme.space[2] // Keeps y-axis labels from getting clipped.
          : 180,
        right: 50,
        bottom: 50,
        containLabel: true,
      },
      ...(zoomable && {
        dataZoom: [
          {
            show: true,
            type: 'slider',
            xAxisIndex: 0,
            minValueSpan: maxZoomPeriod,
            handleIcon: 'path://M 25, 50 a 25,25 0 1,1 50,0 a 25,25 0 1,1 -50,0',
            handleStyle: {
              borderColor: 'transparent',
              color: theme.colors['navyblue-200'],
            },
            backgroundColor: 'transparent',
            handleSize: 12,
            showDetail: true,
            // @ts-ignore - Both moveHandleSize options available after v5 echarts types is available
            moveHandleSize: 4,
            moveHandleStyle: {
              color: theme.colors['navyblue-200'],
            },
            dataBackground: {
              areaStyle: {
                color: theme.colors['navyblue-200'],
              },
              lineStyle: {
                color: theme.colors['navyblue-200'],
              },
            },
            selectedDataBackground: {
              areaStyle: {
                color: theme.colors['navyblue-200'],
              },
              lineStyle: {
                color: theme.colors['navyblue-200'],
              },
            },
            labelFormatter: value => formatDateString(value, useUTC),
            borderColor: theme.colors['navyblue-200'],
            // + 33 is opacity at 40%, what's the best way to do this?
            fillerColor: `${theme.colors['navyblue-200']}4D`,
            textStyle: {
              color: theme.colors['white-200'],
              fontSize: remToPx(theme.fontSizes['x-small']),
            },
          },
        ],
      }),
      tooltip: {
        trigger: 'axis' as const,
        axisPointer: {
          type: chartType === 'line' ? 'line' : 'none',
        },
        borderColor: 'transparent',
        padding: 0,
        position(pos, params, dom, rect, size) {
          const tooltipWidth = dom.getBoundingClientRect().width;
          // mouse position relative to the chart component
          const mouseXPosition = Number(pos[0]);
          const mouseYPosition = Number(pos[1]);
          // sadly size doesn't have typings on echarts sadly, [width,height]
          const { viewSize: chartSize } = size as { viewSize: [number, number] };
          let left: number;
          // if mouse position leaves no space for tooltip to the right render it on left side
          if (Array.isArray(chartSize) && chartSize[0] - mouseXPosition < tooltipWidth) {
            left = mouseXPosition - tooltipWidth - 10;
          } else {
            left = mouseXPosition + 10;
          }
          return { left, top: mouseYPosition - 10 };
        },
        backgroundColor: 'transparent',
        textStyle: {
          color: '#fff',
        },
        formatter: (params: EChartOption.Tooltip.Format[]) => {
          if (!params || !params.length) {
            return '';
          }

          const TooltipComponent = tooltipComponent;
          ReactDOM.render(
            <ThemeProvider>
              <TooltipComponent
                options={tooltipOptions}
                params={params.filter(p => !p.data?.hideFromToolTip)}
                units={units}
              />
            </ThemeProvider>,
            tooltip.current
          );
          return tooltip.current.innerHTML;
        },
      },
      ...(!hideLegend && { legend: getLegend({ series, title }) }),
      xAxis: {
        type: 'time' as const,
        splitLine: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            color: 'transparent',
          },
        },
        axisLabel: {
          showMinLabel: true,
          showMaxLabel: true,
          formatter: value => formatDateString(value, useUTC),
          fontWeight: theme.fontWeights.medium as any,
          fontSize: remToPx(theme.fontSizes['x-small']),
          fontFamily: theme.fonts.primary,
          color: theme.colors['white-200'],
        },
        splitArea: { show: false }, // remove the grid area
      },
      yAxis: dualAxis
        ? [
            getYAxis({ scaleType, units, position: 'right', axisColor: dualAxis.rightAxisColor }),
            getYAxis({ scaleType, units, position: 'left', axisColor: dualAxis.leftAxisColor }),
          ]
        : getYAxis({ scaleType, units }),
      series: [...actualSeries, ...seriesProjections],
    };

    return options;
    // FIXME: look into hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, scaleType]);

  // initialize and load the timeSeriesChart
  React.useEffect(() => {
    (async () => {
      const [echarts] = await Promise.all(
        [
          import(/* webpackChunkName: "echarts" */ 'echarts/lib/echarts'),
          import(/* webpackChunkName: "echarts" */ 'echarts/lib/component/grid'),
          import(/* webpackChunkName: "echarts" */ 'echarts/lib/component/tooltip'),
          // This is needed for reset functionality
          import(/* webpackChunkName: "echarts" */ 'echarts/lib/component/toolbox'),
          import(/* webpackChunkName: "echarts" */ 'echarts/lib/component/legendScroll'),
          import(/* webpackChunkName: "echarts" */ 'echarts/lib/component/aria'),
          chartType === 'line' && import(/* webpackChunkName: "echarts" */ `echarts/lib/chart/line`), // prettier-ignore
          chartType === 'bar' && import(/* webpackChunkName: "echarts" */ `echarts/lib/chart/bar`),
          zoomable && import(/* webpackChunkName: "echarts" */ 'echarts/lib/component/dataZoom'),
        ].filter(Boolean)
      );
      // Stop the initialization process if the component is unmounted i.e. Navigating away from a chart before echart is loaded
      if (!isMounted.current) {
        return;
      }
      const newChart = timeSeriesChart.current ?? echarts.init(container.current);
      /*
       * Overriding default behaviour for legend selection. With this functionality,
       * when user select an specific series, we isolate this series only, subsequent clicks on
       * other series will show them up too. When all series are enabled again this behaviour is reseted
       * We need to disable listeners before enabling them to avoid generating multiple listeners
       */
      newChart.off('legendselectchanged');
      // eslint-disable-next-line func-names
      newChart.on('legendselectchanged', function (obj) {
        const { selected, name } = obj;
        const currentSelected = chartOptions.legend.selected;
        // On first selection currentSelected is 'undefined'
        if (!currentSelected || Object.keys(currentSelected).every(key => currentSelected[key])) {
          chartOptions.legend.selected = Object.keys(selected).reduce((acc, key) => {
            acc[key] = key === name;
            return acc;
          }, {});
          // This checks if everything is going to deselected, if yes we enable all series
        } else if (!Object.keys(selected).some(key => selected[key])) {
          chartOptions.legend.selected = Object.keys(selected).reduce((acc, key) => {
            acc[key] = true;
            return acc;
          }, {});
        } else {
          chartOptions.legend.selected = selected;
        }
        this.setOption(chartOptions);
      });
      newChart.off('datazoom');
      if (zoomable && onZoomChange) {
        newChart.on('datazoom', () => {
          onZoomChange({
            startDateTimestamp: newChart.getOption().dataZoom[0].startValue,
            endDateTimestamp: newChart.getOption().dataZoom[0].endValue,
          });
        });
      }
      newChart.off('click');
      if (onSeriesClick) {
        newChart.on('click', ({ componentType, value: pointValue }) => {
          // Filter only series graph clicks
          if (componentType === 'series') {
            const [timestamp, value, metadata] = pointValue;
            onSeriesClick({
              timestamp,
              value,
              metadata,
            });
          }
        });
      }
      /*
       * Overriding default behaviour for restore functionality. With this functionality,
       * we reset all legend selections, zooms and scaleType. We need to disable listeners
       * before enabling them to avoid generating multiple listeners
       */
      newChart.off('restore');
      // eslint-disable-next-line func-names
      newChart.on('restore', function () {
        // Reset the zoom on chart restoration
        if (zoomable && onZoomChange) {
          onZoomChange({
            startDateTimestamp: newChart.getOption().dataZoom[0].startValue,
            endDateTimestamp: newChart.getOption().dataZoom[0].endValue,
          });
        }
        const options = chartOptions;
        if (options.legend?.selected) {
          options.legend.selected = Object.keys(options.legend.selected).reduce((acc, cur) => {
            acc[cur] = true;
            return acc;
          }, {});
        }
        setScaleType('value');

        this.setOption(options);
      });
      newChart.setOption(chartOptions);
      timeSeriesChart.current = newChart;
    })();
    // FIXME: look into hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartOptions, onZoomChange, onSeriesClick]);

  return (
    <Box position="relative" width="100%" height="100%">
      <Box position="absolute" width="200px" ml={1} fontWeight="bold">
        {title}
      </Box>
      <Box position="absolute" left={0} pl={hideLegend ? '50px' : '210px'} pr="50px" width={1}>
        <Flex align="center" justify="space-between">
          {scaleControls && (
            <ScaleControls chartId={chartId} scaleType={scaleType} onSelect={setScaleType} />
          )}
          <Box zIndex={5} ml="auto">
            <ResetButton
              data-tid={`reset-button-${chartId}`}
              onReset={() => {
                if (timeSeriesChart.current) {
                  timeSeriesChart.current.dispatchAction({ type: 'restore' });
                }
              }}
            />
          </Box>
        </Flex>
      </Box>
      <Box ref={container} width="100%" height="100%" data-testid="time-series-chart" />
    </Box>
  );
};

export default React.memo(TimeSeriesChart);

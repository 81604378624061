/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */
import React from 'react';
import { JobStatus, S3PrefixLogTypes } from 'Generated/schema';
import { AbstractButton, Box, Flex, Text } from 'pouncejs';
import { useSchemasManagementContext } from 'Pages/Integrations/LogSources/S3/SchemasManagement/SchemasManagementContext';
import { useFormikContext } from 'formik';
import { S3PrefixLogTypesFormValues } from 'Pages/Integrations/LogSources/S3/SchemasManagement/SchemasManagement';
import FlatBadge from 'Components/badges/FlatBadge';
import PrefixUnmatchedResults from 'Pages/Integrations/LogSources/S3/SchemasManagement/TestReport/PrefixUnmatchedResults';
import LimitItemDisplay from 'Components/LimitItemDisplay';
import LogTypeCard from './LogTypeCard';

export interface S3PrefixesLogTypesDisplayProps extends S3PrefixLogTypes {
  index: number;
}

const S3PrefixesLogTypesDisplay: React.FC<S3PrefixesLogTypesDisplayProps> = ({
  prefix,
  excludedPrefixes,
  logTypes,
  index,
}) => {
  const [showUnmatched, setShowUnmatched] = React.useState(false);
  const { initialValues, status } = useFormikContext<S3PrefixLogTypesFormValues>();
  const { schemaTestJob } = useSchemasManagementContext();

  const {
    noDataOnPrefix,
    errored,
    unmatched,
    logTypeMatches,
    isTestJobRunning,
  } = React.useMemo(() => {
    if (!schemaTestJob) {
      return { unmatched: null, errored: null, logTypeMatches: [], isTestJobRunning: false };
    }
    // eslint-disable-next-line no-shadow
    const { errored = 0, unmatched = 0, logTypeMatches = [] } =
      schemaTestJob.testResults?.find(result => result.prefix === prefix) ?? {};
    const hasData =
      Boolean(errored) ||
      Boolean(unmatched) ||
      logTypes.some(lg => logTypeMatches?.some(({ logType }) => logType === lg));

    return {
      isTestJobRunning: schemaTestJob.status === JobStatus.Running,
      errored,
      unmatched,
      logTypeMatches,
      noDataOnPrefix: !hasData && schemaTestJob.status === JobStatus.Succeeded,
    };
  }, [schemaTestJob, prefix, logTypes]);

  const existingPrefix = initialValues.s3PrefixLogTypes.find(
    ({ prefix: initialPrefix }) => initialPrefix === prefix
  );

  return (
    <React.Fragment>
      <Flex p={4} px={6} justify="space-between" align="center">
        <Flex spacing={4} align="center">
          <Text as="span" color="gray-300" fontSize="large">
            S3://{status.s3BucketName}/
            <Text color="white" as="span">
              {prefix}
            </Text>
          </Text>
          {Boolean(excludedPrefixes.length) && (
            <>
              <Text fontStyle="italic" fontSize="medium">
                - Exclusions:
              </Text>
              <Flex maxWidth="70%" spacing={2} gap={2} align="center" wrap="wrap">
                <LimitItemDisplay limit={4}>
                  {excludedPrefixes.map(excludedPrefix => {
                    return (
                      <Box
                        my={1}
                        key={excludedPrefix}
                        borderColor="navyblue-300"
                        backgroundColor="navyblue-400"
                        borderWidth={2}
                        borderStyle="solid"
                        px={2}
                        fontSize="small"
                      >
                        S3://{status.s3BucketName}/{excludedPrefix}
                      </Box>
                    );
                  })}
                </LimitItemDisplay>
              </Flex>
            </>
          )}
          {!existingPrefix && (
            <FlatBadge backgroundColor="navyblue-700" color="blue-300">
              NEW
            </FlatBadge>
          )}
        </Flex>
        <Flex spacing={2} mr={4}>
          {noDataOnPrefix ? (
            <Flex textAlign="center" spacing={2} px={2}>
              <Text>No Data found under this prefix</Text>
            </Flex>
          ) : (
            <>
              {Boolean(errored) && (
                <Box backgroundColor="red-300" borderRadius="small">
                  <Flex textAlign="center" spacing={2} px={2}>
                    <Text as={'span'} lineHeight="loose" fontWeight="bold">
                      {errored}
                    </Text>
                    <Text as={'span'} lineHeight="loose">
                      Errors
                    </Text>
                  </Flex>
                </Box>
              )}
              <Flex spacing={2} align="center" fontWeight="bold">
                <Text color={unmatched !== 0 ? 'orange-300' : 'green-400'}>{unmatched}</Text>
                <Text>Unmatched Events</Text>
                <AbstractButton
                  fontSize="small"
                  fontWeight="bold"
                  textDecoration="underline"
                  color="blue-200"
                  _hover={{ color: 'blue-100' }}
                  disabled={isTestJobRunning || unmatched === 0}
                  onClick={() => setShowUnmatched(v => !v)}
                >
                  {showUnmatched ? 'hide' : 'show'}
                </AbstractButton>
              </Flex>
            </>
          )}
        </Flex>
      </Flex>

      <Box>
        {logTypes.length ? (
          <>
            {/* TODO: uncomment for test results */}
            {showUnmatched && <PrefixUnmatchedResults prefix={prefix} />}
            {logTypes.map(logType => {
              return (
                <LogTypeCard
                  prefix={prefix}
                  key={`${index}-${logType}`}
                  logType={logType}
                  matches={logTypeMatches.find(({ logType: lg }) => lg === logType)?.matched ?? 0}
                  isNewPrefix={!existingPrefix}
                />
              );
            })}
          </>
        ) : (
          <Flex backgroundColor="navyblue-400" justify="center" align="center" p={4} mx={6} mb={2}>
            <Text fontStyle="italic" color="gray-400">
              No schemas for prefix
            </Text>
          </Flex>
        )}
      </Box>
    </React.Fragment>
  );
};

export default S3PrefixesLogTypesDisplay;

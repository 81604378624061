/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import useRouter from 'Hooks/useRouter';
import Page404 from 'Pages/404';
import { LogPullerTypeEnum } from 'Generated/schema';
import { LogSourceType, LogTransportMethodsEnum } from 'Source/constants';
import { CreateAsanaLogSource } from '../Asana';
import { CreateBoxLogSource } from '../Box';
import { CreateCloudWatchLogSource } from '../Cloudwatch';
import { CreateCrowdstrikeLogSource } from '../Crowdstrike';
import { CreateDuoLogSource } from '../Duo';
import { CreateEventbridgeSource } from '../Eventbridge';
import { CreateGithubLogSource } from '../Github';
import { CreateGsuiteLogSource } from '../Gsuite';
import { CreateM365LogSource } from '../M365';
import { CreateOktaLogSource } from '../Okta';
import { CreateOnePasswordLogSource } from '../Onepassword';
import { CreateS3LogSource } from '../S3';
import { CreateSalesforceLogSource } from '../Salesforce';
import { CreateSlackLogSource } from '../Slack';
import { CreateSqsLogSource } from '../Sqs';
import { CreateZendeskLogSource } from '../Zendesk';
import { CreateZoomLogSource } from '../Zoom';
import { CreateAtlassianLogSource } from '../Atlassian';
import { CreateGcsLogSource } from '../GoogleCloudStorage';
import { CreateWorkdayLogSource } from '../Workday';
import { CreateDropboxLogSource } from '../Dropbox';
import { CreateSnykLogSource } from '../Snyk';
import { CreateMicrosoftGraphLogSource } from '../MicrosoftGraph';

const createLogSourcePageList: Record<LogSourceType, React.ElementType> = {
  [LogPullerTypeEnum.Asana]: CreateAsanaLogSource,
  [LogPullerTypeEnum.Box]: CreateBoxLogSource,
  [LogTransportMethodsEnum.cloudwatch]: CreateCloudWatchLogSource,
  [LogTransportMethodsEnum.gcs]: CreateGcsLogSource,
  [LogPullerTypeEnum.Crowdstrike]: CreateCrowdstrikeLogSource,
  [LogPullerTypeEnum.Duo]: CreateDuoLogSource,
  [LogTransportMethodsEnum.eventbridge]: CreateEventbridgeSource,
  [LogPullerTypeEnum.Github]: CreateGithubLogSource,
  [LogPullerTypeEnum.Gsuite]: CreateGsuiteLogSource,
  [LogPullerTypeEnum.M365]: CreateM365LogSource,
  [LogPullerTypeEnum.Okta]: CreateOktaLogSource,
  [LogPullerTypeEnum.Onepassword]: CreateOnePasswordLogSource,
  [LogTransportMethodsEnum.s3]: CreateS3LogSource,
  [LogPullerTypeEnum.Salesforce]: CreateSalesforceLogSource,
  [LogPullerTypeEnum.Slack]: CreateSlackLogSource,
  [LogTransportMethodsEnum.sqs]: CreateSqsLogSource,
  [LogPullerTypeEnum.Zendesk]: CreateZendeskLogSource,
  [LogPullerTypeEnum.Zoom]: CreateZoomLogSource,
  [LogPullerTypeEnum.Atlassian]: CreateAtlassianLogSource,
  [LogPullerTypeEnum.Workday]: CreateWorkdayLogSource,
  [LogPullerTypeEnum.Dropbox]: CreateDropboxLogSource,
  [LogPullerTypeEnum.Snyk]: CreateSnykLogSource,
  [LogPullerTypeEnum.Microsoftgraph]: CreateMicrosoftGraphLogSource,
};

const CreateLogSourceMapper = () => {
  const {
    match: {
      params: { type },
    },
  } = useRouter<{ type: LogSourceType }>();

  const LogSourcePages = createLogSourcePageList[type] || Page404;
  return <LogSourcePages />;
};

export default CreateLogSourceMapper;

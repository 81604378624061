/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box } from 'pouncejs';
import useModal from 'Hooks/useModal';
import InferSchemaForm, { NewSchemaFormValues } from './InferSchemaForm';

export interface InferSchemaModalProps {
  initialPrefix: string;
  initialExcludedPrefixes: string[];
  availableLogTypes: string[];
  onSuccess: (values: NewSchemaFormValues) => void;
}

const InferSchemaModal: React.FC<InferSchemaModalProps> = ({
  initialPrefix,
  initialExcludedPrefixes,
  availableLogTypes,
  onSuccess,
}) => {
  const { hideModal } = useModal();

  const onSubmit = React.useCallback(
    (val: NewSchemaFormValues) => {
      onSuccess(val);
      hideModal();
    },
    [hideModal, onSuccess]
  );

  return (
    <Box width={650}>
      <InferSchemaForm
        initialPrefix={initialPrefix}
        initialExcludedPrefixes={initialExcludedPrefixes}
        availableLogTypes={availableLogTypes}
        onSubmit={onSubmit}
      />
    </Box>
  );
};

export default InferSchemaModal;

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import invert from 'lodash/invert';
import { Box, Tabs, TabPanels, TabList, TabPanel, Icon, Text } from 'pouncejs';
import Page403 from 'Pages/403/403';
import { compose } from 'Helpers/compose';
import withSEO from 'Hoc/withSEO';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import useRouter from 'Hooks/useRouter';
import useUrlParams from 'Hooks/useUrlParams';
import { Permission } from 'Generated/schema';
import { OverviewFiltersContextProvider } from 'Components/utils/MetricsFiltersContext';
import { ENABLE_NEW_NAVIGATION } from 'Source/constants';
import urls from 'Source/urls';
import AlertsOverview from './AlertsOverview';
import CloudSecurityOverview from './CloudSecurityOverview';
import DataOverview from './DataOverview';
import OverviewTab from './OverviewTab';

export interface OverviewUrlParams {
  tab?: 'alerts' | 'cloudSecurity' | 'data';
}

const tabToIndex: Record<OverviewUrlParams['tab'], number> = {
  alerts: 0,
  cloudSecurity: 1,
  data: 2,
};

const indexToTab = invert(tabToIndex) as Record<number, OverviewUrlParams['tab']>;

const dashboardIndexMap = {
  [urls.dashboard.home()]: 0,
  [urls.dashboard.health()]: 1,
  [urls.dashboard.data()]: 2,
};

const getActiveIndex = (path: string) => {
  return dashboardIndexMap[path] ?? 0;
};

const indexToRoutes = invert(dashboardIndexMap) as Record<number, string>;

const Overview: React.FC = () => {
  const { history } = useRouter();
  const {
    urlParams: { tab },
    updateUrlParams,
  } = useUrlParams<OverviewUrlParams>();

  return (
    <OverviewFiltersContextProvider>
      <Box mt={6} data-tracking-page="overview">
        <Tabs
          index={
            ENABLE_NEW_NAVIGATION ? getActiveIndex(history.location.pathname) : tabToIndex[tab] || 0
          }
          onChange={index => {
            if (ENABLE_NEW_NAVIGATION) {
              history.replace(indexToRoutes[index]);
            } else {
              updateUrlParams({ tab: indexToTab[index] });
            }
          }}
        >
          <TabList>
            <OverviewTab data-tid="overview-alerts-view">
              <Icon type={ENABLE_NEW_NAVIGATION ? 'dashboard-alt' : 'alert-circle'} />
              <Text as="h4" size="x-small" fontWeight="medium">
                {ENABLE_NEW_NAVIGATION ? 'Overview' : 'Alerts'}
              </Text>
            </OverviewTab>
            <OverviewTab data-tid="overview-cloud-security-view">
              <Icon type={ENABLE_NEW_NAVIGATION ? 'health' : 'cloud-security'} />
              <Text as="h4" size="x-small" fontWeight="medium">
                {ENABLE_NEW_NAVIGATION ? 'Health' : 'Cloud Security'}
              </Text>
            </OverviewTab>
            <OverviewTab data-tid="overview-data-view">
              <Icon type="data" />
              <Text as="h4" size="x-small" fontWeight="medium">
                Data
              </Text>
            </OverviewTab>
          </TabList>
          <Box mt={6}>
            <TabPanels>
              <TabPanel lazy unmountWhenInactive>
                <AlertsOverview />
              </TabPanel>
              <TabPanel lazy unmountWhenInactive>
                <CloudSecurityOverview />
              </TabPanel>
              <TabPanel lazy unmountWhenInactive>
                <DataOverview />
              </TabPanel>
            </TabPanels>
          </Box>
        </Tabs>
      </Box>
    </OverviewFiltersContextProvider>
  );
};

export default compose(
  withSEO({ title: ENABLE_NEW_NAVIGATION ? 'Dashboard' : 'Overview' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.SummaryRead],
    fallback: <Page403 />,
  })
)(Overview);

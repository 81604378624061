/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Form, Formik, FastField } from 'formik';
import FormikAutosave from 'Components/utils/Autosave';
import { Box, Flex } from 'pouncejs';
import { HoldingTankFilters } from 'Generated/schema';
import FormikTextInput from 'Components/fields/TextInput';
import FormikComboBox from 'Components/fields/ComboBox';
import { useSchemasManagementContext } from 'Pages/Integrations/LogSources/S3/SchemasManagement/SchemasManagementContext/SchemasManagementContext';
import {
  schemaGenerationDatePickerItems,
  schemaGenerationDatePickerValues,
} from 'Pages/Integrations/utils';

export type ViewRawDataFiltersKeys = keyof HoldingTankFilters;

type ViewDataFilters = Pick<HoldingTankFilters, 'contains' | 's3Prefix' | 'from' | 'to'> & {
  excludedPrefix: string;
};

const ViewRawDataFilters = () => {
  const { rawDataFilters, setRawDataFilters } = useSchemasManagementContext();
  const initialFilterValues = React.useMemo(
    () => ({
      s3Prefix: rawDataFilters.s3Prefix,
      contains: rawDataFilters.contains,
      from: rawDataFilters.from,
      to: rawDataFilters.to,
      // FIXME: The whole custom handling for excludedPrefixes should be mitigated when multibox components gets updated
      excludedPrefix: rawDataFilters?.excludedPrefixes[0] || '',
    }),
    [rawDataFilters]
  );

  return (
    <Flex spacing={2} width={1}>
      <Box flexGrow={1}>
        <Formik<ViewDataFilters>
          enableReinitialize
          initialValues={initialFilterValues}
          onSubmit={values => {
            setRawDataFilters({
              s3Prefix: values.s3Prefix,
              contains: values.contains,
              from: values.from,
              to: values.to,
              excludedPrefixes: values.excludedPrefix ? [values.excludedPrefix] : [],
            });
          }}
        >
          <Form>
            <FormikAutosave threshold={200} />
            <Flex spacing={4} align="center">
              <Box flexGrow={1}>
                <FastField
                  data-tid="raw-data-contains-filter"
                  name="contains"
                  icon="search"
                  iconAlignment="left"
                  as={FormikTextInput}
                  label="Search"
                  placeholder="Search for text"
                />
              </Box>
              <FastField
                data-tid="raw-data-prefix-filter"
                name="s3Prefix"
                iconAlignment="left"
                as={FormikTextInput}
                label="S3 Prefix"
                placeholder="Filter by prefix"
              />
              <FastField
                data-tid="raw-data-excluded-prefix-filter"
                name="excludedPrefix"
                iconAlignment="left"
                as={FormikTextInput}
                label="Excluded Prefix"
                placeholder="Excluded prefix"
              />
              <Box maxWidth={160}>
                <FastField
                  data-tid="raw-data-date-range-filter"
                  name="from"
                  as={FormikComboBox}
                  items={schemaGenerationDatePickerValues}
                  showClearSelectionControl={false}
                  itemToString={item => schemaGenerationDatePickerItems[item]}
                  label="Time Period"
                />
              </Box>
            </Flex>
          </Form>
        </Formik>
      </Box>
    </Flex>
  );
};

export default React.memo(ViewRawDataFilters);

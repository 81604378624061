/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */
import React from 'react';
import { useFormikContext } from 'formik';
import isEqual from 'lodash/isEqual';
import { Box, Button, Text, Flex, Card, Divider, useSnackbar, Link } from 'pouncejs';
import dayjs from 'dayjs';
import { Link as RRLink } from 'react-router-dom';
import useModal from 'Hooks/useModal';
import SetS3PrefixesModal from 'Components/modals/SetS3PrefixesModal';
import Breadcrumbs from 'Components/Breadcrumbs';
import SubmitButton from 'Components/buttons/SubmitButton';
import useRouter from 'Hooks/useRouter';
import { JobStatus } from 'Generated/schema';
import { appendDataSchemaPrefix, extractErrorMessage, getElapsedTime } from 'Helpers/utils';
import { useSchemasManagementContext } from 'Pages/Integrations/LogSources/S3/SchemasManagement/SchemasManagementContext';
import Panel from 'Components/Panel';
import urls from 'Source/urls';
import { LogTransportMethodsEnum } from 'Source/constants';
import SetS3LogStreamTypeModal from 'Components/modals/SetS3LogStreamTypeModal';
import { S3PrefixLogTypesFormValues } from '../SchemasManagement';
import { s3PrefixLogTypesInitialValues } from '../../S3LogSourceWizard';
import InferJobOverlay from './InferJobOverlay';
import TestSchemasModal from './TestSchemasModal/TestSchemaModal';
import PrefixCard from './PrefixCard';
import { useTestHoldingTankSchemas } from './graphql/testHoldingTankSchemas.generated';

const S3PrefixLogTypesForm: React.FC<{
  loadingSchema: string;
}> = ({ loadingSchema }) => {
  const { showModal } = useModal();
  const { match, history } = useRouter<{ id: string }>();
  const { values, setValues } = useFormikContext<S3PrefixLogTypesFormValues>();
  const { inferSchemaJob, schemaTestJob, setSchemaTestJob } = useSchemasManagementContext();
  const { pushSnackbar } = useSnackbar();

  const [testHoldingTankSchemas, { loading }] = useTestHoldingTankSchemas({
    onCompleted: testData => {
      setSchemaTestJob(testData.testHoldingTankSchemas);
      history.push(
        urls.integrations.logSources
          .schemasManagement(match.params.id)
          .testReport(testData.testHoldingTankSchemas.id)
      );
    },
    onError: testError => {
      setSchemaTestJob(null);
      pushSnackbar({
        variant: 'error',
        title: `Failed to test holding tank data`,
        description: extractErrorMessage(testError),
      });
    },
  });

  const onEdit = React.useCallback(() => {
    showModal(
      <SetS3PrefixesModal
        draftSchemaNames={values.draftSchemas.map(s => appendDataSchemaPrefix(s.name))}
        initialValues={values}
        onSuccess={submittedValues => {
          setValues({ ...values, s3PrefixLogTypes: submittedValues.s3PrefixLogTypes });
        }}
      />,
      {
        title: 'S3 Prefixes & Schemas',
        showCloseButton: true,
      }
    );
  }, [showModal, values, setValues]);

  const isInferJobRunning = inferSchemaJob?.status === JobStatus.Running;

  return (
    <Box position="relative">
      {isInferJobRunning && <InferJobOverlay />}
      <Panel
        title={
          <Text>
            Schemas -{' '}
            <Text as="span" fontWeight="bold" fontStyle="italic">
              Stream Type: {values.logStreamType}
            </Text>
          </Text>
        }
        actions={
          <Flex spacing={2}>
            <Button
              variantColor="navyblue-200"
              onClick={() =>
                showModal(
                  <SetS3LogStreamTypeModal
                    onStreamTypeChange={logStreamType => setValues({ ...values, logStreamType })}
                    streamType={values.logStreamType}
                  />,
                  {
                    showCloseButton: true,
                    title: 'Stream Type',
                  }
                )
              }
            >
              Edit Stream Type
            </Button>
            <Button variantColor="navyblue-200" onClick={onEdit} aria-label="Edit prefixes">
              Edit Schemas
            </Button>
          </Flex>
        }
      >
        {isEqual(values.s3PrefixLogTypes, [s3PrefixLogTypesInitialValues]) ? (
          <Card variant="dark" p={8}>
            <Text fontStyle="italic" fontSize="x-large" color="gray-300" textAlign="center">
              No schemas or prefixes yet - Start by filtering your raw events and inferring a schema
            </Text>
          </Card>
        ) : (
          <Box>
            {values.s3PrefixLogTypes.map((s3PrefixLogTypesElement, i) => {
              return (
                <Box
                  backgroundColor="navyblue-500"
                  key={s3PrefixLogTypesElement.prefix}
                  pb={4}
                  mb={4}
                >
                  <PrefixCard
                    {...s3PrefixLogTypesElement}
                    loadingSchema={loadingSchema}
                    index={i}
                  />
                </Box>
              );
            })}
            <Divider color="navyblue-300" mt={6} mb={4} />
            <Text fontSize="x-large" color="teal-200" fontWeight="bold">
              Test Schemas
            </Text>
            {Boolean(schemaTestJob) && (
              <Flex fontSize="medium">
                <Text mr={1} color="gray-300">
                  Your schema configuration was last tested{' '}
                  {getElapsedTime(Math.floor(new Date(schemaTestJob.completedAt).getTime() / 1000))}
                </Text>
                <Link
                  as={RRLink}
                  to={urls.integrations.logSources
                    .schemasManagement(match.params.id)
                    .testReport(schemaTestJob.id)}
                >
                  View test report
                </Link>
              </Flex>
            )}
            <Flex justify="space-between" mt={2}>
              <Box width={0.8}>
                <Text>
                  Test how your schemas match against your source’s data. You’ll see a live summary
                  while the test is running and a report will be generated at the end, where you can
                  see all the matched and unmatched events per schema and per prefix.
                </Text>
              </Box>
              <Button
                variantColor="yellow-600"
                onClick={() =>
                  showModal(
                    <TestSchemasModal
                      onTestSchemas={from => {
                        testHoldingTankSchemas({
                          variables: {
                            input: {
                              filters: {
                                from,
                                to: dayjs().utc().endOf('day').toISOString(),
                              },
                              sourceId: match.params.id,
                              s3PrefixLogTypes: values.s3PrefixLogTypes,
                              streamType: values.logStreamType,
                              newSchemas: values.draftSchemas.map(draftSchema => ({
                                ...draftSchema,
                                name: appendDataSchemaPrefix(draftSchema.name),
                              })),
                            },
                          },
                        });
                      }}
                    />,
                    {
                      title: (
                        <Text textAlign="left" pl={6}>
                          Test Schemas
                        </Text>
                      ),
                      showCloseButton: true,
                    }
                  )
                }
                loading={loading}
                disabled={loading}
              >
                Run Test
              </Button>
            </Flex>
          </Box>
        )}
        <Breadcrumbs.Actions>
          <Flex spacing={4} justify="flex-end">
            <Button
              variantColor="gray-600"
              icon="close-outline"
              aria-label="Cancel editing"
              onClick={() =>
                history.push(
                  urls.integrations.logSources.details(match.params.id, LogTransportMethodsEnum.s3)
                )
              }
            >
              Cancel
            </Button>
            <SubmitButton variantColor="green-400" icon="save" aria-label="Save editing">
              Save
            </SubmitButton>
          </Flex>
        </Breadcrumbs.Actions>
      </Panel>
    </Box>
  );
};

export default S3PrefixLogTypesForm;

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import useRouter from 'Hooks/useRouter';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import urls from 'Source/urls';
import MitreMatrix from 'Pages/Reports/MitreMatrix';
import MitreMapping from 'Pages/Reports/MitreMapping';

const MitreAttackRoutes: React.FC = () => {
  const { location } = useRouter();

  return (
    <Switch>
      <Redirect
        exact
        from={urls.reports.home()}
        to={{ pathname: urls.build.mitreMatrix(), search: location.search }}
      />
      <Redirect
        exact
        from={urls.reports.mitreMatrix()}
        to={{ pathname: urls.build.mitreMatrix(), search: location.search }}
      />
      <Route exact path={urls.build.mitreMatrix()} component={MitreMatrix} />
      <Redirect
        exact
        from={urls.reports.mitreMapping({})}
        to={{ pathname: urls.build.mitreMapping({}), search: location.search }}
      />
      <Route path={urls.build.mitreMapping({})} component={MitreMapping} />
    </Switch>
  );
};

export default MitreAttackRoutes;

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } &
  { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The internationalized email address.<blockquote><strong>Note:</strong> Up to 64
   * characters are allowed before and 255 characters are allowed after the
   * <code>@</code> sign.
   * However, the generally accepted maximum length for an email address is 254 characters.
   * The pattern verifies that an unquoted <code>@</code> sign exists.</blockquote>
   *
   * minLength: 3
   * maxLength: 254
   * pattern: <code>^.+@[^\"\\-].+$</code>.
   */
  Email: string;
  /** A date in UTC with a format of YYYY-MM-DD hh:mm:ss.000Z. */
  DateTime: string;
  /** An [ISO 8601](https://en.wikipedia.org/wiki/ISO_8601#Times) timestamp in UTC. */
  Timestamp: number;
  /** A JSON map of key/value pairs */
  JSON: Record<string, any>;
  /** An integer that can accept values that are bigger than 2^32 */
  Long: number;
};

/** An Actor is an entity that performs a CRUD operation in Panther */
export type Actor = User | APIToken;

/** The top-level Query type. Queries are used to fetch data. */
export type Query = {
  __typename?: 'Query';
  /** Returns the details of a Panther alert */
  alert: Alert;
  /** Returns a filtered & paginated set of all Panther alerts. */
  alerts: AlertsOutput;
  bulkDownload: BulkDownloadResponse;
  /**
   * Polls the status of the job for importing data into a lookup table, and, if the
   * job is complete, returns the results of the job.
   */
  checkLookupImportDataJob: LookupImportDataJob;
  checkSyncLookupDataJob: LookupSyncJob;
  /** Retrieves the details of a single data lake database */
  dataLakeDatabase: DataLakeDatabase;
  /** Retrieves the details of a table from a single data lake database */
  dataLakeDatabaseTable: DataLakeDatabaseTable;
  /** Lists the details of all available data lake databases */
  dataLakeDatabases: Array<DataLakeDatabase>;
  /** Returns a paginated list of all your previously executed data lake or indicator search queries */
  dataLakeQueries: DataLakeQueriesOutput;
  /** Returns information around previously executed data lake or indicator search query */
  dataLakeQuery: DataLakeQuery;
  dataLakeQuerySummary: SummarizeDataLakeQueryJob;
  destination?: Maybe<Destination>;
  destinations?: Maybe<Array<Maybe<Destination>>>;
  detectionFilter: DetectionFilter;
  detectionFilters: DetectionFiltersOutput;
  detections: ListDetectionsResponse;
  /** Returns Panther general settings */
  generalSettings: GeneralSettingsConfig;
  generateAlertSummaryAttributesQueries: Array<AlertSummaryAttributeForAnalysis>;
  generateEnrichedEvent: GenerateEnrichedEventResponse;
  generateSqlQuerySnippet: GenerateSqlQuerySnippetOutput;
  getAPIToken: GetAPITokenOutput;
  getAnalysisPack: AnalysisPack;
  getAnalysisPackSource: AnalysisPackSource;
  getAsyncQueryDownloadUrl: GetAsyncQueryDownloadUrlOutput;
  /** Returns the information of a particular 3rd-party log service or provider that Panther supports */
  getAvailableLogProvider: GetAvailableLogProviderResponse;
  getCloudWatchLogIntegration: CloudWatchLogIntegration;
  getCloudWatchLogIntegrationTemplate: IntegrationTemplate;
  getComplianceIntegration: ComplianceIntegration;
  getComplianceIntegrationTemplate: IntegrationTemplate;
  getDataModel?: Maybe<DataModel>;
  getEventBridgeLogIntegration: EventBridgeIntegration;
  getGcsLogIntegration: GcsLogSourceIntegration;
  getGlobalHelper: GlobalHelper;
  getLogIntegration: LogIntegration;
  getLogPullingIntegration: LogPullingIntegration;
  getLookup: Lookup;
  getLookupCloudFormationRoleTemplate: IntegrationTemplate;
  /**
   * The return argument is intentionally not required as a running
   * task may not be available
   */
  getRunningTask?: Maybe<Job>;
  getS3LogIntegration: S3LogIntegration;
  getS3LogIntegrationTemplate: IntegrationTemplate;
  getSampleData: GetSampleDataResponse;
  getSavedQuery: SavedQuery;
  getSchema: GetSchemaOutput;
  getSqsLogIntegration: SqsLogSourceIntegration;
  getTerraformTemplate: TerraformTemplateResponse;
  holdingTankJob: Job;
  holdingTankRawData: HoldingTankRawDataResponse;
  holdingTankTestSchemasResult: HoldingTankTestSchemasResultsResponse;
  listAPITokens: ListAPITokensOutput;
  listAnalysisPackSources: ListAnalysisPackSourcesResponse;
  listAnalysisPacks: ListAnalysisPacksResponse;
  /** Lists information around the 3rd-party log services or providers that Panther supports */
  listAvailableLogProviders: ListAvailableLogProvidersResponse;
  /** Lists all the available log types that this Panther installation supports */
  listAvailableLogTypes: ListAvailableLogTypesResponse;
  listComplianceIntegrations: Array<ComplianceIntegration>;
  listDataModels: ListDataModelsResponse;
  listGlobalHelpers: ListGlobalHelpersResponse;
  /** Returns a paginated list of lookup tables. */
  listLookups: ListLookupResponse;
  listSavedQueries: ListSavedQueriesResponse;
  listSchemas: ListSchemasOutput;
  listTestTasks: HoldingTankListTasksResponse;
  logSources: Array<Maybe<LogIntegration>>;
  /** Returns Data & Alert metrics about your Panther installation */
  metrics: MetricsOutput;
  mitreMatrix?: Maybe<MitreMatrix>;
  mitreMatrixTree: MitreMatrixTree;
  organizationStats?: Maybe<OrganizationStatsResponse>;
  policiesForResource?: Maybe<ListComplianceItemsResponse>;
  policy?: Maybe<Policy>;
  replay: Replay;
  replayPreview: ReplayPreview;
  replayedAlert: Alert;
  replayedAlerts: ReplayedAlertsOutput;
  replays: Array<Replay>;
  reportSetting: ReportSetting;
  resource?: Maybe<ResourceDetails>;
  resources?: Maybe<ListResourcesResponse>;
  resourcesForPolicy?: Maybe<ListComplianceItemsResponse>;
  /** Returns a role matching the provided ID */
  roleById: Role;
  /** Returns a role matching the provided case-insensitive name */
  roleByName: Role;
  /** Returns a list of Panther user roles */
  roles: Array<Role>;
  rule?: Maybe<Rule>;
  samlConfig: SamlConfig;
  sendTestAlert: Array<Maybe<AlertDelivery>>;
  sourceMetrics: SourceMetricsResponse;
  /** Returns a single user by Email Address */
  userByEmail: User;
  /** Returns a single user by ID */
  userById: User;
  /** Returns a list of Panther user accounts */
  users: Array<User>;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryAlertArgs = {
  id: Scalars['ID'];
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryAlertsArgs = {
  input: AlertsInput;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryBulkDownloadArgs = {
  input: BulkDownloadInput;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryCheckLookupImportDataJobArgs = {
  jobId: Scalars['ID'];
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryCheckSyncLookupDataJobArgs = {
  id: Scalars['ID'];
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryDataLakeDatabaseArgs = {
  name: Scalars['String'];
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryDataLakeDatabaseTableArgs = {
  input: DataLakeDatabaseTableInput;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryDataLakeQueriesArgs = {
  input?: Maybe<DataLakeQueriesInput>;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryDataLakeQueryArgs = {
  id: Scalars['ID'];
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryDataLakeQuerySummaryArgs = {
  jobId: Scalars['ID'];
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryDestinationArgs = {
  id: Scalars['ID'];
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryDetectionFilterArgs = {
  id: Scalars['ID'];
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryDetectionFiltersArgs = {
  input: DetectionFiltersInput;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryDetectionsArgs = {
  input?: Maybe<ListDetectionsInput>;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryGenerateAlertSummaryAttributesQueriesArgs = {
  input: GenerateAlertSummaryAttributesQueriesInput;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryGenerateEnrichedEventArgs = {
  input: GenerateEnrichedEventInput;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryGenerateSqlQuerySnippetArgs = {
  input: GenerateSqlQuerySnippetInput;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryGetAPITokenArgs = {
  id: Scalars['ID'];
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryGetAnalysisPackArgs = {
  id: Scalars['ID'];
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryGetAnalysisPackSourceArgs = {
  id: Scalars['ID'];
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryGetAsyncQueryDownloadUrlArgs = {
  input: GetAsyncQueryDownloadUrlInput;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryGetAvailableLogProviderArgs = {
  id: Scalars['ID'];
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryGetCloudWatchLogIntegrationArgs = {
  id: Scalars['ID'];
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryGetCloudWatchLogIntegrationTemplateArgs = {
  input: GetCloudWatchLogIntegrationTemplateInput;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryGetComplianceIntegrationArgs = {
  id: Scalars['ID'];
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryGetComplianceIntegrationTemplateArgs = {
  input: GetComplianceIntegrationTemplateInput;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryGetDataModelArgs = {
  id: Scalars['ID'];
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryGetEventBridgeLogIntegrationArgs = {
  id: Scalars['ID'];
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryGetGcsLogIntegrationArgs = {
  id: Scalars['ID'];
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryGetGlobalHelperArgs = {
  input: GetGlobalHelperInput;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryGetLogIntegrationArgs = {
  id: Scalars['ID'];
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryGetLogPullingIntegrationArgs = {
  id: Scalars['ID'];
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryGetLookupArgs = {
  id: Scalars['ID'];
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryGetLookupCloudFormationRoleTemplateArgs = {
  input: GetLookupCloudFormationRoleTemplateInput;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryGetRunningTaskArgs = {
  input: HoldingTankGetRunningTaskInput;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryGetS3LogIntegrationArgs = {
  id: Scalars['ID'];
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryGetS3LogIntegrationTemplateArgs = {
  input: GetS3LogIntegrationTemplateInput;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryGetSampleDataArgs = {
  input: GetSampleDataInput;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryGetSavedQueryArgs = {
  id: Scalars['ID'];
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryGetSchemaArgs = {
  input: GetSchemaInput;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryGetSqsLogIntegrationArgs = {
  id: Scalars['ID'];
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryGetTerraformTemplateArgs = {
  input: GetTerraformTemplateInput;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryHoldingTankJobArgs = {
  id: Scalars['String'];
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryHoldingTankRawDataArgs = {
  input: HoldingTankRawDataInput;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryHoldingTankTestSchemasResultArgs = {
  input?: Maybe<HoldingTankTestSchemasResultInput>;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryListAnalysisPackSourcesArgs = {
  input?: Maybe<ListAnalysisPackSourcesInput>;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryListAnalysisPacksArgs = {
  input?: Maybe<ListAnalysisPacksInput>;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryListAvailableLogProvidersArgs = {
  input?: Maybe<ListAvailableLogProvidersInput>;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryListDataModelsArgs = {
  input: ListDataModelsInput;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryListGlobalHelpersArgs = {
  input: ListGlobalHelpersInput;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryListLookupsArgs = {
  input: ListLookupsInput;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryListSavedQueriesArgs = {
  input: ListSavedQueriesInput;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryListSchemasArgs = {
  input?: Maybe<ListSchemasInput>;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryListTestTasksArgs = {
  sourceId: Scalars['String'];
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryMetricsArgs = {
  input: MetricsInput;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryMitreMatrixArgs = {
  id: Scalars['ID'];
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryOrganizationStatsArgs = {
  input?: Maybe<OrganizationStatsInput>;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryPoliciesForResourceArgs = {
  input?: Maybe<PoliciesForResourceInput>;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryPolicyArgs = {
  input: GetPolicyInput;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryReplayArgs = {
  id: Scalars['ID'];
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryReplayPreviewArgs = {
  endsAt: Scalars['DateTime'];
  startsAt: Scalars['DateTime'];
  logTypes: Array<Scalars['String']>;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryReplayedAlertArgs = {
  alertId: Scalars['ID'];
  replayId: Scalars['ID'];
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryReplayedAlertsArgs = {
  input: ReplayedAlertsInput;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryReplaysArgs = {
  detectionId: Scalars['ID'];
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryReportSettingArgs = {
  tacticId: Scalars['ID'];
  techniqueId: Scalars['ID'];
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryResourceArgs = {
  input: GetResourceInput;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryResourcesArgs = {
  input?: Maybe<ListResourcesInput>;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryResourcesForPolicyArgs = {
  input: ResourcesForPolicyInput;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryRoleByIdArgs = {
  id: Scalars['ID'];
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryRoleByNameArgs = {
  name: Scalars['String'];
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryRolesArgs = {
  input?: Maybe<RolesInput>;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryRuleArgs = {
  input: GetRuleInput;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QuerySendTestAlertArgs = {
  input: SendTestAlertInput;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QuerySourceMetricsArgs = {
  input: SourceMetricsInput;
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryUserByEmailArgs = {
  email: Scalars['String'];
};

/** The top-level Query type. Queries are used to fetch data. */
export type QueryUserByIdArgs = {
  id: Scalars['ID'];
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type Mutation = {
  __typename?: 'Mutation';
  addAnalysisPackSource: AnalysisPackSource;
  addCloudWatchLogIntegration: CloudWatchLogIntegration;
  addComplianceIntegration: ComplianceIntegration;
  addDataModel: DataModel;
  addDestination?: Maybe<Destination>;
  addEventBridgeIntegration: EventBridgeIntegration;
  addGcsLogIntegration: GcsLogSourceIntegration;
  addGlobalHelper: GlobalHelper;
  addLogPullingIntegration: LogPullingIntegration;
  addLookup: Lookup;
  addPolicy?: Maybe<Policy>;
  addRule?: Maybe<Rule>;
  addS3LogIntegration: S3LogIntegration;
  addSavedQuery: SavedQuery;
  addSqsLogIntegration: SqsLogSourceIntegration;
  bulkUpdateDetections?: Maybe<BulkUpdateDetectionsResponse>;
  /** Cancels a data lake or indicator search query that's currently running */
  cancelDataLakeQuery: CancelDataLakeQueryOutput;
  cancelHoldingTankJob: Job;
  computeReplay: ComputeReplayOutput;
  createAPIToken: CreateAPITokenOutput;
  createDetectionFilter: CreateDetectionFilterOutput;
  createReplay: CreateReplayOutput;
  /** Adds a new User role to Panther */
  createRole: CreateRoleOutput;
  deleteAPIToken: DeleteAPITokenOutput;
  deleteAnalysisPackSource?: Maybe<Scalars['Boolean']>;
  deleteComplianceIntegration?: Maybe<Scalars['Boolean']>;
  deleteDataModel?: Maybe<Scalars['Boolean']>;
  deleteDestination?: Maybe<Scalars['Boolean']>;
  deleteDetectionFilter?: Maybe<DeleteDetectionFilterOutput>;
  deleteDetections?: Maybe<Scalars['Boolean']>;
  deleteGlobalHelpers?: Maybe<Scalars['Boolean']>;
  deleteLogIntegration?: Maybe<Scalars['Boolean']>;
  deleteLogIntegrationEventThresholdAlarm?: Maybe<Scalars['Boolean']>;
  deleteLookups?: Maybe<Scalars['Boolean']>;
  /** Deletes a user role */
  deleteRole: DeleteRoleOutput;
  deleteSavedQueries?: Maybe<Scalars['Boolean']>;
  /** Deletes a user */
  deleteUser: DeleteUserOutput;
  deliverAlert: Alert;
  /** Executes an SQL query against a selected data lake database */
  executeDataLakeQuery: ExecuteDataLakeQueryOutput;
  /** Executes an Indicator Search query against Panther's data lake */
  executeIndicatorSearchQuery: ExecuteIndicatorSearchQueryOutput;
  generateLookupImportUrl?: Maybe<LookupS3ImportConfig>;
  generateS3SampleDataUpload: GenerateS3SampleUploadResponse;
  /** Imports data into a lookup table and updates global database views. */
  importLookupData: LookupImportDataJob;
  inferSchema?: Maybe<InferSchemaResponse>;
  inferSchemaFromRawData: Job;
  /** Invites a new user */
  inviteUser: InviteUserOutput;
  putUserSchema: GetSchemaOutput;
  requestLogType?: Maybe<RequestLogTypeResponse>;
  /** Forces a user password reset */
  resetUserPassword: ResetUserPasswordOutput;
  /** Sends user feedback to Panther as an email */
  sendUserFeedback: SendUserFeedbackOutput;
  setLogIntegrationEventThresholdAlarm: EventThresholdAlarm;
  stopReplay: StopReplayOutput;
  summarizeDataLakeQuery: SummarizeDataLakeQueryJob;
  suppressPolicies?: Maybe<Scalars['Boolean']>;
  syncLookupData: LookupSyncJob;
  testHoldingTankSchemas: Job;
  testPolicy: TestPolicyResponse;
  testRule: TestRuleResponse;
  testSchema?: Maybe<TestSchemaResponse>;
  toggleSchema: GetSchemaOutput;
  updateAPIToken: UpdateAPITokenOutput;
  updateAlertStatusByFilter: UpdateAlertStatusByFilterJob;
  /** Updates the status of one or more alerts via a list of IDs */
  updateAlertStatusById: UpdateAlertStatusByIdOutput;
  /** Bulk updates the assignee of an alert via a list of AlertId's and UserId's */
  updateAlertsAssigneeById: UpdateAlertsAssigneeByIdOutput;
  updateAnalysisPack: AnalysisPack;
  updateAnalysisPackSource: AnalysisPackSource;
  updateCloudwatchLogIntegration: CloudWatchLogIntegration;
  updateComplianceIntegration: ComplianceIntegration;
  updateDataModel: DataModel;
  updateDestination?: Maybe<Destination>;
  updateDetectionFilter: UpdateDetectionFilterOutput;
  updateEventbridgeIntegration: EventBridgeIntegration;
  updateGcsLogIntegration: GcsLogSourceIntegration;
  updateGeneralSettings: GeneralSettingsConfig;
  updateGlobalHelper: GlobalHelper;
  updateLogPullingIntegration: LogPullingIntegration;
  /** Updates the metadata for a single lookup table. */
  updateLookup: Lookup;
  updateMitreReportMapping: UpdateMitreReportMappingOutput;
  updateMitreReportSettings: ReportSetting;
  updatePolicy?: Maybe<Policy>;
  /** Updates a role's name or permissions */
  updateRole: UpdateRoleOutput;
  updateRule?: Maybe<Rule>;
  updateS3LogIntegration: S3LogIntegration;
  updateSamlConfig: SamlConfig;
  updateSavedQuery: SavedQuery;
  updateSqsLogIntegration: SqsLogSourceIntegration;
  /** Updates the information for a user */
  updateUser: UpdateUserOutput;
  uploadDetections?: Maybe<UploadDetectionsResponse>;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationAddAnalysisPackSourceArgs = {
  input: AddAnalysisPackSourceInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationAddCloudWatchLogIntegrationArgs = {
  input: AddCloudWatchLogIntegrationInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationAddComplianceIntegrationArgs = {
  input: AddComplianceIntegrationInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationAddDataModelArgs = {
  input: AddOrUpdateDataModelInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationAddDestinationArgs = {
  input: DestinationInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationAddEventBridgeIntegrationArgs = {
  input: AddEventBridgeIntegrationInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationAddGcsLogIntegrationArgs = {
  input: AddGcsLogIntegrationInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationAddGlobalHelperArgs = {
  input: AddGlobalHelperInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationAddLogPullingIntegrationArgs = {
  input: AddLogPullingIntegrationInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationAddLookupArgs = {
  input: AddLookupInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationAddPolicyArgs = {
  input: AddPolicyInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationAddRuleArgs = {
  input: AddRuleInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationAddS3LogIntegrationArgs = {
  input: AddS3LogIntegrationInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationAddSavedQueryArgs = {
  input: AddSavedQueryInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationAddSqsLogIntegrationArgs = {
  input: AddSqsLogIntegrationInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationBulkUpdateDetectionsArgs = {
  input: BulkUpdateDetectionsInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationCancelDataLakeQueryArgs = {
  input: CancelDataLakeQueryInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationCancelHoldingTankJobArgs = {
  id: Scalars['String'];
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationComputeReplayArgs = {
  input: ComputeReplayInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationCreateAPITokenArgs = {
  input: CreateAPITokenInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationCreateDetectionFilterArgs = {
  input: CreateDetectionFilterInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationCreateReplayArgs = {
  input: CreateReplayInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationCreateRoleArgs = {
  input: CreateRoleInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationDeleteAPITokenArgs = {
  id: Scalars['ID'];
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationDeleteAnalysisPackSourceArgs = {
  input: Array<DeleteEntry>;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationDeleteComplianceIntegrationArgs = {
  id: Scalars['ID'];
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationDeleteDataModelArgs = {
  input: DeleteDataModelInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationDeleteDestinationArgs = {
  id: Scalars['ID'];
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationDeleteDetectionFilterArgs = {
  id: Scalars['ID'];
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationDeleteDetectionsArgs = {
  input: DeleteDetectionInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationDeleteGlobalHelpersArgs = {
  input: DeleteGlobalHelpersInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationDeleteLogIntegrationArgs = {
  id: Scalars['ID'];
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationDeleteLogIntegrationEventThresholdAlarmArgs = {
  integrationId: Scalars['ID'];
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationDeleteLookupsArgs = {
  input: DeleteLookupsInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationDeleteRoleArgs = {
  input: DeleteRoleInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationDeleteSavedQueriesArgs = {
  input: DeleteSavedQueryInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationDeliverAlertArgs = {
  input: DeliverAlertInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationExecuteDataLakeQueryArgs = {
  input: ExecuteDataLakeQueryInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationExecuteIndicatorSearchQueryArgs = {
  input: ExecuteIndicatorSearchQueryInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationGenerateLookupImportUrlArgs = {
  input: LookupS3ImportConfigInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationGenerateS3SampleDataUploadArgs = {
  input?: Maybe<GenerateS3SampleUploadInput>;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationImportLookupDataArgs = {
  input: ImportLookupDataInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationInferSchemaArgs = {
  input: InferSchemaInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationInferSchemaFromRawDataArgs = {
  input: HoldingTankInferSchemaInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationInviteUserArgs = {
  input: InviteUserInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationPutUserSchemaArgs = {
  input: PutUserSchemaInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationRequestLogTypeArgs = {
  input: RequestLogTypeInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationResetUserPasswordArgs = {
  input: ResetUserPasswordInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationSendUserFeedbackArgs = {
  input: SendUserFeedbackInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationSetLogIntegrationEventThresholdAlarmArgs = {
  input: EventThresholdAlarmInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationStopReplayArgs = {
  input: StopReplayInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationSummarizeDataLakeQueryArgs = {
  input: SummarizeDataLakeQueryInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationSuppressPoliciesArgs = {
  input: SuppressPoliciesInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationSyncLookupDataArgs = {
  input: SyncLookupDataInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationTestHoldingTankSchemasArgs = {
  input: TestHoldingTankSchemasInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationTestPolicyArgs = {
  input: TestPolicyInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationTestRuleArgs = {
  input: TestRuleInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationTestSchemaArgs = {
  input: TestSchemaInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationToggleSchemaArgs = {
  input: ToggleSchemaInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationUpdateAPITokenArgs = {
  input: UpdateAPITokenInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationUpdateAlertStatusByFilterArgs = {
  input: UpdateAlertStatusByFilterInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationUpdateAlertStatusByIdArgs = {
  input: UpdateAlertStatusByIdInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationUpdateAlertsAssigneeByIdArgs = {
  input: UpdateAlertsAssigneeByIdInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationUpdateAnalysisPackArgs = {
  input: UpdateAnalysisPackInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationUpdateAnalysisPackSourceArgs = {
  input: UpdateAnalysisPackSourceInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationUpdateCloudwatchLogIntegrationArgs = {
  input: UpdateCloudWatchLogIntegrationInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationUpdateComplianceIntegrationArgs = {
  input: UpdateComplianceIntegrationInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationUpdateDataModelArgs = {
  input: AddOrUpdateDataModelInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationUpdateDestinationArgs = {
  input: DestinationInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationUpdateDetectionFilterArgs = {
  input: UpdateDetectionFilterInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationUpdateEventbridgeIntegrationArgs = {
  input: UpdateEventbridgeIntegrationInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationUpdateGcsLogIntegrationArgs = {
  input: UpdateGcsLogIntegrationInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationUpdateGeneralSettingsArgs = {
  input: UpdateGeneralSettingsConfigInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationUpdateGlobalHelperArgs = {
  input: ModifyGlobalHelperInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationUpdateLogPullingIntegrationArgs = {
  input: UpdateLogPullingIntegrationInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationUpdateLookupArgs = {
  input: UpdateLookupInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationUpdateMitreReportMappingArgs = {
  input: UpdateMitreReportMappingInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationUpdateMitreReportSettingsArgs = {
  input: UpdateMitreReportSettings;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationUpdatePolicyArgs = {
  input: UpdatePolicyInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationUpdateRoleArgs = {
  input: UpdateRoleInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationUpdateRuleArgs = {
  input: UpdateRuleInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationUpdateS3LogIntegrationArgs = {
  input: UpdateS3LogIntegrationInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationUpdateSamlConfigArgs = {
  input: UpdateSamlConfigInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationUpdateSavedQueryArgs = {
  input: UpdateSavedQueryInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationUpdateSqsLogIntegrationArgs = {
  input: UpdateSqsLogIntegrationInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

/** The top-level Mutation type. Mutations are used to make requests that create or modify data. */
export type MutationUploadDetectionsArgs = {
  input: UploadDetectionsInput;
};

export type GenerateAlertSummaryAttributesQueriesInput = {
  alertId: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
};

export type GetAlertEventsInput = {
  pageSize?: Maybe<Scalars['Int']>;
  exclusiveStartKey?: Maybe<Scalars['String']>;
};

export type GetRelatedAlertsInput = {
  pageSize?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
};

export type UpdateAlertStatusByFilterInput = {
  filter: AlertsInput;
  status: AlertStatus;
};

/**
 * The available filters of the `alerts` query. You must either specify a particular `detectionId` or a combination of
 * `createdAtAfter` & `createdAtBefore` for the query to be issued successfully.
 */
export type AlertsInput = {
  /** A list of userIds to filter alert assignees by */
  assigneeIds?: Maybe<Array<Scalars['ID']>>;
  /** Returns alerts created after this date */
  createdAtAfter?: Maybe<Scalars['DateTime']>;
  /** Returns alerts created before this date */
  createdAtBefore?: Maybe<Scalars['DateTime']>;
  /** An opaque string used when paginating across multiple pages of results */
  cursor?: Maybe<Scalars['String']>;
  /** A detection (rule, scheduled rule or policy) ID that all returned alerts should originate from */
  detectionId?: Maybe<Scalars['ID']>;
  /** A maximum number of events that the returned alerts must have */
  eventCountMax?: Maybe<Scalars['Int']>;
  /** A minimum number of events that the returned alerts must have */
  eventCountMin?: Maybe<Scalars['Int']>;
  /** A list of log source IDs to filter alerts by */
  logSources?: Maybe<Array<Scalars['String']>>;
  /** A list of log type names (custom or not) to filter alerts by */
  logTypes?: Maybe<Array<Scalars['String']>>;
  /** A string/text to look for in the alert's title */
  nameContains?: Maybe<Scalars['String']>;
  /** The size of each page of results. Defaults to `25` */
  pageSize?: Maybe<Scalars['Int']>;
  /** A list of AWS resource type names to filter alerts by */
  resourceTypes?: Maybe<Array<Scalars['String']>>;
  /** A list of severities to filter alerts by */
  severities?: Maybe<Array<Severity>>;
  /** The field to used for sorting purposes. Currently, `createdAt` is the only option */
  sortBy?: Maybe<AlertsSortFieldsEnum>;
  /** The direction to sort alerts by, based on the value of the `sortBy` filter. Defaults to `descending` */
  sortDir?: Maybe<SortDirEnum>;
  /** A list of statuses to filter alerts by */
  statuses?: Maybe<Array<AlertStatus>>;
  /**
   * Further filter the items returned when `type` is also set.
   *
   * - Valid subtypes when `type: ALERT` is set are `POLICY`, `RULE`, and `SCHEDULED_RULE`.
   * - Valid subtypes when `type: DETECTION_ERROR` is set are `RULE_ERROR` and `SCHEDULED_RULE_ERROR`
   * - There are no subtypes for `type: SYSTEM_ERROR`
   */
  subtypes?: Maybe<Array<AlertType>>;
  /** The type of items that are going to be returned from your query. Defaults to detection alerts. */
  type?: Maybe<AlertListingType>;
  /**
   * @deprecated
   * Deprecated. This field will be removed soon. Please use the `type` field
   */
  types?: Maybe<Array<AlertType>>;
};

export type UpdateAlertStatusByFilterJob = Job & {
  __typename?: 'UpdateAlertStatusByFilterJob';
  id: Scalars['ID'];
  status: JobStatus;
  message?: Maybe<Scalars['String']>;
};

export type AlertEvents = {
  __typename?: 'AlertEvents';
  items: Array<Scalars['JSON']>;
  lastEvaluatedKey?: Maybe<Scalars['String']>;
};

export type AlertOrigin =
  | AlertOriginRule
  | AlertOriginPolicy
  | AlertOriginSystemError
  | AlertOriginReplayedRule;

export type SystemErrorRelatedEntity =
  | S3LogIntegration
  | EventBridgeIntegration
  | LogPullingIntegration
  | SqsLogSourceIntegration
  | CloudWatchLogIntegration
  | GcsLogSourceIntegration
  | Destination;

export type AlertOriginPolicy = {
  __typename?: 'AlertOriginPolicy';
  policyId?: Maybe<Scalars['ID']>;
  resourceId?: Maybe<Scalars['String']>;
  policySourceId: Scalars['String'];
  resourceTypes: Array<Scalars['String']>;
  dedupString: Scalars['String'];
};

export type AlertOriginRule = {
  __typename?: 'AlertOriginRule';
  ruleId?: Maybe<Scalars['ID']>;
  logTypes?: Maybe<Array<Scalars['String']>>;
  scheduledQueries?: Maybe<Array<Scalars['String']>>;
  eventsMatched: Scalars['Int'];
  dedupString: Scalars['String'];
  events?: Maybe<AlertEvents>;
};

export type AlertOriginRuleEventsArgs = {
  input?: Maybe<GetAlertEventsInput>;
};

export type AlertOriginSystemError = {
  __typename?: 'AlertOriginSystemError';
  type: SystemErrorTypeEnum;
  relatedComponent: RelatedComponentEnum;
  relatedEntity?: Maybe<SystemErrorRelatedEntity>;
  events?: Maybe<AlertEvents>;
  alerts: RelatedAlertsOutput;
};

export type AlertOriginSystemErrorEventsArgs = {
  input?: Maybe<GetAlertEventsInput>;
};

export type AlertOriginSystemErrorAlertsArgs = {
  input?: Maybe<GetRelatedAlertsInput>;
};

export type AlertOriginReplayedRule = {
  __typename?: 'AlertOriginReplayedRule';
  ruleId?: Maybe<Scalars['ID']>;
  alertId: Scalars['ID'];
  replayId: Scalars['ID'];
  logTypes?: Maybe<Array<Scalars['String']>>;
  dedupString: Scalars['String'];
  eventsMatched: Scalars['Int'];
  scheduledQueries?: Maybe<Array<Scalars['String']>>;
  events?: Maybe<AlertEvents>;
};

export type AlertOriginReplayedRuleEventsArgs = {
  input?: Maybe<GetAlertEventsInput>;
};

export type AlertSummaryAttributeQueryData = {
  __typename?: 'AlertSummaryAttributeQueryData';
  sql: Scalars['String'];
  id: Scalars['String'];
};

export type AlertSummaryAttributeForAnalysis = {
  __typename?: 'AlertSummaryAttributeForAnalysis';
  logType: Scalars['String'];
  summaryAttribute: Scalars['String'];
  error?: Maybe<Error>;
  query?: Maybe<AlertSummaryAttributeQueryData>;
};

export type DeliverAlertInput = {
  alertId: Scalars['ID'];
  outputIds: Array<Scalars['ID']>;
};

export enum SystemErrorTypeEnum {
  AlertDelivery = 'ALERT_DELIVERY',
  SourceNoData = 'SOURCE_NO_DATA',
  SourcePermissionsChecks = 'SOURCE_PERMISSIONS_CHECKS',
  SourceClassificationFailures = 'SOURCE_CLASSIFICATION_FAILURES',
  SourceLogProcessingErrors = 'SOURCE_LOG_PROCESSING_ERRORS',
  SourceScanningErrors = 'SOURCE_SCANNING_ERRORS',
}

export enum RelatedComponentEnum {
  LogSource = 'LOG_SOURCE',
  Destination = 'DESTINATION',
  CloudAccount = 'CLOUD_ACCOUNT',
  LookupTable = 'LOOKUP_TABLE',
}

export type RelatedAlertsOutput = {
  __typename?: 'RelatedAlertsOutput';
  edges: Array<RelatedAlertsOutputEdge>;
  pageInfo: RelatedAlertsOutputPageInfo;
};

export type RelatedAlertsOutputEdge = {
  __typename?: 'RelatedAlertsOutputEdge';
  node: Alert;
};

export type RelatedAlertsOutputPageInfo = {
  __typename?: 'RelatedAlertsOutputPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export enum AlertActivityHistoryRecordTypes {
  StatusChange = 'STATUS_CHANGE',
  Comment = 'COMMENT',
  AssigneeChange = 'ASSIGNEE_CHANGE',
}

export type AlertActivityHistoryRecordContent =
  | AlertActivityHistoryAssigneeChange
  | AlertActivityHistoryStatusChange;

export type AlertActivityHistoryRecord = {
  __typename?: 'AlertActivityHistoryRecord';
  id: Scalars['ID'];
  type: AlertActivityHistoryRecordTypes;
  createdAt: Scalars['DateTime'];
  triggerBy: Actor;
  content: AlertActivityHistoryRecordContent;
};

export type AlertActivityHistoryAssigneeChange = {
  __typename?: 'AlertActivityHistoryAssigneeChange';
  previousAssignee?: Maybe<User>;
  newAssignee?: Maybe<User>;
};

export type AlertActivityHistoryStatusChange = {
  __typename?: 'AlertActivityHistoryStatusChange';
  previousStatus: AlertStatus;
  newStatus: AlertStatus;
};

export type AlertActivityHistoryOutputEdge = {
  __typename?: 'AlertActivityHistoryOutputEdge';
  node: AlertActivityHistoryRecord;
};

export type AlertActivityHistoryOutputPageInfo = {
  __typename?: 'AlertActivityHistoryOutputPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type AlertActivityHistoryOutput = {
  __typename?: 'AlertActivityHistoryOutput';
  edges: Array<AlertActivityHistoryOutputEdge>;
  pageInfo: AlertActivityHistoryOutputPageInfo;
};

/** The metadata around an Alert */
export type Alert = {
  __typename?: 'Alert';
  activityHistory: AlertActivityHistoryOutput;
  /** The User assigned to the Alert.  If null, the alert is "unassigned" */
  assignee?: Maybe<User>;
  /** Date and time when the Alert got created */
  createdAt: Scalars['DateTime'];
  /** The metadata around the alert's delivery attempts */
  deliveries: Array<Maybe<AlertDelivery>>;
  deliveryOverflow: Scalars['Boolean'];
  /** The type of this Alert, as extracted from its origin */
  description: Scalars['String'];
  /** The unique identifier of this Alert */
  id: Scalars['ID'];
  /** Date and time that the last event related to this Alert was received */
  lastReceivedEventAt: Scalars['DateTime'];
  origin: AlertOrigin;
  /** The reference for this Alert, as extracted from its origin */
  reference: Scalars['String'];
  /** The runbook for this Alert, as extracted from its origin */
  runbook: Scalars['String'];
  /** The severity of this Alert */
  severity: Severity;
  /** The status of this Alert */
  status: AlertStatus;
  /** The title of this Alert */
  title: Scalars['String'];
  /** The type of this Alert */
  type: AlertType;
  /** Date and time when the Alert got last updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The Actor that last updated the state of this alert */
  updatedBy?: Maybe<Actor>;
};

/** The payload to the `updateAlertStatusById` mutation */
export type UpdateAlertStatusByIdInput = {
  /** A list of alert IDs to update the status for */
  ids: Array<Scalars['ID']>;
  /** The new/updated status that those alerts should have */
  status: AlertStatus;
};

/** The response of the updateAlertStatusById mutation */
export type UpdateAlertStatusByIdOutput = {
  __typename?: 'UpdateAlertStatusByIdOutput';
  alerts: Array<Alert>;
};

/** The payload to the `updateAlertsAssigneeById` mutation. */
export type UpdateAlertsAssigneeByIdInput = {
  /** If `assigneeId` is null, the alert is considered `Unassigned`. */
  assigneeId?: Maybe<Scalars['ID']>;
  /** A list of AlertIds that we are updating. */
  ids: Array<Scalars['ID']>;
};

/** The response of the updateAlertsAssigneeById mutation */
export type UpdateAlertsAssigneeByIdOutput = {
  __typename?: 'UpdateAlertsAssigneeByIdOutput';
  alerts: Array<Alert>;
  assignee?: Maybe<Scalars['ID']>;
};

/** The different status that an Alert can have */
export enum AlertStatus {
  /** The alert has been flagged as a false-positive. This is shown as Invalid in the Panther UI. */
  Closed = 'CLOSED',
  /** The alert is still open & pending triage */
  Open = 'OPEN',
  /** The alert has been resolved along with the issue that caused it */
  Resolved = 'RESOLVED',
  /** The alert has been successfully triaged, but it hasn't yet been closed or resolved */
  Triaged = 'TRIAGED',
}

/** The response of the `alerts` query */
export type AlertsOutput = {
  __typename?: 'AlertsOutput';
  /** A list of paginated alert edges */
  edges: Array<AlertsOutputEdge>;
  /** Metadata around this page of results */
  pageInfo: AlertsOutputPageInfo;
};

/** The edge shape of the `AlertsOutput` type */
export type AlertsOutputEdge = {
  __typename?: 'AlertsOutputEdge';
  /** An alert node */
  node: Alert;
};

/** The metadata around a page of results for the `alerts` query */
export type AlertsOutputPageInfo = {
  __typename?: 'AlertsOutputPageInfo';
  /** The cursor that the next page of results should start from or `null` if this is the last page */
  endCursor?: Maybe<Scalars['String']>;
  /** A boolean indicating whether more results exist */
  hasNextPage: Scalars['Boolean'];
  /** A boolean indicating whether this is the first page of results or not */
  hasPreviousPage: Scalars['Boolean'];
  /** The cursor that this page of results started from or `null` if this is the first page */
  startCursor?: Maybe<Scalars['String']>;
};

/** The different type of alerts that Panther Supports */
export enum AlertType {
  /** Alert that got created due to a Policy failure */
  Policy = 'POLICY',
  /** Alert that got created due to a Rule match */
  Rule = 'RULE',
  /** Alert that got created due to an error in the python code of the associated Rule */
  RuleError = 'RULE_ERROR',
  /** Alert that got created due to a Scheduled Rule match */
  ScheduledRule = 'SCHEDULED_RULE',
  /** Alert that got created due to an error in the python code of the associated Scheduled Rule */
  ScheduledRuleError = 'SCHEDULED_RULE_ERROR',
  /** Alert that got created by Panther due to an issue with the data ingestion process */
  SystemError = 'SYSTEM_ERROR',
}

/** The type of items that a list of alerts can be filtered by */
export enum AlertListingType {
  /** Alerts that got created as a result of a detection match from Rules, Policies & Scheduled Rules */
  Alert = 'ALERT',
  /** Alerts that got created as a result of a detection error. Includes errors from Rules, Policies & Scheduled Rules */
  DetectionError = 'DETECTION_ERROR',
  /** Alerts that got created as a result of a Panther system error */
  SystemError = 'SYSTEM_ERROR',
}

/** The metadata around a single Alert delivery attempt */
export type AlertDelivery = {
  __typename?: 'AlertDelivery';
  dispatchedAt: Scalars['DateTime'];
  message: Scalars['String'];
  outputId: Scalars['ID'];
  statusCode: Scalars['Int'];
  success: Scalars['Boolean'];
};

/** The available permissions that an API Token or a Role (and thus a User) can hold */
export enum Permission {
  AlertModify = 'AlertModify',
  AlertRead = 'AlertRead',
  BulkUpload = 'BulkUpload',
  CloudsecSourceModify = 'CloudsecSourceModify',
  CloudsecSourceRead = 'CloudsecSourceRead',
  DataAnalyticsModify = 'DataAnalyticsModify',
  DataAnalyticsRead = 'DataAnalyticsRead',
  DestinationModify = 'DestinationModify',
  DestinationRead = 'DestinationRead',
  GeneralSettingsModify = 'GeneralSettingsModify',
  GeneralSettingsRead = 'GeneralSettingsRead',
  LogSourceModify = 'LogSourceModify',
  LogSourceRawDataRead = 'LogSourceRawDataRead',
  LogSourceRead = 'LogSourceRead',
  LookupModify = 'LookupModify',
  LookupRead = 'LookupRead',
  OrganizationApiTokenModify = 'OrganizationAPITokenModify',
  OrganizationApiTokenRead = 'OrganizationAPITokenRead',
  PolicyModify = 'PolicyModify',
  PolicyRead = 'PolicyRead',
  ResourceModify = 'ResourceModify',
  ResourceRead = 'ResourceRead',
  RuleModify = 'RuleModify',
  RuleRead = 'RuleRead',
  SummaryRead = 'SummaryRead',
  UserModify = 'UserModify',
  UserRead = 'UserRead',
}

/** The supported sorting field when listing alerts */
export enum AlertsSortFieldsEnum {
  CreatedAt = 'createdAt',
}

export type CreateAPITokenInput = {
  name: Scalars['String'];
  permissions: Array<Permission>;
};

export type UpdateAPITokenInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Permission>>;
};

/** The metadata of an API token that can act upon Panther resources. */
export type APIToken = {
  __typename?: 'APIToken';
  /** Date and time when the API token got created */
  createdAt: Scalars['DateTime'];
  /** The physical User that created this API token */
  createdBy: User;
  /** Date and time when the API token will expire. Will be `null` if the token is non-expiring. */
  expiresAt?: Maybe<Scalars['DateTime']>;
  /** The unique identifier of this token */
  id: Scalars['ID'];
  /** The name given to this token during its creation */
  name: Scalars['String'];
  /** The set of permissions associated with this token */
  permissions: Array<Permission>;
  /** Date and time when the API token last got updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The physical User that last updated this API token */
  updatedBy?: Maybe<User>;
  value?: Maybe<Scalars['String']>;
};

export type CreateAPITokenOutput = {
  __typename?: 'CreateAPITokenOutput';
  token?: Maybe<APIToken>;
  error?: Maybe<Error>;
};

export type GetAPITokenOutput = {
  __typename?: 'GetAPITokenOutput';
  token?: Maybe<APIToken>;
  error?: Maybe<Error>;
};

export type UpdateAPITokenOutput = {
  __typename?: 'UpdateAPITokenOutput';
  token?: Maybe<APIToken>;
  error?: Maybe<Error>;
};

export type DeleteAPITokenOutput = {
  __typename?: 'DeleteAPITokenOutput';
  token?: Maybe<APIToken>;
  error?: Maybe<Error>;
};

export type ListAPITokensOutput = {
  __typename?: 'ListAPITokensOutput';
  tokens: Array<APIToken>;
  error?: Maybe<Error>;
};

export type DeleteEntry = {
  id: Scalars['ID'];
};

export type Error = {
  __typename?: 'Error';
  code?: Maybe<ErrorCodeEnum>;
  message: Scalars['String'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  paginationToken?: Maybe<Scalars['ID']>;
};

export type PagingData = {
  __typename?: 'PagingData';
  thisPage?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
  totalItems?: Maybe<Scalars['Int']>;
};

/** Different execution statuses on an async job */
export enum JobStatus {
  /** The job has been started, and is currently running. */
  Running = 'Running',
  /** The job finished successfully. */
  Succeeded = 'Succeeded',
  /** The job failed to finish successfully. */
  Failed = 'Failed',
  /** The job was manually cancelled before it was able to finish. */
  Cancelled = 'Cancelled',
}

/** An async operation that can be polled for status updates */
export type Job = {
  id: Scalars['ID'];
  status: JobStatus;
  message?: Maybe<Scalars['String']>;
};

export enum ErrorCodeEnum {
  AlreadyExists = 'AlreadyExists',
  InUse = 'InUse',
  InvalidInput = 'InvalidInput',
  InvalidLogSchema = 'InvalidLogSchema',
  InvalidSyntax = 'InvalidSyntax',
  InvalidUpdate = 'InvalidUpdate',
  NotFound = 'NotFound',
  RevisionConflict = 'RevisionConflict',
  ServerError = 'ServerError',
}

/** The available severity levels that can be associated with a detection or an alert */
export enum Severity {
  Info = 'INFO',
  Low = 'LOW',
  Medium = 'MEDIUM',
  High = 'HIGH',
  Critical = 'CRITICAL',
}

/** The available sorting direction on a listing operation */
export enum SortDirEnum {
  Ascending = 'ascending',
  Descending = 'descending',
}

export type DataModelMappingInput = {
  name: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
};

export type AddOrUpdateDataModelInput = {
  displayName: Scalars['String'];
  id: Scalars['ID'];
  enabled: Scalars['Boolean'];
  logTypes: Array<Scalars['String']>;
  mappings: Array<DataModelMappingInput>;
  body?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  managed?: Maybe<Scalars['Boolean']>;
};

export type ListDataModelsInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  nameContains?: Maybe<Scalars['String']>;
  logTypes?: Maybe<Array<Scalars['String']>>;
  sortBy?: Maybe<ListDataModelsSortFieldsEnum>;
  sortDir?: Maybe<SortDirEnum>;
  packIds?: Maybe<Array<Scalars['String']>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type DeleteDataModelInput = {
  dataModels: Array<DeleteEntry>;
};

export type DataModelMapping = {
  __typename?: 'DataModelMapping';
  name: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
};

export type DataModel = {
  __typename?: 'DataModel';
  displayName: Scalars['String'];
  id: Scalars['ID'];
  enabled: Scalars['Boolean'];
  logTypes: Array<Scalars['String']>;
  mappings: Array<DataModelMapping>;
  body?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  lastModified: Scalars['DateTime'];
  parentId?: Maybe<Scalars['String']>;
  managed?: Maybe<Scalars['Boolean']>;
};

export type ListDataModelsResponse = {
  __typename?: 'ListDataModelsResponse';
  models: Array<DataModel>;
  paging: PagingData;
};

export enum ListDataModelsSortFieldsEnum {
  Enabled = 'enabled',
  Id = 'id',
  LastModified = 'lastModified',
  LogTypes = 'logTypes',
}

export type SummarizeDataLakeQueryInput = {
  /** The ID of the query to summarize */
  id: Scalars['ID'];
  /** The column to summarize results by. */
  column: Scalars['String'];
  /** The direction to sort the summarized results. */
  direction: DataLakeQuerySummaryDirection;
};

export type GenerateSqlQuerySnippetInput = {
  queryType: Scalars['String'];
  queryParameters: Scalars['JSON'];
};

export type GetAsyncQueryDownloadUrlInput = {
  id: Scalars['ID'];
};

/** The optional input filters to the `dataLakeQueries` query */
export type DataLakeQueriesInput = {
  /** Filter queries by their name and/or SQL statement */
  contains?: Maybe<Scalars['String']>;
  /** An opaque string used when paginating across multiple pages of results */
  cursor?: Maybe<Scalars['String']>;
  /** Only return queries that are either scheduled or not (i.e. issued by a user). Leave blank to return both. */
  isScheduled?: Maybe<Scalars['Boolean']>;
  /** Only return queries issued by those user IDs */
  issuedBy?: Maybe<Array<Scalars['ID']>>;
  kind?: Maybe<Array<Scalars['String']>>;
  /** The number of results that each page will contain. Defaults to 25 with a maximum value of 999. */
  pageSize?: Maybe<Scalars['Int']>;
  /** Field used for sorting purposes. Currently, `startedAt` is the only option */
  sortBy?: Maybe<DataLakeQueriesSortFields>;
  /** The direction to sort results by */
  sortDir?: Maybe<SortDirEnum>;
  /** Only return queries that started after this fdate */
  startedAtAfter?: Maybe<Scalars['DateTime']>;
  /** Only return queries that started after this fdate */
  startedAtBefore?: Maybe<Scalars['DateTime']>;
  /** A list of query statuses to filter queries by */
  status?: Maybe<Array<DataLakeQueryStatus>>;
};

/** The input to a data lake query execution */
export type ExecuteDataLakeQueryInput = {
  /**
   * The name of the database that the query should be executed against. This is optional you prefix all tables with
   * their corresponding database names in your SQL queries. Defaults to `panther_logs`
   */
  databaseName?: Maybe<Scalars['String']>;
  queryName?: Maybe<Scalars['String']>;
  /** The SQL code of the data lake query */
  sql: Scalars['String'];
};

/** A table within a Panther data lake database */
export type DataLakeDatabaseTable = {
  __typename?: 'DataLakeDatabaseTable';
  /** The column that this table has */
  columns: Array<DataLakeDatabaseTableColumn>;
  databaseName: Scalars['String'];
  /** A description for this table */
  description?: Maybe<Scalars['String']>;
  /** The name of the table */
  name: Scalars['String'];
};

/** The metadata around a data lake or indicator search query */
export type DataLakeQuery = {
  __typename?: 'DataLakeQuery';
  /** The datetime at which the query completed or errored. This value is empty if the query is still running */
  completedAt?: Maybe<Scalars['DateTime']>;
  /** The ID of this query */
  id: Scalars['ID'];
  /** A boolean denoting whether this was a scheduled query or not */
  isScheduled: Scalars['Boolean'];
  /** The entity that issued this query */
  issuedBy?: Maybe<Actor>;
  kind: Scalars['String'];
  /** A message that describes the current status of this query */
  message: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
  /** The name of this query or empty if it's an ad-hoc query */
  name?: Maybe<Scalars['String']>;
  /** The results this query yielded. This field is `null` if the query hasn't successfully completed */
  results?: Maybe<DataLakeQueryResults>;
  /** The SQL statement that was ran */
  sql: Scalars['String'];
  /** The datetime at which the query started executing */
  startedAt: Scalars['DateTime'];
  /** The current status of this query */
  status: DataLakeQueryStatus;
};

/** The metadata around a data lake or indicator search query */
export type DataLakeQueryResultsArgs = {
  input?: Maybe<DataLakeQueryResultsInput>;
};

export type GetAsyncQueryDownloadUrlOutput = {
  __typename?: 'GetAsyncQueryDownloadUrlOutput';
  error?: Maybe<Error>;
  url?: Maybe<Scalars['String']>;
};

export type GenerateSqlQuerySnippetOutput = {
  __typename?: 'GenerateSqlQuerySnippetOutput';
  sqlQuery: Scalars['String'];
};

export type SummarizeDataLakeQueryJob = Job & {
  __typename?: 'SummarizeDataLakeQueryJob';
  id: Scalars['ID'];
  status: JobStatus;
  message?: Maybe<Scalars['String']>;
  columnInfo?: Maybe<DataLakeQueryResultsColumnInfo>;
  rows?: Maybe<Array<Scalars['JSON']>>;
};

export enum DataLakeQuerySummaryDirection {
  Asc = 'asc',
  Desc = 'desc',
}

/** The input to the indicator search query */
export type ExecuteIndicatorSearchQueryInput = {
  /** The end of the indicator search period */
  endTime: Scalars['DateTime'];
  /** The type of the indicator to search for */
  indicatorName?: Maybe<IndicatorType>;
  /** An array of indicators to search for */
  indicators: Array<Scalars['String']>;
  /** The start of the indicator search period */
  startTime: Scalars['DateTime'];
};

/** The input to a data lake query cancellation operation */
export type CancelDataLakeQueryInput = {
  /** The ID of the query to cancel */
  id: Scalars['ID'];
};

/** The input used to describe a data lake table */
export type DataLakeDatabaseTableInput = {
  /** The name of the database that the table belongs in */
  databaseName: Scalars['String'];
  /** The name of the table */
  tableName: Scalars['String'];
};

/** The input to the `results` field of the `DataLakeQuery` type */
export type DataLakeQueryResultsInput = {
  /** The pagination cursor used to fetch more pages. `null` if you're fetching the first page of results */
  cursor?: Maybe<Scalars['String']>;
  /** The number of results that each page will contain. Defaults to the maximum value of 999. */
  pageSize?: Maybe<Scalars['Int']>;
};

/** The return value of a `dataLakeQueries` query */
export type DataLakeQueriesOutput = {
  __typename?: 'DataLakeQueriesOutput';
  /** A list of paginated query edges */
  edges: Array<DataLakeQueryEdge>;
  /** Metadata around this page of results */
  pageInfo: DataLakeQueriesOutputPageInfo;
};

/** The edge shape of the `DataLakeQueryEdge` type */
export type DataLakeQueryEdge = {
  __typename?: 'DataLakeQueryEdge';
  /** A data lake query node */
  node: DataLakeQuery;
};

/** The metadata around a page of results for the `dataLakeQueries` query */
export type DataLakeQueriesOutputPageInfo = {
  __typename?: 'DataLakeQueriesOutputPageInfo';
  /** The cursor that the next page of results should start from or `null` if this is the last page */
  endCursor?: Maybe<Scalars['String']>;
  /** A boolean indicating whether more results exist */
  hasNextPage: Scalars['Boolean'];
  /** A boolean indicating whether this is the first page of results or not */
  hasPreviousPage: Scalars['Boolean'];
  /** The cursor that this page of results started from or `null` if this is the first page */
  startCursor?: Maybe<Scalars['String']>;
};

/** The shape of a data lake query results */
export type DataLakeQueryResults = {
  __typename?: 'DataLakeQueryResults';
  /** Metadata around the data lake columns returned in this page of results */
  columnInfo: DataLakeQueryResultsColumnInfo;
  /** A list of paginated data lake result edges */
  edges: Array<DataLakeQueryResultEdge>;
  /** Metadata around this page of results */
  pageInfo: DataLakeQueryResultRecordsPageInfo;
  /** Stats around the data lake or indicator search query results */
  stats: DataLakeQueryStats;
};

/** Metadata related to the underlying database columns returned as part of a data lake or indicator search query */
export type DataLakeQueryResultsColumnInfo = {
  __typename?: 'DataLakeQueryResultsColumnInfo';
  /** The ordered name of the columns as returned by the underlying database */
  order: Array<Scalars['String']>;
  /**
   * A mapping of column name to column type. The type of each column is directly related to the underlying database
   * and varies from one backend to another (e.g. Athena, Snowflake, etc.)
   */
  types: Scalars['JSON'];
};

/** Data lake query execution stats */
export type DataLakeQueryStats = {
  __typename?: 'DataLakeQueryStats';
  /** The amount of data scanned (expressed in bytes) during this data lake or indicator query execution */
  bytesScanned: Scalars['Float'];
  /** The amount of time (expressed in milliseconds) that the data lake or indicator search query ran for */
  executionTime: Scalars['Float'];
};

/** The edge shape of the `DataLakeQueryResultRecords` type */
export type DataLakeQueryResultEdge = {
  __typename?: 'DataLakeQueryResultEdge';
  /** A data lake record object */
  node: Scalars['JSON'];
};

/** Pagination metadata for the records returned by a data lake or indicator search query */
export type DataLakeQueryResultRecordsPageInfo = {
  __typename?: 'DataLakeQueryResultRecordsPageInfo';
  /** The cursor that the next page of results should start from or `null` if this is the last page */
  endCursor?: Maybe<Scalars['String']>;
  /** A boolean indicating whether more results exist */
  hasNextPage: Scalars['Boolean'];
  /** A boolean indicating whether this is the first page of results or not */
  hasPreviousPage: Scalars['Boolean'];
  /** The cursor that this page of results started from or `null` if this is the first page */
  startCursor?: Maybe<Scalars['String']>;
};

/** The return value of a data lake or indicator search query cancellation request */
export type CancelDataLakeQueryOutput = {
  __typename?: 'CancelDataLakeQueryOutput';
  /** The ID of the query that just got cancelled */
  id: Scalars['ID'];
};

/** The return value of a data lake query execution request */
export type ExecuteDataLakeQueryOutput = {
  __typename?: 'ExecuteDataLakeQueryOutput';
  /** The ID of the data lake sql query that started executing */
  id: Scalars['ID'];
};

/** The return value of a data lake indicator search query execution request */
export type ExecuteIndicatorSearchQueryOutput = {
  __typename?: 'ExecuteIndicatorSearchQueryOutput';
  /** The ID of the indicator search query that started executing */
  id: Scalars['ID'];
};

/** A Panther data lake database */
export type DataLakeDatabase = {
  __typename?: 'DataLakeDatabase';
  /** A description for this database */
  description?: Maybe<Scalars['String']>;
  /** The name of this database */
  name: Scalars['String'];
  /** The tables that this database has */
  tables: Array<DataLakeDatabaseTable>;
};

/** The column of a Panther data lake table */
export type DataLakeDatabaseTableColumn = {
  __typename?: 'DataLakeDatabaseTableColumn';
  /** A description for this column */
  description?: Maybe<Scalars['String']>;
  /** The name of the column */
  name: Scalars['String'];
  /** The column's type. Exact values depend on your choice of data lake. */
  type?: Maybe<Scalars['String']>;
};

/** The available statuses for a data lake or indicator search query */
export enum DataLakeQueryStatus {
  /** The status when the query was cancelled by the user */
  Cancelled = 'cancelled',
  /** The status when the query has errored out */
  Failed = 'failed',
  /** The status when the query is running */
  Running = 'running',
  /** The status when the query has successfully completed */
  Succeeded = 'succeeded',
}

/** The available sort values for sorting a paginated list of data lake queries */
export enum DataLakeQueriesSortFields {
  StartedAt = 'startedAt',
}

export type SendTestAlertInput = {
  outputIds: Array<Scalars['ID']>;
};

export type Destination = {
  __typename?: 'Destination';
  createdBy?: Maybe<Actor>;
  creationTime: Scalars['DateTime'];
  displayName: Scalars['String'];
  lastModifiedBy?: Maybe<Actor>;
  lastModifiedTime: Scalars['DateTime'];
  outputId: Scalars['ID'];
  outputType: DestinationTypeEnum;
  outputConfig: DestinationConfig;
  verificationStatus?: Maybe<Scalars['String']>;
  defaultForSeverity: Array<Maybe<Severity>>;
  alertTypes: Array<AlertType>;
};

export type DestinationConfig = {
  __typename?: 'DestinationConfig';
  slack?: Maybe<SlackConfig>;
  sns?: Maybe<SnsConfig>;
  sqs?: Maybe<SqsDestinationConfig>;
  pagerDuty?: Maybe<PagerDutyConfig>;
  github?: Maybe<GithubConfig>;
  jira?: Maybe<JiraConfig>;
  opsgenie?: Maybe<OpsgenieConfig>;
  msTeams?: Maybe<MsTeamsConfig>;
  asana?: Maybe<AsanaConfig>;
  customWebhook?: Maybe<CustomWebhookConfig>;
};

export type SqsDestinationConfig = {
  __typename?: 'SqsDestinationConfig';
  queueUrl: Scalars['String'];
};

export type OpsgenieConfig = {
  __typename?: 'OpsgenieConfig';
  apiKey: Scalars['String'];
  serviceRegion: OpsgenieServiceRegionEnum;
};

export type MsTeamsConfig = {
  __typename?: 'MsTeamsConfig';
  webhookURL: Scalars['String'];
};

export type JiraConfig = {
  __typename?: 'JiraConfig';
  orgDomain: Scalars['String'];
  projectKey: Scalars['String'];
  userName: Scalars['String'];
  apiKey: Scalars['String'];
  assigneeId?: Maybe<Scalars['String']>;
  issueType: Scalars['String'];
  labels: Array<Scalars['String']>;
};

export type AsanaConfig = {
  __typename?: 'AsanaConfig';
  personalAccessToken: Scalars['String'];
  projectGids: Array<Scalars['String']>;
};

export type CustomWebhookConfig = {
  __typename?: 'CustomWebhookConfig';
  webhookURL: Scalars['String'];
};

export type GithubConfig = {
  __typename?: 'GithubConfig';
  repoName: Scalars['String'];
  token: Scalars['String'];
};

export type SlackConfig = {
  __typename?: 'SlackConfig';
  webhookURL: Scalars['String'];
};

export type SnsConfig = {
  __typename?: 'SnsConfig';
  topicArn: Scalars['String'];
};

export type PagerDutyConfig = {
  __typename?: 'PagerDutyConfig';
  integrationKey: Scalars['String'];
};

export type DestinationInput = {
  outputId?: Maybe<Scalars['ID']>;
  displayName: Scalars['String'];
  outputConfig: DestinationConfigInput;
  outputType: Scalars['String'];
  defaultForSeverity: Array<Maybe<Severity>>;
  alertTypes: Array<Maybe<AlertType>>;
};

export type DestinationConfigInput = {
  slack?: Maybe<SlackConfigInput>;
  sns?: Maybe<SnsConfigInput>;
  sqs?: Maybe<SqsConfigInput>;
  pagerDuty?: Maybe<PagerDutyConfigInput>;
  github?: Maybe<GithubConfigInput>;
  jira?: Maybe<JiraConfigInput>;
  opsgenie?: Maybe<OpsgenieConfigInput>;
  msTeams?: Maybe<MsTeamsConfigInput>;
  asana?: Maybe<AsanaConfigInput>;
  customWebhook?: Maybe<CustomWebhookConfigInput>;
};

export type SqsConfigInput = {
  queueUrl: Scalars['String'];
};

export type OpsgenieConfigInput = {
  apiKey: Scalars['String'];
  serviceRegion: OpsgenieServiceRegionEnum;
};

export type MsTeamsConfigInput = {
  webhookURL: Scalars['String'];
};

export type JiraConfigInput = {
  orgDomain: Scalars['String'];
  projectKey: Scalars['String'];
  userName: Scalars['String'];
  apiKey: Scalars['String'];
  assigneeId?: Maybe<Scalars['String']>;
  issueType: Scalars['String'];
  labels?: Maybe<Array<Scalars['String']>>;
};

export type AsanaConfigInput = {
  personalAccessToken: Scalars['String'];
  projectGids: Array<Scalars['String']>;
};

export type CustomWebhookConfigInput = {
  webhookURL: Scalars['String'];
};

export type GithubConfigInput = {
  repoName: Scalars['String'];
  token: Scalars['String'];
};

export type SlackConfigInput = {
  webhookURL: Scalars['String'];
};

export type SnsConfigInput = {
  topicArn: Scalars['String'];
};

export type PagerDutyConfigInput = {
  integrationKey: Scalars['String'];
};

export enum DestinationTypeEnum {
  Slack = 'slack',
  Pagerduty = 'pagerduty',
  Github = 'github',
  Jira = 'jira',
  Opsgenie = 'opsgenie',
  Msteams = 'msteams',
  Sns = 'sns',
  Sqs = 'sqs',
  Asana = 'asana',
  Customwebhook = 'customwebhook',
}

export enum OpsgenieServiceRegionEnum {
  Eu = 'EU',
  Us = 'US',
}

export type PoliciesForResourceInput = {
  resourceId?: Maybe<Scalars['ID']>;
  severity?: Maybe<Severity>;
  status?: Maybe<ComplianceStatusEnum>;
  suppressed?: Maybe<Scalars['Boolean']>;
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type BulkDownloadInput = {
  detectionIds?: Maybe<Array<Scalars['ID']>>;
  dataModelIds?: Maybe<Array<Scalars['ID']>>;
  globalHelperIds?: Maybe<Array<Scalars['ID']>>;
  savedQueryIds?: Maybe<Array<Scalars['ID']>>;
};

export type BulkDownloadResponse = {
  __typename?: 'BulkDownloadResponse';
  data: Scalars['String'];
};

export type UploadDetectionsInput = {
  data: Scalars['String'];
};

export type ListDetectionsInput = {
  complianceStatus?: Maybe<ComplianceStatusEnum>;
  resourceTypes?: Maybe<Array<Scalars['String']>>;
  logTypes?: Maybe<Array<Scalars['String']>>;
  scheduledQueries?: Maybe<Array<Scalars['String']>>;
  packIds?: Maybe<Array<Scalars['String']>>;
  analysisTypes?: Maybe<Array<DetectionTypeEnum>>;
  nameContains?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  severity?: Maybe<Array<Severity>>;
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  initialSet?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Scalars['String']>>;
  sortBy?: Maybe<ListDetectionsSortFieldsEnum>;
  sortDir?: Maybe<SortDirEnum>;
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type DeleteDetectionInput = {
  detections: Array<DeleteEntry>;
};

export type AddRuleInput = {
  body: Scalars['String'];
  dedupPeriodMinutes: Scalars['Int'];
  threshold: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  logTypes?: Maybe<Array<Scalars['String']>>;
  managed?: Maybe<Scalars['Boolean']>;
  scheduledQueries?: Maybe<Array<Scalars['String']>>;
  outputIds?: Maybe<Array<Scalars['ID']>>;
  parentId?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  runbook?: Maybe<Scalars['String']>;
  severity: Severity;
  summaryAttributes?: Maybe<Array<Scalars['String']>>;
  tags?: Maybe<Array<Scalars['String']>>;
  tests?: Maybe<Array<DetectionTestDefinitionInput>>;
  analysisType: DetectionTypeEnum;
  reports: Array<DetectionReportMappingInput>;
};

export type UpdateRuleInput = {
  body?: Maybe<Scalars['String']>;
  dedupPeriodMinutes?: Maybe<Scalars['Int']>;
  threshold?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  logTypes?: Maybe<Array<Scalars['String']>>;
  managed?: Maybe<Scalars['Boolean']>;
  scheduledQueries?: Maybe<Array<Scalars['String']>>;
  outputIds?: Maybe<Array<Scalars['ID']>>;
  parentId?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  runbook?: Maybe<Scalars['String']>;
  severity?: Maybe<Severity>;
  summaryAttributes: Array<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  tests?: Maybe<Array<DetectionTestDefinitionInput>>;
  analysisType: DetectionTypeEnum;
  reports: Array<DetectionReportMappingInput>;
};

export type GetRuleInput = {
  id: Scalars['ID'];
  versionId?: Maybe<Scalars['ID']>;
};

export type AddPolicyInput = {
  body: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  outputIds?: Maybe<Array<Scalars['ID']>>;
  reference?: Maybe<Scalars['String']>;
  resourceTypes: Array<Scalars['String']>;
  runbook?: Maybe<Scalars['String']>;
  severity: Severity;
  suppressions?: Maybe<Array<Scalars['String']>>;
  tags?: Maybe<Array<Scalars['String']>>;
  tests?: Maybe<Array<DetectionTestDefinitionInput>>;
  managed?: Maybe<Scalars['Boolean']>;
  parentId?: Maybe<Scalars['String']>;
  reports: Array<DetectionReportMappingInput>;
};

export type UpdatePolicyInput = {
  body?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  outputIds?: Maybe<Array<Scalars['ID']>>;
  reference?: Maybe<Scalars['String']>;
  resourceTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  runbook?: Maybe<Scalars['String']>;
  severity?: Maybe<Severity>;
  suppressions?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  tests?: Maybe<Array<Maybe<DetectionTestDefinitionInput>>>;
  managed?: Maybe<Scalars['Boolean']>;
  parentId?: Maybe<Scalars['String']>;
  reports: Array<DetectionReportMappingInput>;
};

export type GetPolicyInput = {
  id: Scalars['ID'];
  versionId?: Maybe<Scalars['ID']>;
};

export type TestPolicyInput = {
  body: Scalars['String'];
  resourceTypes: Array<Scalars['String']>;
  tests: Array<DetectionTestDefinitionInput>;
};

export type TestRuleInput = {
  body: Scalars['String'];
  logTypes: Array<Scalars['String']>;
  tests: Array<DetectionTestDefinitionInput>;
};

export type GenerateEnrichedEventInput = {
  event: Scalars['JSON'];
};

export type GenerateEnrichedEventResponse = {
  __typename?: 'GenerateEnrichedEventResponse';
  enrichedEvent: Scalars['JSON'];
};

export type UploadDetectionsResponse = {
  __typename?: 'UploadDetectionsResponse';
  totalPolicies: Scalars['Int'];
  newPolicies: Scalars['Int'];
  modifiedPolicies: Scalars['Int'];
  totalRules: Scalars['Int'];
  newRules: Scalars['Int'];
  modifiedRules: Scalars['Int'];
  totalGlobals: Scalars['Int'];
  newGlobals: Scalars['Int'];
  modifiedGlobals: Scalars['Int'];
  totalDataModels: Scalars['Int'];
  newDataModels: Scalars['Int'];
  modifiedDataModels: Scalars['Int'];
  totalQueries: Scalars['Int'];
  newQueries: Scalars['Int'];
  modifiedQueries: Scalars['Int'];
};

export type DetectionTestMockDefinition = {
  __typename?: 'DetectionTestMockDefinition';
  objectName: Scalars['String'];
  returnValue?: Maybe<Scalars['String']>;
};

export type DetectionTestDefinition = {
  __typename?: 'DetectionTestDefinition';
  expectedResult: Scalars['Boolean'];
  name: Scalars['String'];
  resource: Scalars['String'];
  mocks?: Maybe<Array<DetectionTestMockDefinition>>;
};

export type DetectionTestDefinitionMockInput = {
  objectName: Scalars['String'];
  returnValue?: Maybe<Scalars['String']>;
};

export type DetectionTestDefinitionInput = {
  expectedResult: Scalars['Boolean'];
  name: Scalars['String'];
  resource: Scalars['String'];
  mocks?: Maybe<Array<DetectionTestDefinitionMockInput>>;
};

export type SuppressPoliciesInput = {
  policyIds: Array<Maybe<Scalars['ID']>>;
  resourcePatterns: Array<Maybe<Scalars['String']>>;
};

export type DetectionReportMapping = {
  __typename?: 'DetectionReportMapping';
  key: Scalars['String'];
  values: Array<Scalars['String']>;
};

export type DetectionReportMappingInput = {
  key: Scalars['String'];
  values: Array<Scalars['String']>;
};

export type Detection = {
  body: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Actor>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  lastModified?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Actor>;
  outputIds: Array<Scalars['ID']>;
  reference?: Maybe<Scalars['String']>;
  runbook?: Maybe<Scalars['String']>;
  severity: Severity;
  tags: Array<Scalars['String']>;
  tests: Array<DetectionTestDefinition>;
  reports: Array<DetectionReportMapping>;
  versionId?: Maybe<Scalars['ID']>;
  analysisType: DetectionTypeEnum;
};

export type DetectionsOutput = {
  __typename?: 'DetectionsOutput';
  edges: Array<DetectionsOutputEdge>;
  pageInfo: DetectionsOutputPageInfo;
};

export type DetectionsOutputPageInfo = {
  __typename?: 'DetectionsOutputPageInfo';
  hasNextPage: Scalars['Boolean'];
};

export type DetectionsOutputEdge = {
  __typename?: 'DetectionsOutputEdge';
  node: Detection;
};

export type Rule = Detection & {
  __typename?: 'Rule';
  body: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Actor>;
  dedupPeriodMinutes: Scalars['Int'];
  threshold: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  lastModified?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Actor>;
  managed?: Maybe<Scalars['Boolean']>;
  outputIds: Array<Scalars['ID']>;
  parentId?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  runbook?: Maybe<Scalars['String']>;
  severity: Severity;
  summaryAttributes: Array<Scalars['String']>;
  tags: Array<Scalars['String']>;
  tests: Array<DetectionTestDefinition>;
  versionId?: Maybe<Scalars['ID']>;
  analysisType: DetectionTypeEnum;
  reports: Array<DetectionReportMapping>;
  logTypes?: Maybe<Array<Scalars['String']>>;
  scheduledQueries?: Maybe<Array<Scalars['String']>>;
};

export type Policy = Detection & {
  __typename?: 'Policy';
  body: Scalars['String'];
  complianceStatus?: Maybe<ComplianceStatusEnum>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Actor>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  lastModified?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Actor>;
  managed?: Maybe<Scalars['Boolean']>;
  outputIds: Array<Scalars['ID']>;
  parentId?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  resourceTypes?: Maybe<Array<Scalars['String']>>;
  runbook?: Maybe<Scalars['String']>;
  severity: Severity;
  suppressions?: Maybe<Array<Scalars['String']>>;
  tags: Array<Scalars['String']>;
  tests: Array<DetectionTestDefinition>;
  versionId?: Maybe<Scalars['ID']>;
  analysisType: DetectionTypeEnum;
  reports: Array<DetectionReportMapping>;
};

export type ListDetectionsResponse = {
  __typename?: 'ListDetectionsResponse';
  detections: Array<Detection>;
  paging: PagingData;
};

export type TestDetectionSubRecord = {
  __typename?: 'TestDetectionSubRecord';
  output?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
};

export type TestPolicyRecordFunctions = {
  __typename?: 'TestPolicyRecordFunctions';
  detectionFunction?: Maybe<TestDetectionSubRecord>;
  titleFunction?: Maybe<TestDetectionSubRecord>;
  dedupFunction?: Maybe<TestDetectionSubRecord>;
  alertContextFunction?: Maybe<TestDetectionSubRecord>;
  descriptionFunction?: Maybe<TestDetectionSubRecord>;
  destinationsFunction?: Maybe<TestDetectionSubRecord>;
  referenceFunction?: Maybe<TestDetectionSubRecord>;
  runbookFunction?: Maybe<TestDetectionSubRecord>;
  severityFunction?: Maybe<TestDetectionSubRecord>;
};

export type TestRecord = {
  id: Scalars['String'];
  name: Scalars['String'];
  passed: Scalars['Boolean'];
  error?: Maybe<Error>;
};

export type TestPolicyRecord = TestRecord & {
  __typename?: 'TestPolicyRecord';
  id: Scalars['String'];
  name: Scalars['String'];
  passed: Scalars['Boolean'];
  functions: TestPolicyRecordFunctions;
  error?: Maybe<Error>;
};

export type TestPolicyResponse = {
  __typename?: 'TestPolicyResponse';
  results: Array<TestPolicyRecord>;
};

export type TestDetectionRecordFunctions = {
  __typename?: 'TestDetectionRecordFunctions';
  detectionFunction?: Maybe<TestDetectionSubRecord>;
  titleFunction?: Maybe<TestDetectionSubRecord>;
  dedupFunction?: Maybe<TestDetectionSubRecord>;
  alertContextFunction?: Maybe<TestDetectionSubRecord>;
  descriptionFunction?: Maybe<TestDetectionSubRecord>;
  destinationsFunction?: Maybe<TestDetectionSubRecord>;
  referenceFunction?: Maybe<TestDetectionSubRecord>;
  runbookFunction?: Maybe<TestDetectionSubRecord>;
  severityFunction?: Maybe<TestDetectionSubRecord>;
};

export type TestDetectionRecord = TestRecord & {
  __typename?: 'TestDetectionRecord';
  id: Scalars['String'];
  name: Scalars['String'];
  passed: Scalars['Boolean'];
  functions: TestDetectionRecordFunctions;
  error?: Maybe<Error>;
};

export type TestRuleResponse = {
  __typename?: 'TestRuleResponse';
  results: Array<TestDetectionRecord>;
};

export type BulkUpdateDetectionsInput = {
  detections: Array<DetectionPatch>;
};

export type DetectionPatch = {
  id: Scalars['String'];
  enabled?: Maybe<Scalars['Boolean']>;
};

export type DetectionUpdateError = {
  __typename?: 'DetectionUpdateError';
  detectionId: Scalars['String'];
  error: Error;
};

export type BulkUpdateDetectionsResponse = {
  __typename?: 'BulkUpdateDetectionsResponse';
  errors: Array<Maybe<DetectionUpdateError>>;
  detections: Array<Detection>;
};

export enum ListDetectionsSortFieldsEnum {
  DisplayName = 'displayName',
  Id = 'id',
  LastModified = 'lastModified',
  Enabled = 'enabled',
  Severity = 'severity',
}

export enum DetectionTypeEnum {
  Rule = 'RULE',
  Policy = 'POLICY',
  ScheduledRule = 'SCHEDULED_RULE',
}

export type DetectionFilter = {
  __typename?: 'DetectionFilter';
  id: Scalars['ID'];
  name: Scalars['String'];
  when: DetectionFilterStatement;
  detections: DetectionsOutput;
};

export type DetectionFilterDetectionsArgs = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type DetectionFilterStatement = {
  __typename?: 'DetectionFilterStatement';
  or?: Maybe<Array<DetectionFilterStatement>>;
  and?: Maybe<Array<DetectionFilterStatement>>;
  not?: Maybe<Array<DetectionFilterStatement>>;
  key: Scalars['String'];
  value: Scalars['String'];
  condition: Scalars['String'];
};

export type DetectionFiltersOutput = {
  __typename?: 'DetectionFiltersOutput';
  cursor: Scalars['String'];
  detectionFilters: Array<DetectionFilter>;
};

export type CreateDetectionFilterOutput = {
  __typename?: 'CreateDetectionFilterOutput';
  detectionFilter: DetectionFilter;
};

export type UpdateDetectionFilterOutput = {
  __typename?: 'UpdateDetectionFilterOutput';
  detectionFilter: DetectionFilter;
};

export type DeleteDetectionFilterOutput = {
  __typename?: 'DeleteDetectionFilterOutput';
  id: Scalars['ID'];
};

export type DetectionFiltersInput = {
  cursor: Scalars['String'];
  pageSize: Scalars['Int'];
};

export type CreateDetectionFilterInput = {
  name: Scalars['String'];
  when: DetectionFilterStatementInput;
};

export type UpdateDetectionFilterInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  when: DetectionFilterStatementInput;
};

export type DetectionFilterStatementInput = {
  or?: Maybe<Array<DetectionFilterStatementInput>>;
  and?: Maybe<Array<DetectionFilterStatementInput>>;
  not?: Maybe<Array<DetectionFilterStatementInput>>;
  key: Scalars['String'];
  value: Scalars['String'];
  condition: Scalars['String'];
};

export type ListLookupsInput = {
  exclusiveStartKey?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
  importMethods?: Maybe<Array<Scalars['String']>>;
  managed?: Maybe<Scalars['Boolean']>;
};

export type AddLookupInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  logTypeMap: LogTypeMapInput;
  lookupSchema: LookupSchemaInput;
  refresh?: Maybe<LookupRefreshConfigInput>;
};

export type UpdateLookupInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  logTypeMap?: Maybe<LogTypeMapInput>;
  lookupSchema?: Maybe<LookupSchemaInput>;
  refresh?: Maybe<LookupRefreshConfigInput>;
};

export type LookupRefreshConfigInput = {
  roleARN: Scalars['String'];
  periodMinutes: Scalars['Int'];
  alarmPeriodMinutes?: Maybe<Scalars['Int']>;
  objectPath: Scalars['String'];
  objectKMSKey?: Maybe<Scalars['String']>;
};

export type LookupSchemaInput = {
  logType: Scalars['String'];
};

export type LogTypeMapInput = {
  primaryKey: Scalars['String'];
  associatedLogTypes: Array<AssociatedLogTypeInput>;
};

export type AssociatedLogTypeInput = {
  logType: Scalars['String'];
  selectors: Array<Scalars['String']>;
};

export type ImportLookupDataInput = {
  lookupId: Scalars['ID'];
  s3UploadUrl: Scalars['String'];
};

export type DeleteLookupsInput = {
  ids: Array<Scalars['ID']>;
};

export type ListLookupResponse = {
  __typename?: 'ListLookupResponse';
  lastEvaluatedKey?: Maybe<Scalars['String']>;
  lookups: Array<Lookup>;
};

export type Lookup = {
  __typename?: 'Lookup';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  /** Whether the lookup table is provided out-of-the-box by Panther or set up by the customer. */
  managed: Scalars['Boolean'];
  reference: Scalars['String'];
  enabled: Scalars['Boolean'];
  activeVersion?: Maybe<LookupVersion>;
  createdBy: Scalars['ID'];
  lastModifiedBy: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  lastModifiedAt: Scalars['DateTime'];
  /** Sending a `null` refresh value will remove any existing refresh settings. */
  refresh?: Maybe<LookupRefreshConfig>;
};

/**
 * Configuration about how the lookup table refreshes data from an S3 object, if the
 * lookup table is configured to import data from S3
 */
export type LookupRefreshConfig = {
  __typename?: 'LookupRefreshConfig';
  roleARN: Scalars['String'];
  periodMinutes: Scalars['Int'];
  alarmPeriodMinutes?: Maybe<Scalars['Int']>;
  objectPath: Scalars['String'];
  objectKMSKey?: Maybe<Scalars['String']>;
};

export type LookupImportDataJob = Job & {
  __typename?: 'LookupImportDataJob';
  /** In the import lookup job, the `id` for polling will be the execution ARN of the step function. */
  id: Scalars['ID'];
  status: JobStatus;
  message?: Maybe<Scalars['String']>;
  stats?: Maybe<LookupImportDataJobStats>;
};

export type LookupSyncJob = Job & {
  __typename?: 'LookupSyncJob';
  /** In the import lookup job, the `id` for polling will be the execution ARN of the step function. */
  id: Scalars['ID'];
  status: JobStatus;
  message?: Maybe<Scalars['String']>;
  stats?: Maybe<LookupSyncJobStats>;
};

export type LookupSyncJobStats = {
  __typename?: 'LookupSyncJobStats';
  total: Scalars['Int'];
  skipped: Scalars['Int'];
};

export type SyncLookupDataInput = {
  lookupId: Scalars['ID'];
};

export type LookupImportDataJobStats = {
  __typename?: 'LookupImportDataJobStats';
  total: Scalars['Int'];
  skipped: Scalars['Int'];
};

export type LookupVersion = {
  __typename?: 'LookupVersion';
  id?: Maybe<Scalars['ID']>;
  lookupId?: Maybe<Scalars['ID']>;
  version: Scalars['Int'];
  primaryKey: Scalars['String'];
  associatedLogTypes: Array<AssociatedLogType>;
  logType: Scalars['String'];
  objectPath?: Maybe<Scalars['String']>;
  refreshedAt?: Maybe<Scalars['DateTime']>;
  rowCount?: Maybe<Scalars['Int']>;
  erroredAt?: Maybe<Scalars['DateTime']>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type AssociatedLogType = {
  __typename?: 'AssociatedLogType';
  logType: Scalars['String'];
  selectors: Array<Scalars['String']>;
};

export type LookupS3ImportConfig = {
  __typename?: 'LookupS3ImportConfig';
  url: Scalars['String'];
};

export type LookupS3ImportConfigInput = {
  lookupId: Scalars['ID'];
};

export type GlobalHelper = {
  __typename?: 'GlobalHelper';
  body: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  lastModified: Scalars['DateTime'];
  parentId?: Maybe<Scalars['String']>;
  managed?: Maybe<Scalars['Boolean']>;
};

export type ListGlobalHelpersInput = {
  nameContains?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  packIds?: Maybe<Array<Scalars['String']>>;
  sortDir?: Maybe<SortDirEnum>;
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type GetGlobalHelperInput = {
  id: Scalars['ID'];
  versionId?: Maybe<Scalars['ID']>;
};

export type ModifyGlobalHelperInput = {
  description: Scalars['String'];
  id: Scalars['ID'];
  body: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  managed?: Maybe<Scalars['Boolean']>;
};

export type AddGlobalHelperInput = {
  id: Scalars['ID'];
  description: Scalars['String'];
  body: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  managed?: Maybe<Scalars['Boolean']>;
};

export type DeleteGlobalHelpersInput = {
  globals: Array<DeleteEntry>;
};

export type ListGlobalHelpersResponse = {
  __typename?: 'ListGlobalHelpersResponse';
  paging?: Maybe<PagingData>;
  globals?: Maybe<Array<Maybe<GlobalHelper>>>;
};

export type HoldingTankGetRunningTaskInput = {
  sourceId: Scalars['String'];
};

export type HoldingTankTestSchemasResultInput = {
  taskId: Scalars['String'];
  logType: Scalars['String'];
  prefix: Scalars['String'];
  pageSize?: Maybe<Scalars['Int']>;
  paginationToken?: Maybe<Scalars['String']>;
};

export type HoldingTankRawDataInput = {
  sourceId: Scalars['String'];
  filters: HoldingTankFilters;
  streamType: Scalars['String'];
  pageSize?: Maybe<Scalars['Int']>;
  paginationToken?: Maybe<Scalars['String']>;
};

export type HoldingTankInferSchemaInput = {
  sourceId: Scalars['String'];
  filters: HoldingTankFilters;
  streamType: Scalars['String'];
};

export type TestHoldingTankSchemasInput = {
  sourceId: Scalars['String'];
  s3PrefixLogTypes: Array<S3PrefixLogTypesInput>;
  newSchemas?: Maybe<Array<PutUserSchemaInput>>;
  streamType: Scalars['String'];
  filters: TestHoldingTankSchemaFilters;
};

export type TestHoldingTankSchemaFilters = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};

export type HoldingTankFilters = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  contains?: Maybe<Scalars['String']>;
  s3Prefix?: Maybe<Scalars['String']>;
  excludedPrefixes?: Maybe<Array<Scalars['String']>>;
};

export type HoldingTankTestJob = Job & {
  __typename?: 'HoldingTankTestJob';
  id: Scalars['ID'];
  createdBy?: Maybe<Actor>;
  createdAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  status: JobStatus;
  taskType: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  stats?: Maybe<HoldingTankJobStats>;
  testParameters: HoldingTankTestJobParams;
  testResults?: Maybe<Array<HoldingTankTestJobPrefixStats>>;
};

export type DraftSchema = {
  __typename?: 'DraftSchema';
  name: Scalars['String'];
  spec: Scalars['String'];
  description: Scalars['String'];
  referenceURL: Scalars['String'];
};

export type HoldingTankTestJobParams = {
  __typename?: 'HoldingTankTestJobParams';
  streamType: Scalars['String'];
  s3PrefixLogTypes: Array<S3PrefixLogTypes>;
  newSchemas?: Maybe<Array<DraftSchema>>;
  filters: DateRange;
};

export type DateRange = {
  __typename?: 'DateRange';
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};

export type HoldingTankListTasksResponse = {
  __typename?: 'HoldingTankListTasksResponse';
  tasks: Array<HoldingTankTestJob>;
};

export type HoldingTankInferJob = Job & {
  __typename?: 'HoldingTankInferJob';
  id: Scalars['ID'];
  status: JobStatus;
  taskType: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  stats?: Maybe<HoldingTankJobStats>;
  schemaInferenceResult?: Maybe<HoldingTankSchemaInferenceResult>;
};

export type HoldingTankSchemaInferenceResult = {
  __typename?: 'HoldingTankSchemaInferenceResult';
  spec?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
};

export type HoldingTankJobStats = {
  __typename?: 'HoldingTankJobStats';
  totalSizeBytes: Scalars['Int'];
  elapsedTimeSeconds: Scalars['Int'];
};

export type HoldingTankTestJobPrefixStats = {
  __typename?: 'HoldingTankTestJobPrefixStats';
  prefix: Scalars['String'];
  logTypeMatches: Array<HoldingTankLogTypeTestStats>;
  unmatched: Scalars['Int'];
  errored: Scalars['Int'];
};

export type HoldingTankLogTypeTestStats = {
  __typename?: 'HoldingTankLogTypeTestStats';
  logType: Scalars['String'];
  matched: Scalars['Int'];
};

export type HoldingTankRawEvent = {
  __typename?: 'HoldingTankRawEvent';
  s3Key: Scalars['String'];
  event: Scalars['String'];
  error?: Maybe<Scalars['String']>;
};

export type HoldingTankResults = {
  __typename?: 'HoldingTankResults';
  event: Scalars['String'];
  s3Key: Scalars['String'];
  error?: Maybe<Scalars['String']>;
};

export type HoldingTankTestSchemasResultsResponse = {
  __typename?: 'HoldingTankTestSchemasResultsResponse';
  results: Array<HoldingTankResults>;
  pageInfo: PageInfo;
};

export type HoldingTankRawDataResponse = {
  __typename?: 'HoldingTankRawDataResponse';
  data: Array<HoldingTankRawEvent>;
  pageInfo: PageInfo;
};

/** The available indicator types used in an Indicator Search Query */
export enum IndicatorType {
  AutoDetectType = 'AutoDetectType',
  PAnyAwsAccountIds = 'p_any_aws_account_ids',
  PAnyAwsArns = 'p_any_aws_arns',
  PAnyAwsInstanceIds = 'p_any_aws_instance_ids',
  PAnyAwsTags = 'p_any_aws_tags',
  PAnyDomainNames = 'p_any_domain_names',
  PAnyEmails = 'p_any_emails',
  PAnyIpAddresses = 'p_any_ip_addresses',
  PAnyMd5Hashes = 'p_any_md5_hashes',
  PAnySha1Hashes = 'p_any_sha1_hashes',
  PAnySha256Hashes = 'p_any_sha256_hashes',
  PAnyTraceIds = 'p_any_trace_ids',
  PAnyUsernames = 'p_any_usernames',
}

export type ListSchemasInput = {
  isDisabled?: Maybe<Scalars['Boolean']>;
  isManaged?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type GetSchemaInput = {
  name: Scalars['String'];
  clone?: Maybe<Scalars['Boolean']>;
};

export type PutUserSchemaInput = {
  revision?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  referenceURL?: Maybe<Scalars['String']>;
  spec: Scalars['String'];
};

export type ToggleSchemaInput = {
  name: Scalars['String'];
  toggle: Scalars['Boolean'];
};

export type GetSchemaOutput = {
  __typename?: 'GetSchemaOutput';
  record?: Maybe<SchemaRecord>;
  error?: Maybe<Error>;
};

export type ListSchemasOutput = {
  __typename?: 'ListSchemasOutput';
  results?: Maybe<Array<SchemaRecord>>;
  error?: Maybe<Error>;
};

export type SchemaRecord = {
  __typename?: 'SchemaRecord';
  name: Scalars['String'];
  revision: Scalars['Int'];
  release?: Maybe<Scalars['String']>;
  managed: Scalars['Boolean'];
  updatedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  disabled: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  referenceURL?: Maybe<Scalars['String']>;
  spec: Scalars['String'];
  fields: Array<SchemaField>;
};

export type SchemaField = {
  __typename?: 'SchemaField';
  name: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  type: SchemaValueType;
  target?: Maybe<Scalars['String']>;
  indicators: Array<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  isEventTime?: Maybe<Scalars['Boolean']>;
  validate?: Maybe<SchemaFieldValueValidation>;
  isEmbeddedJSON?: Maybe<Scalars['Boolean']>;
};

export type SchemaFieldValueValidation = {
  __typename?: 'SchemaFieldValueValidation';
  allow: Array<Scalars['String']>;
  deny: Array<Scalars['String']>;
  cidr?: Maybe<Scalars['String']>;
  ip?: Maybe<Scalars['String']>;
};

export type GenerateS3SampleUploadInput = {
  sessionId?: Maybe<Scalars['String']>;
};

export type GenerateS3SampleUploadResponse = {
  __typename?: 'GenerateS3SampleUploadResponse';
  uploadURL: Scalars['String'];
  sessionId: Scalars['String'];
};

export type GetSampleDataInput = {
  sessionId: Scalars['String'];
};

export type InferSchemaInput = {
  sessionId: Scalars['String'];
};

export type InferSchemaResponse = {
  __typename?: 'InferSchemaResponse';
  schema?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
};

export type GetSampleDataResponse = {
  __typename?: 'GetSampleDataResponse';
  data: Array<Scalars['String']>;
  total: Scalars['Int'];
  error?: Maybe<Error>;
};

export type TestSchemaInput = {
  schema: Scalars['String'];
  sessionId: Scalars['String'];
};

export type TestSchemaResult = {
  __typename?: 'TestSchemaResult';
  stats: TestSchemaStats;
  data: TestSchemaData;
};

export type TestSchemaResponse = {
  __typename?: 'TestSchemaResponse';
  result?: Maybe<TestSchemaResult>;
  error?: Maybe<Error>;
};

export type TestSchemaStats = {
  __typename?: 'TestSchemaStats';
  matched: Scalars['Int'];
  unmatched: Scalars['Int'];
  totalLogs: Scalars['Int'];
};

export type UnmatchedLog = {
  __typename?: 'UnmatchedLog';
  raw: Scalars['String'];
  error: Scalars['String'];
};

export type MatchedLog = {
  __typename?: 'MatchedLog';
  raw: Scalars['String'];
  event: Scalars['String'];
};

export type TestSchemaData = {
  __typename?: 'TestSchemaData';
  matched: Array<MatchedLog>;
  unmatched: Array<UnmatchedLog>;
};

export enum SchemaValueType {
  Object = 'object',
  Array = 'array',
  Timestamp = 'timestamp',
  Ref = 'ref',
  String = 'string',
  Boolean = 'boolean',
  Int = 'int',
  Smallint = 'smallint',
  Bigint = 'bigint',
  Float = 'float',
  Json = 'json',
}

export type RequestLogTypeInput = {
  name: Scalars['String'];
  description: Scalars['String'];
};

export type ListAvailableLogProvidersInput = {
  category?: Maybe<Scalars['String']>;
};

export type RequestLogTypeResponse = {
  __typename?: 'RequestLogTypeResponse';
  id: Scalars['String'];
  url: Scalars['String'];
};

export type ListAvailableLogTypesResponse = {
  __typename?: 'ListAvailableLogTypesResponse';
  logTypes: Array<Scalars['String']>;
};

export type ListAvailableLogProvidersResponse = {
  __typename?: 'ListAvailableLogProvidersResponse';
  results?: Maybe<Array<AvailableLogProvider>>;
  error?: Maybe<Error>;
};

export type GetAvailableLogProviderResponse = {
  __typename?: 'GetAvailableLogProviderResponse';
  result?: Maybe<AvailableLogProvider>;
  error?: Maybe<Error>;
};

export type AvailableLogProvider = {
  __typename?: 'AvailableLogProvider';
  id: Scalars['ID'];
  displayName: Scalars['String'];
  popularity: Scalars['Int'];
  categories: Array<Scalars['String']>;
  availableParsers: Array<AvailableLogProviderParser>;
  details?: Maybe<AvailableLogProviderDetails>;
};

export type AvailableLogProviderDetails = {
  __typename?: 'AvailableLogProviderDetails';
  appInfo?: Maybe<Scalars['String']>;
  howItWorks?: Maybe<Scalars['String']>;
  useCases?: Maybe<Scalars['String']>;
  defaultParsers: Array<Scalars['String']>;
  transports: Array<Scalars['String']>;
};

export type AvailableLogProviderParser = {
  __typename?: 'AvailableLogProviderParser';
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  referenceUrl?: Maybe<Scalars['String']>;
};

export type OrganizationStatsResponse = {
  __typename?: 'OrganizationStatsResponse';
  appliedPolicies?: Maybe<OrganizationReportBySeverity>;
  scannedResources?: Maybe<ScannedResources>;
  topFailingPolicies: Array<Policy>;
  topFailingResources: Array<ResourceSummary>;
};

export type ScannedResourceStats = {
  __typename?: 'ScannedResourceStats';
  count?: Maybe<ComplianceStatusCounts>;
  type?: Maybe<Scalars['String']>;
};

export type ScannedResources = {
  __typename?: 'ScannedResources';
  byType?: Maybe<Array<Maybe<ScannedResourceStats>>>;
};

export type OrganizationStatsInput = {
  limitTopFailing?: Maybe<Scalars['Int']>;
};

export type OrganizationReportBySeverity = {
  __typename?: 'OrganizationReportBySeverity';
  info?: Maybe<ComplianceStatusCounts>;
  low?: Maybe<ComplianceStatusCounts>;
  medium?: Maybe<ComplianceStatusCounts>;
  high?: Maybe<ComplianceStatusCounts>;
  critical?: Maybe<ComplianceStatusCounts>;
};

export type ComplianceStatusCounts = {
  __typename?: 'ComplianceStatusCounts';
  error?: Maybe<Scalars['Int']>;
  fail?: Maybe<Scalars['Int']>;
  pass?: Maybe<Scalars['Int']>;
};

/** The output to the `metrics` query */
export type MetricsOutput = {
  __typename?: 'MetricsOutput';
  /** The number of alerts corresponding to each rule */
  alertsPerRule?: Maybe<Array<SeriesWithEntityID>>;
  /** The number of alerts corresponding to each severity */
  alertsPerSeverity: Array<SeriesWithBreakdown>;
  /** The number of bytes that Panther ingested for each individual source */
  bytesIngestedPerSource?: Maybe<Array<Series>>;
  /** The number of bytes that Panther processed for each individual source */
  bytesProcessedPerSource: Array<SeriesWithBreakdown>;
  /** The number of bytes that got queried via Panther for each individual source */
  bytesQueriedPerSource: Array<SeriesWithBreakdown>;
  /** The number of events that got processed for each log tye */
  eventsProcessedPerLogType: Array<SeriesWithBreakdown>;
  /** The latency related to each log type */
  latencyPerLogType?: Maybe<Array<Series>>;
  /** The total number of alerts */
  totalAlerts: Scalars['Float'];
  totalAlertsDiff: Scalars['Float'];
  /** The total number of bytes that Panther ingested */
  totalBytesIngested: Scalars['Float'];
  /** The total number of bytes that Panther processed */
  totalBytesProcessed: Scalars['Float'];
  /** The total number of bytes that got queried via Panther */
  totalBytesQueried: Scalars['Float'];
  /** The total number of events (logs) that got processed */
  totalEventsProcessed: Scalars['Float'];
};

/** The input to the `metrics` query */
export type MetricsInput = {
  /** The start date for the metrics evaluation */
  fromDate: Scalars['DateTime'];
  /** The interval between metric checks. Used for plotting charts */
  intervalInMinutes?: Maybe<Scalars['Int']>;
  /** The end date for the metrics evaluation */
  toDate: Scalars['DateTime'];
};

/** A simple series represented as a label/value pair */
export type Series = {
  __typename?: 'Series';
  /** The label of this particular value */
  label: Scalars['String'];
  /** The value for the label above */
  value: Scalars['Float'];
};

/** Same as `Series` with the addition of a timestamp breakdown. Useful for plotting charts. */
export type SeriesWithBreakdown = {
  __typename?: 'SeriesWithBreakdown';
  /**
   * A key/value pair that breaks down the `value` field to its constituents. Each key is a timestamp and each value is a
   * fraction of the (total) `value` above
   */
  breakdown: Scalars['JSON'];
  /** The label of this particular value */
  label: Scalars['String'];
  /** The (total) value for the label above */
  value: Scalars['Float'];
};

/**
 * Same as `Series` with the addition of an Panther Entity ID. The ID can be mapped to any Panther entity, depending on the
 * field that uses the type
 */
export type SeriesWithEntityID = {
  __typename?: 'SeriesWithEntityID';
  /**
   * The ID of an entity. Could be a Detection ID, a Resource ID, an Alert ID, etc. depending on the field that uses this
   * particular type
   */
  entityId: Scalars['ID'];
  /** The label of this particular value */
  label: Scalars['String'];
  /** The value for the label above */
  value: Scalars['Float'];
};

export type UpdateGeneralSettingsConfigInput = {
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  analyticsConsent?: Maybe<Scalars['Boolean']>;
  licenseAccepted?: Maybe<Scalars['Boolean']>;
};

export type UpdateSamlConfigInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  defaultRoleId?: Maybe<Scalars['String']>;
  metadataUrl?: Maybe<Scalars['String']>;
  metadataFile?: Maybe<Scalars['String']>;
};

/** General Panther configuration settings */
export type GeneralSettingsConfig = {
  __typename?: 'GeneralSettingsConfig';
  analyticsConsent?: Maybe<Scalars['Boolean']>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  infrastructureInfo?: Maybe<InfrastructureInfo>;
  licenseAccepted?: Maybe<Scalars['Boolean']>;
  /** The currently deployed version of Panther */
  pantherVersion?: Maybe<Scalars['String']>;
};

export type InfrastructureInfo = {
  __typename?: 'InfrastructureInfo';
  networking: NetworkingInfo;
  storage: StorageInfo;
};

export type NetworkingInfo = {
  __typename?: 'NetworkingInfo';
  publicIp: Scalars['String'];
};

export type StorageInfo = {
  __typename?: 'StorageInfo';
  processedDataRetentionDays: Scalars['Int'];
};

export type SamlConfig = {
  __typename?: 'SamlConfig';
  enabled?: Maybe<Scalars['Boolean']>;
  defaultRoleId?: Maybe<Scalars['String']>;
  hasMetadataFile?: Maybe<Scalars['Boolean']>;
  metadataUrl?: Maybe<Scalars['String']>;
};

export type ListAnalysisPackSourcesInput = {
  nameContains?: Maybe<Scalars['String']>;
  sortDir?: Maybe<SortDirEnum>;
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type ListAnalysisPacksInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  updateAvailable?: Maybe<Scalars['Boolean']>;
  nameContains?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDir?: Maybe<SortDirEnum>;
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type UpdateAnalysisPackInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  versionId?: Maybe<Scalars['Int']>;
};

export type UpdateAnalysisPackSourceInput = {
  id: Scalars['ID'];
  githubAccessToken?: Maybe<Scalars['String']>;
  kmsKey?: Maybe<Scalars['String']>;
};

export type AddAnalysisPackSourceInput = {
  id: Scalars['ID'];
  githubAccessToken?: Maybe<Scalars['String']>;
  owner: Scalars['String'];
  repository: Scalars['String'];
  kmsKey?: Maybe<Scalars['String']>;
};

export type ListAnalysisPacksResponse = {
  __typename?: 'ListAnalysisPacksResponse';
  packs: Array<AnalysisPack>;
  paging: PagingData;
};

export type ListAnalysisPackSourcesResponse = {
  __typename?: 'ListAnalysisPackSourcesResponse';
  paging: PagingData;
  packs: Array<AnalysisPackSource>;
};

export type AnalysisPackVersion = {
  __typename?: 'AnalysisPackVersion';
  id: Scalars['Int'];
  semVer: Scalars['String'];
};

export type AnalysisPackDefinition = {
  __typename?: 'AnalysisPackDefinition';
  IDs?: Maybe<Array<Scalars['ID']>>;
};

export type AnalysisPackTypes = {
  __typename?: 'AnalysisPackTypes';
  GLOBAL?: Maybe<Scalars['Int']>;
  RULE?: Maybe<Scalars['Int']>;
  DATAMODEL?: Maybe<Scalars['Int']>;
  POLICY?: Maybe<Scalars['Int']>;
  SCHEDULED_RULE?: Maybe<Scalars['Int']>;
  SCHEDULED_QUERY?: Maybe<Scalars['Int']>;
};

export type AnalysisPack = {
  __typename?: 'AnalysisPack';
  id: Scalars['ID'];
  enabled: Scalars['Boolean'];
  updateAvailable: Scalars['Boolean'];
  description: Scalars['String'];
  displayName: Scalars['String'];
  packVersion: AnalysisPackVersion;
  availableVersions: Array<AnalysisPackVersion>;
  createdBy?: Maybe<Actor>;
  lastModifiedBy?: Maybe<Actor>;
  createdAt: Scalars['DateTime'];
  lastModified: Scalars['DateTime'];
  packDefinition: AnalysisPackDefinition;
  packTypes: AnalysisPackTypes;
};

export type AnalysisPackSource = {
  __typename?: 'AnalysisPackSource';
  id: Scalars['ID'];
  githubAccessToken?: Maybe<Scalars['String']>;
  owner: Scalars['String'];
  repository: Scalars['String'];
  kmsKey?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Actor>;
  lastModified: Scalars['DateTime'];
  lastModifiedBy?: Maybe<Actor>;
};

export enum ReplayState {
  Done = 'DONE',
  Unknown = 'UNKNOWN',
  Canceled = 'CANCELED',
  EvaluationInProgress = 'EVALUATION_IN_PROGRESS',
  ComputationInProgress = 'COMPUTATION_IN_PROGRESS',
  ErrorEvaluation = 'ERROR_EVALUATION',
  ErrorComputation = 'ERROR_COMPUTATION',
}

export type ReplayScope = {
  __typename?: 'ReplayScope';
  endsAt: Scalars['DateTime'];
  startsAt: Scalars['DateTime'];
  logTypes: Array<Scalars['String']>;
};

export type Replay = {
  __typename?: 'Replay';
  id: Scalars['ID'];
  state: ReplayState;
  scope: ReplayScope;
  summary: ReplaySummary;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  detectionId: Scalars['ID'];
};

export type ReplayPreview = {
  __typename?: 'ReplayPreview';
  timeEstimate: Scalars['Int'];
  logDataSizeEstimate: Scalars['Int'];
};

export type ReplaySummary = {
  __typename?: 'ReplaySummary';
  totalAlerts: Scalars['Int'];
  completedAt?: Maybe<Scalars['DateTime']>;
  ruleErrorCount: Scalars['Int'];
  ruleMatchCount: Scalars['Int'];
  evaluationProgress: Scalars['Int'];
  computationProgress: Scalars['Int'];
  logDataSizeEstimate: Scalars['Int'];
  matchesProcessedCount: Scalars['Int'];
};

export type StopReplayInput = {
  id: Scalars['ID'];
};

export type ComputeReplayInput = {
  id: Scalars['ID'];
};

export type StopReplayOutput = {
  __typename?: 'StopReplayOutput';
  replay: Replay;
};

export type CreateReplayOutput = {
  __typename?: 'CreateReplayOutput';
  replay: Replay;
};

export type ComputeReplayOutput = {
  __typename?: 'ComputeReplayOutput';
  replay: Replay;
};

export type CreateReplayInput = {
  endsAt: Scalars['DateTime'];
  startsAt: Scalars['DateTime'];
  logTypes: Array<Scalars['String']>;
  detectionId: Scalars['ID'];
};

export type ReplayedAlertsInput = {
  replayId: Scalars['ID'];
  pageSize: Scalars['Int'];
  types?: Maybe<Array<Scalars['String']>>;
  cursor?: Maybe<Scalars['String']>;
  sortDir?: Maybe<Scalars['String']>;
  logTypes?: Maybe<Array<Scalars['String']>>;
  severities?: Maybe<Array<Scalars['String']>>;
  eventCountMin?: Maybe<Scalars['Int']>;
  eventCountMax?: Maybe<Scalars['Int']>;
  nameContains?: Maybe<Scalars['String']>;
  createdAtAfter?: Maybe<Scalars['DateTime']>;
  createdAtBefore?: Maybe<Scalars['DateTime']>;
};

export type ReplayedAlertsOutput = {
  __typename?: 'ReplayedAlertsOutput';
  alerts: Array<Alert>;
  cursor?: Maybe<Scalars['String']>;
};

export type MitreMatrixTree = {
  __typename?: 'MitreMatrixTree';
  matrices: Array<MitreMatrixTreeNode>;
  mitreVersion: Scalars['String'];
  mitreVersionUrl: Scalars['String'];
  defaultMatrixId: Scalars['String'];
  enabledLogTypes: Array<Scalars['String']>;
};

export type MitreMatrixTreeNode = {
  __typename?: 'MitreMatrixTreeNode';
  id: Scalars['ID'];
  name: Scalars['String'];
  submatrices: Array<MitreMatrixTreeNode>;
};

export type MitreMatrix = {
  __typename?: 'MitreMatrix';
  id: Scalars['ID'];
  name: Scalars['String'];
  content: MitreMatrixContent;
  mappings: Array<MitreMatrixTacticMapping>;
  reportSettings: Array<ReportSetting>;
};

export type MitreMatrixTacticMapping = {
  __typename?: 'MitreMatrixTacticMapping';
  tacticId: Scalars['ID'];
  techniqueIds: Array<Scalars['ID']>;
};

export type MitreMatrixContent = {
  __typename?: 'MitreMatrixContent';
  tactics: Array<MitreTactic>;
  techniques: Array<MitreTechnique>;
};

export type MitreTactic = {
  __typename?: 'MitreTactic';
  id: Scalars['ID'];
  url: Scalars['String'];
  name: Scalars['String'];
};

export type MitreTechnique = {
  __typename?: 'MitreTechnique';
  id: Scalars['ID'];
  url: Scalars['String'];
  name: Scalars['String'];
};

export enum ReportType {
  Mitre = 'MITRE',
  Unknown = 'UNKNOWN',
}

export type ReportSetting = {
  __typename?: 'ReportSetting';
  id: Scalars['ID'];
  note: Scalars['String'];
  override: ReportOverrideSetting;
  updatedBy?: Maybe<Actor>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  reportType: ReportType;
  detections: Array<Detection>;
  association: ReportSettingAssociation;
  matchingLogTypes: Array<Scalars['String']>;
  matchingDetectionIds: Array<Scalars['String']>;
};

export type ReportSettingAssociation = {
  __typename?: 'ReportSettingAssociation';
  mitre: ReportSettingAssociationMitre;
};

export type ReportSettingAssociationMitre = {
  __typename?: 'ReportSettingAssociationMitre';
  tactic: MitreTactic;
  technique: MitreTechnique;
  tacticId: Scalars['String'];
  techniqueId: Scalars['String'];
};

export enum ReportOverrideSetting {
  None = 'NONE',
  Ignored = 'IGNORED',
  Covered = 'COVERED',
  Unknown = 'UNKNOWN',
  NotCovered = 'NOT_COVERED',
}

export type UpdateMitreReportSettings = {
  note: Scalars['String'];
  override: ReportOverrideSetting;
  tacticId: Scalars['ID'];
  techniqueId: Scalars['ID'];
};

export type UpdateMitreReportMappingInput = {
  add: UpdateMitreReportMappingSelection;
  remove: UpdateMitreReportMappingSelection;
  reportKeys: Array<Scalars['String']>;
};

export type UpdateMitreReportMappingSelection = {
  detectionIds: Array<Scalars['ID']>;
};

export type UpdateMitreReportMappingOutput = {
  __typename?: 'UpdateMitreReportMappingOutput';
  detections: Array<Detection>;
};

export type ResourcesForPolicyInput = {
  policyId?: Maybe<Scalars['ID']>;
  status?: Maybe<ComplianceStatusEnum>;
  suppressed?: Maybe<Scalars['Boolean']>;
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type ListResourcesResponse = {
  __typename?: 'ListResourcesResponse';
  paging?: Maybe<PagingData>;
  resources?: Maybe<Array<Maybe<ResourceSummary>>>;
};

export type GetResourceInput = {
  resourceId: Scalars['ID'];
};

export type ListResourcesInput = {
  complianceStatus?: Maybe<ComplianceStatusEnum>;
  deleted?: Maybe<Scalars['Boolean']>;
  idContains?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['ID']>;
  types?: Maybe<Array<Scalars['String']>>;
  sortBy?: Maybe<ListResourcesSortFieldsEnum>;
  sortDir?: Maybe<SortDirEnum>;
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type ListComplianceItemsResponse = {
  __typename?: 'ListComplianceItemsResponse';
  items?: Maybe<Array<Maybe<ComplianceItem>>>;
  paging?: Maybe<PagingData>;
  status?: Maybe<ComplianceStatusEnum>;
  totals?: Maybe<ActiveSuppressCount>;
};

export type ComplianceItem = {
  __typename?: 'ComplianceItem';
  errorMessage?: Maybe<Scalars['String']>;
  lastUpdated?: Maybe<Scalars['DateTime']>;
  policyId?: Maybe<Scalars['ID']>;
  policySeverity?: Maybe<Severity>;
  resourceId?: Maybe<Scalars['ID']>;
  resourceType?: Maybe<Scalars['String']>;
  status?: Maybe<ComplianceStatusEnum>;
  suppressed?: Maybe<Scalars['Boolean']>;
  integration?: Maybe<ComplianceIntegration>;
};

export type ActiveSuppressCount = {
  __typename?: 'ActiveSuppressCount';
  active?: Maybe<ComplianceStatusCounts>;
  suppressed?: Maybe<ComplianceStatusCounts>;
};

export type ResourceSummary = {
  __typename?: 'ResourceSummary';
  id?: Maybe<Scalars['ID']>;
  integration?: Maybe<ComplianceIntegration>;
  complianceStatus?: Maybe<ComplianceStatusEnum>;
  deleted?: Maybe<Scalars['Boolean']>;
  lastModified?: Maybe<Scalars['DateTime']>;
  type?: Maybe<Scalars['String']>;
};

export type ResourceDetails = {
  __typename?: 'ResourceDetails';
  attributes?: Maybe<Scalars['JSON']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  integration?: Maybe<ComplianceIntegration>;
  complianceStatus?: Maybe<ComplianceStatusEnum>;
  lastModified?: Maybe<Scalars['DateTime']>;
  type?: Maybe<Scalars['String']>;
};

export enum ComplianceStatusEnum {
  Error = 'ERROR',
  Fail = 'FAIL',
  Pass = 'PASS',
}

export enum ListResourcesSortFieldsEnum {
  ComplianceStatus = 'complianceStatus',
  Id = 'id',
  LastModified = 'lastModified',
  Type = 'type',
}

/** The input to the createRole mutation */
export type CreateRoleInput = {
  /**
   * logTypeAccess must include the log types that the role is allowed or denied to access
   * according with the logTypeAccessKind field.
   * This field must be null if logTypeAccessKind is ALLOW_ALL or DENY_ALL.
   * If the logTypeAccessKind is ALLOW or DENY, this field must be a non-empty array.
   */
  logTypeAccess?: Maybe<Array<Scalars['String']>>;
  logTypeAccessKind?: Maybe<LogTypeAccessKind>;
  /** The name of the role */
  name: Scalars['String'];
  /** An array of the role's permissions */
  permissions: Array<Permission>;
};

/** The input to the updateRole mutation */
export type UpdateRoleInput = {
  /** The ID of the role to update */
  id: Scalars['ID'];
  /**
   * logTypeAccess must include the log types that the role is allowed or denied to access
   * according with the logTypeAccessKind field.
   * This field must be null if logTypeAccessKind is ALLOW_ALL or DENY_ALL.
   * If the logTypeAccessKind is ALLOW or DENY, this field must be a non-empty array.
   */
  logTypeAccess?: Maybe<Array<Scalars['String']>>;
  logTypeAccessKind?: Maybe<LogTypeAccessKind>;
  /** A new name for the role */
  name?: Maybe<Scalars['String']>;
  /** An array of the role's new permissions */
  permissions?: Maybe<Array<Permission>>;
};

/** The filter input to the Roles query */
export type RolesInput = {
  /** A string to search for in the Role name */
  nameContains?: Maybe<Scalars['String']>;
  /** The sort direction of the results */
  sortDir?: Maybe<SortDirEnum>;
};

/** The input to the deleteRole mutation */
export type DeleteRoleInput = {
  /** The ID of the role */
  id: Scalars['ID'];
};

/** The output to the deleteRole mutation */
export type DeleteRoleOutput = {
  __typename?: 'DeleteRoleOutput';
  /** The ID of the deleted role */
  id: Scalars['ID'];
};

/** The output to the createRole mutation */
export type CreateRoleOutput = {
  __typename?: 'CreateRoleOutput';
  /** The created role */
  role: Role;
};

/** The output to the updateRole mutation */
export type UpdateRoleOutput = {
  __typename?: 'UpdateRoleOutput';
  /** The updated role */
  role: Role;
};

/** The details of a Role entity in Panther */
export type Role = {
  __typename?: 'Role';
  /** Date and time when the Role got created */
  createdAt: Scalars['Timestamp'];
  /** The unique identifier of this Role */
  id: Scalars['ID'];
  /**
   * The set of log types the Role is allowed/denied access to, according to the `logTypeAccessKind` field.
   * Null if logTypeAccessKind is ALLOW_ALL or DENY_ALL.
   */
  logTypeAccess?: Maybe<Array<Scalars['String']>>;
  /** Indicates whether the Role is allowed or denied to query the selected log types */
  logTypeAccessKind: LogTypeAccessKind;
  /** The name given to this Role */
  name: Scalars['String'];
  /** The set of permissions associated with the Role (and it's holder) */
  permissions: Array<Permission>;
  /** Date and time when the Role got last updated */
  updatedAt: Scalars['Timestamp'];
  /** The Actor that last updated this Role */
  updatedBy?: Maybe<Actor>;
};

/** The supported types for log types access management */
export enum LogTypeAccessKind {
  /** Allow access to selected log types */
  Allow = 'ALLOW',
  /** Allow access to all log types */
  AllowAll = 'ALLOW_ALL',
  /** Deny access to the selected log types */
  Deny = 'DENY',
  /** Deny access to all log types */
  DenyAll = 'DENY_ALL',
}

export type AddSavedQueryInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  sqlQuery: Scalars['String'];
  disable?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Scalars['String']>>;
  defaultDatabase?: Maybe<Scalars['String']>;
  schedule?: Maybe<ScheduleInput>;
  parentId?: Maybe<Scalars['String']>;
};

export type UpdateSavedQueryInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  sqlQuery: Scalars['String'];
  disable?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Scalars['String']>>;
  defaultDatabase?: Maybe<Scalars['String']>;
  schedule?: Maybe<ScheduleInput>;
  parentId?: Maybe<Scalars['String']>;
};

export type DeleteSavedQueryInput = {
  ids: Array<Scalars['ID']>;
};

export type ListSavedQueriesInput = {
  pageSize?: Maybe<Scalars['Int']>;
  exclusiveStartKey?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  showDeleted?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['ID']>;
  sortDir?: Maybe<SortDirEnum>;
  isScheduled?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Scalars['String']>>;
  packIds?: Maybe<Array<Scalars['String']>>;
};

export type ScheduleInput = {
  disabled?: Maybe<Scalars['Boolean']>;
  cronExpression?: Maybe<Scalars['String']>;
  rateMinutes?: Maybe<Scalars['Int']>;
  timeout: Scalars['Int'];
};

export type Schedule = {
  __typename?: 'Schedule';
  cronExpression?: Maybe<Scalars['String']>;
  disabled: Scalars['Boolean'];
  rateMinutes?: Maybe<Scalars['Int']>;
  timeout: Scalars['Int'];
};

export type SavedQuery = {
  __typename?: 'SavedQuery';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  sqlQuery: Scalars['String'];
  tags?: Maybe<Array<Scalars['String']>>;
  schedule?: Maybe<Schedule>;
  defaultDatabase?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Actor>;
  lastModifiedAt: Scalars['DateTime'];
  lastModifiedBy?: Maybe<Actor>;
  managed?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<SavedQuery>;
};

export type ListSavedQueriesResponse = {
  __typename?: 'ListSavedQueriesResponse';
  savedQueries: Array<SavedQuery>;
  lastEvaluatedKey?: Maybe<Scalars['String']>;
};

export type EventThresholdAlarmInput = {
  integrationId: Scalars['ID'];
  minutesThreshold: Scalars['Int'];
};

export type GetComplianceIntegrationTemplateInput = {
  integrationLabel: Scalars['String'];
};

export type GetTerraformTemplateInput = {
  integrationType: Scalars['String'];
  integrationLabel: Scalars['String'];
};

export type GetS3LogIntegrationTemplateInput = {
  integrationLabel: Scalars['String'];
  s3Bucket: Scalars['String'];
  kmsKey?: Maybe<Scalars['String']>;
  managedBucketNotifications: Scalars['Boolean'];
};

export type GetCloudWatchLogIntegrationTemplateInput = {
  integrationLabel: Scalars['String'];
  cloudwatchLogGroup: Scalars['String'];
  cloudwatchFilter?: Maybe<Scalars['String']>;
  managedBucketNotifications: Scalars['Boolean'];
};

export type SqsConfig = {
  __typename?: 'SqsConfig';
  allowedPrincipalArns?: Maybe<Array<Maybe<Scalars['String']>>>;
  allowedSourceArns?: Maybe<Array<Maybe<Scalars['String']>>>;
  s3Bucket: Scalars['String'];
  s3Prefix?: Maybe<Scalars['String']>;
  logProcessingRole?: Maybe<Scalars['String']>;
  queueUrl: Scalars['String'];
};

export type GcsConfig = {
  __typename?: 'GcsConfig';
  projectId: Scalars['String'];
  subscriptionId: Scalars['String'];
  gcsBucket: Scalars['String'];
  credentials: Scalars['String'];
  userEmail: Scalars['String'];
  logTypes: Array<Scalars['String']>;
};

export type AWSScanConfig = {
  __typename?: 'AWSScanConfig';
  auditRole: Scalars['String'];
};

export type LogIntegration = {
  createdAtTime: Scalars['DateTime'];
  createdBy?: Maybe<Actor>;
  integrationId: Scalars['ID'];
  integrationLabel: Scalars['String'];
  integrationType: Scalars['String'];
  logTypes: Array<Scalars['String']>;
  alarms: LogIntegrationAlarms;
  isHealthy: Scalars['Boolean'];
  lastEventReceived?: Maybe<Scalars['DateTime']>;
  lastModified?: Maybe<Scalars['DateTime']>;
  isEditable: Scalars['Boolean'];
  isPantherManaged: Scalars['Boolean'];
};

export type ComplianceIntegration = {
  __typename?: 'ComplianceIntegration';
  awsAccountId: Scalars['String'];
  createdAtTime: Scalars['DateTime'];
  createdBy?: Maybe<Actor>;
  integrationId: Scalars['ID'];
  integrationLabel: Scalars['String'];
  regionIgnoreList?: Maybe<Array<Scalars['String']>>;
  resourceTypeIgnoreList?: Maybe<Array<Scalars['String']>>;
  resourceRegexIgnoreList?: Maybe<Array<Scalars['String']>>;
  health: ComplianceIntegrationHealth;
  stackName: Scalars['String'];
  realtimeScanningEnabled: Scalars['Boolean'];
  lastModified?: Maybe<Scalars['DateTime']>;
  isEditable: Scalars['Boolean'];
  awsScanConfig?: Maybe<AWSScanConfig>;
  isPantherManaged: Scalars['Boolean'];
};

export type LogIntegrationAlarms = {
  __typename?: 'LogIntegrationAlarms';
  eventThreshold?: Maybe<EventThresholdAlarm>;
};

export type S3PrefixLogTypes = {
  __typename?: 'S3PrefixLogTypes';
  prefix: Scalars['String'];
  logTypes: Array<Scalars['String']>;
  excludedPrefixes: Array<Scalars['String']>;
};

export type S3LogIntegration = LogIntegration & {
  __typename?: 'S3LogIntegration';
  awsAccountId: Scalars['String'];
  createdAtTime: Scalars['DateTime'];
  createdBy?: Maybe<Actor>;
  integrationId: Scalars['ID'];
  integrationType: Scalars['String'];
  integrationLabel: Scalars['String'];
  logProviderId?: Maybe<Scalars['String']>;
  lastEventReceived?: Maybe<Scalars['DateTime']>;
  s3Bucket: Scalars['String'];
  s3Prefix?: Maybe<Scalars['String']>;
  kmsKey?: Maybe<Scalars['String']>;
  s3PrefixLogTypes: Array<S3PrefixLogTypes>;
  logTypes: Array<Scalars['String']>;
  managedBucketNotifications: Scalars['Boolean'];
  notificationsConfigurationSucceeded: Scalars['Boolean'];
  health: S3LogIntegrationHealth;
  isHealthy: Scalars['Boolean'];
  logProcessingRole?: Maybe<Scalars['String']>;
  stackName: Scalars['String'];
  alarms: LogIntegrationAlarms;
  logStreamType?: Maybe<LogStreamTypeEnum>;
  lastModified?: Maybe<Scalars['DateTime']>;
  isEditable: Scalars['Boolean'];
  isPantherManaged: Scalars['Boolean'];
};

export type CloudWatchLogIntegration = LogIntegration & {
  __typename?: 'CloudWatchLogIntegration';
  awsAccountId: Scalars['String'];
  createdAtTime: Scalars['DateTime'];
  createdBy?: Maybe<Actor>;
  integrationId: Scalars['ID'];
  integrationType: Scalars['String'];
  integrationLabel: Scalars['String'];
  logProviderId?: Maybe<Scalars['String']>;
  lastEventReceived?: Maybe<Scalars['DateTime']>;
  s3Bucket: Scalars['String'];
  managedBucketNotifications: Scalars['Boolean'];
  notificationsConfigurationSucceeded: Scalars['Boolean'];
  health: CloudWatchLogIntegrationHealth;
  isHealthy: Scalars['Boolean'];
  logProcessingRole?: Maybe<Scalars['String']>;
  stackName: Scalars['String'];
  alarms: LogIntegrationAlarms;
  logGroup: Scalars['String'];
  logTypes: Array<Scalars['String']>;
  filterPattern: Scalars['String'];
  lastModified?: Maybe<Scalars['DateTime']>;
  isEditable: Scalars['Boolean'];
  isPantherManaged: Scalars['Boolean'];
};

export type EventBridgeIntegration = LogIntegration & {
  __typename?: 'EventBridgeIntegration';
  createdAtTime: Scalars['DateTime'];
  createdBy?: Maybe<Actor>;
  integrationId: Scalars['ID'];
  integrationLabel: Scalars['String'];
  integrationType: Scalars['String'];
  logProviderId?: Maybe<Scalars['String']>;
  lastEventReceived?: Maybe<Scalars['DateTime']>;
  eventbridgeConfig: EventBridgeConfig;
  logTypes: Array<Scalars['String']>;
  health: EventBridgeIntegrationHealth;
  isHealthy: Scalars['Boolean'];
  alarms: LogIntegrationAlarms;
  lastModified?: Maybe<Scalars['DateTime']>;
  isEditable: Scalars['Boolean'];
  isPantherManaged: Scalars['Boolean'];
};

export type SqsLogSourceIntegration = LogIntegration & {
  __typename?: 'SqsLogSourceIntegration';
  createdAtTime: Scalars['DateTime'];
  createdBy?: Maybe<Actor>;
  integrationId: Scalars['ID'];
  integrationLabel: Scalars['String'];
  integrationType: Scalars['String'];
  logProviderId?: Maybe<Scalars['String']>;
  lastEventReceived?: Maybe<Scalars['DateTime']>;
  logTypes: Array<Scalars['String']>;
  sqsConfig: SqsConfig;
  health: SqsLogIntegrationHealth;
  isHealthy: Scalars['Boolean'];
  alarms: LogIntegrationAlarms;
  lastModified?: Maybe<Scalars['DateTime']>;
  isEditable: Scalars['Boolean'];
  isPantherManaged: Scalars['Boolean'];
};

export type GcsLogSourceIntegration = LogIntegration & {
  __typename?: 'GcsLogSourceIntegration';
  createdAtTime: Scalars['DateTime'];
  createdBy?: Maybe<Actor>;
  integrationId: Scalars['ID'];
  integrationLabel: Scalars['String'];
  integrationType: Scalars['String'];
  logProviderId?: Maybe<Scalars['String']>;
  lastEventReceived?: Maybe<Scalars['DateTime']>;
  logTypes: Array<Scalars['String']>;
  gcsConfig: GcsConfig;
  health: GcsLogIntegrationHealth;
  isHealthy: Scalars['Boolean'];
  alarms: LogIntegrationAlarms;
  lastModified?: Maybe<Scalars['DateTime']>;
  isEditable: Scalars['Boolean'];
  isPantherManaged: Scalars['Boolean'];
};

export type LogPullingIntegration = LogIntegration & {
  __typename?: 'LogPullingIntegration';
  createdAtTime: Scalars['DateTime'];
  createdBy?: Maybe<Actor>;
  integrationId: Scalars['ID'];
  integrationLabel: Scalars['String'];
  integrationType: Scalars['String'];
  pullerType: LogPullerTypeEnum;
  lastEventReceived?: Maybe<Scalars['DateTime']>;
  pullerConfig: PullerConfig;
  logTypes: Array<Scalars['String']>;
  health: LogPullingIntegrationHealth;
  isHealthy: Scalars['Boolean'];
  alarms: LogIntegrationAlarms;
  oauth2?: Maybe<OAuth2Info>;
  lastModified?: Maybe<Scalars['DateTime']>;
  isEditable: Scalars['Boolean'];
  isPantherManaged: Scalars['Boolean'];
};

export type OAuth2Info = {
  __typename?: 'OAuth2Info';
  mustAuthorize: Scalars['Boolean'];
};

export type AddComplianceIntegrationInput = {
  awsAccountId: Scalars['String'];
  awsScanConfig: AWSScanConfigInput;
  integrationLabel: Scalars['String'];
  regionIgnoreList?: Maybe<Array<Scalars['String']>>;
  resourceTypeIgnoreList?: Maybe<Array<Scalars['String']>>;
  resourceRegexIgnoreList?: Maybe<Array<Scalars['String']>>;
};

export type S3PrefixLogTypesInput = {
  prefix: Scalars['String'];
  excludedPrefixes: Array<Scalars['String']>;
  logTypes: Array<Scalars['String']>;
};

export type AddS3LogIntegrationInput = {
  awsAccountId: Scalars['String'];
  integrationLabel: Scalars['String'];
  logProviderId?: Maybe<Scalars['String']>;
  s3Bucket: Scalars['String'];
  kmsKey?: Maybe<Scalars['String']>;
  s3PrefixLogTypes: Array<S3PrefixLogTypesInput>;
  managedBucketNotifications: Scalars['Boolean'];
  logProcessingRole: Scalars['String'];
  logStreamType?: Maybe<LogStreamTypeEnum>;
};

export type AddCloudWatchLogIntegrationInput = {
  awsAccountId: Scalars['String'];
  integrationLabel: Scalars['String'];
  logProviderId?: Maybe<Scalars['String']>;
  s3Bucket: Scalars['String'];
  managedBucketNotifications: Scalars['Boolean'];
  logProcessingRole: Scalars['String'];
  logGroup: Scalars['String'];
  logTypes: Array<Scalars['String']>;
  filterPattern?: Maybe<Scalars['String']>;
};

export type AddEventBridgeIntegrationInput = {
  integrationLabel: Scalars['String'];
  logProviderId?: Maybe<Scalars['String']>;
  busName: Scalars['String'];
  logType: Scalars['String'];
};

export type AsanaLogSourceConfigInput = {
  organizationId: Scalars['String'];
  serviceAccountToken: Scalars['String'];
};

export type OktaConfigInput = {
  domain: Scalars['String'];
  apiToken: Scalars['String'];
};

export type GsuiteConfigInput = {
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
  applications: Array<Scalars['String']>;
};

export type CrowdstrikeInput = {
  awsAccessKey: Scalars['String'];
  awsAccessSecret: Scalars['String'];
  queueUrl: Scalars['String'];
};

export type DuoConfigInput = {
  integrationKey: Scalars['String'];
  secretKey: Scalars['String'];
  apiHostname: Scalars['String'];
};

export type SalesforceConfigInput = {
  username: Scalars['String'];
  password: Scalars['String'];
  securityToken: Scalars['String'];
  fileInterval: Scalars['String'];
};

export type OAuthConfigInput = {
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
};

export type M365ConfigInput = {
  tenantId: Scalars['String'];
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
};

export type MicrosoftGraphConfigInput = {
  tenantId: Scalars['String'];
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
};

export type GithubOAuth2ConfigInput = {
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
};

export type GithubPatConfigInput = {
  personalAccessToken: Scalars['String'];
};

export type GithubLogSourceInput = {
  organization: Scalars['String'];
  authorizationMethod: GithubAuthorizationMethodEnum;
  oauth2Credentials?: Maybe<GithubOAuth2ConfigInput>;
  patCredentials?: Maybe<GithubPatConfigInput>;
};

export type OnePasswordConfigInput = {
  token: Scalars['String'];
  host: OnePasswordHost;
};

export type AtlassianConfigInput = {
  organization: Scalars['String'];
  apiKey: Scalars['String'];
};

export type ZendeskOAuth2ConfigInput = {
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
};

export type GetLookupCloudFormationRoleTemplateInput = {
  s3FileLocation: Scalars['String'];
  integrationLabel: Scalars['String'];
  kmsKey?: Maybe<Scalars['String']>;
};

export type ZendeskBasicConfigInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type ZendeskApiConfigInput = {
  email: Scalars['String'];
  apiToken: Scalars['String'];
};

export type ZendeskLogSourceInput = {
  subdomain: Scalars['String'];
  authorizationMethod: ZendeskAuthorizationMethodEnum;
  oauth2Credentials?: Maybe<ZendeskOAuth2ConfigInput>;
  basicCredentials?: Maybe<ZendeskBasicConfigInput>;
  apiCredentials?: Maybe<ZendeskApiConfigInput>;
};

export type WorkdayConfigInput = {
  tenantHostName: Scalars['String'];
  tenantName: Scalars['String'];
  activityClientId?: Maybe<Scalars['String']>;
  activityClientSecret?: Maybe<Scalars['String']>;
  activityRefreshToken?: Maybe<Scalars['String']>;
  signOnCustomReport?: Maybe<Scalars['String']>;
  signOnAccountName?: Maybe<Scalars['String']>;
  integrationUsername?: Maybe<Scalars['String']>;
  integrationPassword?: Maybe<Scalars['String']>;
};

export type SnykConfigInput = {
  orgId: Scalars['String'];
  apiToken: Scalars['String'];
};

export type PullerConfigInput = {
  logTypes?: Maybe<Array<Scalars['String']>>;
  asana?: Maybe<AsanaLogSourceConfigInput>;
  okta?: Maybe<OktaConfigInput>;
  gsuite?: Maybe<GsuiteConfigInput>;
  box?: Maybe<OAuthConfigInput>;
  slack?: Maybe<OAuthConfigInput>;
  zoom?: Maybe<OAuthConfigInput>;
  dropbox?: Maybe<OAuthConfigInput>;
  crowdstrike?: Maybe<CrowdstrikeInput>;
  github?: Maybe<GithubLogSourceInput>;
  zendesk?: Maybe<ZendeskLogSourceInput>;
  duo?: Maybe<DuoConfigInput>;
  salesforce?: Maybe<SalesforceConfigInput>;
  m365?: Maybe<M365ConfigInput>;
  onePassword?: Maybe<OnePasswordConfigInput>;
  atlassian?: Maybe<AtlassianConfigInput>;
  workday?: Maybe<WorkdayConfigInput>;
  snyk?: Maybe<SnykConfigInput>;
  microsoftGraph?: Maybe<MicrosoftGraphConfigInput>;
};

export type AddLogPullingIntegrationInput = {
  integrationLabel: Scalars['String'];
  pullerConfig: PullerConfigInput;
};

export type SqsLogConfigInput = {
  logTypes: Array<Scalars['String']>;
  allowedPrincipalArns: Array<Maybe<Scalars['String']>>;
  allowedSourceArns: Array<Maybe<Scalars['String']>>;
};

export type GcsLogConfigInput = {
  projectId: Scalars['String'];
  subscriptionId: Scalars['String'];
  gcsBucket: Scalars['String'];
  credentials: Scalars['String'];
  userEmail: Scalars['String'];
  logTypes: Array<Scalars['String']>;
};

export type AWSScanConfigInput = {
  auditRole: Scalars['String'];
};

export type AddSqsLogIntegrationInput = {
  integrationLabel: Scalars['String'];
  logProviderId?: Maybe<Scalars['String']>;
  sqsConfig: SqsLogConfigInput;
};

export type AddGcsLogIntegrationInput = {
  integrationLabel: Scalars['String'];
  logProviderId?: Maybe<Scalars['String']>;
  gcsConfig: GcsLogConfigInput;
};

export type UpdateComplianceIntegrationInput = {
  integrationId: Scalars['String'];
  integrationLabel?: Maybe<Scalars['String']>;
  awsScanConfig: AWSScanConfigInput;
  regionIgnoreList?: Maybe<Array<Scalars['String']>>;
  resourceTypeIgnoreList?: Maybe<Array<Scalars['String']>>;
  resourceRegexIgnoreList?: Maybe<Array<Scalars['String']>>;
};

export type UpdateS3LogIntegrationInput = {
  integrationId: Scalars['String'];
  integrationLabel?: Maybe<Scalars['String']>;
  s3Bucket?: Maybe<Scalars['String']>;
  kmsKey?: Maybe<Scalars['String']>;
  s3PrefixLogTypes?: Maybe<Array<S3PrefixLogTypesInput>>;
  logProcessingRole: Scalars['String'];
  managedBucketNotifications?: Maybe<Scalars['Boolean']>;
  logStreamType?: Maybe<LogStreamTypeEnum>;
};

export type UpdateCloudWatchLogIntegrationInput = {
  integrationId: Scalars['String'];
  integrationLabel?: Maybe<Scalars['String']>;
  s3Bucket?: Maybe<Scalars['String']>;
  logProcessingRole: Scalars['String'];
  managedBucketNotifications?: Maybe<Scalars['Boolean']>;
  logGroup: Scalars['String'];
  logTypes: Array<Scalars['String']>;
  filterPattern?: Maybe<Scalars['String']>;
};

export type UpdateEventbridgeIntegrationInput = {
  integrationId: Scalars['String'];
  integrationLabel: Scalars['String'];
  busName: Scalars['String'];
  logType: Scalars['String'];
};

export type UpdateLogPullingIntegrationInput = {
  integrationId: Scalars['String'];
  integrationLabel: Scalars['String'];
  pullerConfig: PullerConfigInput;
};

export type UpdateSqsLogIntegrationInput = {
  integrationId: Scalars['String'];
  integrationLabel: Scalars['String'];
  sqsConfig: SqsLogConfigInput;
};

export type UpdateGcsLogIntegrationInput = {
  integrationId: Scalars['String'];
  integrationLabel: Scalars['String'];
  gcsConfig: GcsLogConfigInput;
};

export type SourceMetricsInput = {
  integrationId: Scalars['String'];
  intervalInMinutes?: Maybe<Scalars['Int']>;
  fromDate: Scalars['DateTime'];
  toDate: Scalars['DateTime'];
};

export type IntegrationTemplate = {
  __typename?: 'IntegrationTemplate';
  body: Scalars['String'];
  stackName: Scalars['String'];
};

export type TerraformTemplateResponse = {
  __typename?: 'TerraformTemplateResponse';
  data: Scalars['String'];
  stackName: Scalars['String'];
};

export type IntegrationItemHealthStatus = {
  __typename?: 'IntegrationItemHealthStatus';
  healthy: Scalars['Boolean'];
  message: Scalars['String'];
  rawErrorMessage?: Maybe<Scalars['String']>;
  type?: Maybe<SourceHealthTypesEnum>;
};

export type ComplianceIntegrationHealth = {
  __typename?: 'ComplianceIntegrationHealth';
  auditRoleStatus: IntegrationItemHealthStatus;
  realtimeStatus: IntegrationItemHealthStatus;
};

export type S3LogIntegrationHealth = {
  __typename?: 'S3LogIntegrationHealth';
  processingRoleStatus: IntegrationItemHealthStatus;
  s3BucketStatus?: Maybe<IntegrationItemHealthStatus>;
  kmsKeyStatus?: Maybe<IntegrationItemHealthStatus>;
  bucketNotificationsStatus?: Maybe<IntegrationItemHealthStatus>;
  systemAlarmStatus?: Maybe<IntegrationItemHealthStatus>;
  logProcessingErrorsStatus?: Maybe<IntegrationItemHealthStatus>;
  classificationFailuresStatus?: Maybe<IntegrationItemHealthStatus>;
};

export type CloudWatchLogIntegrationHealth = {
  __typename?: 'CloudWatchLogIntegrationHealth';
  processingRoleStatus: IntegrationItemHealthStatus;
  s3BucketStatus?: Maybe<IntegrationItemHealthStatus>;
  bucketNotificationsStatus?: Maybe<IntegrationItemHealthStatus>;
  systemAlarmStatus?: Maybe<IntegrationItemHealthStatus>;
  logProcessingErrorsStatus?: Maybe<IntegrationItemHealthStatus>;
  classificationFailuresStatus?: Maybe<IntegrationItemHealthStatus>;
};

export type EventBridgeIntegrationHealth = {
  __typename?: 'EventBridgeIntegrationHealth';
  eventBridgeStatus?: Maybe<IntegrationItemHealthStatus>;
  systemAlarmStatus?: Maybe<IntegrationItemHealthStatus>;
  logProcessingErrorsStatus?: Maybe<IntegrationItemHealthStatus>;
  classificationFailuresStatus?: Maybe<IntegrationItemHealthStatus>;
};

export type LogPullingIntegrationHealth = {
  __typename?: 'LogPullingIntegrationHealth';
  logPullingStatus?: Maybe<IntegrationItemHealthStatus>;
  systemAlarmStatus?: Maybe<IntegrationItemHealthStatus>;
  logProcessingErrorsStatus?: Maybe<IntegrationItemHealthStatus>;
  classificationFailuresStatus?: Maybe<IntegrationItemHealthStatus>;
};

export type SqsLogIntegrationHealth = {
  __typename?: 'SqsLogIntegrationHealth';
  sqsStatus?: Maybe<IntegrationItemHealthStatus>;
  systemAlarmStatus?: Maybe<IntegrationItemHealthStatus>;
  logProcessingErrorsStatus?: Maybe<IntegrationItemHealthStatus>;
  classificationFailuresStatus?: Maybe<IntegrationItemHealthStatus>;
};

export type GcsLogIntegrationHealth = {
  __typename?: 'GcsLogIntegrationHealth';
  systemAlarmStatus?: Maybe<IntegrationItemHealthStatus>;
  logProcessingErrorsStatus?: Maybe<IntegrationItemHealthStatus>;
  classificationFailuresStatus?: Maybe<IntegrationItemHealthStatus>;
  gcsStatus?: Maybe<IntegrationItemHealthStatus>;
};

export type EventBridgeConfig = {
  __typename?: 'EventBridgeConfig';
  busName: Scalars['String'];
};

export type OktaConfig = {
  __typename?: 'OktaConfig';
  domain: Scalars['String'];
  apiToken: Scalars['String'];
};

export type GsuiteConfig = {
  __typename?: 'GsuiteConfig';
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
  applications: Array<Scalars['String']>;
};

export type CrowdstrikeConfig = {
  __typename?: 'CrowdstrikeConfig';
  awsAccessKey: Scalars['String'];
  awsAccessSecret: Scalars['String'];
  queueUrl: Scalars['String'];
};

export type DuoConfig = {
  __typename?: 'DuoConfig';
  integrationKey: Scalars['String'];
  secretKey: Scalars['String'];
  apiHostname: Scalars['String'];
};

export type SalesforceConfig = {
  __typename?: 'SalesforceConfig';
  username: Scalars['String'];
  password: Scalars['String'];
  securityToken: Scalars['String'];
  fileInterval: Scalars['String'];
};

export type OAuthConfig = {
  __typename?: 'OAuthConfig';
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
};

export type M365Config = {
  __typename?: 'M365Config';
  tenantId: Scalars['String'];
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
};

export type MicrosoftGraphConfig = {
  __typename?: 'MicrosoftGraphConfig';
  tenantId: Scalars['String'];
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
};

export type GithubLogSourceOAuth2Config = {
  __typename?: 'GithubLogSourceOAuth2Config';
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
};

export type GithubLogSourcePatConfig = {
  __typename?: 'GithubLogSourcePatConfig';
  personalAccessToken: Scalars['String'];
};

export type GithubLogSourceCredentialsConfig =
  | GithubLogSourceOAuth2Config
  | GithubLogSourcePatConfig;

export type GithubLogSourceConfig = {
  __typename?: 'GithubLogSourceConfig';
  organization: Scalars['String'];
  credentials: GithubLogSourceCredentialsConfig;
  authorizationMethod: GithubAuthorizationMethodEnum;
};

export type OnePasswordConfig = {
  __typename?: 'OnePasswordConfig';
  token: Scalars['String'];
  host: OnePasswordHost;
};

export type AtlassianConfig = {
  __typename?: 'AtlassianConfig';
  organization: Scalars['String'];
  apiKey: Scalars['String'];
};

export type ZendeskLogSourceOAuth2Config = {
  __typename?: 'ZendeskLogSourceOAuth2Config';
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
};

export type ZendeskLogSourceBasicConfig = {
  __typename?: 'ZendeskLogSourceBasicConfig';
  email: Scalars['String'];
  password: Scalars['String'];
};

export type ZendeskLogSourceApiConfig = {
  __typename?: 'ZendeskLogSourceApiConfig';
  email: Scalars['String'];
  apiToken: Scalars['String'];
};

export type ZendeskLogSourceCredentialsConfig =
  | ZendeskLogSourceOAuth2Config
  | ZendeskLogSourceBasicConfig
  | ZendeskLogSourceApiConfig;

export type ZendeskLogSourceConfig = {
  __typename?: 'ZendeskLogSourceConfig';
  subdomain: Scalars['String'];
  credentials: ZendeskLogSourceCredentialsConfig;
  authorizationMethod: ZendeskAuthorizationMethodEnum;
};

export type AsanaLogSourceConfig = {
  __typename?: 'AsanaLogSourceConfig';
  organizationId: Scalars['String'];
  serviceAccountToken: Scalars['String'];
};

export type WorkdayConfig = {
  __typename?: 'WorkdayConfig';
  tenantHostName: Scalars['String'];
  tenantName: Scalars['String'];
  activityClientId: Scalars['String'];
  activityClientSecret: Scalars['String'];
  activityRefreshToken: Scalars['String'];
  signOnCustomReport: Scalars['String'];
  signOnAccountName: Scalars['String'];
  integrationUsername: Scalars['String'];
  integrationPassword: Scalars['String'];
};

export type SnykLogSourceConfig = {
  __typename?: 'SnykLogSourceConfig';
  orgId: Scalars['String'];
  apiToken: Scalars['String'];
};

export type PullerConfig = {
  __typename?: 'PullerConfig';
  asana?: Maybe<AsanaLogSourceConfig>;
  okta?: Maybe<OktaConfig>;
  gsuite?: Maybe<GsuiteConfig>;
  box?: Maybe<OAuthConfig>;
  slack?: Maybe<OAuthConfig>;
  zoom?: Maybe<OAuthConfig>;
  dropbox?: Maybe<OAuthConfig>;
  crowdstrike?: Maybe<CrowdstrikeConfig>;
  github?: Maybe<GithubLogSourceConfig>;
  zendesk?: Maybe<ZendeskLogSourceConfig>;
  duo?: Maybe<DuoConfig>;
  salesforce?: Maybe<SalesforceConfig>;
  m365?: Maybe<M365Config>;
  onePassword?: Maybe<OnePasswordConfig>;
  atlassian?: Maybe<AtlassianConfig>;
  workday?: Maybe<WorkdayConfig>;
  snyk?: Maybe<SnykLogSourceConfig>;
  microsoftGraph?: Maybe<MicrosoftGraphConfig>;
};

export type EventThresholdAlarm = {
  __typename?: 'EventThresholdAlarm';
  minutesThreshold: Scalars['Int'];
};

export type SeriesMetric = {
  __typename?: 'SeriesMetric';
  label: Scalars['String'];
  values: Array<Scalars['Long']>;
};

export type SeriesData = {
  __typename?: 'SeriesData';
  timestamps: Array<Scalars['DateTime']>;
  series: Array<SeriesMetric>;
};

export type SourceMetricsResponse = {
  __typename?: 'SourceMetricsResponse';
  bytesProcessedWithDimensions?: Maybe<SeriesData>;
  eventsProcessedWithDimensions?: Maybe<SeriesData>;
  classificationFailuresWithDimensions?: Maybe<SeriesData>;
};

export enum SourceHealthTypesEnum {
  AuditRoleStatus = 'auditRoleStatus',
  RealtimeStatus = 'realtimeStatus',
  ProcessingRoleStatus = 'processingRoleStatus',
  S3BucketStatus = 's3BucketStatus',
  KmsKeyStatus = 'kmsKeyStatus',
  BucketNotificationsStatus = 'bucketNotificationsStatus',
  SystemAlarmStatus = 'systemAlarmStatus',
  LogProcessingErrorsStatus = 'logProcessingErrorsStatus',
  ClassificationFailuresStatus = 'classificationFailuresStatus',
  SqsStatus = 'sqsStatus',
  EventBridgeStatus = 'eventBridgeStatus',
  LogPullingStatus = 'logPullingStatus',
  GcsStatus = 'gcsStatus',
}

export enum GithubAuthorizationMethodEnum {
  Oauth2 = 'OAUTH2',
  Pat = 'PAT',
}

export enum ZendeskAuthorizationMethodEnum {
  Oauth2 = 'OAUTH2',
  Basic = 'BASIC',
  ApiToken = 'API_TOKEN',
}

export enum LogStreamTypeEnum {
  CloudWatchLogs = 'CloudWatchLogs',
  JsonArray = 'JsonArray',
  Lines = 'Lines',
}

export enum LogPullerTypeEnum {
  Asana = 'asana',
  Gsuite = 'gsuite',
  Slack = 'slack',
  Okta = 'okta',
  Box = 'box',
  Zoom = 'zoom',
  Crowdstrike = 'crowdstrike',
  Duo = 'duo',
  Salesforce = 'salesforce',
  M365 = 'm365',
  Github = 'github',
  Zendesk = 'zendesk',
  Onepassword = 'onepassword',
  Atlassian = 'atlassian',
  Workday = 'workday',
  Dropbox = 'dropbox',
  Snyk = 'snyk',
  Microsoftgraph = 'microsoftgraph',
}

export enum OnePasswordHost {
  Business = 'Business',
  Enterprise = 'Enterprise',
  Canada = 'Canada',
  Europe = 'Europe',
}

/** The input for the resetUserPassword mutation */
export type ResetUserPasswordInput = {
  /** The ID of the User */
  id: Scalars['ID'];
};

/** The input for the sendUserFeedback mutation */
export type SendUserFeedbackInput = {
  /** Information around the feature that the user is submitting feedback for */
  feature: UserFeedbackFeatureInput;
  /** The feedback data that the user submitted */
  feedback: UserFeedbackDataInput;
};

/** The input payload for the `feature` field of the `SendUserFeedbackInput` */
export type UserFeedbackFeatureInput = {
  /** The name of the feature that the user is submitting feedback for */
  name: Scalars['String'];
};

/** The input payload for the `feedback` field of the `SendUserFeedbackInput` */
export type UserFeedbackDataInput = {
  /** The text that the user sent as feedback */
  text: Scalars['String'];
  /** Marks how useful the feature is on a scale of 1 to 5 */
  usefulness?: Maybe<Scalars['Int']>;
  /** The URL of the page that the feedback was given from */
  locationUrl?: Maybe<Scalars['String']>;
  /** The width of the user's screen in pixels */
  screenWidth?: Maybe<Scalars['Int']>;
};

/** The output of the sendUserFeedback mutation */
export type SendUserFeedbackOutput = {
  __typename?: 'SendUserFeedbackOutput';
  /** The ID of the ticket that got created. Included in the email title that got sent as a result of the feedback submission */
  id: Scalars['ID'];
};

/** The Output of resetting a user password */
export type ResetUserPasswordOutput = {
  __typename?: 'ResetUserPasswordOutput';
  /** The ID of the user */
  user: User;
};

export enum MessageActionEnum {
  Resend = 'RESEND',
  Suppress = 'SUPPRESS',
}

/** The input to invite a new User */
export type InviteUserInput = {
  /** The email address of the User */
  email: Scalars['Email'];
  /** The family/last name of the User */
  familyName: Scalars['String'];
  /** The given/first name of the User */
  givenName: Scalars['String'];
  messageAction?: Maybe<MessageActionEnum>;
  /** The ID or Name of the User's role */
  role: UserRoleInput;
};

/** The return value of the Invite User mutation */
export type InviteUserOutput = {
  __typename?: 'InviteUserOutput';
  /** The created Panther User object */
  user: User;
};

/** The return value of the Update User mutation */
export type UpdateUserOutput = {
  __typename?: 'UpdateUserOutput';
  /** The updated Panther User object */
  user: User;
};

/** The return value of the Delete User mutation */
export type DeleteUserOutput = {
  __typename?: 'DeleteUserOutput';
  /** The deleted Panther User object */
  id: Scalars['ID'];
};

/** The input for the deleteUser mutation */
export type DeleteUserInput = {
  /** The ID of the user to delete */
  id: Scalars['ID'];
};

/** The input to the updateUser query */
export type UpdateUserInput = {
  /** The email address of the User */
  email?: Maybe<Scalars['Email']>;
  /** The family/last Name of the User */
  familyName?: Maybe<Scalars['String']>;
  /** The given/first name of the User */
  givenName?: Maybe<Scalars['String']>;
  /** The unique identifier of this User */
  id: Scalars['ID'];
  /** The ID or Name of the User's role */
  role?: Maybe<UserRoleInput>;
};

/** The details around a physical User entity in Pantheer */
export type User = {
  __typename?: 'User';
  /** Date and time when the User got created */
  createdAt: Scalars['Timestamp'];
  /** The email address of the User */
  email: Scalars['Email'];
  /** The family/last name of the User */
  familyName?: Maybe<Scalars['String']>;
  /** The given/first name of the User */
  givenName?: Maybe<Scalars['String']>;
  /** The unique identifier of this User */
  id: Scalars['ID'];
  /** The details of the Role that this User has */
  role: Role;
  /** The Cognito auth-related status of this User */
  status: Scalars['String'];
};

/** The input to select a role by either ID or Name */
export type UserRoleInput = {
  /** The role field to search by */
  kind: UserRoleInputKind;
  /** The value of the role field */
  value: Scalars['String'];
};

/** The role field to search by */
export enum UserRoleInputKind {
  Id = 'ID',
  Name = 'NAME',
}

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> =
  | LegacyStitchingResolver<TResult, TParent, TContext, TArgs>
  | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs
> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {}
> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}> = (
  obj: T,
  info: GraphQLResolveInfo
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Actor: ResolversTypes['User'] | ResolversTypes['APIToken'];
  Query: ResolverTypeWrapper<{}>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  String: ResolverTypeWrapper<Scalars['String']>;
  Mutation: ResolverTypeWrapper<{}>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  GenerateAlertSummaryAttributesQueriesInput: GenerateAlertSummaryAttributesQueriesInput;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  GetAlertEventsInput: GetAlertEventsInput;
  GetRelatedAlertsInput: GetRelatedAlertsInput;
  UpdateAlertStatusByFilterInput: UpdateAlertStatusByFilterInput;
  AlertsInput: AlertsInput;
  UpdateAlertStatusByFilterJob: ResolverTypeWrapper<UpdateAlertStatusByFilterJob>;
  AlertEvents: ResolverTypeWrapper<AlertEvents>;
  AlertOrigin:
    | ResolversTypes['AlertOriginRule']
    | ResolversTypes['AlertOriginPolicy']
    | ResolversTypes['AlertOriginSystemError']
    | ResolversTypes['AlertOriginReplayedRule'];
  SystemErrorRelatedEntity:
    | ResolversTypes['S3LogIntegration']
    | ResolversTypes['EventBridgeIntegration']
    | ResolversTypes['LogPullingIntegration']
    | ResolversTypes['SqsLogSourceIntegration']
    | ResolversTypes['CloudWatchLogIntegration']
    | ResolversTypes['GcsLogSourceIntegration']
    | ResolversTypes['Destination'];
  AlertOriginPolicy: ResolverTypeWrapper<AlertOriginPolicy>;
  AlertOriginRule: ResolverTypeWrapper<AlertOriginRule>;
  AlertOriginSystemError: ResolverTypeWrapper<
    Omit<AlertOriginSystemError, 'relatedEntity'> & {
      relatedEntity?: Maybe<ResolversTypes['SystemErrorRelatedEntity']>;
    }
  >;
  AlertOriginReplayedRule: ResolverTypeWrapper<AlertOriginReplayedRule>;
  AlertSummaryAttributeQueryData: ResolverTypeWrapper<AlertSummaryAttributeQueryData>;
  AlertSummaryAttributeForAnalysis: ResolverTypeWrapper<AlertSummaryAttributeForAnalysis>;
  DeliverAlertInput: DeliverAlertInput;
  SystemErrorTypeEnum: SystemErrorTypeEnum;
  RelatedComponentEnum: RelatedComponentEnum;
  RelatedAlertsOutput: ResolverTypeWrapper<RelatedAlertsOutput>;
  RelatedAlertsOutputEdge: ResolverTypeWrapper<RelatedAlertsOutputEdge>;
  RelatedAlertsOutputPageInfo: ResolverTypeWrapper<RelatedAlertsOutputPageInfo>;
  AlertActivityHistoryRecordTypes: AlertActivityHistoryRecordTypes;
  AlertActivityHistoryRecordContent:
    | ResolversTypes['AlertActivityHistoryAssigneeChange']
    | ResolversTypes['AlertActivityHistoryStatusChange'];
  AlertActivityHistoryRecord: ResolverTypeWrapper<
    Omit<AlertActivityHistoryRecord, 'triggerBy' | 'content'> & {
      triggerBy: ResolversTypes['Actor'];
      content: ResolversTypes['AlertActivityHistoryRecordContent'];
    }
  >;
  AlertActivityHistoryAssigneeChange: ResolverTypeWrapper<AlertActivityHistoryAssigneeChange>;
  AlertActivityHistoryStatusChange: ResolverTypeWrapper<AlertActivityHistoryStatusChange>;
  AlertActivityHistoryOutputEdge: ResolverTypeWrapper<AlertActivityHistoryOutputEdge>;
  AlertActivityHistoryOutputPageInfo: ResolverTypeWrapper<AlertActivityHistoryOutputPageInfo>;
  AlertActivityHistoryOutput: ResolverTypeWrapper<AlertActivityHistoryOutput>;
  Alert: ResolverTypeWrapper<
    Omit<Alert, 'origin' | 'updatedBy'> & {
      origin: ResolversTypes['AlertOrigin'];
      updatedBy?: Maybe<ResolversTypes['Actor']>;
    }
  >;
  UpdateAlertStatusByIdInput: UpdateAlertStatusByIdInput;
  UpdateAlertStatusByIdOutput: ResolverTypeWrapper<UpdateAlertStatusByIdOutput>;
  UpdateAlertsAssigneeByIdInput: UpdateAlertsAssigneeByIdInput;
  UpdateAlertsAssigneeByIdOutput: ResolverTypeWrapper<UpdateAlertsAssigneeByIdOutput>;
  AlertStatus: AlertStatus;
  AlertsOutput: ResolverTypeWrapper<AlertsOutput>;
  AlertsOutputEdge: ResolverTypeWrapper<AlertsOutputEdge>;
  AlertsOutputPageInfo: ResolverTypeWrapper<AlertsOutputPageInfo>;
  AlertType: AlertType;
  AlertListingType: AlertListingType;
  AlertDelivery: ResolverTypeWrapper<AlertDelivery>;
  Permission: Permission;
  AlertsSortFieldsEnum: AlertsSortFieldsEnum;
  CreateAPITokenInput: CreateAPITokenInput;
  UpdateAPITokenInput: UpdateAPITokenInput;
  APIToken: ResolverTypeWrapper<APIToken>;
  CreateAPITokenOutput: ResolverTypeWrapper<CreateAPITokenOutput>;
  GetAPITokenOutput: ResolverTypeWrapper<GetAPITokenOutput>;
  UpdateAPITokenOutput: ResolverTypeWrapper<UpdateAPITokenOutput>;
  DeleteAPITokenOutput: ResolverTypeWrapper<DeleteAPITokenOutput>;
  ListAPITokensOutput: ResolverTypeWrapper<ListAPITokensOutput>;
  Email: ResolverTypeWrapper<Scalars['Email']>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>;
  Timestamp: ResolverTypeWrapper<Scalars['Timestamp']>;
  JSON: ResolverTypeWrapper<Scalars['JSON']>;
  Long: ResolverTypeWrapper<Scalars['Long']>;
  DeleteEntry: DeleteEntry;
  Error: ResolverTypeWrapper<Error>;
  PageInfo: ResolverTypeWrapper<PageInfo>;
  PagingData: ResolverTypeWrapper<PagingData>;
  JobStatus: JobStatus;
  Job:
    | ResolversTypes['UpdateAlertStatusByFilterJob']
    | ResolversTypes['SummarizeDataLakeQueryJob']
    | ResolversTypes['LookupImportDataJob']
    | ResolversTypes['LookupSyncJob']
    | ResolversTypes['HoldingTankTestJob']
    | ResolversTypes['HoldingTankInferJob'];
  ErrorCodeEnum: ErrorCodeEnum;
  Severity: Severity;
  SortDirEnum: SortDirEnum;
  DataModelMappingInput: DataModelMappingInput;
  AddOrUpdateDataModelInput: AddOrUpdateDataModelInput;
  ListDataModelsInput: ListDataModelsInput;
  DeleteDataModelInput: DeleteDataModelInput;
  DataModelMapping: ResolverTypeWrapper<DataModelMapping>;
  DataModel: ResolverTypeWrapper<DataModel>;
  ListDataModelsResponse: ResolverTypeWrapper<ListDataModelsResponse>;
  ListDataModelsSortFieldsEnum: ListDataModelsSortFieldsEnum;
  SummarizeDataLakeQueryInput: SummarizeDataLakeQueryInput;
  GenerateSqlQuerySnippetInput: GenerateSqlQuerySnippetInput;
  GetAsyncQueryDownloadUrlInput: GetAsyncQueryDownloadUrlInput;
  DataLakeQueriesInput: DataLakeQueriesInput;
  ExecuteDataLakeQueryInput: ExecuteDataLakeQueryInput;
  DataLakeDatabaseTable: ResolverTypeWrapper<DataLakeDatabaseTable>;
  DataLakeQuery: ResolverTypeWrapper<
    Omit<DataLakeQuery, 'issuedBy'> & { issuedBy?: Maybe<ResolversTypes['Actor']> }
  >;
  GetAsyncQueryDownloadUrlOutput: ResolverTypeWrapper<GetAsyncQueryDownloadUrlOutput>;
  GenerateSqlQuerySnippetOutput: ResolverTypeWrapper<GenerateSqlQuerySnippetOutput>;
  SummarizeDataLakeQueryJob: ResolverTypeWrapper<SummarizeDataLakeQueryJob>;
  DataLakeQuerySummaryDirection: DataLakeQuerySummaryDirection;
  ExecuteIndicatorSearchQueryInput: ExecuteIndicatorSearchQueryInput;
  CancelDataLakeQueryInput: CancelDataLakeQueryInput;
  DataLakeDatabaseTableInput: DataLakeDatabaseTableInput;
  DataLakeQueryResultsInput: DataLakeQueryResultsInput;
  DataLakeQueriesOutput: ResolverTypeWrapper<DataLakeQueriesOutput>;
  DataLakeQueryEdge: ResolverTypeWrapper<DataLakeQueryEdge>;
  DataLakeQueriesOutputPageInfo: ResolverTypeWrapper<DataLakeQueriesOutputPageInfo>;
  DataLakeQueryResults: ResolverTypeWrapper<DataLakeQueryResults>;
  DataLakeQueryResultsColumnInfo: ResolverTypeWrapper<DataLakeQueryResultsColumnInfo>;
  DataLakeQueryStats: ResolverTypeWrapper<DataLakeQueryStats>;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  DataLakeQueryResultEdge: ResolverTypeWrapper<DataLakeQueryResultEdge>;
  DataLakeQueryResultRecordsPageInfo: ResolverTypeWrapper<DataLakeQueryResultRecordsPageInfo>;
  CancelDataLakeQueryOutput: ResolverTypeWrapper<CancelDataLakeQueryOutput>;
  ExecuteDataLakeQueryOutput: ResolverTypeWrapper<ExecuteDataLakeQueryOutput>;
  ExecuteIndicatorSearchQueryOutput: ResolverTypeWrapper<ExecuteIndicatorSearchQueryOutput>;
  DataLakeDatabase: ResolverTypeWrapper<DataLakeDatabase>;
  DataLakeDatabaseTableColumn: ResolverTypeWrapper<DataLakeDatabaseTableColumn>;
  DataLakeQueryStatus: DataLakeQueryStatus;
  DataLakeQueriesSortFields: DataLakeQueriesSortFields;
  SendTestAlertInput: SendTestAlertInput;
  Destination: ResolverTypeWrapper<
    Omit<Destination, 'createdBy' | 'lastModifiedBy'> & {
      createdBy?: Maybe<ResolversTypes['Actor']>;
      lastModifiedBy?: Maybe<ResolversTypes['Actor']>;
    }
  >;
  DestinationConfig: ResolverTypeWrapper<DestinationConfig>;
  SqsDestinationConfig: ResolverTypeWrapper<SqsDestinationConfig>;
  OpsgenieConfig: ResolverTypeWrapper<OpsgenieConfig>;
  MsTeamsConfig: ResolverTypeWrapper<MsTeamsConfig>;
  JiraConfig: ResolverTypeWrapper<JiraConfig>;
  AsanaConfig: ResolverTypeWrapper<AsanaConfig>;
  CustomWebhookConfig: ResolverTypeWrapper<CustomWebhookConfig>;
  GithubConfig: ResolverTypeWrapper<GithubConfig>;
  SlackConfig: ResolverTypeWrapper<SlackConfig>;
  SnsConfig: ResolverTypeWrapper<SnsConfig>;
  PagerDutyConfig: ResolverTypeWrapper<PagerDutyConfig>;
  DestinationInput: DestinationInput;
  DestinationConfigInput: DestinationConfigInput;
  SqsConfigInput: SqsConfigInput;
  OpsgenieConfigInput: OpsgenieConfigInput;
  MsTeamsConfigInput: MsTeamsConfigInput;
  JiraConfigInput: JiraConfigInput;
  AsanaConfigInput: AsanaConfigInput;
  CustomWebhookConfigInput: CustomWebhookConfigInput;
  GithubConfigInput: GithubConfigInput;
  SlackConfigInput: SlackConfigInput;
  SnsConfigInput: SnsConfigInput;
  PagerDutyConfigInput: PagerDutyConfigInput;
  DestinationTypeEnum: DestinationTypeEnum;
  OpsgenieServiceRegionEnum: OpsgenieServiceRegionEnum;
  PoliciesForResourceInput: PoliciesForResourceInput;
  BulkDownloadInput: BulkDownloadInput;
  BulkDownloadResponse: ResolverTypeWrapper<BulkDownloadResponse>;
  UploadDetectionsInput: UploadDetectionsInput;
  ListDetectionsInput: ListDetectionsInput;
  DeleteDetectionInput: DeleteDetectionInput;
  AddRuleInput: AddRuleInput;
  UpdateRuleInput: UpdateRuleInput;
  GetRuleInput: GetRuleInput;
  AddPolicyInput: AddPolicyInput;
  UpdatePolicyInput: UpdatePolicyInput;
  GetPolicyInput: GetPolicyInput;
  TestPolicyInput: TestPolicyInput;
  TestRuleInput: TestRuleInput;
  GenerateEnrichedEventInput: GenerateEnrichedEventInput;
  GenerateEnrichedEventResponse: ResolverTypeWrapper<GenerateEnrichedEventResponse>;
  UploadDetectionsResponse: ResolverTypeWrapper<UploadDetectionsResponse>;
  DetectionTestMockDefinition: ResolverTypeWrapper<DetectionTestMockDefinition>;
  DetectionTestDefinition: ResolverTypeWrapper<DetectionTestDefinition>;
  DetectionTestDefinitionMockInput: DetectionTestDefinitionMockInput;
  DetectionTestDefinitionInput: DetectionTestDefinitionInput;
  SuppressPoliciesInput: SuppressPoliciesInput;
  DetectionReportMapping: ResolverTypeWrapper<DetectionReportMapping>;
  DetectionReportMappingInput: DetectionReportMappingInput;
  Detection: ResolversTypes['Rule'] | ResolversTypes['Policy'];
  DetectionsOutput: ResolverTypeWrapper<DetectionsOutput>;
  DetectionsOutputPageInfo: ResolverTypeWrapper<DetectionsOutputPageInfo>;
  DetectionsOutputEdge: ResolverTypeWrapper<DetectionsOutputEdge>;
  Rule: ResolverTypeWrapper<
    Omit<Rule, 'createdBy' | 'lastModifiedBy'> & {
      createdBy?: Maybe<ResolversTypes['Actor']>;
      lastModifiedBy?: Maybe<ResolversTypes['Actor']>;
    }
  >;
  Policy: ResolverTypeWrapper<
    Omit<Policy, 'createdBy' | 'lastModifiedBy'> & {
      createdBy?: Maybe<ResolversTypes['Actor']>;
      lastModifiedBy?: Maybe<ResolversTypes['Actor']>;
    }
  >;
  ListDetectionsResponse: ResolverTypeWrapper<ListDetectionsResponse>;
  TestDetectionSubRecord: ResolverTypeWrapper<TestDetectionSubRecord>;
  TestPolicyRecordFunctions: ResolverTypeWrapper<TestPolicyRecordFunctions>;
  TestRecord: ResolversTypes['TestPolicyRecord'] | ResolversTypes['TestDetectionRecord'];
  TestPolicyRecord: ResolverTypeWrapper<TestPolicyRecord>;
  TestPolicyResponse: ResolverTypeWrapper<TestPolicyResponse>;
  TestDetectionRecordFunctions: ResolverTypeWrapper<TestDetectionRecordFunctions>;
  TestDetectionRecord: ResolverTypeWrapper<TestDetectionRecord>;
  TestRuleResponse: ResolverTypeWrapper<TestRuleResponse>;
  BulkUpdateDetectionsInput: BulkUpdateDetectionsInput;
  DetectionPatch: DetectionPatch;
  DetectionUpdateError: ResolverTypeWrapper<DetectionUpdateError>;
  BulkUpdateDetectionsResponse: ResolverTypeWrapper<BulkUpdateDetectionsResponse>;
  ListDetectionsSortFieldsEnum: ListDetectionsSortFieldsEnum;
  DetectionTypeEnum: DetectionTypeEnum;
  DetectionFilter: ResolverTypeWrapper<DetectionFilter>;
  DetectionFilterStatement: ResolverTypeWrapper<DetectionFilterStatement>;
  DetectionFiltersOutput: ResolverTypeWrapper<DetectionFiltersOutput>;
  CreateDetectionFilterOutput: ResolverTypeWrapper<CreateDetectionFilterOutput>;
  UpdateDetectionFilterOutput: ResolverTypeWrapper<UpdateDetectionFilterOutput>;
  DeleteDetectionFilterOutput: ResolverTypeWrapper<DeleteDetectionFilterOutput>;
  DetectionFiltersInput: DetectionFiltersInput;
  CreateDetectionFilterInput: CreateDetectionFilterInput;
  UpdateDetectionFilterInput: UpdateDetectionFilterInput;
  DetectionFilterStatementInput: DetectionFilterStatementInput;
  ListLookupsInput: ListLookupsInput;
  AddLookupInput: AddLookupInput;
  UpdateLookupInput: UpdateLookupInput;
  LookupRefreshConfigInput: LookupRefreshConfigInput;
  LookupSchemaInput: LookupSchemaInput;
  LogTypeMapInput: LogTypeMapInput;
  AssociatedLogTypeInput: AssociatedLogTypeInput;
  ImportLookupDataInput: ImportLookupDataInput;
  DeleteLookupsInput: DeleteLookupsInput;
  ListLookupResponse: ResolverTypeWrapper<ListLookupResponse>;
  Lookup: ResolverTypeWrapper<Lookup>;
  LookupRefreshConfig: ResolverTypeWrapper<LookupRefreshConfig>;
  LookupImportDataJob: ResolverTypeWrapper<LookupImportDataJob>;
  LookupSyncJob: ResolverTypeWrapper<LookupSyncJob>;
  LookupSyncJobStats: ResolverTypeWrapper<LookupSyncJobStats>;
  SyncLookupDataInput: SyncLookupDataInput;
  LookupImportDataJobStats: ResolverTypeWrapper<LookupImportDataJobStats>;
  LookupVersion: ResolverTypeWrapper<LookupVersion>;
  AssociatedLogType: ResolverTypeWrapper<AssociatedLogType>;
  LookupS3ImportConfig: ResolverTypeWrapper<LookupS3ImportConfig>;
  LookupS3ImportConfigInput: LookupS3ImportConfigInput;
  GlobalHelper: ResolverTypeWrapper<GlobalHelper>;
  ListGlobalHelpersInput: ListGlobalHelpersInput;
  GetGlobalHelperInput: GetGlobalHelperInput;
  ModifyGlobalHelperInput: ModifyGlobalHelperInput;
  AddGlobalHelperInput: AddGlobalHelperInput;
  DeleteGlobalHelpersInput: DeleteGlobalHelpersInput;
  ListGlobalHelpersResponse: ResolverTypeWrapper<ListGlobalHelpersResponse>;
  HoldingTankGetRunningTaskInput: HoldingTankGetRunningTaskInput;
  HoldingTankTestSchemasResultInput: HoldingTankTestSchemasResultInput;
  HoldingTankRawDataInput: HoldingTankRawDataInput;
  HoldingTankInferSchemaInput: HoldingTankInferSchemaInput;
  TestHoldingTankSchemasInput: TestHoldingTankSchemasInput;
  TestHoldingTankSchemaFilters: TestHoldingTankSchemaFilters;
  HoldingTankFilters: HoldingTankFilters;
  HoldingTankTestJob: ResolverTypeWrapper<
    Omit<HoldingTankTestJob, 'createdBy'> & { createdBy?: Maybe<ResolversTypes['Actor']> }
  >;
  DraftSchema: ResolverTypeWrapper<DraftSchema>;
  HoldingTankTestJobParams: ResolverTypeWrapper<HoldingTankTestJobParams>;
  DateRange: ResolverTypeWrapper<DateRange>;
  HoldingTankListTasksResponse: ResolverTypeWrapper<HoldingTankListTasksResponse>;
  HoldingTankInferJob: ResolverTypeWrapper<HoldingTankInferJob>;
  HoldingTankSchemaInferenceResult: ResolverTypeWrapper<HoldingTankSchemaInferenceResult>;
  HoldingTankJobStats: ResolverTypeWrapper<HoldingTankJobStats>;
  HoldingTankTestJobPrefixStats: ResolverTypeWrapper<HoldingTankTestJobPrefixStats>;
  HoldingTankLogTypeTestStats: ResolverTypeWrapper<HoldingTankLogTypeTestStats>;
  HoldingTankRawEvent: ResolverTypeWrapper<HoldingTankRawEvent>;
  HoldingTankResults: ResolverTypeWrapper<HoldingTankResults>;
  HoldingTankTestSchemasResultsResponse: ResolverTypeWrapper<HoldingTankTestSchemasResultsResponse>;
  HoldingTankRawDataResponse: ResolverTypeWrapper<HoldingTankRawDataResponse>;
  IndicatorType: IndicatorType;
  ListSchemasInput: ListSchemasInput;
  GetSchemaInput: GetSchemaInput;
  PutUserSchemaInput: PutUserSchemaInput;
  ToggleSchemaInput: ToggleSchemaInput;
  GetSchemaOutput: ResolverTypeWrapper<GetSchemaOutput>;
  ListSchemasOutput: ResolverTypeWrapper<ListSchemasOutput>;
  SchemaRecord: ResolverTypeWrapper<SchemaRecord>;
  SchemaField: ResolverTypeWrapper<SchemaField>;
  SchemaFieldValueValidation: ResolverTypeWrapper<SchemaFieldValueValidation>;
  GenerateS3SampleUploadInput: GenerateS3SampleUploadInput;
  GenerateS3SampleUploadResponse: ResolverTypeWrapper<GenerateS3SampleUploadResponse>;
  GetSampleDataInput: GetSampleDataInput;
  InferSchemaInput: InferSchemaInput;
  InferSchemaResponse: ResolverTypeWrapper<InferSchemaResponse>;
  GetSampleDataResponse: ResolverTypeWrapper<GetSampleDataResponse>;
  TestSchemaInput: TestSchemaInput;
  TestSchemaResult: ResolverTypeWrapper<TestSchemaResult>;
  TestSchemaResponse: ResolverTypeWrapper<TestSchemaResponse>;
  TestSchemaStats: ResolverTypeWrapper<TestSchemaStats>;
  UnmatchedLog: ResolverTypeWrapper<UnmatchedLog>;
  MatchedLog: ResolverTypeWrapper<MatchedLog>;
  TestSchemaData: ResolverTypeWrapper<TestSchemaData>;
  SchemaValueType: SchemaValueType;
  RequestLogTypeInput: RequestLogTypeInput;
  ListAvailableLogProvidersInput: ListAvailableLogProvidersInput;
  RequestLogTypeResponse: ResolverTypeWrapper<RequestLogTypeResponse>;
  ListAvailableLogTypesResponse: ResolverTypeWrapper<ListAvailableLogTypesResponse>;
  ListAvailableLogProvidersResponse: ResolverTypeWrapper<ListAvailableLogProvidersResponse>;
  GetAvailableLogProviderResponse: ResolverTypeWrapper<GetAvailableLogProviderResponse>;
  AvailableLogProvider: ResolverTypeWrapper<AvailableLogProvider>;
  AvailableLogProviderDetails: ResolverTypeWrapper<AvailableLogProviderDetails>;
  AvailableLogProviderParser: ResolverTypeWrapper<AvailableLogProviderParser>;
  OrganizationStatsResponse: ResolverTypeWrapper<OrganizationStatsResponse>;
  ScannedResourceStats: ResolverTypeWrapper<ScannedResourceStats>;
  ScannedResources: ResolverTypeWrapper<ScannedResources>;
  OrganizationStatsInput: OrganizationStatsInput;
  OrganizationReportBySeverity: ResolverTypeWrapper<OrganizationReportBySeverity>;
  ComplianceStatusCounts: ResolverTypeWrapper<ComplianceStatusCounts>;
  MetricsOutput: ResolverTypeWrapper<MetricsOutput>;
  MetricsInput: MetricsInput;
  Series: ResolverTypeWrapper<Series>;
  SeriesWithBreakdown: ResolverTypeWrapper<SeriesWithBreakdown>;
  SeriesWithEntityID: ResolverTypeWrapper<SeriesWithEntityID>;
  UpdateGeneralSettingsConfigInput: UpdateGeneralSettingsConfigInput;
  UpdateSamlConfigInput: UpdateSamlConfigInput;
  GeneralSettingsConfig: ResolverTypeWrapper<GeneralSettingsConfig>;
  InfrastructureInfo: ResolverTypeWrapper<InfrastructureInfo>;
  NetworkingInfo: ResolverTypeWrapper<NetworkingInfo>;
  StorageInfo: ResolverTypeWrapper<StorageInfo>;
  SamlConfig: ResolverTypeWrapper<SamlConfig>;
  ListAnalysisPackSourcesInput: ListAnalysisPackSourcesInput;
  ListAnalysisPacksInput: ListAnalysisPacksInput;
  UpdateAnalysisPackInput: UpdateAnalysisPackInput;
  UpdateAnalysisPackSourceInput: UpdateAnalysisPackSourceInput;
  AddAnalysisPackSourceInput: AddAnalysisPackSourceInput;
  ListAnalysisPacksResponse: ResolverTypeWrapper<ListAnalysisPacksResponse>;
  ListAnalysisPackSourcesResponse: ResolverTypeWrapper<ListAnalysisPackSourcesResponse>;
  AnalysisPackVersion: ResolverTypeWrapper<AnalysisPackVersion>;
  AnalysisPackDefinition: ResolverTypeWrapper<AnalysisPackDefinition>;
  AnalysisPackTypes: ResolverTypeWrapper<AnalysisPackTypes>;
  AnalysisPack: ResolverTypeWrapper<
    Omit<AnalysisPack, 'createdBy' | 'lastModifiedBy'> & {
      createdBy?: Maybe<ResolversTypes['Actor']>;
      lastModifiedBy?: Maybe<ResolversTypes['Actor']>;
    }
  >;
  AnalysisPackSource: ResolverTypeWrapper<
    Omit<AnalysisPackSource, 'createdBy' | 'lastModifiedBy'> & {
      createdBy?: Maybe<ResolversTypes['Actor']>;
      lastModifiedBy?: Maybe<ResolversTypes['Actor']>;
    }
  >;
  ReplayState: ReplayState;
  ReplayScope: ResolverTypeWrapper<ReplayScope>;
  Replay: ResolverTypeWrapper<Replay>;
  ReplayPreview: ResolverTypeWrapper<ReplayPreview>;
  ReplaySummary: ResolverTypeWrapper<ReplaySummary>;
  StopReplayInput: StopReplayInput;
  ComputeReplayInput: ComputeReplayInput;
  StopReplayOutput: ResolverTypeWrapper<StopReplayOutput>;
  CreateReplayOutput: ResolverTypeWrapper<CreateReplayOutput>;
  ComputeReplayOutput: ResolverTypeWrapper<ComputeReplayOutput>;
  CreateReplayInput: CreateReplayInput;
  ReplayedAlertsInput: ReplayedAlertsInput;
  ReplayedAlertsOutput: ResolverTypeWrapper<ReplayedAlertsOutput>;
  MitreMatrixTree: ResolverTypeWrapper<MitreMatrixTree>;
  MitreMatrixTreeNode: ResolverTypeWrapper<MitreMatrixTreeNode>;
  MitreMatrix: ResolverTypeWrapper<MitreMatrix>;
  MitreMatrixTacticMapping: ResolverTypeWrapper<MitreMatrixTacticMapping>;
  MitreMatrixContent: ResolverTypeWrapper<MitreMatrixContent>;
  MitreTactic: ResolverTypeWrapper<MitreTactic>;
  MitreTechnique: ResolverTypeWrapper<MitreTechnique>;
  ReportType: ReportType;
  ReportSetting: ResolverTypeWrapper<
    Omit<ReportSetting, 'updatedBy'> & { updatedBy?: Maybe<ResolversTypes['Actor']> }
  >;
  ReportSettingAssociation: ResolverTypeWrapper<ReportSettingAssociation>;
  ReportSettingAssociationMitre: ResolverTypeWrapper<ReportSettingAssociationMitre>;
  ReportOverrideSetting: ReportOverrideSetting;
  UpdateMitreReportSettings: UpdateMitreReportSettings;
  UpdateMitreReportMappingInput: UpdateMitreReportMappingInput;
  UpdateMitreReportMappingSelection: UpdateMitreReportMappingSelection;
  UpdateMitreReportMappingOutput: ResolverTypeWrapper<UpdateMitreReportMappingOutput>;
  ResourcesForPolicyInput: ResourcesForPolicyInput;
  ListResourcesResponse: ResolverTypeWrapper<ListResourcesResponse>;
  GetResourceInput: GetResourceInput;
  ListResourcesInput: ListResourcesInput;
  ListComplianceItemsResponse: ResolverTypeWrapper<ListComplianceItemsResponse>;
  ComplianceItem: ResolverTypeWrapper<ComplianceItem>;
  ActiveSuppressCount: ResolverTypeWrapper<ActiveSuppressCount>;
  ResourceSummary: ResolverTypeWrapper<ResourceSummary>;
  ResourceDetails: ResolverTypeWrapper<ResourceDetails>;
  ComplianceStatusEnum: ComplianceStatusEnum;
  ListResourcesSortFieldsEnum: ListResourcesSortFieldsEnum;
  CreateRoleInput: CreateRoleInput;
  UpdateRoleInput: UpdateRoleInput;
  RolesInput: RolesInput;
  DeleteRoleInput: DeleteRoleInput;
  DeleteRoleOutput: ResolverTypeWrapper<DeleteRoleOutput>;
  CreateRoleOutput: ResolverTypeWrapper<CreateRoleOutput>;
  UpdateRoleOutput: ResolverTypeWrapper<UpdateRoleOutput>;
  Role: ResolverTypeWrapper<
    Omit<Role, 'updatedBy'> & { updatedBy?: Maybe<ResolversTypes['Actor']> }
  >;
  LogTypeAccessKind: LogTypeAccessKind;
  AddSavedQueryInput: AddSavedQueryInput;
  UpdateSavedQueryInput: UpdateSavedQueryInput;
  DeleteSavedQueryInput: DeleteSavedQueryInput;
  ListSavedQueriesInput: ListSavedQueriesInput;
  ScheduleInput: ScheduleInput;
  Schedule: ResolverTypeWrapper<Schedule>;
  SavedQuery: ResolverTypeWrapper<
    Omit<SavedQuery, 'createdBy' | 'lastModifiedBy'> & {
      createdBy?: Maybe<ResolversTypes['Actor']>;
      lastModifiedBy?: Maybe<ResolversTypes['Actor']>;
    }
  >;
  ListSavedQueriesResponse: ResolverTypeWrapper<ListSavedQueriesResponse>;
  EventThresholdAlarmInput: EventThresholdAlarmInput;
  GetComplianceIntegrationTemplateInput: GetComplianceIntegrationTemplateInput;
  GetTerraformTemplateInput: GetTerraformTemplateInput;
  GetS3LogIntegrationTemplateInput: GetS3LogIntegrationTemplateInput;
  GetCloudWatchLogIntegrationTemplateInput: GetCloudWatchLogIntegrationTemplateInput;
  SqsConfig: ResolverTypeWrapper<SqsConfig>;
  GcsConfig: ResolverTypeWrapper<GcsConfig>;
  AWSScanConfig: ResolverTypeWrapper<AWSScanConfig>;
  LogIntegration:
    | ResolversTypes['S3LogIntegration']
    | ResolversTypes['CloudWatchLogIntegration']
    | ResolversTypes['EventBridgeIntegration']
    | ResolversTypes['SqsLogSourceIntegration']
    | ResolversTypes['GcsLogSourceIntegration']
    | ResolversTypes['LogPullingIntegration'];
  ComplianceIntegration: ResolverTypeWrapper<
    Omit<ComplianceIntegration, 'createdBy'> & { createdBy?: Maybe<ResolversTypes['Actor']> }
  >;
  LogIntegrationAlarms: ResolverTypeWrapper<LogIntegrationAlarms>;
  S3PrefixLogTypes: ResolverTypeWrapper<S3PrefixLogTypes>;
  S3LogIntegration: ResolverTypeWrapper<
    Omit<S3LogIntegration, 'createdBy'> & { createdBy?: Maybe<ResolversTypes['Actor']> }
  >;
  CloudWatchLogIntegration: ResolverTypeWrapper<
    Omit<CloudWatchLogIntegration, 'createdBy'> & { createdBy?: Maybe<ResolversTypes['Actor']> }
  >;
  EventBridgeIntegration: ResolverTypeWrapper<
    Omit<EventBridgeIntegration, 'createdBy'> & { createdBy?: Maybe<ResolversTypes['Actor']> }
  >;
  SqsLogSourceIntegration: ResolverTypeWrapper<
    Omit<SqsLogSourceIntegration, 'createdBy'> & { createdBy?: Maybe<ResolversTypes['Actor']> }
  >;
  GcsLogSourceIntegration: ResolverTypeWrapper<
    Omit<GcsLogSourceIntegration, 'createdBy'> & { createdBy?: Maybe<ResolversTypes['Actor']> }
  >;
  LogPullingIntegration: ResolverTypeWrapper<
    Omit<LogPullingIntegration, 'createdBy'> & { createdBy?: Maybe<ResolversTypes['Actor']> }
  >;
  OAuth2Info: ResolverTypeWrapper<OAuth2Info>;
  AddComplianceIntegrationInput: AddComplianceIntegrationInput;
  S3PrefixLogTypesInput: S3PrefixLogTypesInput;
  AddS3LogIntegrationInput: AddS3LogIntegrationInput;
  AddCloudWatchLogIntegrationInput: AddCloudWatchLogIntegrationInput;
  AddEventBridgeIntegrationInput: AddEventBridgeIntegrationInput;
  AsanaLogSourceConfigInput: AsanaLogSourceConfigInput;
  OktaConfigInput: OktaConfigInput;
  GsuiteConfigInput: GsuiteConfigInput;
  CrowdstrikeInput: CrowdstrikeInput;
  DuoConfigInput: DuoConfigInput;
  SalesforceConfigInput: SalesforceConfigInput;
  OAuthConfigInput: OAuthConfigInput;
  M365ConfigInput: M365ConfigInput;
  MicrosoftGraphConfigInput: MicrosoftGraphConfigInput;
  GithubOAuth2ConfigInput: GithubOAuth2ConfigInput;
  GithubPatConfigInput: GithubPatConfigInput;
  GithubLogSourceInput: GithubLogSourceInput;
  OnePasswordConfigInput: OnePasswordConfigInput;
  AtlassianConfigInput: AtlassianConfigInput;
  ZendeskOAuth2ConfigInput: ZendeskOAuth2ConfigInput;
  GetLookupCloudFormationRoleTemplateInput: GetLookupCloudFormationRoleTemplateInput;
  ZendeskBasicConfigInput: ZendeskBasicConfigInput;
  ZendeskApiConfigInput: ZendeskApiConfigInput;
  ZendeskLogSourceInput: ZendeskLogSourceInput;
  WorkdayConfigInput: WorkdayConfigInput;
  SnykConfigInput: SnykConfigInput;
  PullerConfigInput: PullerConfigInput;
  AddLogPullingIntegrationInput: AddLogPullingIntegrationInput;
  SqsLogConfigInput: SqsLogConfigInput;
  GcsLogConfigInput: GcsLogConfigInput;
  AWSScanConfigInput: AWSScanConfigInput;
  AddSqsLogIntegrationInput: AddSqsLogIntegrationInput;
  AddGcsLogIntegrationInput: AddGcsLogIntegrationInput;
  UpdateComplianceIntegrationInput: UpdateComplianceIntegrationInput;
  UpdateS3LogIntegrationInput: UpdateS3LogIntegrationInput;
  UpdateCloudWatchLogIntegrationInput: UpdateCloudWatchLogIntegrationInput;
  UpdateEventbridgeIntegrationInput: UpdateEventbridgeIntegrationInput;
  UpdateLogPullingIntegrationInput: UpdateLogPullingIntegrationInput;
  UpdateSqsLogIntegrationInput: UpdateSqsLogIntegrationInput;
  UpdateGcsLogIntegrationInput: UpdateGcsLogIntegrationInput;
  SourceMetricsInput: SourceMetricsInput;
  IntegrationTemplate: ResolverTypeWrapper<IntegrationTemplate>;
  TerraformTemplateResponse: ResolverTypeWrapper<TerraformTemplateResponse>;
  IntegrationItemHealthStatus: ResolverTypeWrapper<IntegrationItemHealthStatus>;
  ComplianceIntegrationHealth: ResolverTypeWrapper<ComplianceIntegrationHealth>;
  S3LogIntegrationHealth: ResolverTypeWrapper<S3LogIntegrationHealth>;
  CloudWatchLogIntegrationHealth: ResolverTypeWrapper<CloudWatchLogIntegrationHealth>;
  EventBridgeIntegrationHealth: ResolverTypeWrapper<EventBridgeIntegrationHealth>;
  LogPullingIntegrationHealth: ResolverTypeWrapper<LogPullingIntegrationHealth>;
  SqsLogIntegrationHealth: ResolverTypeWrapper<SqsLogIntegrationHealth>;
  GcsLogIntegrationHealth: ResolverTypeWrapper<GcsLogIntegrationHealth>;
  EventBridgeConfig: ResolverTypeWrapper<EventBridgeConfig>;
  OktaConfig: ResolverTypeWrapper<OktaConfig>;
  GsuiteConfig: ResolverTypeWrapper<GsuiteConfig>;
  CrowdstrikeConfig: ResolverTypeWrapper<CrowdstrikeConfig>;
  DuoConfig: ResolverTypeWrapper<DuoConfig>;
  SalesforceConfig: ResolverTypeWrapper<SalesforceConfig>;
  OAuthConfig: ResolverTypeWrapper<OAuthConfig>;
  M365Config: ResolverTypeWrapper<M365Config>;
  MicrosoftGraphConfig: ResolverTypeWrapper<MicrosoftGraphConfig>;
  GithubLogSourceOAuth2Config: ResolverTypeWrapper<GithubLogSourceOAuth2Config>;
  GithubLogSourcePatConfig: ResolverTypeWrapper<GithubLogSourcePatConfig>;
  GithubLogSourceCredentialsConfig:
    | ResolversTypes['GithubLogSourceOAuth2Config']
    | ResolversTypes['GithubLogSourcePatConfig'];
  GithubLogSourceConfig: ResolverTypeWrapper<
    Omit<GithubLogSourceConfig, 'credentials'> & {
      credentials: ResolversTypes['GithubLogSourceCredentialsConfig'];
    }
  >;
  OnePasswordConfig: ResolverTypeWrapper<OnePasswordConfig>;
  AtlassianConfig: ResolverTypeWrapper<AtlassianConfig>;
  ZendeskLogSourceOAuth2Config: ResolverTypeWrapper<ZendeskLogSourceOAuth2Config>;
  ZendeskLogSourceBasicConfig: ResolverTypeWrapper<ZendeskLogSourceBasicConfig>;
  ZendeskLogSourceApiConfig: ResolverTypeWrapper<ZendeskLogSourceApiConfig>;
  ZendeskLogSourceCredentialsConfig:
    | ResolversTypes['ZendeskLogSourceOAuth2Config']
    | ResolversTypes['ZendeskLogSourceBasicConfig']
    | ResolversTypes['ZendeskLogSourceApiConfig'];
  ZendeskLogSourceConfig: ResolverTypeWrapper<
    Omit<ZendeskLogSourceConfig, 'credentials'> & {
      credentials: ResolversTypes['ZendeskLogSourceCredentialsConfig'];
    }
  >;
  AsanaLogSourceConfig: ResolverTypeWrapper<AsanaLogSourceConfig>;
  WorkdayConfig: ResolverTypeWrapper<WorkdayConfig>;
  SnykLogSourceConfig: ResolverTypeWrapper<SnykLogSourceConfig>;
  PullerConfig: ResolverTypeWrapper<PullerConfig>;
  EventThresholdAlarm: ResolverTypeWrapper<EventThresholdAlarm>;
  SeriesMetric: ResolverTypeWrapper<SeriesMetric>;
  SeriesData: ResolverTypeWrapper<SeriesData>;
  SourceMetricsResponse: ResolverTypeWrapper<SourceMetricsResponse>;
  SourceHealthTypesEnum: SourceHealthTypesEnum;
  GithubAuthorizationMethodEnum: GithubAuthorizationMethodEnum;
  ZendeskAuthorizationMethodEnum: ZendeskAuthorizationMethodEnum;
  LogStreamTypeEnum: LogStreamTypeEnum;
  LogPullerTypeEnum: LogPullerTypeEnum;
  OnePasswordHost: OnePasswordHost;
  ResetUserPasswordInput: ResetUserPasswordInput;
  SendUserFeedbackInput: SendUserFeedbackInput;
  UserFeedbackFeatureInput: UserFeedbackFeatureInput;
  UserFeedbackDataInput: UserFeedbackDataInput;
  SendUserFeedbackOutput: ResolverTypeWrapper<SendUserFeedbackOutput>;
  ResetUserPasswordOutput: ResolverTypeWrapper<ResetUserPasswordOutput>;
  MessageActionEnum: MessageActionEnum;
  InviteUserInput: InviteUserInput;
  InviteUserOutput: ResolverTypeWrapper<InviteUserOutput>;
  UpdateUserOutput: ResolverTypeWrapper<UpdateUserOutput>;
  DeleteUserOutput: ResolverTypeWrapper<DeleteUserOutput>;
  DeleteUserInput: DeleteUserInput;
  UpdateUserInput: UpdateUserInput;
  User: ResolverTypeWrapper<User>;
  UserRoleInput: UserRoleInput;
  UserRoleInputKind: UserRoleInputKind;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Actor: ResolversParentTypes['User'] | ResolversParentTypes['APIToken'];
  Query: {};
  ID: Scalars['ID'];
  String: Scalars['String'];
  Mutation: {};
  Boolean: Scalars['Boolean'];
  GenerateAlertSummaryAttributesQueriesInput: GenerateAlertSummaryAttributesQueriesInput;
  Int: Scalars['Int'];
  GetAlertEventsInput: GetAlertEventsInput;
  GetRelatedAlertsInput: GetRelatedAlertsInput;
  UpdateAlertStatusByFilterInput: UpdateAlertStatusByFilterInput;
  AlertsInput: AlertsInput;
  UpdateAlertStatusByFilterJob: UpdateAlertStatusByFilterJob;
  AlertEvents: AlertEvents;
  AlertOrigin:
    | ResolversParentTypes['AlertOriginRule']
    | ResolversParentTypes['AlertOriginPolicy']
    | ResolversParentTypes['AlertOriginSystemError']
    | ResolversParentTypes['AlertOriginReplayedRule'];
  SystemErrorRelatedEntity:
    | ResolversParentTypes['S3LogIntegration']
    | ResolversParentTypes['EventBridgeIntegration']
    | ResolversParentTypes['LogPullingIntegration']
    | ResolversParentTypes['SqsLogSourceIntegration']
    | ResolversParentTypes['CloudWatchLogIntegration']
    | ResolversParentTypes['GcsLogSourceIntegration']
    | ResolversParentTypes['Destination'];
  AlertOriginPolicy: AlertOriginPolicy;
  AlertOriginRule: AlertOriginRule;
  AlertOriginSystemError: Omit<AlertOriginSystemError, 'relatedEntity'> & {
    relatedEntity?: Maybe<ResolversParentTypes['SystemErrorRelatedEntity']>;
  };
  AlertOriginReplayedRule: AlertOriginReplayedRule;
  AlertSummaryAttributeQueryData: AlertSummaryAttributeQueryData;
  AlertSummaryAttributeForAnalysis: AlertSummaryAttributeForAnalysis;
  DeliverAlertInput: DeliverAlertInput;
  RelatedAlertsOutput: RelatedAlertsOutput;
  RelatedAlertsOutputEdge: RelatedAlertsOutputEdge;
  RelatedAlertsOutputPageInfo: RelatedAlertsOutputPageInfo;
  AlertActivityHistoryRecordContent:
    | ResolversParentTypes['AlertActivityHistoryAssigneeChange']
    | ResolversParentTypes['AlertActivityHistoryStatusChange'];
  AlertActivityHistoryRecord: Omit<AlertActivityHistoryRecord, 'triggerBy' | 'content'> & {
    triggerBy: ResolversParentTypes['Actor'];
    content: ResolversParentTypes['AlertActivityHistoryRecordContent'];
  };
  AlertActivityHistoryAssigneeChange: AlertActivityHistoryAssigneeChange;
  AlertActivityHistoryStatusChange: AlertActivityHistoryStatusChange;
  AlertActivityHistoryOutputEdge: AlertActivityHistoryOutputEdge;
  AlertActivityHistoryOutputPageInfo: AlertActivityHistoryOutputPageInfo;
  AlertActivityHistoryOutput: AlertActivityHistoryOutput;
  Alert: Omit<Alert, 'origin' | 'updatedBy'> & {
    origin: ResolversParentTypes['AlertOrigin'];
    updatedBy?: Maybe<ResolversParentTypes['Actor']>;
  };
  UpdateAlertStatusByIdInput: UpdateAlertStatusByIdInput;
  UpdateAlertStatusByIdOutput: UpdateAlertStatusByIdOutput;
  UpdateAlertsAssigneeByIdInput: UpdateAlertsAssigneeByIdInput;
  UpdateAlertsAssigneeByIdOutput: UpdateAlertsAssigneeByIdOutput;
  AlertsOutput: AlertsOutput;
  AlertsOutputEdge: AlertsOutputEdge;
  AlertsOutputPageInfo: AlertsOutputPageInfo;
  AlertDelivery: AlertDelivery;
  CreateAPITokenInput: CreateAPITokenInput;
  UpdateAPITokenInput: UpdateAPITokenInput;
  APIToken: APIToken;
  CreateAPITokenOutput: CreateAPITokenOutput;
  GetAPITokenOutput: GetAPITokenOutput;
  UpdateAPITokenOutput: UpdateAPITokenOutput;
  DeleteAPITokenOutput: DeleteAPITokenOutput;
  ListAPITokensOutput: ListAPITokensOutput;
  Email: Scalars['Email'];
  DateTime: Scalars['DateTime'];
  Timestamp: Scalars['Timestamp'];
  JSON: Scalars['JSON'];
  Long: Scalars['Long'];
  DeleteEntry: DeleteEntry;
  Error: Error;
  PageInfo: PageInfo;
  PagingData: PagingData;
  Job:
    | ResolversParentTypes['UpdateAlertStatusByFilterJob']
    | ResolversParentTypes['SummarizeDataLakeQueryJob']
    | ResolversParentTypes['LookupImportDataJob']
    | ResolversParentTypes['LookupSyncJob']
    | ResolversParentTypes['HoldingTankTestJob']
    | ResolversParentTypes['HoldingTankInferJob'];
  DataModelMappingInput: DataModelMappingInput;
  AddOrUpdateDataModelInput: AddOrUpdateDataModelInput;
  ListDataModelsInput: ListDataModelsInput;
  DeleteDataModelInput: DeleteDataModelInput;
  DataModelMapping: DataModelMapping;
  DataModel: DataModel;
  ListDataModelsResponse: ListDataModelsResponse;
  SummarizeDataLakeQueryInput: SummarizeDataLakeQueryInput;
  GenerateSqlQuerySnippetInput: GenerateSqlQuerySnippetInput;
  GetAsyncQueryDownloadUrlInput: GetAsyncQueryDownloadUrlInput;
  DataLakeQueriesInput: DataLakeQueriesInput;
  ExecuteDataLakeQueryInput: ExecuteDataLakeQueryInput;
  DataLakeDatabaseTable: DataLakeDatabaseTable;
  DataLakeQuery: Omit<DataLakeQuery, 'issuedBy'> & {
    issuedBy?: Maybe<ResolversParentTypes['Actor']>;
  };
  GetAsyncQueryDownloadUrlOutput: GetAsyncQueryDownloadUrlOutput;
  GenerateSqlQuerySnippetOutput: GenerateSqlQuerySnippetOutput;
  SummarizeDataLakeQueryJob: SummarizeDataLakeQueryJob;
  ExecuteIndicatorSearchQueryInput: ExecuteIndicatorSearchQueryInput;
  CancelDataLakeQueryInput: CancelDataLakeQueryInput;
  DataLakeDatabaseTableInput: DataLakeDatabaseTableInput;
  DataLakeQueryResultsInput: DataLakeQueryResultsInput;
  DataLakeQueriesOutput: DataLakeQueriesOutput;
  DataLakeQueryEdge: DataLakeQueryEdge;
  DataLakeQueriesOutputPageInfo: DataLakeQueriesOutputPageInfo;
  DataLakeQueryResults: DataLakeQueryResults;
  DataLakeQueryResultsColumnInfo: DataLakeQueryResultsColumnInfo;
  DataLakeQueryStats: DataLakeQueryStats;
  Float: Scalars['Float'];
  DataLakeQueryResultEdge: DataLakeQueryResultEdge;
  DataLakeQueryResultRecordsPageInfo: DataLakeQueryResultRecordsPageInfo;
  CancelDataLakeQueryOutput: CancelDataLakeQueryOutput;
  ExecuteDataLakeQueryOutput: ExecuteDataLakeQueryOutput;
  ExecuteIndicatorSearchQueryOutput: ExecuteIndicatorSearchQueryOutput;
  DataLakeDatabase: DataLakeDatabase;
  DataLakeDatabaseTableColumn: DataLakeDatabaseTableColumn;
  SendTestAlertInput: SendTestAlertInput;
  Destination: Omit<Destination, 'createdBy' | 'lastModifiedBy'> & {
    createdBy?: Maybe<ResolversParentTypes['Actor']>;
    lastModifiedBy?: Maybe<ResolversParentTypes['Actor']>;
  };
  DestinationConfig: DestinationConfig;
  SqsDestinationConfig: SqsDestinationConfig;
  OpsgenieConfig: OpsgenieConfig;
  MsTeamsConfig: MsTeamsConfig;
  JiraConfig: JiraConfig;
  AsanaConfig: AsanaConfig;
  CustomWebhookConfig: CustomWebhookConfig;
  GithubConfig: GithubConfig;
  SlackConfig: SlackConfig;
  SnsConfig: SnsConfig;
  PagerDutyConfig: PagerDutyConfig;
  DestinationInput: DestinationInput;
  DestinationConfigInput: DestinationConfigInput;
  SqsConfigInput: SqsConfigInput;
  OpsgenieConfigInput: OpsgenieConfigInput;
  MsTeamsConfigInput: MsTeamsConfigInput;
  JiraConfigInput: JiraConfigInput;
  AsanaConfigInput: AsanaConfigInput;
  CustomWebhookConfigInput: CustomWebhookConfigInput;
  GithubConfigInput: GithubConfigInput;
  SlackConfigInput: SlackConfigInput;
  SnsConfigInput: SnsConfigInput;
  PagerDutyConfigInput: PagerDutyConfigInput;
  PoliciesForResourceInput: PoliciesForResourceInput;
  BulkDownloadInput: BulkDownloadInput;
  BulkDownloadResponse: BulkDownloadResponse;
  UploadDetectionsInput: UploadDetectionsInput;
  ListDetectionsInput: ListDetectionsInput;
  DeleteDetectionInput: DeleteDetectionInput;
  AddRuleInput: AddRuleInput;
  UpdateRuleInput: UpdateRuleInput;
  GetRuleInput: GetRuleInput;
  AddPolicyInput: AddPolicyInput;
  UpdatePolicyInput: UpdatePolicyInput;
  GetPolicyInput: GetPolicyInput;
  TestPolicyInput: TestPolicyInput;
  TestRuleInput: TestRuleInput;
  GenerateEnrichedEventInput: GenerateEnrichedEventInput;
  GenerateEnrichedEventResponse: GenerateEnrichedEventResponse;
  UploadDetectionsResponse: UploadDetectionsResponse;
  DetectionTestMockDefinition: DetectionTestMockDefinition;
  DetectionTestDefinition: DetectionTestDefinition;
  DetectionTestDefinitionMockInput: DetectionTestDefinitionMockInput;
  DetectionTestDefinitionInput: DetectionTestDefinitionInput;
  SuppressPoliciesInput: SuppressPoliciesInput;
  DetectionReportMapping: DetectionReportMapping;
  DetectionReportMappingInput: DetectionReportMappingInput;
  Detection: ResolversParentTypes['Rule'] | ResolversParentTypes['Policy'];
  DetectionsOutput: DetectionsOutput;
  DetectionsOutputPageInfo: DetectionsOutputPageInfo;
  DetectionsOutputEdge: DetectionsOutputEdge;
  Rule: Omit<Rule, 'createdBy' | 'lastModifiedBy'> & {
    createdBy?: Maybe<ResolversParentTypes['Actor']>;
    lastModifiedBy?: Maybe<ResolversParentTypes['Actor']>;
  };
  Policy: Omit<Policy, 'createdBy' | 'lastModifiedBy'> & {
    createdBy?: Maybe<ResolversParentTypes['Actor']>;
    lastModifiedBy?: Maybe<ResolversParentTypes['Actor']>;
  };
  ListDetectionsResponse: ListDetectionsResponse;
  TestDetectionSubRecord: TestDetectionSubRecord;
  TestPolicyRecordFunctions: TestPolicyRecordFunctions;
  TestRecord:
    | ResolversParentTypes['TestPolicyRecord']
    | ResolversParentTypes['TestDetectionRecord'];
  TestPolicyRecord: TestPolicyRecord;
  TestPolicyResponse: TestPolicyResponse;
  TestDetectionRecordFunctions: TestDetectionRecordFunctions;
  TestDetectionRecord: TestDetectionRecord;
  TestRuleResponse: TestRuleResponse;
  BulkUpdateDetectionsInput: BulkUpdateDetectionsInput;
  DetectionPatch: DetectionPatch;
  DetectionUpdateError: DetectionUpdateError;
  BulkUpdateDetectionsResponse: BulkUpdateDetectionsResponse;
  DetectionFilter: DetectionFilter;
  DetectionFilterStatement: DetectionFilterStatement;
  DetectionFiltersOutput: DetectionFiltersOutput;
  CreateDetectionFilterOutput: CreateDetectionFilterOutput;
  UpdateDetectionFilterOutput: UpdateDetectionFilterOutput;
  DeleteDetectionFilterOutput: DeleteDetectionFilterOutput;
  DetectionFiltersInput: DetectionFiltersInput;
  CreateDetectionFilterInput: CreateDetectionFilterInput;
  UpdateDetectionFilterInput: UpdateDetectionFilterInput;
  DetectionFilterStatementInput: DetectionFilterStatementInput;
  ListLookupsInput: ListLookupsInput;
  AddLookupInput: AddLookupInput;
  UpdateLookupInput: UpdateLookupInput;
  LookupRefreshConfigInput: LookupRefreshConfigInput;
  LookupSchemaInput: LookupSchemaInput;
  LogTypeMapInput: LogTypeMapInput;
  AssociatedLogTypeInput: AssociatedLogTypeInput;
  ImportLookupDataInput: ImportLookupDataInput;
  DeleteLookupsInput: DeleteLookupsInput;
  ListLookupResponse: ListLookupResponse;
  Lookup: Lookup;
  LookupRefreshConfig: LookupRefreshConfig;
  LookupImportDataJob: LookupImportDataJob;
  LookupSyncJob: LookupSyncJob;
  LookupSyncJobStats: LookupSyncJobStats;
  SyncLookupDataInput: SyncLookupDataInput;
  LookupImportDataJobStats: LookupImportDataJobStats;
  LookupVersion: LookupVersion;
  AssociatedLogType: AssociatedLogType;
  LookupS3ImportConfig: LookupS3ImportConfig;
  LookupS3ImportConfigInput: LookupS3ImportConfigInput;
  GlobalHelper: GlobalHelper;
  ListGlobalHelpersInput: ListGlobalHelpersInput;
  GetGlobalHelperInput: GetGlobalHelperInput;
  ModifyGlobalHelperInput: ModifyGlobalHelperInput;
  AddGlobalHelperInput: AddGlobalHelperInput;
  DeleteGlobalHelpersInput: DeleteGlobalHelpersInput;
  ListGlobalHelpersResponse: ListGlobalHelpersResponse;
  HoldingTankGetRunningTaskInput: HoldingTankGetRunningTaskInput;
  HoldingTankTestSchemasResultInput: HoldingTankTestSchemasResultInput;
  HoldingTankRawDataInput: HoldingTankRawDataInput;
  HoldingTankInferSchemaInput: HoldingTankInferSchemaInput;
  TestHoldingTankSchemasInput: TestHoldingTankSchemasInput;
  TestHoldingTankSchemaFilters: TestHoldingTankSchemaFilters;
  HoldingTankFilters: HoldingTankFilters;
  HoldingTankTestJob: Omit<HoldingTankTestJob, 'createdBy'> & {
    createdBy?: Maybe<ResolversParentTypes['Actor']>;
  };
  DraftSchema: DraftSchema;
  HoldingTankTestJobParams: HoldingTankTestJobParams;
  DateRange: DateRange;
  HoldingTankListTasksResponse: HoldingTankListTasksResponse;
  HoldingTankInferJob: HoldingTankInferJob;
  HoldingTankSchemaInferenceResult: HoldingTankSchemaInferenceResult;
  HoldingTankJobStats: HoldingTankJobStats;
  HoldingTankTestJobPrefixStats: HoldingTankTestJobPrefixStats;
  HoldingTankLogTypeTestStats: HoldingTankLogTypeTestStats;
  HoldingTankRawEvent: HoldingTankRawEvent;
  HoldingTankResults: HoldingTankResults;
  HoldingTankTestSchemasResultsResponse: HoldingTankTestSchemasResultsResponse;
  HoldingTankRawDataResponse: HoldingTankRawDataResponse;
  ListSchemasInput: ListSchemasInput;
  GetSchemaInput: GetSchemaInput;
  PutUserSchemaInput: PutUserSchemaInput;
  ToggleSchemaInput: ToggleSchemaInput;
  GetSchemaOutput: GetSchemaOutput;
  ListSchemasOutput: ListSchemasOutput;
  SchemaRecord: SchemaRecord;
  SchemaField: SchemaField;
  SchemaFieldValueValidation: SchemaFieldValueValidation;
  GenerateS3SampleUploadInput: GenerateS3SampleUploadInput;
  GenerateS3SampleUploadResponse: GenerateS3SampleUploadResponse;
  GetSampleDataInput: GetSampleDataInput;
  InferSchemaInput: InferSchemaInput;
  InferSchemaResponse: InferSchemaResponse;
  GetSampleDataResponse: GetSampleDataResponse;
  TestSchemaInput: TestSchemaInput;
  TestSchemaResult: TestSchemaResult;
  TestSchemaResponse: TestSchemaResponse;
  TestSchemaStats: TestSchemaStats;
  UnmatchedLog: UnmatchedLog;
  MatchedLog: MatchedLog;
  TestSchemaData: TestSchemaData;
  RequestLogTypeInput: RequestLogTypeInput;
  ListAvailableLogProvidersInput: ListAvailableLogProvidersInput;
  RequestLogTypeResponse: RequestLogTypeResponse;
  ListAvailableLogTypesResponse: ListAvailableLogTypesResponse;
  ListAvailableLogProvidersResponse: ListAvailableLogProvidersResponse;
  GetAvailableLogProviderResponse: GetAvailableLogProviderResponse;
  AvailableLogProvider: AvailableLogProvider;
  AvailableLogProviderDetails: AvailableLogProviderDetails;
  AvailableLogProviderParser: AvailableLogProviderParser;
  OrganizationStatsResponse: OrganizationStatsResponse;
  ScannedResourceStats: ScannedResourceStats;
  ScannedResources: ScannedResources;
  OrganizationStatsInput: OrganizationStatsInput;
  OrganizationReportBySeverity: OrganizationReportBySeverity;
  ComplianceStatusCounts: ComplianceStatusCounts;
  MetricsOutput: MetricsOutput;
  MetricsInput: MetricsInput;
  Series: Series;
  SeriesWithBreakdown: SeriesWithBreakdown;
  SeriesWithEntityID: SeriesWithEntityID;
  UpdateGeneralSettingsConfigInput: UpdateGeneralSettingsConfigInput;
  UpdateSamlConfigInput: UpdateSamlConfigInput;
  GeneralSettingsConfig: GeneralSettingsConfig;
  InfrastructureInfo: InfrastructureInfo;
  NetworkingInfo: NetworkingInfo;
  StorageInfo: StorageInfo;
  SamlConfig: SamlConfig;
  ListAnalysisPackSourcesInput: ListAnalysisPackSourcesInput;
  ListAnalysisPacksInput: ListAnalysisPacksInput;
  UpdateAnalysisPackInput: UpdateAnalysisPackInput;
  UpdateAnalysisPackSourceInput: UpdateAnalysisPackSourceInput;
  AddAnalysisPackSourceInput: AddAnalysisPackSourceInput;
  ListAnalysisPacksResponse: ListAnalysisPacksResponse;
  ListAnalysisPackSourcesResponse: ListAnalysisPackSourcesResponse;
  AnalysisPackVersion: AnalysisPackVersion;
  AnalysisPackDefinition: AnalysisPackDefinition;
  AnalysisPackTypes: AnalysisPackTypes;
  AnalysisPack: Omit<AnalysisPack, 'createdBy' | 'lastModifiedBy'> & {
    createdBy?: Maybe<ResolversParentTypes['Actor']>;
    lastModifiedBy?: Maybe<ResolversParentTypes['Actor']>;
  };
  AnalysisPackSource: Omit<AnalysisPackSource, 'createdBy' | 'lastModifiedBy'> & {
    createdBy?: Maybe<ResolversParentTypes['Actor']>;
    lastModifiedBy?: Maybe<ResolversParentTypes['Actor']>;
  };
  ReplayScope: ReplayScope;
  Replay: Replay;
  ReplayPreview: ReplayPreview;
  ReplaySummary: ReplaySummary;
  StopReplayInput: StopReplayInput;
  ComputeReplayInput: ComputeReplayInput;
  StopReplayOutput: StopReplayOutput;
  CreateReplayOutput: CreateReplayOutput;
  ComputeReplayOutput: ComputeReplayOutput;
  CreateReplayInput: CreateReplayInput;
  ReplayedAlertsInput: ReplayedAlertsInput;
  ReplayedAlertsOutput: ReplayedAlertsOutput;
  MitreMatrixTree: MitreMatrixTree;
  MitreMatrixTreeNode: MitreMatrixTreeNode;
  MitreMatrix: MitreMatrix;
  MitreMatrixTacticMapping: MitreMatrixTacticMapping;
  MitreMatrixContent: MitreMatrixContent;
  MitreTactic: MitreTactic;
  MitreTechnique: MitreTechnique;
  ReportSetting: Omit<ReportSetting, 'updatedBy'> & {
    updatedBy?: Maybe<ResolversParentTypes['Actor']>;
  };
  ReportSettingAssociation: ReportSettingAssociation;
  ReportSettingAssociationMitre: ReportSettingAssociationMitre;
  UpdateMitreReportSettings: UpdateMitreReportSettings;
  UpdateMitreReportMappingInput: UpdateMitreReportMappingInput;
  UpdateMitreReportMappingSelection: UpdateMitreReportMappingSelection;
  UpdateMitreReportMappingOutput: UpdateMitreReportMappingOutput;
  ResourcesForPolicyInput: ResourcesForPolicyInput;
  ListResourcesResponse: ListResourcesResponse;
  GetResourceInput: GetResourceInput;
  ListResourcesInput: ListResourcesInput;
  ListComplianceItemsResponse: ListComplianceItemsResponse;
  ComplianceItem: ComplianceItem;
  ActiveSuppressCount: ActiveSuppressCount;
  ResourceSummary: ResourceSummary;
  ResourceDetails: ResourceDetails;
  CreateRoleInput: CreateRoleInput;
  UpdateRoleInput: UpdateRoleInput;
  RolesInput: RolesInput;
  DeleteRoleInput: DeleteRoleInput;
  DeleteRoleOutput: DeleteRoleOutput;
  CreateRoleOutput: CreateRoleOutput;
  UpdateRoleOutput: UpdateRoleOutput;
  Role: Omit<Role, 'updatedBy'> & { updatedBy?: Maybe<ResolversParentTypes['Actor']> };
  AddSavedQueryInput: AddSavedQueryInput;
  UpdateSavedQueryInput: UpdateSavedQueryInput;
  DeleteSavedQueryInput: DeleteSavedQueryInput;
  ListSavedQueriesInput: ListSavedQueriesInput;
  ScheduleInput: ScheduleInput;
  Schedule: Schedule;
  SavedQuery: Omit<SavedQuery, 'createdBy' | 'lastModifiedBy'> & {
    createdBy?: Maybe<ResolversParentTypes['Actor']>;
    lastModifiedBy?: Maybe<ResolversParentTypes['Actor']>;
  };
  ListSavedQueriesResponse: ListSavedQueriesResponse;
  EventThresholdAlarmInput: EventThresholdAlarmInput;
  GetComplianceIntegrationTemplateInput: GetComplianceIntegrationTemplateInput;
  GetTerraformTemplateInput: GetTerraformTemplateInput;
  GetS3LogIntegrationTemplateInput: GetS3LogIntegrationTemplateInput;
  GetCloudWatchLogIntegrationTemplateInput: GetCloudWatchLogIntegrationTemplateInput;
  SqsConfig: SqsConfig;
  GcsConfig: GcsConfig;
  AWSScanConfig: AWSScanConfig;
  LogIntegration:
    | ResolversParentTypes['S3LogIntegration']
    | ResolversParentTypes['CloudWatchLogIntegration']
    | ResolversParentTypes['EventBridgeIntegration']
    | ResolversParentTypes['SqsLogSourceIntegration']
    | ResolversParentTypes['GcsLogSourceIntegration']
    | ResolversParentTypes['LogPullingIntegration'];
  ComplianceIntegration: Omit<ComplianceIntegration, 'createdBy'> & {
    createdBy?: Maybe<ResolversParentTypes['Actor']>;
  };
  LogIntegrationAlarms: LogIntegrationAlarms;
  S3PrefixLogTypes: S3PrefixLogTypes;
  S3LogIntegration: Omit<S3LogIntegration, 'createdBy'> & {
    createdBy?: Maybe<ResolversParentTypes['Actor']>;
  };
  CloudWatchLogIntegration: Omit<CloudWatchLogIntegration, 'createdBy'> & {
    createdBy?: Maybe<ResolversParentTypes['Actor']>;
  };
  EventBridgeIntegration: Omit<EventBridgeIntegration, 'createdBy'> & {
    createdBy?: Maybe<ResolversParentTypes['Actor']>;
  };
  SqsLogSourceIntegration: Omit<SqsLogSourceIntegration, 'createdBy'> & {
    createdBy?: Maybe<ResolversParentTypes['Actor']>;
  };
  GcsLogSourceIntegration: Omit<GcsLogSourceIntegration, 'createdBy'> & {
    createdBy?: Maybe<ResolversParentTypes['Actor']>;
  };
  LogPullingIntegration: Omit<LogPullingIntegration, 'createdBy'> & {
    createdBy?: Maybe<ResolversParentTypes['Actor']>;
  };
  OAuth2Info: OAuth2Info;
  AddComplianceIntegrationInput: AddComplianceIntegrationInput;
  S3PrefixLogTypesInput: S3PrefixLogTypesInput;
  AddS3LogIntegrationInput: AddS3LogIntegrationInput;
  AddCloudWatchLogIntegrationInput: AddCloudWatchLogIntegrationInput;
  AddEventBridgeIntegrationInput: AddEventBridgeIntegrationInput;
  AsanaLogSourceConfigInput: AsanaLogSourceConfigInput;
  OktaConfigInput: OktaConfigInput;
  GsuiteConfigInput: GsuiteConfigInput;
  CrowdstrikeInput: CrowdstrikeInput;
  DuoConfigInput: DuoConfigInput;
  SalesforceConfigInput: SalesforceConfigInput;
  OAuthConfigInput: OAuthConfigInput;
  M365ConfigInput: M365ConfigInput;
  MicrosoftGraphConfigInput: MicrosoftGraphConfigInput;
  GithubOAuth2ConfigInput: GithubOAuth2ConfigInput;
  GithubPatConfigInput: GithubPatConfigInput;
  GithubLogSourceInput: GithubLogSourceInput;
  OnePasswordConfigInput: OnePasswordConfigInput;
  AtlassianConfigInput: AtlassianConfigInput;
  ZendeskOAuth2ConfigInput: ZendeskOAuth2ConfigInput;
  GetLookupCloudFormationRoleTemplateInput: GetLookupCloudFormationRoleTemplateInput;
  ZendeskBasicConfigInput: ZendeskBasicConfigInput;
  ZendeskApiConfigInput: ZendeskApiConfigInput;
  ZendeskLogSourceInput: ZendeskLogSourceInput;
  WorkdayConfigInput: WorkdayConfigInput;
  SnykConfigInput: SnykConfigInput;
  PullerConfigInput: PullerConfigInput;
  AddLogPullingIntegrationInput: AddLogPullingIntegrationInput;
  SqsLogConfigInput: SqsLogConfigInput;
  GcsLogConfigInput: GcsLogConfigInput;
  AWSScanConfigInput: AWSScanConfigInput;
  AddSqsLogIntegrationInput: AddSqsLogIntegrationInput;
  AddGcsLogIntegrationInput: AddGcsLogIntegrationInput;
  UpdateComplianceIntegrationInput: UpdateComplianceIntegrationInput;
  UpdateS3LogIntegrationInput: UpdateS3LogIntegrationInput;
  UpdateCloudWatchLogIntegrationInput: UpdateCloudWatchLogIntegrationInput;
  UpdateEventbridgeIntegrationInput: UpdateEventbridgeIntegrationInput;
  UpdateLogPullingIntegrationInput: UpdateLogPullingIntegrationInput;
  UpdateSqsLogIntegrationInput: UpdateSqsLogIntegrationInput;
  UpdateGcsLogIntegrationInput: UpdateGcsLogIntegrationInput;
  SourceMetricsInput: SourceMetricsInput;
  IntegrationTemplate: IntegrationTemplate;
  TerraformTemplateResponse: TerraformTemplateResponse;
  IntegrationItemHealthStatus: IntegrationItemHealthStatus;
  ComplianceIntegrationHealth: ComplianceIntegrationHealth;
  S3LogIntegrationHealth: S3LogIntegrationHealth;
  CloudWatchLogIntegrationHealth: CloudWatchLogIntegrationHealth;
  EventBridgeIntegrationHealth: EventBridgeIntegrationHealth;
  LogPullingIntegrationHealth: LogPullingIntegrationHealth;
  SqsLogIntegrationHealth: SqsLogIntegrationHealth;
  GcsLogIntegrationHealth: GcsLogIntegrationHealth;
  EventBridgeConfig: EventBridgeConfig;
  OktaConfig: OktaConfig;
  GsuiteConfig: GsuiteConfig;
  CrowdstrikeConfig: CrowdstrikeConfig;
  DuoConfig: DuoConfig;
  SalesforceConfig: SalesforceConfig;
  OAuthConfig: OAuthConfig;
  M365Config: M365Config;
  MicrosoftGraphConfig: MicrosoftGraphConfig;
  GithubLogSourceOAuth2Config: GithubLogSourceOAuth2Config;
  GithubLogSourcePatConfig: GithubLogSourcePatConfig;
  GithubLogSourceCredentialsConfig:
    | ResolversParentTypes['GithubLogSourceOAuth2Config']
    | ResolversParentTypes['GithubLogSourcePatConfig'];
  GithubLogSourceConfig: Omit<GithubLogSourceConfig, 'credentials'> & {
    credentials: ResolversParentTypes['GithubLogSourceCredentialsConfig'];
  };
  OnePasswordConfig: OnePasswordConfig;
  AtlassianConfig: AtlassianConfig;
  ZendeskLogSourceOAuth2Config: ZendeskLogSourceOAuth2Config;
  ZendeskLogSourceBasicConfig: ZendeskLogSourceBasicConfig;
  ZendeskLogSourceApiConfig: ZendeskLogSourceApiConfig;
  ZendeskLogSourceCredentialsConfig:
    | ResolversParentTypes['ZendeskLogSourceOAuth2Config']
    | ResolversParentTypes['ZendeskLogSourceBasicConfig']
    | ResolversParentTypes['ZendeskLogSourceApiConfig'];
  ZendeskLogSourceConfig: Omit<ZendeskLogSourceConfig, 'credentials'> & {
    credentials: ResolversParentTypes['ZendeskLogSourceCredentialsConfig'];
  };
  AsanaLogSourceConfig: AsanaLogSourceConfig;
  WorkdayConfig: WorkdayConfig;
  SnykLogSourceConfig: SnykLogSourceConfig;
  PullerConfig: PullerConfig;
  EventThresholdAlarm: EventThresholdAlarm;
  SeriesMetric: SeriesMetric;
  SeriesData: SeriesData;
  SourceMetricsResponse: SourceMetricsResponse;
  ResetUserPasswordInput: ResetUserPasswordInput;
  SendUserFeedbackInput: SendUserFeedbackInput;
  UserFeedbackFeatureInput: UserFeedbackFeatureInput;
  UserFeedbackDataInput: UserFeedbackDataInput;
  SendUserFeedbackOutput: SendUserFeedbackOutput;
  ResetUserPasswordOutput: ResetUserPasswordOutput;
  InviteUserInput: InviteUserInput;
  InviteUserOutput: InviteUserOutput;
  UpdateUserOutput: UpdateUserOutput;
  DeleteUserOutput: DeleteUserOutput;
  DeleteUserInput: DeleteUserInput;
  UpdateUserInput: UpdateUserInput;
  User: User;
  UserRoleInput: UserRoleInput;
};

export type AuthDirectiveArgs = { permissions: Array<Permission> };

export type AuthDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = AuthDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type SensitiveDirectiveArgs = {};

export type SensitiveDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = SensitiveDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type AuditDirectiveArgs = { name: Scalars['String'] };

export type AuditDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = AuditDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type ActorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Actor'] = ResolversParentTypes['Actor']
> = {
  __resolveType: TypeResolveFn<'User' | 'APIToken', ParentType, ContextType>;
};

export type QueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']
> = {
  alert?: Resolver<
    ResolversTypes['Alert'],
    ParentType,
    ContextType,
    RequireFields<QueryAlertArgs, 'id'>
  >;
  alerts?: Resolver<
    ResolversTypes['AlertsOutput'],
    ParentType,
    ContextType,
    RequireFields<QueryAlertsArgs, 'input'>
  >;
  bulkDownload?: Resolver<
    ResolversTypes['BulkDownloadResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryBulkDownloadArgs, 'input'>
  >;
  checkLookupImportDataJob?: Resolver<
    ResolversTypes['LookupImportDataJob'],
    ParentType,
    ContextType,
    RequireFields<QueryCheckLookupImportDataJobArgs, 'jobId'>
  >;
  checkSyncLookupDataJob?: Resolver<
    ResolversTypes['LookupSyncJob'],
    ParentType,
    ContextType,
    RequireFields<QueryCheckSyncLookupDataJobArgs, 'id'>
  >;
  dataLakeDatabase?: Resolver<
    ResolversTypes['DataLakeDatabase'],
    ParentType,
    ContextType,
    RequireFields<QueryDataLakeDatabaseArgs, 'name'>
  >;
  dataLakeDatabaseTable?: Resolver<
    ResolversTypes['DataLakeDatabaseTable'],
    ParentType,
    ContextType,
    RequireFields<QueryDataLakeDatabaseTableArgs, 'input'>
  >;
  dataLakeDatabases?: Resolver<Array<ResolversTypes['DataLakeDatabase']>, ParentType, ContextType>;
  dataLakeQueries?: Resolver<
    ResolversTypes['DataLakeQueriesOutput'],
    ParentType,
    ContextType,
    RequireFields<QueryDataLakeQueriesArgs, never>
  >;
  dataLakeQuery?: Resolver<
    ResolversTypes['DataLakeQuery'],
    ParentType,
    ContextType,
    RequireFields<QueryDataLakeQueryArgs, 'id'>
  >;
  dataLakeQuerySummary?: Resolver<
    ResolversTypes['SummarizeDataLakeQueryJob'],
    ParentType,
    ContextType,
    RequireFields<QueryDataLakeQuerySummaryArgs, 'jobId'>
  >;
  destination?: Resolver<
    Maybe<ResolversTypes['Destination']>,
    ParentType,
    ContextType,
    RequireFields<QueryDestinationArgs, 'id'>
  >;
  destinations?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Destination']>>>,
    ParentType,
    ContextType
  >;
  detectionFilter?: Resolver<
    ResolversTypes['DetectionFilter'],
    ParentType,
    ContextType,
    RequireFields<QueryDetectionFilterArgs, 'id'>
  >;
  detectionFilters?: Resolver<
    ResolversTypes['DetectionFiltersOutput'],
    ParentType,
    ContextType,
    RequireFields<QueryDetectionFiltersArgs, 'input'>
  >;
  detections?: Resolver<
    ResolversTypes['ListDetectionsResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryDetectionsArgs, never>
  >;
  generalSettings?: Resolver<ResolversTypes['GeneralSettingsConfig'], ParentType, ContextType>;
  generateAlertSummaryAttributesQueries?: Resolver<
    Array<ResolversTypes['AlertSummaryAttributeForAnalysis']>,
    ParentType,
    ContextType,
    RequireFields<QueryGenerateAlertSummaryAttributesQueriesArgs, 'input'>
  >;
  generateEnrichedEvent?: Resolver<
    ResolversTypes['GenerateEnrichedEventResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGenerateEnrichedEventArgs, 'input'>
  >;
  generateSqlQuerySnippet?: Resolver<
    ResolversTypes['GenerateSqlQuerySnippetOutput'],
    ParentType,
    ContextType,
    RequireFields<QueryGenerateSqlQuerySnippetArgs, 'input'>
  >;
  getAPIToken?: Resolver<
    ResolversTypes['GetAPITokenOutput'],
    ParentType,
    ContextType,
    RequireFields<QueryGetAPITokenArgs, 'id'>
  >;
  getAnalysisPack?: Resolver<
    ResolversTypes['AnalysisPack'],
    ParentType,
    ContextType,
    RequireFields<QueryGetAnalysisPackArgs, 'id'>
  >;
  getAnalysisPackSource?: Resolver<
    ResolversTypes['AnalysisPackSource'],
    ParentType,
    ContextType,
    RequireFields<QueryGetAnalysisPackSourceArgs, 'id'>
  >;
  getAsyncQueryDownloadUrl?: Resolver<
    ResolversTypes['GetAsyncQueryDownloadUrlOutput'],
    ParentType,
    ContextType,
    RequireFields<QueryGetAsyncQueryDownloadUrlArgs, 'input'>
  >;
  getAvailableLogProvider?: Resolver<
    ResolversTypes['GetAvailableLogProviderResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGetAvailableLogProviderArgs, 'id'>
  >;
  getCloudWatchLogIntegration?: Resolver<
    ResolversTypes['CloudWatchLogIntegration'],
    ParentType,
    ContextType,
    RequireFields<QueryGetCloudWatchLogIntegrationArgs, 'id'>
  >;
  getCloudWatchLogIntegrationTemplate?: Resolver<
    ResolversTypes['IntegrationTemplate'],
    ParentType,
    ContextType,
    RequireFields<QueryGetCloudWatchLogIntegrationTemplateArgs, 'input'>
  >;
  getComplianceIntegration?: Resolver<
    ResolversTypes['ComplianceIntegration'],
    ParentType,
    ContextType,
    RequireFields<QueryGetComplianceIntegrationArgs, 'id'>
  >;
  getComplianceIntegrationTemplate?: Resolver<
    ResolversTypes['IntegrationTemplate'],
    ParentType,
    ContextType,
    RequireFields<QueryGetComplianceIntegrationTemplateArgs, 'input'>
  >;
  getDataModel?: Resolver<
    Maybe<ResolversTypes['DataModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetDataModelArgs, 'id'>
  >;
  getEventBridgeLogIntegration?: Resolver<
    ResolversTypes['EventBridgeIntegration'],
    ParentType,
    ContextType,
    RequireFields<QueryGetEventBridgeLogIntegrationArgs, 'id'>
  >;
  getGcsLogIntegration?: Resolver<
    ResolversTypes['GcsLogSourceIntegration'],
    ParentType,
    ContextType,
    RequireFields<QueryGetGcsLogIntegrationArgs, 'id'>
  >;
  getGlobalHelper?: Resolver<
    ResolversTypes['GlobalHelper'],
    ParentType,
    ContextType,
    RequireFields<QueryGetGlobalHelperArgs, 'input'>
  >;
  getLogIntegration?: Resolver<
    ResolversTypes['LogIntegration'],
    ParentType,
    ContextType,
    RequireFields<QueryGetLogIntegrationArgs, 'id'>
  >;
  getLogPullingIntegration?: Resolver<
    ResolversTypes['LogPullingIntegration'],
    ParentType,
    ContextType,
    RequireFields<QueryGetLogPullingIntegrationArgs, 'id'>
  >;
  getLookup?: Resolver<
    ResolversTypes['Lookup'],
    ParentType,
    ContextType,
    RequireFields<QueryGetLookupArgs, 'id'>
  >;
  getLookupCloudFormationRoleTemplate?: Resolver<
    ResolversTypes['IntegrationTemplate'],
    ParentType,
    ContextType,
    RequireFields<QueryGetLookupCloudFormationRoleTemplateArgs, 'input'>
  >;
  getRunningTask?: Resolver<
    Maybe<ResolversTypes['Job']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetRunningTaskArgs, 'input'>
  >;
  getS3LogIntegration?: Resolver<
    ResolversTypes['S3LogIntegration'],
    ParentType,
    ContextType,
    RequireFields<QueryGetS3LogIntegrationArgs, 'id'>
  >;
  getS3LogIntegrationTemplate?: Resolver<
    ResolversTypes['IntegrationTemplate'],
    ParentType,
    ContextType,
    RequireFields<QueryGetS3LogIntegrationTemplateArgs, 'input'>
  >;
  getSampleData?: Resolver<
    ResolversTypes['GetSampleDataResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGetSampleDataArgs, 'input'>
  >;
  getSavedQuery?: Resolver<
    ResolversTypes['SavedQuery'],
    ParentType,
    ContextType,
    RequireFields<QueryGetSavedQueryArgs, 'id'>
  >;
  getSchema?: Resolver<
    ResolversTypes['GetSchemaOutput'],
    ParentType,
    ContextType,
    RequireFields<QueryGetSchemaArgs, 'input'>
  >;
  getSqsLogIntegration?: Resolver<
    ResolversTypes['SqsLogSourceIntegration'],
    ParentType,
    ContextType,
    RequireFields<QueryGetSqsLogIntegrationArgs, 'id'>
  >;
  getTerraformTemplate?: Resolver<
    ResolversTypes['TerraformTemplateResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGetTerraformTemplateArgs, 'input'>
  >;
  holdingTankJob?: Resolver<
    ResolversTypes['Job'],
    ParentType,
    ContextType,
    RequireFields<QueryHoldingTankJobArgs, 'id'>
  >;
  holdingTankRawData?: Resolver<
    ResolversTypes['HoldingTankRawDataResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryHoldingTankRawDataArgs, 'input'>
  >;
  holdingTankTestSchemasResult?: Resolver<
    ResolversTypes['HoldingTankTestSchemasResultsResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryHoldingTankTestSchemasResultArgs, never>
  >;
  listAPITokens?: Resolver<ResolversTypes['ListAPITokensOutput'], ParentType, ContextType>;
  listAnalysisPackSources?: Resolver<
    ResolversTypes['ListAnalysisPackSourcesResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryListAnalysisPackSourcesArgs, never>
  >;
  listAnalysisPacks?: Resolver<
    ResolversTypes['ListAnalysisPacksResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryListAnalysisPacksArgs, never>
  >;
  listAvailableLogProviders?: Resolver<
    ResolversTypes['ListAvailableLogProvidersResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryListAvailableLogProvidersArgs, never>
  >;
  listAvailableLogTypes?: Resolver<
    ResolversTypes['ListAvailableLogTypesResponse'],
    ParentType,
    ContextType
  >;
  listComplianceIntegrations?: Resolver<
    Array<ResolversTypes['ComplianceIntegration']>,
    ParentType,
    ContextType
  >;
  listDataModels?: Resolver<
    ResolversTypes['ListDataModelsResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryListDataModelsArgs, 'input'>
  >;
  listGlobalHelpers?: Resolver<
    ResolversTypes['ListGlobalHelpersResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryListGlobalHelpersArgs, 'input'>
  >;
  listLookups?: Resolver<
    ResolversTypes['ListLookupResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryListLookupsArgs, 'input'>
  >;
  listSavedQueries?: Resolver<
    ResolversTypes['ListSavedQueriesResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryListSavedQueriesArgs, 'input'>
  >;
  listSchemas?: Resolver<
    ResolversTypes['ListSchemasOutput'],
    ParentType,
    ContextType,
    RequireFields<QueryListSchemasArgs, never>
  >;
  listTestTasks?: Resolver<
    ResolversTypes['HoldingTankListTasksResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryListTestTasksArgs, 'sourceId'>
  >;
  logSources?: Resolver<Array<Maybe<ResolversTypes['LogIntegration']>>, ParentType, ContextType>;
  metrics?: Resolver<
    ResolversTypes['MetricsOutput'],
    ParentType,
    ContextType,
    RequireFields<QueryMetricsArgs, 'input'>
  >;
  mitreMatrix?: Resolver<
    Maybe<ResolversTypes['MitreMatrix']>,
    ParentType,
    ContextType,
    RequireFields<QueryMitreMatrixArgs, 'id'>
  >;
  mitreMatrixTree?: Resolver<ResolversTypes['MitreMatrixTree'], ParentType, ContextType>;
  organizationStats?: Resolver<
    Maybe<ResolversTypes['OrganizationStatsResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryOrganizationStatsArgs, never>
  >;
  policiesForResource?: Resolver<
    Maybe<ResolversTypes['ListComplianceItemsResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryPoliciesForResourceArgs, never>
  >;
  policy?: Resolver<
    Maybe<ResolversTypes['Policy']>,
    ParentType,
    ContextType,
    RequireFields<QueryPolicyArgs, 'input'>
  >;
  replay?: Resolver<
    ResolversTypes['Replay'],
    ParentType,
    ContextType,
    RequireFields<QueryReplayArgs, 'id'>
  >;
  replayPreview?: Resolver<
    ResolversTypes['ReplayPreview'],
    ParentType,
    ContextType,
    RequireFields<QueryReplayPreviewArgs, 'endsAt' | 'startsAt' | 'logTypes'>
  >;
  replayedAlert?: Resolver<
    ResolversTypes['Alert'],
    ParentType,
    ContextType,
    RequireFields<QueryReplayedAlertArgs, 'alertId' | 'replayId'>
  >;
  replayedAlerts?: Resolver<
    ResolversTypes['ReplayedAlertsOutput'],
    ParentType,
    ContextType,
    RequireFields<QueryReplayedAlertsArgs, 'input'>
  >;
  replays?: Resolver<
    Array<ResolversTypes['Replay']>,
    ParentType,
    ContextType,
    RequireFields<QueryReplaysArgs, 'detectionId'>
  >;
  reportSetting?: Resolver<
    ResolversTypes['ReportSetting'],
    ParentType,
    ContextType,
    RequireFields<QueryReportSettingArgs, 'tacticId' | 'techniqueId'>
  >;
  resource?: Resolver<
    Maybe<ResolversTypes['ResourceDetails']>,
    ParentType,
    ContextType,
    RequireFields<QueryResourceArgs, 'input'>
  >;
  resources?: Resolver<
    Maybe<ResolversTypes['ListResourcesResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryResourcesArgs, never>
  >;
  resourcesForPolicy?: Resolver<
    Maybe<ResolversTypes['ListComplianceItemsResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryResourcesForPolicyArgs, 'input'>
  >;
  roleById?: Resolver<
    ResolversTypes['Role'],
    ParentType,
    ContextType,
    RequireFields<QueryRoleByIdArgs, 'id'>
  >;
  roleByName?: Resolver<
    ResolversTypes['Role'],
    ParentType,
    ContextType,
    RequireFields<QueryRoleByNameArgs, 'name'>
  >;
  roles?: Resolver<
    Array<ResolversTypes['Role']>,
    ParentType,
    ContextType,
    RequireFields<QueryRolesArgs, never>
  >;
  rule?: Resolver<
    Maybe<ResolversTypes['Rule']>,
    ParentType,
    ContextType,
    RequireFields<QueryRuleArgs, 'input'>
  >;
  samlConfig?: Resolver<ResolversTypes['SamlConfig'], ParentType, ContextType>;
  sendTestAlert?: Resolver<
    Array<Maybe<ResolversTypes['AlertDelivery']>>,
    ParentType,
    ContextType,
    RequireFields<QuerySendTestAlertArgs, 'input'>
  >;
  sourceMetrics?: Resolver<
    ResolversTypes['SourceMetricsResponse'],
    ParentType,
    ContextType,
    RequireFields<QuerySourceMetricsArgs, 'input'>
  >;
  userByEmail?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<QueryUserByEmailArgs, 'email'>
  >;
  userById?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<QueryUserByIdArgs, 'id'>
  >;
  users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
};

export type MutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']
> = {
  addAnalysisPackSource?: Resolver<
    ResolversTypes['AnalysisPackSource'],
    ParentType,
    ContextType,
    RequireFields<MutationAddAnalysisPackSourceArgs, 'input'>
  >;
  addCloudWatchLogIntegration?: Resolver<
    ResolversTypes['CloudWatchLogIntegration'],
    ParentType,
    ContextType,
    RequireFields<MutationAddCloudWatchLogIntegrationArgs, 'input'>
  >;
  addComplianceIntegration?: Resolver<
    ResolversTypes['ComplianceIntegration'],
    ParentType,
    ContextType,
    RequireFields<MutationAddComplianceIntegrationArgs, 'input'>
  >;
  addDataModel?: Resolver<
    ResolversTypes['DataModel'],
    ParentType,
    ContextType,
    RequireFields<MutationAddDataModelArgs, 'input'>
  >;
  addDestination?: Resolver<
    Maybe<ResolversTypes['Destination']>,
    ParentType,
    ContextType,
    RequireFields<MutationAddDestinationArgs, 'input'>
  >;
  addEventBridgeIntegration?: Resolver<
    ResolversTypes['EventBridgeIntegration'],
    ParentType,
    ContextType,
    RequireFields<MutationAddEventBridgeIntegrationArgs, 'input'>
  >;
  addGcsLogIntegration?: Resolver<
    ResolversTypes['GcsLogSourceIntegration'],
    ParentType,
    ContextType,
    RequireFields<MutationAddGcsLogIntegrationArgs, 'input'>
  >;
  addGlobalHelper?: Resolver<
    ResolversTypes['GlobalHelper'],
    ParentType,
    ContextType,
    RequireFields<MutationAddGlobalHelperArgs, 'input'>
  >;
  addLogPullingIntegration?: Resolver<
    ResolversTypes['LogPullingIntegration'],
    ParentType,
    ContextType,
    RequireFields<MutationAddLogPullingIntegrationArgs, 'input'>
  >;
  addLookup?: Resolver<
    ResolversTypes['Lookup'],
    ParentType,
    ContextType,
    RequireFields<MutationAddLookupArgs, 'input'>
  >;
  addPolicy?: Resolver<
    Maybe<ResolversTypes['Policy']>,
    ParentType,
    ContextType,
    RequireFields<MutationAddPolicyArgs, 'input'>
  >;
  addRule?: Resolver<
    Maybe<ResolversTypes['Rule']>,
    ParentType,
    ContextType,
    RequireFields<MutationAddRuleArgs, 'input'>
  >;
  addS3LogIntegration?: Resolver<
    ResolversTypes['S3LogIntegration'],
    ParentType,
    ContextType,
    RequireFields<MutationAddS3LogIntegrationArgs, 'input'>
  >;
  addSavedQuery?: Resolver<
    ResolversTypes['SavedQuery'],
    ParentType,
    ContextType,
    RequireFields<MutationAddSavedQueryArgs, 'input'>
  >;
  addSqsLogIntegration?: Resolver<
    ResolversTypes['SqsLogSourceIntegration'],
    ParentType,
    ContextType,
    RequireFields<MutationAddSqsLogIntegrationArgs, 'input'>
  >;
  bulkUpdateDetections?: Resolver<
    Maybe<ResolversTypes['BulkUpdateDetectionsResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationBulkUpdateDetectionsArgs, 'input'>
  >;
  cancelDataLakeQuery?: Resolver<
    ResolversTypes['CancelDataLakeQueryOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationCancelDataLakeQueryArgs, 'input'>
  >;
  cancelHoldingTankJob?: Resolver<
    ResolversTypes['Job'],
    ParentType,
    ContextType,
    RequireFields<MutationCancelHoldingTankJobArgs, 'id'>
  >;
  computeReplay?: Resolver<
    ResolversTypes['ComputeReplayOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationComputeReplayArgs, 'input'>
  >;
  createAPIToken?: Resolver<
    ResolversTypes['CreateAPITokenOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateAPITokenArgs, 'input'>
  >;
  createDetectionFilter?: Resolver<
    ResolversTypes['CreateDetectionFilterOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateDetectionFilterArgs, 'input'>
  >;
  createReplay?: Resolver<
    ResolversTypes['CreateReplayOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateReplayArgs, 'input'>
  >;
  createRole?: Resolver<
    ResolversTypes['CreateRoleOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateRoleArgs, 'input'>
  >;
  deleteAPIToken?: Resolver<
    ResolversTypes['DeleteAPITokenOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteAPITokenArgs, 'id'>
  >;
  deleteAnalysisPackSource?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteAnalysisPackSourceArgs, 'input'>
  >;
  deleteComplianceIntegration?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteComplianceIntegrationArgs, 'id'>
  >;
  deleteDataModel?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteDataModelArgs, 'input'>
  >;
  deleteDestination?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteDestinationArgs, 'id'>
  >;
  deleteDetectionFilter?: Resolver<
    Maybe<ResolversTypes['DeleteDetectionFilterOutput']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteDetectionFilterArgs, 'id'>
  >;
  deleteDetections?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteDetectionsArgs, 'input'>
  >;
  deleteGlobalHelpers?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteGlobalHelpersArgs, 'input'>
  >;
  deleteLogIntegration?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteLogIntegrationArgs, 'id'>
  >;
  deleteLogIntegrationEventThresholdAlarm?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteLogIntegrationEventThresholdAlarmArgs, 'integrationId'>
  >;
  deleteLookups?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteLookupsArgs, 'input'>
  >;
  deleteRole?: Resolver<
    ResolversTypes['DeleteRoleOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteRoleArgs, 'input'>
  >;
  deleteSavedQueries?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteSavedQueriesArgs, 'input'>
  >;
  deleteUser?: Resolver<
    ResolversTypes['DeleteUserOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteUserArgs, 'input'>
  >;
  deliverAlert?: Resolver<
    ResolversTypes['Alert'],
    ParentType,
    ContextType,
    RequireFields<MutationDeliverAlertArgs, 'input'>
  >;
  executeDataLakeQuery?: Resolver<
    ResolversTypes['ExecuteDataLakeQueryOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationExecuteDataLakeQueryArgs, 'input'>
  >;
  executeIndicatorSearchQuery?: Resolver<
    ResolversTypes['ExecuteIndicatorSearchQueryOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationExecuteIndicatorSearchQueryArgs, 'input'>
  >;
  generateLookupImportUrl?: Resolver<
    Maybe<ResolversTypes['LookupS3ImportConfig']>,
    ParentType,
    ContextType,
    RequireFields<MutationGenerateLookupImportUrlArgs, 'input'>
  >;
  generateS3SampleDataUpload?: Resolver<
    ResolversTypes['GenerateS3SampleUploadResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationGenerateS3SampleDataUploadArgs, never>
  >;
  importLookupData?: Resolver<
    ResolversTypes['LookupImportDataJob'],
    ParentType,
    ContextType,
    RequireFields<MutationImportLookupDataArgs, 'input'>
  >;
  inferSchema?: Resolver<
    Maybe<ResolversTypes['InferSchemaResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationInferSchemaArgs, 'input'>
  >;
  inferSchemaFromRawData?: Resolver<
    ResolversTypes['Job'],
    ParentType,
    ContextType,
    RequireFields<MutationInferSchemaFromRawDataArgs, 'input'>
  >;
  inviteUser?: Resolver<
    ResolversTypes['InviteUserOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationInviteUserArgs, 'input'>
  >;
  putUserSchema?: Resolver<
    ResolversTypes['GetSchemaOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationPutUserSchemaArgs, 'input'>
  >;
  requestLogType?: Resolver<
    Maybe<ResolversTypes['RequestLogTypeResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationRequestLogTypeArgs, 'input'>
  >;
  resetUserPassword?: Resolver<
    ResolversTypes['ResetUserPasswordOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationResetUserPasswordArgs, 'input'>
  >;
  sendUserFeedback?: Resolver<
    ResolversTypes['SendUserFeedbackOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationSendUserFeedbackArgs, 'input'>
  >;
  setLogIntegrationEventThresholdAlarm?: Resolver<
    ResolversTypes['EventThresholdAlarm'],
    ParentType,
    ContextType,
    RequireFields<MutationSetLogIntegrationEventThresholdAlarmArgs, 'input'>
  >;
  stopReplay?: Resolver<
    ResolversTypes['StopReplayOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationStopReplayArgs, 'input'>
  >;
  summarizeDataLakeQuery?: Resolver<
    ResolversTypes['SummarizeDataLakeQueryJob'],
    ParentType,
    ContextType,
    RequireFields<MutationSummarizeDataLakeQueryArgs, 'input'>
  >;
  suppressPolicies?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationSuppressPoliciesArgs, 'input'>
  >;
  syncLookupData?: Resolver<
    ResolversTypes['LookupSyncJob'],
    ParentType,
    ContextType,
    RequireFields<MutationSyncLookupDataArgs, 'input'>
  >;
  testHoldingTankSchemas?: Resolver<
    ResolversTypes['Job'],
    ParentType,
    ContextType,
    RequireFields<MutationTestHoldingTankSchemasArgs, 'input'>
  >;
  testPolicy?: Resolver<
    ResolversTypes['TestPolicyResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationTestPolicyArgs, 'input'>
  >;
  testRule?: Resolver<
    ResolversTypes['TestRuleResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationTestRuleArgs, 'input'>
  >;
  testSchema?: Resolver<
    Maybe<ResolversTypes['TestSchemaResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationTestSchemaArgs, 'input'>
  >;
  toggleSchema?: Resolver<
    ResolversTypes['GetSchemaOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationToggleSchemaArgs, 'input'>
  >;
  updateAPIToken?: Resolver<
    ResolversTypes['UpdateAPITokenOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAPITokenArgs, 'input'>
  >;
  updateAlertStatusByFilter?: Resolver<
    ResolversTypes['UpdateAlertStatusByFilterJob'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAlertStatusByFilterArgs, 'input'>
  >;
  updateAlertStatusById?: Resolver<
    ResolversTypes['UpdateAlertStatusByIdOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAlertStatusByIdArgs, 'input'>
  >;
  updateAlertsAssigneeById?: Resolver<
    ResolversTypes['UpdateAlertsAssigneeByIdOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAlertsAssigneeByIdArgs, 'input'>
  >;
  updateAnalysisPack?: Resolver<
    ResolversTypes['AnalysisPack'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAnalysisPackArgs, 'input'>
  >;
  updateAnalysisPackSource?: Resolver<
    ResolversTypes['AnalysisPackSource'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAnalysisPackSourceArgs, 'input'>
  >;
  updateCloudwatchLogIntegration?: Resolver<
    ResolversTypes['CloudWatchLogIntegration'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCloudwatchLogIntegrationArgs, 'input'>
  >;
  updateComplianceIntegration?: Resolver<
    ResolversTypes['ComplianceIntegration'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateComplianceIntegrationArgs, 'input'>
  >;
  updateDataModel?: Resolver<
    ResolversTypes['DataModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateDataModelArgs, 'input'>
  >;
  updateDestination?: Resolver<
    Maybe<ResolversTypes['Destination']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateDestinationArgs, 'input'>
  >;
  updateDetectionFilter?: Resolver<
    ResolversTypes['UpdateDetectionFilterOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateDetectionFilterArgs, 'input'>
  >;
  updateEventbridgeIntegration?: Resolver<
    ResolversTypes['EventBridgeIntegration'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateEventbridgeIntegrationArgs, 'input'>
  >;
  updateGcsLogIntegration?: Resolver<
    ResolversTypes['GcsLogSourceIntegration'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateGcsLogIntegrationArgs, 'input'>
  >;
  updateGeneralSettings?: Resolver<
    ResolversTypes['GeneralSettingsConfig'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateGeneralSettingsArgs, 'input'>
  >;
  updateGlobalHelper?: Resolver<
    ResolversTypes['GlobalHelper'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateGlobalHelperArgs, 'input'>
  >;
  updateLogPullingIntegration?: Resolver<
    ResolversTypes['LogPullingIntegration'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateLogPullingIntegrationArgs, 'input'>
  >;
  updateLookup?: Resolver<
    ResolversTypes['Lookup'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateLookupArgs, 'input'>
  >;
  updateMitreReportMapping?: Resolver<
    ResolversTypes['UpdateMitreReportMappingOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateMitreReportMappingArgs, 'input'>
  >;
  updateMitreReportSettings?: Resolver<
    ResolversTypes['ReportSetting'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateMitreReportSettingsArgs, 'input'>
  >;
  updatePolicy?: Resolver<
    Maybe<ResolversTypes['Policy']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdatePolicyArgs, 'input'>
  >;
  updateRole?: Resolver<
    ResolversTypes['UpdateRoleOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateRoleArgs, 'input'>
  >;
  updateRule?: Resolver<
    Maybe<ResolversTypes['Rule']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateRuleArgs, 'input'>
  >;
  updateS3LogIntegration?: Resolver<
    ResolversTypes['S3LogIntegration'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateS3LogIntegrationArgs, 'input'>
  >;
  updateSamlConfig?: Resolver<
    ResolversTypes['SamlConfig'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateSamlConfigArgs, 'input'>
  >;
  updateSavedQuery?: Resolver<
    ResolversTypes['SavedQuery'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateSavedQueryArgs, 'input'>
  >;
  updateSqsLogIntegration?: Resolver<
    ResolversTypes['SqsLogSourceIntegration'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateSqsLogIntegrationArgs, 'input'>
  >;
  updateUser?: Resolver<
    ResolversTypes['UpdateUserOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateUserArgs, 'input'>
  >;
  uploadDetections?: Resolver<
    Maybe<ResolversTypes['UploadDetectionsResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationUploadDetectionsArgs, 'input'>
  >;
};

export type UpdateAlertStatusByFilterJobResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateAlertStatusByFilterJob'] = ResolversParentTypes['UpdateAlertStatusByFilterJob']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['JobStatus'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AlertEventsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AlertEvents'] = ResolversParentTypes['AlertEvents']
> = {
  items?: Resolver<Array<ResolversTypes['JSON']>, ParentType, ContextType>;
  lastEvaluatedKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AlertOriginResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AlertOrigin'] = ResolversParentTypes['AlertOrigin']
> = {
  __resolveType: TypeResolveFn<
    'AlertOriginRule' | 'AlertOriginPolicy' | 'AlertOriginSystemError' | 'AlertOriginReplayedRule',
    ParentType,
    ContextType
  >;
};

export type SystemErrorRelatedEntityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SystemErrorRelatedEntity'] = ResolversParentTypes['SystemErrorRelatedEntity']
> = {
  __resolveType: TypeResolveFn<
    | 'S3LogIntegration'
    | 'EventBridgeIntegration'
    | 'LogPullingIntegration'
    | 'SqsLogSourceIntegration'
    | 'CloudWatchLogIntegration'
    | 'GcsLogSourceIntegration'
    | 'Destination',
    ParentType,
    ContextType
  >;
};

export type AlertOriginPolicyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AlertOriginPolicy'] = ResolversParentTypes['AlertOriginPolicy']
> = {
  policyId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  resourceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  policySourceId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  resourceTypes?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  dedupString?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AlertOriginRuleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AlertOriginRule'] = ResolversParentTypes['AlertOriginRule']
> = {
  ruleId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  logTypes?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  scheduledQueries?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  eventsMatched?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  dedupString?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  events?: Resolver<
    Maybe<ResolversTypes['AlertEvents']>,
    ParentType,
    ContextType,
    RequireFields<AlertOriginRuleEventsArgs, never>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AlertOriginSystemErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AlertOriginSystemError'] = ResolversParentTypes['AlertOriginSystemError']
> = {
  type?: Resolver<ResolversTypes['SystemErrorTypeEnum'], ParentType, ContextType>;
  relatedComponent?: Resolver<ResolversTypes['RelatedComponentEnum'], ParentType, ContextType>;
  relatedEntity?: Resolver<
    Maybe<ResolversTypes['SystemErrorRelatedEntity']>,
    ParentType,
    ContextType
  >;
  events?: Resolver<
    Maybe<ResolversTypes['AlertEvents']>,
    ParentType,
    ContextType,
    RequireFields<AlertOriginSystemErrorEventsArgs, never>
  >;
  alerts?: Resolver<
    ResolversTypes['RelatedAlertsOutput'],
    ParentType,
    ContextType,
    RequireFields<AlertOriginSystemErrorAlertsArgs, never>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AlertOriginReplayedRuleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AlertOriginReplayedRule'] = ResolversParentTypes['AlertOriginReplayedRule']
> = {
  ruleId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  alertId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  replayId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  logTypes?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  dedupString?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  eventsMatched?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  scheduledQueries?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  events?: Resolver<
    Maybe<ResolversTypes['AlertEvents']>,
    ParentType,
    ContextType,
    RequireFields<AlertOriginReplayedRuleEventsArgs, never>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AlertSummaryAttributeQueryDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AlertSummaryAttributeQueryData'] = ResolversParentTypes['AlertSummaryAttributeQueryData']
> = {
  sql?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AlertSummaryAttributeForAnalysisResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AlertSummaryAttributeForAnalysis'] = ResolversParentTypes['AlertSummaryAttributeForAnalysis']
> = {
  logType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  summaryAttribute?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['Error']>, ParentType, ContextType>;
  query?: Resolver<
    Maybe<ResolversTypes['AlertSummaryAttributeQueryData']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type RelatedAlertsOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RelatedAlertsOutput'] = ResolversParentTypes['RelatedAlertsOutput']
> = {
  edges?: Resolver<Array<ResolversTypes['RelatedAlertsOutputEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['RelatedAlertsOutputPageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type RelatedAlertsOutputEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RelatedAlertsOutputEdge'] = ResolversParentTypes['RelatedAlertsOutputEdge']
> = {
  node?: Resolver<ResolversTypes['Alert'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type RelatedAlertsOutputPageInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RelatedAlertsOutputPageInfo'] = ResolversParentTypes['RelatedAlertsOutputPageInfo']
> = {
  endCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  startCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AlertActivityHistoryRecordContentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AlertActivityHistoryRecordContent'] = ResolversParentTypes['AlertActivityHistoryRecordContent']
> = {
  __resolveType: TypeResolveFn<
    'AlertActivityHistoryAssigneeChange' | 'AlertActivityHistoryStatusChange',
    ParentType,
    ContextType
  >;
};

export type AlertActivityHistoryRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AlertActivityHistoryRecord'] = ResolversParentTypes['AlertActivityHistoryRecord']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['AlertActivityHistoryRecordTypes'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  triggerBy?: Resolver<ResolversTypes['Actor'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['AlertActivityHistoryRecordContent'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AlertActivityHistoryAssigneeChangeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AlertActivityHistoryAssigneeChange'] = ResolversParentTypes['AlertActivityHistoryAssigneeChange']
> = {
  previousAssignee?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  newAssignee?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AlertActivityHistoryStatusChangeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AlertActivityHistoryStatusChange'] = ResolversParentTypes['AlertActivityHistoryStatusChange']
> = {
  previousStatus?: Resolver<ResolversTypes['AlertStatus'], ParentType, ContextType>;
  newStatus?: Resolver<ResolversTypes['AlertStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AlertActivityHistoryOutputEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AlertActivityHistoryOutputEdge'] = ResolversParentTypes['AlertActivityHistoryOutputEdge']
> = {
  node?: Resolver<ResolversTypes['AlertActivityHistoryRecord'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AlertActivityHistoryOutputPageInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AlertActivityHistoryOutputPageInfo'] = ResolversParentTypes['AlertActivityHistoryOutputPageInfo']
> = {
  endCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  startCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AlertActivityHistoryOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AlertActivityHistoryOutput'] = ResolversParentTypes['AlertActivityHistoryOutput']
> = {
  edges?: Resolver<
    Array<ResolversTypes['AlertActivityHistoryOutputEdge']>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<
    ResolversTypes['AlertActivityHistoryOutputPageInfo'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AlertResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Alert'] = ResolversParentTypes['Alert']
> = {
  activityHistory?: Resolver<ResolversTypes['AlertActivityHistoryOutput'], ParentType, ContextType>;
  assignee?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  deliveries?: Resolver<Array<Maybe<ResolversTypes['AlertDelivery']>>, ParentType, ContextType>;
  deliveryOverflow?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastReceivedEventAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  origin?: Resolver<ResolversTypes['AlertOrigin'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  runbook?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  severity?: Resolver<ResolversTypes['Severity'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['AlertStatus'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['AlertType'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UpdateAlertStatusByIdOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateAlertStatusByIdOutput'] = ResolversParentTypes['UpdateAlertStatusByIdOutput']
> = {
  alerts?: Resolver<Array<ResolversTypes['Alert']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UpdateAlertsAssigneeByIdOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateAlertsAssigneeByIdOutput'] = ResolversParentTypes['UpdateAlertsAssigneeByIdOutput']
> = {
  alerts?: Resolver<Array<ResolversTypes['Alert']>, ParentType, ContextType>;
  assignee?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AlertsOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AlertsOutput'] = ResolversParentTypes['AlertsOutput']
> = {
  edges?: Resolver<Array<ResolversTypes['AlertsOutputEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['AlertsOutputPageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AlertsOutputEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AlertsOutputEdge'] = ResolversParentTypes['AlertsOutputEdge']
> = {
  node?: Resolver<ResolversTypes['Alert'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AlertsOutputPageInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AlertsOutputPageInfo'] = ResolversParentTypes['AlertsOutputPageInfo']
> = {
  endCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  startCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AlertDeliveryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AlertDelivery'] = ResolversParentTypes['AlertDelivery']
> = {
  dispatchedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  outputId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  statusCode?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type APITokenResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['APIToken'] = ResolversParentTypes['APIToken']
> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  expiresAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  permissions?: Resolver<Array<ResolversTypes['Permission']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CreateAPITokenOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateAPITokenOutput'] = ResolversParentTypes['CreateAPITokenOutput']
> = {
  token?: Resolver<Maybe<ResolversTypes['APIToken']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['Error']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type GetAPITokenOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetAPITokenOutput'] = ResolversParentTypes['GetAPITokenOutput']
> = {
  token?: Resolver<Maybe<ResolversTypes['APIToken']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['Error']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UpdateAPITokenOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateAPITokenOutput'] = ResolversParentTypes['UpdateAPITokenOutput']
> = {
  token?: Resolver<Maybe<ResolversTypes['APIToken']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['Error']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DeleteAPITokenOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteAPITokenOutput'] = ResolversParentTypes['DeleteAPITokenOutput']
> = {
  token?: Resolver<Maybe<ResolversTypes['APIToken']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['Error']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ListAPITokensOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ListAPITokensOutput'] = ResolversParentTypes['ListAPITokensOutput']
> = {
  tokens?: Resolver<Array<ResolversTypes['APIToken']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['Error']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export interface EmailScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Email'], any> {
  name: 'Email';
}

export interface DateTimeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export interface TimestampScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Timestamp'], any> {
  name: 'Timestamp';
}

export interface JSONScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export interface LongScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Long'], any> {
  name: 'Long';
}

export type ErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Error'] = ResolversParentTypes['Error']
> = {
  code?: Resolver<Maybe<ResolversTypes['ErrorCodeEnum']>, ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type PageInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PageInfo'] = ResolversParentTypes['PageInfo']
> = {
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  paginationToken?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type PagingDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PagingData'] = ResolversParentTypes['PagingData']
> = {
  thisPage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalPages?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalItems?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Job'] = ResolversParentTypes['Job']
> = {
  __resolveType: TypeResolveFn<
    | 'UpdateAlertStatusByFilterJob'
    | 'SummarizeDataLakeQueryJob'
    | 'LookupImportDataJob'
    | 'LookupSyncJob'
    | 'HoldingTankTestJob'
    | 'HoldingTankInferJob',
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['JobStatus'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
};

export type DataModelMappingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DataModelMapping'] = ResolversParentTypes['DataModelMapping']
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  path?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  method?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DataModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DataModel'] = ResolversParentTypes['DataModel']
> = {
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  logTypes?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  mappings?: Resolver<Array<ResolversTypes['DataModelMapping']>, ParentType, ContextType>;
  body?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  lastModified?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  parentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  managed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ListDataModelsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ListDataModelsResponse'] = ResolversParentTypes['ListDataModelsResponse']
> = {
  models?: Resolver<Array<ResolversTypes['DataModel']>, ParentType, ContextType>;
  paging?: Resolver<ResolversTypes['PagingData'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DataLakeDatabaseTableResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DataLakeDatabaseTable'] = ResolversParentTypes['DataLakeDatabaseTable']
> = {
  columns?: Resolver<Array<ResolversTypes['DataLakeDatabaseTableColumn']>, ParentType, ContextType>;
  databaseName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DataLakeQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DataLakeQuery'] = ResolversParentTypes['DataLakeQuery']
> = {
  completedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isScheduled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  issuedBy?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  metadata?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  results?: Resolver<
    Maybe<ResolversTypes['DataLakeQueryResults']>,
    ParentType,
    ContextType,
    RequireFields<DataLakeQueryResultsArgs, never>
  >;
  sql?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['DataLakeQueryStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type GetAsyncQueryDownloadUrlOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetAsyncQueryDownloadUrlOutput'] = ResolversParentTypes['GetAsyncQueryDownloadUrlOutput']
> = {
  error?: Resolver<Maybe<ResolversTypes['Error']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type GenerateSqlQuerySnippetOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GenerateSqlQuerySnippetOutput'] = ResolversParentTypes['GenerateSqlQuerySnippetOutput']
> = {
  sqlQuery?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SummarizeDataLakeQueryJobResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SummarizeDataLakeQueryJob'] = ResolversParentTypes['SummarizeDataLakeQueryJob']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['JobStatus'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  columnInfo?: Resolver<
    Maybe<ResolversTypes['DataLakeQueryResultsColumnInfo']>,
    ParentType,
    ContextType
  >;
  rows?: Resolver<Maybe<Array<ResolversTypes['JSON']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DataLakeQueriesOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DataLakeQueriesOutput'] = ResolversParentTypes['DataLakeQueriesOutput']
> = {
  edges?: Resolver<Array<ResolversTypes['DataLakeQueryEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['DataLakeQueriesOutputPageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DataLakeQueryEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DataLakeQueryEdge'] = ResolversParentTypes['DataLakeQueryEdge']
> = {
  node?: Resolver<ResolversTypes['DataLakeQuery'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DataLakeQueriesOutputPageInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DataLakeQueriesOutputPageInfo'] = ResolversParentTypes['DataLakeQueriesOutputPageInfo']
> = {
  endCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  startCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DataLakeQueryResultsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DataLakeQueryResults'] = ResolversParentTypes['DataLakeQueryResults']
> = {
  columnInfo?: Resolver<ResolversTypes['DataLakeQueryResultsColumnInfo'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['DataLakeQueryResultEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<
    ResolversTypes['DataLakeQueryResultRecordsPageInfo'],
    ParentType,
    ContextType
  >;
  stats?: Resolver<ResolversTypes['DataLakeQueryStats'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DataLakeQueryResultsColumnInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DataLakeQueryResultsColumnInfo'] = ResolversParentTypes['DataLakeQueryResultsColumnInfo']
> = {
  order?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  types?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DataLakeQueryStatsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DataLakeQueryStats'] = ResolversParentTypes['DataLakeQueryStats']
> = {
  bytesScanned?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  executionTime?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DataLakeQueryResultEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DataLakeQueryResultEdge'] = ResolversParentTypes['DataLakeQueryResultEdge']
> = {
  node?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DataLakeQueryResultRecordsPageInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DataLakeQueryResultRecordsPageInfo'] = ResolversParentTypes['DataLakeQueryResultRecordsPageInfo']
> = {
  endCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  startCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CancelDataLakeQueryOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CancelDataLakeQueryOutput'] = ResolversParentTypes['CancelDataLakeQueryOutput']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ExecuteDataLakeQueryOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ExecuteDataLakeQueryOutput'] = ResolversParentTypes['ExecuteDataLakeQueryOutput']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ExecuteIndicatorSearchQueryOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ExecuteIndicatorSearchQueryOutput'] = ResolversParentTypes['ExecuteIndicatorSearchQueryOutput']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DataLakeDatabaseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DataLakeDatabase'] = ResolversParentTypes['DataLakeDatabase']
> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tables?: Resolver<Array<ResolversTypes['DataLakeDatabaseTable']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DataLakeDatabaseTableColumnResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DataLakeDatabaseTableColumn'] = ResolversParentTypes['DataLakeDatabaseTableColumn']
> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DestinationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Destination'] = ResolversParentTypes['Destination']
> = {
  createdBy?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  creationTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastModifiedBy?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  lastModifiedTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  outputId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  outputType?: Resolver<ResolversTypes['DestinationTypeEnum'], ParentType, ContextType>;
  outputConfig?: Resolver<ResolversTypes['DestinationConfig'], ParentType, ContextType>;
  verificationStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  defaultForSeverity?: Resolver<Array<Maybe<ResolversTypes['Severity']>>, ParentType, ContextType>;
  alertTypes?: Resolver<Array<ResolversTypes['AlertType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DestinationConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DestinationConfig'] = ResolversParentTypes['DestinationConfig']
> = {
  slack?: Resolver<Maybe<ResolversTypes['SlackConfig']>, ParentType, ContextType>;
  sns?: Resolver<Maybe<ResolversTypes['SnsConfig']>, ParentType, ContextType>;
  sqs?: Resolver<Maybe<ResolversTypes['SqsDestinationConfig']>, ParentType, ContextType>;
  pagerDuty?: Resolver<Maybe<ResolversTypes['PagerDutyConfig']>, ParentType, ContextType>;
  github?: Resolver<Maybe<ResolversTypes['GithubConfig']>, ParentType, ContextType>;
  jira?: Resolver<Maybe<ResolversTypes['JiraConfig']>, ParentType, ContextType>;
  opsgenie?: Resolver<Maybe<ResolversTypes['OpsgenieConfig']>, ParentType, ContextType>;
  msTeams?: Resolver<Maybe<ResolversTypes['MsTeamsConfig']>, ParentType, ContextType>;
  asana?: Resolver<Maybe<ResolversTypes['AsanaConfig']>, ParentType, ContextType>;
  customWebhook?: Resolver<Maybe<ResolversTypes['CustomWebhookConfig']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SqsDestinationConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SqsDestinationConfig'] = ResolversParentTypes['SqsDestinationConfig']
> = {
  queueUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OpsgenieConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OpsgenieConfig'] = ResolversParentTypes['OpsgenieConfig']
> = {
  apiKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  serviceRegion?: Resolver<ResolversTypes['OpsgenieServiceRegionEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type MsTeamsConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MsTeamsConfig'] = ResolversParentTypes['MsTeamsConfig']
> = {
  webhookURL?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JiraConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JiraConfig'] = ResolversParentTypes['JiraConfig']
> = {
  orgDomain?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  projectKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  apiKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  assigneeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  issueType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  labels?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AsanaConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AsanaConfig'] = ResolversParentTypes['AsanaConfig']
> = {
  personalAccessToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  projectGids?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CustomWebhookConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomWebhookConfig'] = ResolversParentTypes['CustomWebhookConfig']
> = {
  webhookURL?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type GithubConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GithubConfig'] = ResolversParentTypes['GithubConfig']
> = {
  repoName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SlackConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SlackConfig'] = ResolversParentTypes['SlackConfig']
> = {
  webhookURL?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SnsConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SnsConfig'] = ResolversParentTypes['SnsConfig']
> = {
  topicArn?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type PagerDutyConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PagerDutyConfig'] = ResolversParentTypes['PagerDutyConfig']
> = {
  integrationKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type BulkDownloadResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BulkDownloadResponse'] = ResolversParentTypes['BulkDownloadResponse']
> = {
  data?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type GenerateEnrichedEventResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GenerateEnrichedEventResponse'] = ResolversParentTypes['GenerateEnrichedEventResponse']
> = {
  enrichedEvent?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UploadDetectionsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UploadDetectionsResponse'] = ResolversParentTypes['UploadDetectionsResponse']
> = {
  totalPolicies?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  newPolicies?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  modifiedPolicies?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalRules?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  newRules?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  modifiedRules?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalGlobals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  newGlobals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  modifiedGlobals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalDataModels?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  newDataModels?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  modifiedDataModels?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalQueries?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  newQueries?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  modifiedQueries?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DetectionTestMockDefinitionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DetectionTestMockDefinition'] = ResolversParentTypes['DetectionTestMockDefinition']
> = {
  objectName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  returnValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DetectionTestDefinitionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DetectionTestDefinition'] = ResolversParentTypes['DetectionTestDefinition']
> = {
  expectedResult?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  resource?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mocks?: Resolver<
    Maybe<Array<ResolversTypes['DetectionTestMockDefinition']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DetectionReportMappingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DetectionReportMapping'] = ResolversParentTypes['DetectionReportMapping']
> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  values?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DetectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Detection'] = ResolversParentTypes['Detection']
> = {
  __resolveType: TypeResolveFn<'Rule' | 'Policy', ParentType, ContextType>;
  body?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  lastModifiedBy?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  outputIds?: Resolver<Array<ResolversTypes['ID']>, ParentType, ContextType>;
  reference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  runbook?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  severity?: Resolver<ResolversTypes['Severity'], ParentType, ContextType>;
  tags?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  tests?: Resolver<Array<ResolversTypes['DetectionTestDefinition']>, ParentType, ContextType>;
  reports?: Resolver<Array<ResolversTypes['DetectionReportMapping']>, ParentType, ContextType>;
  versionId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  analysisType?: Resolver<ResolversTypes['DetectionTypeEnum'], ParentType, ContextType>;
};

export type DetectionsOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DetectionsOutput'] = ResolversParentTypes['DetectionsOutput']
> = {
  edges?: Resolver<Array<ResolversTypes['DetectionsOutputEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['DetectionsOutputPageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DetectionsOutputPageInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DetectionsOutputPageInfo'] = ResolversParentTypes['DetectionsOutputPageInfo']
> = {
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DetectionsOutputEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DetectionsOutputEdge'] = ResolversParentTypes['DetectionsOutputEdge']
> = {
  node?: Resolver<ResolversTypes['Detection'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type RuleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Rule'] = ResolversParentTypes['Rule']
> = {
  body?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  dedupPeriodMinutes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  threshold?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  lastModifiedBy?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  managed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  outputIds?: Resolver<Array<ResolversTypes['ID']>, ParentType, ContextType>;
  parentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  runbook?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  severity?: Resolver<ResolversTypes['Severity'], ParentType, ContextType>;
  summaryAttributes?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  tags?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  tests?: Resolver<Array<ResolversTypes['DetectionTestDefinition']>, ParentType, ContextType>;
  versionId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  analysisType?: Resolver<ResolversTypes['DetectionTypeEnum'], ParentType, ContextType>;
  reports?: Resolver<Array<ResolversTypes['DetectionReportMapping']>, ParentType, ContextType>;
  logTypes?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  scheduledQueries?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type PolicyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Policy'] = ResolversParentTypes['Policy']
> = {
  body?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  complianceStatus?: Resolver<
    Maybe<ResolversTypes['ComplianceStatusEnum']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  lastModifiedBy?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  managed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  outputIds?: Resolver<Array<ResolversTypes['ID']>, ParentType, ContextType>;
  parentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  resourceTypes?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  runbook?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  severity?: Resolver<ResolversTypes['Severity'], ParentType, ContextType>;
  suppressions?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  tags?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  tests?: Resolver<Array<ResolversTypes['DetectionTestDefinition']>, ParentType, ContextType>;
  versionId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  analysisType?: Resolver<ResolversTypes['DetectionTypeEnum'], ParentType, ContextType>;
  reports?: Resolver<Array<ResolversTypes['DetectionReportMapping']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ListDetectionsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ListDetectionsResponse'] = ResolversParentTypes['ListDetectionsResponse']
> = {
  detections?: Resolver<Array<ResolversTypes['Detection']>, ParentType, ContextType>;
  paging?: Resolver<ResolversTypes['PagingData'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type TestDetectionSubRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TestDetectionSubRecord'] = ResolversParentTypes['TestDetectionSubRecord']
> = {
  output?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['Error']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type TestPolicyRecordFunctionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TestPolicyRecordFunctions'] = ResolversParentTypes['TestPolicyRecordFunctions']
> = {
  detectionFunction?: Resolver<
    Maybe<ResolversTypes['TestDetectionSubRecord']>,
    ParentType,
    ContextType
  >;
  titleFunction?: Resolver<
    Maybe<ResolversTypes['TestDetectionSubRecord']>,
    ParentType,
    ContextType
  >;
  dedupFunction?: Resolver<
    Maybe<ResolversTypes['TestDetectionSubRecord']>,
    ParentType,
    ContextType
  >;
  alertContextFunction?: Resolver<
    Maybe<ResolversTypes['TestDetectionSubRecord']>,
    ParentType,
    ContextType
  >;
  descriptionFunction?: Resolver<
    Maybe<ResolversTypes['TestDetectionSubRecord']>,
    ParentType,
    ContextType
  >;
  destinationsFunction?: Resolver<
    Maybe<ResolversTypes['TestDetectionSubRecord']>,
    ParentType,
    ContextType
  >;
  referenceFunction?: Resolver<
    Maybe<ResolversTypes['TestDetectionSubRecord']>,
    ParentType,
    ContextType
  >;
  runbookFunction?: Resolver<
    Maybe<ResolversTypes['TestDetectionSubRecord']>,
    ParentType,
    ContextType
  >;
  severityFunction?: Resolver<
    Maybe<ResolversTypes['TestDetectionSubRecord']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type TestRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TestRecord'] = ResolversParentTypes['TestRecord']
> = {
  __resolveType: TypeResolveFn<'TestPolicyRecord' | 'TestDetectionRecord', ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  passed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['Error']>, ParentType, ContextType>;
};

export type TestPolicyRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TestPolicyRecord'] = ResolversParentTypes['TestPolicyRecord']
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  passed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  functions?: Resolver<ResolversTypes['TestPolicyRecordFunctions'], ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['Error']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type TestPolicyResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TestPolicyResponse'] = ResolversParentTypes['TestPolicyResponse']
> = {
  results?: Resolver<Array<ResolversTypes['TestPolicyRecord']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type TestDetectionRecordFunctionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TestDetectionRecordFunctions'] = ResolversParentTypes['TestDetectionRecordFunctions']
> = {
  detectionFunction?: Resolver<
    Maybe<ResolversTypes['TestDetectionSubRecord']>,
    ParentType,
    ContextType
  >;
  titleFunction?: Resolver<
    Maybe<ResolversTypes['TestDetectionSubRecord']>,
    ParentType,
    ContextType
  >;
  dedupFunction?: Resolver<
    Maybe<ResolversTypes['TestDetectionSubRecord']>,
    ParentType,
    ContextType
  >;
  alertContextFunction?: Resolver<
    Maybe<ResolversTypes['TestDetectionSubRecord']>,
    ParentType,
    ContextType
  >;
  descriptionFunction?: Resolver<
    Maybe<ResolversTypes['TestDetectionSubRecord']>,
    ParentType,
    ContextType
  >;
  destinationsFunction?: Resolver<
    Maybe<ResolversTypes['TestDetectionSubRecord']>,
    ParentType,
    ContextType
  >;
  referenceFunction?: Resolver<
    Maybe<ResolversTypes['TestDetectionSubRecord']>,
    ParentType,
    ContextType
  >;
  runbookFunction?: Resolver<
    Maybe<ResolversTypes['TestDetectionSubRecord']>,
    ParentType,
    ContextType
  >;
  severityFunction?: Resolver<
    Maybe<ResolversTypes['TestDetectionSubRecord']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type TestDetectionRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TestDetectionRecord'] = ResolversParentTypes['TestDetectionRecord']
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  passed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  functions?: Resolver<ResolversTypes['TestDetectionRecordFunctions'], ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['Error']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type TestRuleResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TestRuleResponse'] = ResolversParentTypes['TestRuleResponse']
> = {
  results?: Resolver<Array<ResolversTypes['TestDetectionRecord']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DetectionUpdateErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DetectionUpdateError'] = ResolversParentTypes['DetectionUpdateError']
> = {
  detectionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  error?: Resolver<ResolversTypes['Error'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type BulkUpdateDetectionsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BulkUpdateDetectionsResponse'] = ResolversParentTypes['BulkUpdateDetectionsResponse']
> = {
  errors?: Resolver<Array<Maybe<ResolversTypes['DetectionUpdateError']>>, ParentType, ContextType>;
  detections?: Resolver<Array<ResolversTypes['Detection']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DetectionFilterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DetectionFilter'] = ResolversParentTypes['DetectionFilter']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  when?: Resolver<ResolversTypes['DetectionFilterStatement'], ParentType, ContextType>;
  detections?: Resolver<
    ResolversTypes['DetectionsOutput'],
    ParentType,
    ContextType,
    RequireFields<DetectionFilterDetectionsArgs, 'page' | 'pageSize'>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DetectionFilterStatementResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DetectionFilterStatement'] = ResolversParentTypes['DetectionFilterStatement']
> = {
  or?: Resolver<Maybe<Array<ResolversTypes['DetectionFilterStatement']>>, ParentType, ContextType>;
  and?: Resolver<Maybe<Array<ResolversTypes['DetectionFilterStatement']>>, ParentType, ContextType>;
  not?: Resolver<Maybe<Array<ResolversTypes['DetectionFilterStatement']>>, ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  condition?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DetectionFiltersOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DetectionFiltersOutput'] = ResolversParentTypes['DetectionFiltersOutput']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  detectionFilters?: Resolver<Array<ResolversTypes['DetectionFilter']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CreateDetectionFilterOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateDetectionFilterOutput'] = ResolversParentTypes['CreateDetectionFilterOutput']
> = {
  detectionFilter?: Resolver<ResolversTypes['DetectionFilter'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UpdateDetectionFilterOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateDetectionFilterOutput'] = ResolversParentTypes['UpdateDetectionFilterOutput']
> = {
  detectionFilter?: Resolver<ResolversTypes['DetectionFilter'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DeleteDetectionFilterOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteDetectionFilterOutput'] = ResolversParentTypes['DeleteDetectionFilterOutput']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ListLookupResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ListLookupResponse'] = ResolversParentTypes['ListLookupResponse']
> = {
  lastEvaluatedKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lookups?: Resolver<Array<ResolversTypes['Lookup']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type LookupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Lookup'] = ResolversParentTypes['Lookup']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  managed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  activeVersion?: Resolver<Maybe<ResolversTypes['LookupVersion']>, ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastModifiedBy?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  lastModifiedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  refresh?: Resolver<Maybe<ResolversTypes['LookupRefreshConfig']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type LookupRefreshConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LookupRefreshConfig'] = ResolversParentTypes['LookupRefreshConfig']
> = {
  roleARN?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  periodMinutes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  alarmPeriodMinutes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  objectPath?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  objectKMSKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type LookupImportDataJobResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LookupImportDataJob'] = ResolversParentTypes['LookupImportDataJob']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['JobStatus'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stats?: Resolver<Maybe<ResolversTypes['LookupImportDataJobStats']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type LookupSyncJobResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LookupSyncJob'] = ResolversParentTypes['LookupSyncJob']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['JobStatus'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stats?: Resolver<Maybe<ResolversTypes['LookupSyncJobStats']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type LookupSyncJobStatsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LookupSyncJobStats'] = ResolversParentTypes['LookupSyncJobStats']
> = {
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  skipped?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type LookupImportDataJobStatsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LookupImportDataJobStats'] = ResolversParentTypes['LookupImportDataJobStats']
> = {
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  skipped?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type LookupVersionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LookupVersion'] = ResolversParentTypes['LookupVersion']
> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  lookupId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  version?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  primaryKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  associatedLogTypes?: Resolver<
    Array<ResolversTypes['AssociatedLogType']>,
    ParentType,
    ContextType
  >;
  logType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  objectPath?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  refreshedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  rowCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  erroredAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  errorMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AssociatedLogTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AssociatedLogType'] = ResolversParentTypes['AssociatedLogType']
> = {
  logType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  selectors?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type LookupS3ImportConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LookupS3ImportConfig'] = ResolversParentTypes['LookupS3ImportConfig']
> = {
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type GlobalHelperResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GlobalHelper'] = ResolversParentTypes['GlobalHelper']
> = {
  body?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  lastModified?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  parentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  managed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ListGlobalHelpersResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ListGlobalHelpersResponse'] = ResolversParentTypes['ListGlobalHelpersResponse']
> = {
  paging?: Resolver<Maybe<ResolversTypes['PagingData']>, ParentType, ContextType>;
  globals?: Resolver<Maybe<Array<Maybe<ResolversTypes['GlobalHelper']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type HoldingTankTestJobResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HoldingTankTestJob'] = ResolversParentTypes['HoldingTankTestJob']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  completedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['JobStatus'], ParentType, ContextType>;
  taskType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stats?: Resolver<Maybe<ResolversTypes['HoldingTankJobStats']>, ParentType, ContextType>;
  testParameters?: Resolver<ResolversTypes['HoldingTankTestJobParams'], ParentType, ContextType>;
  testResults?: Resolver<
    Maybe<Array<ResolversTypes['HoldingTankTestJobPrefixStats']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DraftSchemaResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DraftSchema'] = ResolversParentTypes['DraftSchema']
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  spec?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  referenceURL?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type HoldingTankTestJobParamsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HoldingTankTestJobParams'] = ResolversParentTypes['HoldingTankTestJobParams']
> = {
  streamType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  s3PrefixLogTypes?: Resolver<Array<ResolversTypes['S3PrefixLogTypes']>, ParentType, ContextType>;
  newSchemas?: Resolver<Maybe<Array<ResolversTypes['DraftSchema']>>, ParentType, ContextType>;
  filters?: Resolver<ResolversTypes['DateRange'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DateRangeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DateRange'] = ResolversParentTypes['DateRange']
> = {
  from?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  to?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type HoldingTankListTasksResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HoldingTankListTasksResponse'] = ResolversParentTypes['HoldingTankListTasksResponse']
> = {
  tasks?: Resolver<Array<ResolversTypes['HoldingTankTestJob']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type HoldingTankInferJobResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HoldingTankInferJob'] = ResolversParentTypes['HoldingTankInferJob']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['JobStatus'], ParentType, ContextType>;
  taskType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stats?: Resolver<Maybe<ResolversTypes['HoldingTankJobStats']>, ParentType, ContextType>;
  schemaInferenceResult?: Resolver<
    Maybe<ResolversTypes['HoldingTankSchemaInferenceResult']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type HoldingTankSchemaInferenceResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HoldingTankSchemaInferenceResult'] = ResolversParentTypes['HoldingTankSchemaInferenceResult']
> = {
  spec?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type HoldingTankJobStatsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HoldingTankJobStats'] = ResolversParentTypes['HoldingTankJobStats']
> = {
  totalSizeBytes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  elapsedTimeSeconds?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type HoldingTankTestJobPrefixStatsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HoldingTankTestJobPrefixStats'] = ResolversParentTypes['HoldingTankTestJobPrefixStats']
> = {
  prefix?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  logTypeMatches?: Resolver<
    Array<ResolversTypes['HoldingTankLogTypeTestStats']>,
    ParentType,
    ContextType
  >;
  unmatched?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  errored?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type HoldingTankLogTypeTestStatsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HoldingTankLogTypeTestStats'] = ResolversParentTypes['HoldingTankLogTypeTestStats']
> = {
  logType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  matched?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type HoldingTankRawEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HoldingTankRawEvent'] = ResolversParentTypes['HoldingTankRawEvent']
> = {
  s3Key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  event?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type HoldingTankResultsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HoldingTankResults'] = ResolversParentTypes['HoldingTankResults']
> = {
  event?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  s3Key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type HoldingTankTestSchemasResultsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HoldingTankTestSchemasResultsResponse'] = ResolversParentTypes['HoldingTankTestSchemasResultsResponse']
> = {
  results?: Resolver<Array<ResolversTypes['HoldingTankResults']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type HoldingTankRawDataResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HoldingTankRawDataResponse'] = ResolversParentTypes['HoldingTankRawDataResponse']
> = {
  data?: Resolver<Array<ResolversTypes['HoldingTankRawEvent']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type GetSchemaOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetSchemaOutput'] = ResolversParentTypes['GetSchemaOutput']
> = {
  record?: Resolver<Maybe<ResolversTypes['SchemaRecord']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['Error']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ListSchemasOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ListSchemasOutput'] = ResolversParentTypes['ListSchemasOutput']
> = {
  results?: Resolver<Maybe<Array<ResolversTypes['SchemaRecord']>>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['Error']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SchemaRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SchemaRecord'] = ResolversParentTypes['SchemaRecord']
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  revision?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  release?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  managed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  disabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  referenceURL?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  spec?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fields?: Resolver<Array<ResolversTypes['SchemaField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SchemaFieldResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SchemaField'] = ResolversParentTypes['SchemaField']
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  required?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['SchemaValueType'], ParentType, ContextType>;
  target?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  indicators?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  timeFormat?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isEventTime?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  validate?: Resolver<Maybe<ResolversTypes['SchemaFieldValueValidation']>, ParentType, ContextType>;
  isEmbeddedJSON?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SchemaFieldValueValidationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SchemaFieldValueValidation'] = ResolversParentTypes['SchemaFieldValueValidation']
> = {
  allow?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  deny?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  cidr?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type GenerateS3SampleUploadResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GenerateS3SampleUploadResponse'] = ResolversParentTypes['GenerateS3SampleUploadResponse']
> = {
  uploadURL?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sessionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type InferSchemaResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InferSchemaResponse'] = ResolversParentTypes['InferSchemaResponse']
> = {
  schema?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['Error']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type GetSampleDataResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetSampleDataResponse'] = ResolversParentTypes['GetSampleDataResponse']
> = {
  data?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['Error']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type TestSchemaResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TestSchemaResult'] = ResolversParentTypes['TestSchemaResult']
> = {
  stats?: Resolver<ResolversTypes['TestSchemaStats'], ParentType, ContextType>;
  data?: Resolver<ResolversTypes['TestSchemaData'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type TestSchemaResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TestSchemaResponse'] = ResolversParentTypes['TestSchemaResponse']
> = {
  result?: Resolver<Maybe<ResolversTypes['TestSchemaResult']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['Error']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type TestSchemaStatsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TestSchemaStats'] = ResolversParentTypes['TestSchemaStats']
> = {
  matched?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  unmatched?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalLogs?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UnmatchedLogResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UnmatchedLog'] = ResolversParentTypes['UnmatchedLog']
> = {
  raw?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  error?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type MatchedLogResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MatchedLog'] = ResolversParentTypes['MatchedLog']
> = {
  raw?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  event?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type TestSchemaDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TestSchemaData'] = ResolversParentTypes['TestSchemaData']
> = {
  matched?: Resolver<Array<ResolversTypes['MatchedLog']>, ParentType, ContextType>;
  unmatched?: Resolver<Array<ResolversTypes['UnmatchedLog']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type RequestLogTypeResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RequestLogTypeResponse'] = ResolversParentTypes['RequestLogTypeResponse']
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ListAvailableLogTypesResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ListAvailableLogTypesResponse'] = ResolversParentTypes['ListAvailableLogTypesResponse']
> = {
  logTypes?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ListAvailableLogProvidersResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ListAvailableLogProvidersResponse'] = ResolversParentTypes['ListAvailableLogProvidersResponse']
> = {
  results?: Resolver<Maybe<Array<ResolversTypes['AvailableLogProvider']>>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['Error']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type GetAvailableLogProviderResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetAvailableLogProviderResponse'] = ResolversParentTypes['GetAvailableLogProviderResponse']
> = {
  result?: Resolver<Maybe<ResolversTypes['AvailableLogProvider']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['Error']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AvailableLogProviderResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AvailableLogProvider'] = ResolversParentTypes['AvailableLogProvider']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  popularity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  categories?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  availableParsers?: Resolver<
    Array<ResolversTypes['AvailableLogProviderParser']>,
    ParentType,
    ContextType
  >;
  details?: Resolver<Maybe<ResolversTypes['AvailableLogProviderDetails']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AvailableLogProviderDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AvailableLogProviderDetails'] = ResolversParentTypes['AvailableLogProviderDetails']
> = {
  appInfo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  howItWorks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  useCases?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  defaultParsers?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  transports?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AvailableLogProviderParserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AvailableLogProviderParser'] = ResolversParentTypes['AvailableLogProviderParser']
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  referenceUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OrganizationStatsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationStatsResponse'] = ResolversParentTypes['OrganizationStatsResponse']
> = {
  appliedPolicies?: Resolver<
    Maybe<ResolversTypes['OrganizationReportBySeverity']>,
    ParentType,
    ContextType
  >;
  scannedResources?: Resolver<Maybe<ResolversTypes['ScannedResources']>, ParentType, ContextType>;
  topFailingPolicies?: Resolver<Array<ResolversTypes['Policy']>, ParentType, ContextType>;
  topFailingResources?: Resolver<Array<ResolversTypes['ResourceSummary']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ScannedResourceStatsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ScannedResourceStats'] = ResolversParentTypes['ScannedResourceStats']
> = {
  count?: Resolver<Maybe<ResolversTypes['ComplianceStatusCounts']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ScannedResourcesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ScannedResources'] = ResolversParentTypes['ScannedResources']
> = {
  byType?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ScannedResourceStats']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OrganizationReportBySeverityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationReportBySeverity'] = ResolversParentTypes['OrganizationReportBySeverity']
> = {
  info?: Resolver<Maybe<ResolversTypes['ComplianceStatusCounts']>, ParentType, ContextType>;
  low?: Resolver<Maybe<ResolversTypes['ComplianceStatusCounts']>, ParentType, ContextType>;
  medium?: Resolver<Maybe<ResolversTypes['ComplianceStatusCounts']>, ParentType, ContextType>;
  high?: Resolver<Maybe<ResolversTypes['ComplianceStatusCounts']>, ParentType, ContextType>;
  critical?: Resolver<Maybe<ResolversTypes['ComplianceStatusCounts']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ComplianceStatusCountsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ComplianceStatusCounts'] = ResolversParentTypes['ComplianceStatusCounts']
> = {
  error?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  fail?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pass?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type MetricsOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MetricsOutput'] = ResolversParentTypes['MetricsOutput']
> = {
  alertsPerRule?: Resolver<
    Maybe<Array<ResolversTypes['SeriesWithEntityID']>>,
    ParentType,
    ContextType
  >;
  alertsPerSeverity?: Resolver<
    Array<ResolversTypes['SeriesWithBreakdown']>,
    ParentType,
    ContextType
  >;
  bytesIngestedPerSource?: Resolver<
    Maybe<Array<ResolversTypes['Series']>>,
    ParentType,
    ContextType
  >;
  bytesProcessedPerSource?: Resolver<
    Array<ResolversTypes['SeriesWithBreakdown']>,
    ParentType,
    ContextType
  >;
  bytesQueriedPerSource?: Resolver<
    Array<ResolversTypes['SeriesWithBreakdown']>,
    ParentType,
    ContextType
  >;
  eventsProcessedPerLogType?: Resolver<
    Array<ResolversTypes['SeriesWithBreakdown']>,
    ParentType,
    ContextType
  >;
  latencyPerLogType?: Resolver<Maybe<Array<ResolversTypes['Series']>>, ParentType, ContextType>;
  totalAlerts?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalAlertsDiff?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalBytesIngested?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalBytesProcessed?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalBytesQueried?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalEventsProcessed?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SeriesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Series'] = ResolversParentTypes['Series']
> = {
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SeriesWithBreakdownResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SeriesWithBreakdown'] = ResolversParentTypes['SeriesWithBreakdown']
> = {
  breakdown?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SeriesWithEntityIDResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SeriesWithEntityID'] = ResolversParentTypes['SeriesWithEntityID']
> = {
  entityId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type GeneralSettingsConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeneralSettingsConfig'] = ResolversParentTypes['GeneralSettingsConfig']
> = {
  analyticsConsent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  infrastructureInfo?: Resolver<
    Maybe<ResolversTypes['InfrastructureInfo']>,
    ParentType,
    ContextType
  >;
  licenseAccepted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  pantherVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type InfrastructureInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InfrastructureInfo'] = ResolversParentTypes['InfrastructureInfo']
> = {
  networking?: Resolver<ResolversTypes['NetworkingInfo'], ParentType, ContextType>;
  storage?: Resolver<ResolversTypes['StorageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type NetworkingInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NetworkingInfo'] = ResolversParentTypes['NetworkingInfo']
> = {
  publicIp?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type StorageInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StorageInfo'] = ResolversParentTypes['StorageInfo']
> = {
  processedDataRetentionDays?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SamlConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SamlConfig'] = ResolversParentTypes['SamlConfig']
> = {
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  defaultRoleId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasMetadataFile?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  metadataUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ListAnalysisPacksResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ListAnalysisPacksResponse'] = ResolversParentTypes['ListAnalysisPacksResponse']
> = {
  packs?: Resolver<Array<ResolversTypes['AnalysisPack']>, ParentType, ContextType>;
  paging?: Resolver<ResolversTypes['PagingData'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ListAnalysisPackSourcesResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ListAnalysisPackSourcesResponse'] = ResolversParentTypes['ListAnalysisPackSourcesResponse']
> = {
  paging?: Resolver<ResolversTypes['PagingData'], ParentType, ContextType>;
  packs?: Resolver<Array<ResolversTypes['AnalysisPackSource']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AnalysisPackVersionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AnalysisPackVersion'] = ResolversParentTypes['AnalysisPackVersion']
> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  semVer?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AnalysisPackDefinitionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AnalysisPackDefinition'] = ResolversParentTypes['AnalysisPackDefinition']
> = {
  IDs?: Resolver<Maybe<Array<ResolversTypes['ID']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AnalysisPackTypesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AnalysisPackTypes'] = ResolversParentTypes['AnalysisPackTypes']
> = {
  GLOBAL?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  RULE?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  DATAMODEL?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  POLICY?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  SCHEDULED_RULE?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  SCHEDULED_QUERY?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AnalysisPackResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AnalysisPack'] = ResolversParentTypes['AnalysisPack']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updateAvailable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  packVersion?: Resolver<ResolversTypes['AnalysisPackVersion'], ParentType, ContextType>;
  availableVersions?: Resolver<
    Array<ResolversTypes['AnalysisPackVersion']>,
    ParentType,
    ContextType
  >;
  createdBy?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  lastModifiedBy?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  lastModified?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  packDefinition?: Resolver<ResolversTypes['AnalysisPackDefinition'], ParentType, ContextType>;
  packTypes?: Resolver<ResolversTypes['AnalysisPackTypes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AnalysisPackSourceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AnalysisPackSource'] = ResolversParentTypes['AnalysisPackSource']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  githubAccessToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  owner?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  repository?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  kmsKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  lastModified?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  lastModifiedBy?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ReplayScopeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReplayScope'] = ResolversParentTypes['ReplayScope']
> = {
  endsAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  startsAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  logTypes?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ReplayResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Replay'] = ResolversParentTypes['Replay']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  state?: Resolver<ResolversTypes['ReplayState'], ParentType, ContextType>;
  scope?: Resolver<ResolversTypes['ReplayScope'], ParentType, ContextType>;
  summary?: Resolver<ResolversTypes['ReplaySummary'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  completedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  detectionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ReplayPreviewResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReplayPreview'] = ResolversParentTypes['ReplayPreview']
> = {
  timeEstimate?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  logDataSizeEstimate?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ReplaySummaryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReplaySummary'] = ResolversParentTypes['ReplaySummary']
> = {
  totalAlerts?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  completedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ruleErrorCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  ruleMatchCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  evaluationProgress?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  computationProgress?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  logDataSizeEstimate?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  matchesProcessedCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type StopReplayOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StopReplayOutput'] = ResolversParentTypes['StopReplayOutput']
> = {
  replay?: Resolver<ResolversTypes['Replay'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CreateReplayOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateReplayOutput'] = ResolversParentTypes['CreateReplayOutput']
> = {
  replay?: Resolver<ResolversTypes['Replay'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ComputeReplayOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ComputeReplayOutput'] = ResolversParentTypes['ComputeReplayOutput']
> = {
  replay?: Resolver<ResolversTypes['Replay'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ReplayedAlertsOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReplayedAlertsOutput'] = ResolversParentTypes['ReplayedAlertsOutput']
> = {
  alerts?: Resolver<Array<ResolversTypes['Alert']>, ParentType, ContextType>;
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type MitreMatrixTreeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MitreMatrixTree'] = ResolversParentTypes['MitreMatrixTree']
> = {
  matrices?: Resolver<Array<ResolversTypes['MitreMatrixTreeNode']>, ParentType, ContextType>;
  mitreVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mitreVersionUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  defaultMatrixId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  enabledLogTypes?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type MitreMatrixTreeNodeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MitreMatrixTreeNode'] = ResolversParentTypes['MitreMatrixTreeNode']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  submatrices?: Resolver<Array<ResolversTypes['MitreMatrixTreeNode']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type MitreMatrixResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MitreMatrix'] = ResolversParentTypes['MitreMatrix']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['MitreMatrixContent'], ParentType, ContextType>;
  mappings?: Resolver<Array<ResolversTypes['MitreMatrixTacticMapping']>, ParentType, ContextType>;
  reportSettings?: Resolver<Array<ResolversTypes['ReportSetting']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type MitreMatrixTacticMappingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MitreMatrixTacticMapping'] = ResolversParentTypes['MitreMatrixTacticMapping']
> = {
  tacticId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  techniqueIds?: Resolver<Array<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type MitreMatrixContentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MitreMatrixContent'] = ResolversParentTypes['MitreMatrixContent']
> = {
  tactics?: Resolver<Array<ResolversTypes['MitreTactic']>, ParentType, ContextType>;
  techniques?: Resolver<Array<ResolversTypes['MitreTechnique']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type MitreTacticResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MitreTactic'] = ResolversParentTypes['MitreTactic']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type MitreTechniqueResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MitreTechnique'] = ResolversParentTypes['MitreTechnique']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ReportSettingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReportSetting'] = ResolversParentTypes['ReportSetting']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  note?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  override?: Resolver<ResolversTypes['ReportOverrideSetting'], ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  reportType?: Resolver<ResolversTypes['ReportType'], ParentType, ContextType>;
  detections?: Resolver<Array<ResolversTypes['Detection']>, ParentType, ContextType>;
  association?: Resolver<ResolversTypes['ReportSettingAssociation'], ParentType, ContextType>;
  matchingLogTypes?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  matchingDetectionIds?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ReportSettingAssociationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReportSettingAssociation'] = ResolversParentTypes['ReportSettingAssociation']
> = {
  mitre?: Resolver<ResolversTypes['ReportSettingAssociationMitre'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ReportSettingAssociationMitreResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReportSettingAssociationMitre'] = ResolversParentTypes['ReportSettingAssociationMitre']
> = {
  tactic?: Resolver<ResolversTypes['MitreTactic'], ParentType, ContextType>;
  technique?: Resolver<ResolversTypes['MitreTechnique'], ParentType, ContextType>;
  tacticId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  techniqueId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UpdateMitreReportMappingOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateMitreReportMappingOutput'] = ResolversParentTypes['UpdateMitreReportMappingOutput']
> = {
  detections?: Resolver<Array<ResolversTypes['Detection']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ListResourcesResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ListResourcesResponse'] = ResolversParentTypes['ListResourcesResponse']
> = {
  paging?: Resolver<Maybe<ResolversTypes['PagingData']>, ParentType, ContextType>;
  resources?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ResourceSummary']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ListComplianceItemsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ListComplianceItemsResponse'] = ResolversParentTypes['ListComplianceItemsResponse']
> = {
  items?: Resolver<Maybe<Array<Maybe<ResolversTypes['ComplianceItem']>>>, ParentType, ContextType>;
  paging?: Resolver<Maybe<ResolversTypes['PagingData']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['ComplianceStatusEnum']>, ParentType, ContextType>;
  totals?: Resolver<Maybe<ResolversTypes['ActiveSuppressCount']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ComplianceItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ComplianceItem'] = ResolversParentTypes['ComplianceItem']
> = {
  errorMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  policyId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  policySeverity?: Resolver<Maybe<ResolversTypes['Severity']>, ParentType, ContextType>;
  resourceId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  resourceType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['ComplianceStatusEnum']>, ParentType, ContextType>;
  suppressed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  integration?: Resolver<Maybe<ResolversTypes['ComplianceIntegration']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ActiveSuppressCountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ActiveSuppressCount'] = ResolversParentTypes['ActiveSuppressCount']
> = {
  active?: Resolver<Maybe<ResolversTypes['ComplianceStatusCounts']>, ParentType, ContextType>;
  suppressed?: Resolver<Maybe<ResolversTypes['ComplianceStatusCounts']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ResourceSummaryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResourceSummary'] = ResolversParentTypes['ResourceSummary']
> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  integration?: Resolver<Maybe<ResolversTypes['ComplianceIntegration']>, ParentType, ContextType>;
  complianceStatus?: Resolver<
    Maybe<ResolversTypes['ComplianceStatusEnum']>,
    ParentType,
    ContextType
  >;
  deleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  lastModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ResourceDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResourceDetails'] = ResolversParentTypes['ResourceDetails']
> = {
  attributes?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  deleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  integration?: Resolver<Maybe<ResolversTypes['ComplianceIntegration']>, ParentType, ContextType>;
  complianceStatus?: Resolver<
    Maybe<ResolversTypes['ComplianceStatusEnum']>,
    ParentType,
    ContextType
  >;
  lastModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DeleteRoleOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteRoleOutput'] = ResolversParentTypes['DeleteRoleOutput']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CreateRoleOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateRoleOutput'] = ResolversParentTypes['CreateRoleOutput']
> = {
  role?: Resolver<ResolversTypes['Role'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UpdateRoleOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateRoleOutput'] = ResolversParentTypes['UpdateRoleOutput']
> = {
  role?: Resolver<ResolversTypes['Role'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type RoleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Role'] = ResolversParentTypes['Role']
> = {
  createdAt?: Resolver<ResolversTypes['Timestamp'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  logTypeAccess?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  logTypeAccessKind?: Resolver<ResolversTypes['LogTypeAccessKind'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  permissions?: Resolver<Array<ResolversTypes['Permission']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Timestamp'], ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ScheduleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Schedule'] = ResolversParentTypes['Schedule']
> = {
  cronExpression?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  disabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  rateMinutes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  timeout?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SavedQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SavedQuery'] = ResolversParentTypes['SavedQuery']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sqlQuery?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  schedule?: Resolver<Maybe<ResolversTypes['Schedule']>, ParentType, ContextType>;
  defaultDatabase?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  lastModifiedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  lastModifiedBy?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  managed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['SavedQuery']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ListSavedQueriesResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ListSavedQueriesResponse'] = ResolversParentTypes['ListSavedQueriesResponse']
> = {
  savedQueries?: Resolver<Array<ResolversTypes['SavedQuery']>, ParentType, ContextType>;
  lastEvaluatedKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SqsConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SqsConfig'] = ResolversParentTypes['SqsConfig']
> = {
  allowedPrincipalArns?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  allowedSourceArns?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  s3Bucket?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  s3Prefix?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logProcessingRole?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  queueUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type GcsConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GcsConfig'] = ResolversParentTypes['GcsConfig']
> = {
  projectId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subscriptionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gcsBucket?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  credentials?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userEmail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  logTypes?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AWSScanConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AWSScanConfig'] = ResolversParentTypes['AWSScanConfig']
> = {
  auditRole?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type LogIntegrationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LogIntegration'] = ResolversParentTypes['LogIntegration']
> = {
  __resolveType: TypeResolveFn<
    | 'S3LogIntegration'
    | 'CloudWatchLogIntegration'
    | 'EventBridgeIntegration'
    | 'SqsLogSourceIntegration'
    | 'GcsLogSourceIntegration'
    | 'LogPullingIntegration',
    ParentType,
    ContextType
  >;
  createdAtTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  integrationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  integrationLabel?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  integrationType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  logTypes?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  alarms?: Resolver<ResolversTypes['LogIntegrationAlarms'], ParentType, ContextType>;
  isHealthy?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastEventReceived?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  lastModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  isEditable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPantherManaged?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
};

export type ComplianceIntegrationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ComplianceIntegration'] = ResolversParentTypes['ComplianceIntegration']
> = {
  awsAccountId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAtTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  integrationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  integrationLabel?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  regionIgnoreList?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  resourceTypeIgnoreList?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  resourceRegexIgnoreList?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  health?: Resolver<ResolversTypes['ComplianceIntegrationHealth'], ParentType, ContextType>;
  stackName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  realtimeScanningEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  isEditable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  awsScanConfig?: Resolver<Maybe<ResolversTypes['AWSScanConfig']>, ParentType, ContextType>;
  isPantherManaged?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type LogIntegrationAlarmsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LogIntegrationAlarms'] = ResolversParentTypes['LogIntegrationAlarms']
> = {
  eventThreshold?: Resolver<Maybe<ResolversTypes['EventThresholdAlarm']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type S3PrefixLogTypesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['S3PrefixLogTypes'] = ResolversParentTypes['S3PrefixLogTypes']
> = {
  prefix?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  logTypes?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  excludedPrefixes?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type S3LogIntegrationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['S3LogIntegration'] = ResolversParentTypes['S3LogIntegration']
> = {
  awsAccountId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAtTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  integrationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  integrationType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  integrationLabel?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  logProviderId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastEventReceived?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  s3Bucket?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  s3Prefix?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  kmsKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  s3PrefixLogTypes?: Resolver<Array<ResolversTypes['S3PrefixLogTypes']>, ParentType, ContextType>;
  logTypes?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  managedBucketNotifications?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  notificationsConfigurationSucceeded?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  health?: Resolver<ResolversTypes['S3LogIntegrationHealth'], ParentType, ContextType>;
  isHealthy?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  logProcessingRole?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stackName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  alarms?: Resolver<ResolversTypes['LogIntegrationAlarms'], ParentType, ContextType>;
  logStreamType?: Resolver<Maybe<ResolversTypes['LogStreamTypeEnum']>, ParentType, ContextType>;
  lastModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  isEditable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPantherManaged?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CloudWatchLogIntegrationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CloudWatchLogIntegration'] = ResolversParentTypes['CloudWatchLogIntegration']
> = {
  awsAccountId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAtTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  integrationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  integrationType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  integrationLabel?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  logProviderId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastEventReceived?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  s3Bucket?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  managedBucketNotifications?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  notificationsConfigurationSucceeded?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  health?: Resolver<ResolversTypes['CloudWatchLogIntegrationHealth'], ParentType, ContextType>;
  isHealthy?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  logProcessingRole?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stackName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  alarms?: Resolver<ResolversTypes['LogIntegrationAlarms'], ParentType, ContextType>;
  logGroup?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  logTypes?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  filterPattern?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  isEditable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPantherManaged?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type EventBridgeIntegrationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventBridgeIntegration'] = ResolversParentTypes['EventBridgeIntegration']
> = {
  createdAtTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  integrationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  integrationLabel?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  integrationType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  logProviderId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastEventReceived?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  eventbridgeConfig?: Resolver<ResolversTypes['EventBridgeConfig'], ParentType, ContextType>;
  logTypes?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  health?: Resolver<ResolversTypes['EventBridgeIntegrationHealth'], ParentType, ContextType>;
  isHealthy?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  alarms?: Resolver<ResolversTypes['LogIntegrationAlarms'], ParentType, ContextType>;
  lastModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  isEditable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPantherManaged?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SqsLogSourceIntegrationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SqsLogSourceIntegration'] = ResolversParentTypes['SqsLogSourceIntegration']
> = {
  createdAtTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  integrationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  integrationLabel?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  integrationType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  logProviderId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastEventReceived?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  logTypes?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  sqsConfig?: Resolver<ResolversTypes['SqsConfig'], ParentType, ContextType>;
  health?: Resolver<ResolversTypes['SqsLogIntegrationHealth'], ParentType, ContextType>;
  isHealthy?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  alarms?: Resolver<ResolversTypes['LogIntegrationAlarms'], ParentType, ContextType>;
  lastModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  isEditable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPantherManaged?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type GcsLogSourceIntegrationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GcsLogSourceIntegration'] = ResolversParentTypes['GcsLogSourceIntegration']
> = {
  createdAtTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  integrationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  integrationLabel?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  integrationType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  logProviderId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastEventReceived?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  logTypes?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  gcsConfig?: Resolver<ResolversTypes['GcsConfig'], ParentType, ContextType>;
  health?: Resolver<ResolversTypes['GcsLogIntegrationHealth'], ParentType, ContextType>;
  isHealthy?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  alarms?: Resolver<ResolversTypes['LogIntegrationAlarms'], ParentType, ContextType>;
  lastModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  isEditable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPantherManaged?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type LogPullingIntegrationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LogPullingIntegration'] = ResolversParentTypes['LogPullingIntegration']
> = {
  createdAtTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  integrationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  integrationLabel?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  integrationType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pullerType?: Resolver<ResolversTypes['LogPullerTypeEnum'], ParentType, ContextType>;
  lastEventReceived?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  pullerConfig?: Resolver<ResolversTypes['PullerConfig'], ParentType, ContextType>;
  logTypes?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  health?: Resolver<ResolversTypes['LogPullingIntegrationHealth'], ParentType, ContextType>;
  isHealthy?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  alarms?: Resolver<ResolversTypes['LogIntegrationAlarms'], ParentType, ContextType>;
  oauth2?: Resolver<Maybe<ResolversTypes['OAuth2Info']>, ParentType, ContextType>;
  lastModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  isEditable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPantherManaged?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OAuth2InfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OAuth2Info'] = ResolversParentTypes['OAuth2Info']
> = {
  mustAuthorize?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type IntegrationTemplateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IntegrationTemplate'] = ResolversParentTypes['IntegrationTemplate']
> = {
  body?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  stackName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type TerraformTemplateResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TerraformTemplateResponse'] = ResolversParentTypes['TerraformTemplateResponse']
> = {
  data?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  stackName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type IntegrationItemHealthStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IntegrationItemHealthStatus'] = ResolversParentTypes['IntegrationItemHealthStatus']
> = {
  healthy?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rawErrorMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['SourceHealthTypesEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ComplianceIntegrationHealthResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ComplianceIntegrationHealth'] = ResolversParentTypes['ComplianceIntegrationHealth']
> = {
  auditRoleStatus?: Resolver<
    ResolversTypes['IntegrationItemHealthStatus'],
    ParentType,
    ContextType
  >;
  realtimeStatus?: Resolver<ResolversTypes['IntegrationItemHealthStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type S3LogIntegrationHealthResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['S3LogIntegrationHealth'] = ResolversParentTypes['S3LogIntegrationHealth']
> = {
  processingRoleStatus?: Resolver<
    ResolversTypes['IntegrationItemHealthStatus'],
    ParentType,
    ContextType
  >;
  s3BucketStatus?: Resolver<
    Maybe<ResolversTypes['IntegrationItemHealthStatus']>,
    ParentType,
    ContextType
  >;
  kmsKeyStatus?: Resolver<
    Maybe<ResolversTypes['IntegrationItemHealthStatus']>,
    ParentType,
    ContextType
  >;
  bucketNotificationsStatus?: Resolver<
    Maybe<ResolversTypes['IntegrationItemHealthStatus']>,
    ParentType,
    ContextType
  >;
  systemAlarmStatus?: Resolver<
    Maybe<ResolversTypes['IntegrationItemHealthStatus']>,
    ParentType,
    ContextType
  >;
  logProcessingErrorsStatus?: Resolver<
    Maybe<ResolversTypes['IntegrationItemHealthStatus']>,
    ParentType,
    ContextType
  >;
  classificationFailuresStatus?: Resolver<
    Maybe<ResolversTypes['IntegrationItemHealthStatus']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CloudWatchLogIntegrationHealthResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CloudWatchLogIntegrationHealth'] = ResolversParentTypes['CloudWatchLogIntegrationHealth']
> = {
  processingRoleStatus?: Resolver<
    ResolversTypes['IntegrationItemHealthStatus'],
    ParentType,
    ContextType
  >;
  s3BucketStatus?: Resolver<
    Maybe<ResolversTypes['IntegrationItemHealthStatus']>,
    ParentType,
    ContextType
  >;
  bucketNotificationsStatus?: Resolver<
    Maybe<ResolversTypes['IntegrationItemHealthStatus']>,
    ParentType,
    ContextType
  >;
  systemAlarmStatus?: Resolver<
    Maybe<ResolversTypes['IntegrationItemHealthStatus']>,
    ParentType,
    ContextType
  >;
  logProcessingErrorsStatus?: Resolver<
    Maybe<ResolversTypes['IntegrationItemHealthStatus']>,
    ParentType,
    ContextType
  >;
  classificationFailuresStatus?: Resolver<
    Maybe<ResolversTypes['IntegrationItemHealthStatus']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type EventBridgeIntegrationHealthResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventBridgeIntegrationHealth'] = ResolversParentTypes['EventBridgeIntegrationHealth']
> = {
  eventBridgeStatus?: Resolver<
    Maybe<ResolversTypes['IntegrationItemHealthStatus']>,
    ParentType,
    ContextType
  >;
  systemAlarmStatus?: Resolver<
    Maybe<ResolversTypes['IntegrationItemHealthStatus']>,
    ParentType,
    ContextType
  >;
  logProcessingErrorsStatus?: Resolver<
    Maybe<ResolversTypes['IntegrationItemHealthStatus']>,
    ParentType,
    ContextType
  >;
  classificationFailuresStatus?: Resolver<
    Maybe<ResolversTypes['IntegrationItemHealthStatus']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type LogPullingIntegrationHealthResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LogPullingIntegrationHealth'] = ResolversParentTypes['LogPullingIntegrationHealth']
> = {
  logPullingStatus?: Resolver<
    Maybe<ResolversTypes['IntegrationItemHealthStatus']>,
    ParentType,
    ContextType
  >;
  systemAlarmStatus?: Resolver<
    Maybe<ResolversTypes['IntegrationItemHealthStatus']>,
    ParentType,
    ContextType
  >;
  logProcessingErrorsStatus?: Resolver<
    Maybe<ResolversTypes['IntegrationItemHealthStatus']>,
    ParentType,
    ContextType
  >;
  classificationFailuresStatus?: Resolver<
    Maybe<ResolversTypes['IntegrationItemHealthStatus']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SqsLogIntegrationHealthResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SqsLogIntegrationHealth'] = ResolversParentTypes['SqsLogIntegrationHealth']
> = {
  sqsStatus?: Resolver<
    Maybe<ResolversTypes['IntegrationItemHealthStatus']>,
    ParentType,
    ContextType
  >;
  systemAlarmStatus?: Resolver<
    Maybe<ResolversTypes['IntegrationItemHealthStatus']>,
    ParentType,
    ContextType
  >;
  logProcessingErrorsStatus?: Resolver<
    Maybe<ResolversTypes['IntegrationItemHealthStatus']>,
    ParentType,
    ContextType
  >;
  classificationFailuresStatus?: Resolver<
    Maybe<ResolversTypes['IntegrationItemHealthStatus']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type GcsLogIntegrationHealthResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GcsLogIntegrationHealth'] = ResolversParentTypes['GcsLogIntegrationHealth']
> = {
  systemAlarmStatus?: Resolver<
    Maybe<ResolversTypes['IntegrationItemHealthStatus']>,
    ParentType,
    ContextType
  >;
  logProcessingErrorsStatus?: Resolver<
    Maybe<ResolversTypes['IntegrationItemHealthStatus']>,
    ParentType,
    ContextType
  >;
  classificationFailuresStatus?: Resolver<
    Maybe<ResolversTypes['IntegrationItemHealthStatus']>,
    ParentType,
    ContextType
  >;
  gcsStatus?: Resolver<
    Maybe<ResolversTypes['IntegrationItemHealthStatus']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type EventBridgeConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventBridgeConfig'] = ResolversParentTypes['EventBridgeConfig']
> = {
  busName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OktaConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OktaConfig'] = ResolversParentTypes['OktaConfig']
> = {
  domain?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  apiToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type GsuiteConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GsuiteConfig'] = ResolversParentTypes['GsuiteConfig']
> = {
  clientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clientSecret?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  applications?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CrowdstrikeConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CrowdstrikeConfig'] = ResolversParentTypes['CrowdstrikeConfig']
> = {
  awsAccessKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  awsAccessSecret?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  queueUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DuoConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DuoConfig'] = ResolversParentTypes['DuoConfig']
> = {
  integrationKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  secretKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  apiHostname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SalesforceConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SalesforceConfig'] = ResolversParentTypes['SalesforceConfig']
> = {
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  password?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  securityToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fileInterval?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OAuthConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OAuthConfig'] = ResolversParentTypes['OAuthConfig']
> = {
  clientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clientSecret?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type M365ConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['M365Config'] = ResolversParentTypes['M365Config']
> = {
  tenantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clientSecret?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type MicrosoftGraphConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MicrosoftGraphConfig'] = ResolversParentTypes['MicrosoftGraphConfig']
> = {
  tenantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clientSecret?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type GithubLogSourceOAuth2ConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GithubLogSourceOAuth2Config'] = ResolversParentTypes['GithubLogSourceOAuth2Config']
> = {
  clientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clientSecret?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type GithubLogSourcePatConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GithubLogSourcePatConfig'] = ResolversParentTypes['GithubLogSourcePatConfig']
> = {
  personalAccessToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type GithubLogSourceCredentialsConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GithubLogSourceCredentialsConfig'] = ResolversParentTypes['GithubLogSourceCredentialsConfig']
> = {
  __resolveType: TypeResolveFn<
    'GithubLogSourceOAuth2Config' | 'GithubLogSourcePatConfig',
    ParentType,
    ContextType
  >;
};

export type GithubLogSourceConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GithubLogSourceConfig'] = ResolversParentTypes['GithubLogSourceConfig']
> = {
  organization?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  credentials?: Resolver<
    ResolversTypes['GithubLogSourceCredentialsConfig'],
    ParentType,
    ContextType
  >;
  authorizationMethod?: Resolver<
    ResolversTypes['GithubAuthorizationMethodEnum'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OnePasswordConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnePasswordConfig'] = ResolversParentTypes['OnePasswordConfig']
> = {
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  host?: Resolver<ResolversTypes['OnePasswordHost'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AtlassianConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AtlassianConfig'] = ResolversParentTypes['AtlassianConfig']
> = {
  organization?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  apiKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ZendeskLogSourceOAuth2ConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ZendeskLogSourceOAuth2Config'] = ResolversParentTypes['ZendeskLogSourceOAuth2Config']
> = {
  clientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clientSecret?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ZendeskLogSourceBasicConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ZendeskLogSourceBasicConfig'] = ResolversParentTypes['ZendeskLogSourceBasicConfig']
> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  password?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ZendeskLogSourceApiConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ZendeskLogSourceApiConfig'] = ResolversParentTypes['ZendeskLogSourceApiConfig']
> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  apiToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ZendeskLogSourceCredentialsConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ZendeskLogSourceCredentialsConfig'] = ResolversParentTypes['ZendeskLogSourceCredentialsConfig']
> = {
  __resolveType: TypeResolveFn<
    'ZendeskLogSourceOAuth2Config' | 'ZendeskLogSourceBasicConfig' | 'ZendeskLogSourceApiConfig',
    ParentType,
    ContextType
  >;
};

export type ZendeskLogSourceConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ZendeskLogSourceConfig'] = ResolversParentTypes['ZendeskLogSourceConfig']
> = {
  subdomain?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  credentials?: Resolver<
    ResolversTypes['ZendeskLogSourceCredentialsConfig'],
    ParentType,
    ContextType
  >;
  authorizationMethod?: Resolver<
    ResolversTypes['ZendeskAuthorizationMethodEnum'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AsanaLogSourceConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AsanaLogSourceConfig'] = ResolversParentTypes['AsanaLogSourceConfig']
> = {
  organizationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  serviceAccountToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type WorkdayConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WorkdayConfig'] = ResolversParentTypes['WorkdayConfig']
> = {
  tenantHostName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tenantName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  activityClientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  activityClientSecret?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  activityRefreshToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  signOnCustomReport?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  signOnAccountName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  integrationUsername?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  integrationPassword?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SnykLogSourceConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SnykLogSourceConfig'] = ResolversParentTypes['SnykLogSourceConfig']
> = {
  orgId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  apiToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type PullerConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PullerConfig'] = ResolversParentTypes['PullerConfig']
> = {
  asana?: Resolver<Maybe<ResolversTypes['AsanaLogSourceConfig']>, ParentType, ContextType>;
  okta?: Resolver<Maybe<ResolversTypes['OktaConfig']>, ParentType, ContextType>;
  gsuite?: Resolver<Maybe<ResolversTypes['GsuiteConfig']>, ParentType, ContextType>;
  box?: Resolver<Maybe<ResolversTypes['OAuthConfig']>, ParentType, ContextType>;
  slack?: Resolver<Maybe<ResolversTypes['OAuthConfig']>, ParentType, ContextType>;
  zoom?: Resolver<Maybe<ResolversTypes['OAuthConfig']>, ParentType, ContextType>;
  dropbox?: Resolver<Maybe<ResolversTypes['OAuthConfig']>, ParentType, ContextType>;
  crowdstrike?: Resolver<Maybe<ResolversTypes['CrowdstrikeConfig']>, ParentType, ContextType>;
  github?: Resolver<Maybe<ResolversTypes['GithubLogSourceConfig']>, ParentType, ContextType>;
  zendesk?: Resolver<Maybe<ResolversTypes['ZendeskLogSourceConfig']>, ParentType, ContextType>;
  duo?: Resolver<Maybe<ResolversTypes['DuoConfig']>, ParentType, ContextType>;
  salesforce?: Resolver<Maybe<ResolversTypes['SalesforceConfig']>, ParentType, ContextType>;
  m365?: Resolver<Maybe<ResolversTypes['M365Config']>, ParentType, ContextType>;
  onePassword?: Resolver<Maybe<ResolversTypes['OnePasswordConfig']>, ParentType, ContextType>;
  atlassian?: Resolver<Maybe<ResolversTypes['AtlassianConfig']>, ParentType, ContextType>;
  workday?: Resolver<Maybe<ResolversTypes['WorkdayConfig']>, ParentType, ContextType>;
  snyk?: Resolver<Maybe<ResolversTypes['SnykLogSourceConfig']>, ParentType, ContextType>;
  microsoftGraph?: Resolver<Maybe<ResolversTypes['MicrosoftGraphConfig']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type EventThresholdAlarmResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventThresholdAlarm'] = ResolversParentTypes['EventThresholdAlarm']
> = {
  minutesThreshold?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SeriesMetricResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SeriesMetric'] = ResolversParentTypes['SeriesMetric']
> = {
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  values?: Resolver<Array<ResolversTypes['Long']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SeriesDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SeriesData'] = ResolversParentTypes['SeriesData']
> = {
  timestamps?: Resolver<Array<ResolversTypes['DateTime']>, ParentType, ContextType>;
  series?: Resolver<Array<ResolversTypes['SeriesMetric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SourceMetricsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SourceMetricsResponse'] = ResolversParentTypes['SourceMetricsResponse']
> = {
  bytesProcessedWithDimensions?: Resolver<
    Maybe<ResolversTypes['SeriesData']>,
    ParentType,
    ContextType
  >;
  eventsProcessedWithDimensions?: Resolver<
    Maybe<ResolversTypes['SeriesData']>,
    ParentType,
    ContextType
  >;
  classificationFailuresWithDimensions?: Resolver<
    Maybe<ResolversTypes['SeriesData']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SendUserFeedbackOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SendUserFeedbackOutput'] = ResolversParentTypes['SendUserFeedbackOutput']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ResetUserPasswordOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResetUserPasswordOutput'] = ResolversParentTypes['ResetUserPasswordOutput']
> = {
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type InviteUserOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InviteUserOutput'] = ResolversParentTypes['InviteUserOutput']
> = {
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UpdateUserOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateUserOutput'] = ResolversParentTypes['UpdateUserOutput']
> = {
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DeleteUserOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteUserOutput'] = ResolversParentTypes['DeleteUserOutput']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']
> = {
  createdAt?: Resolver<ResolversTypes['Timestamp'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['Email'], ParentType, ContextType>;
  familyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  givenName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['Role'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Resolvers<ContextType = any> = {
  Actor?: ActorResolvers;
  Query?: QueryResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  UpdateAlertStatusByFilterJob?: UpdateAlertStatusByFilterJobResolvers<ContextType>;
  AlertEvents?: AlertEventsResolvers<ContextType>;
  AlertOrigin?: AlertOriginResolvers;
  SystemErrorRelatedEntity?: SystemErrorRelatedEntityResolvers;
  AlertOriginPolicy?: AlertOriginPolicyResolvers<ContextType>;
  AlertOriginRule?: AlertOriginRuleResolvers<ContextType>;
  AlertOriginSystemError?: AlertOriginSystemErrorResolvers<ContextType>;
  AlertOriginReplayedRule?: AlertOriginReplayedRuleResolvers<ContextType>;
  AlertSummaryAttributeQueryData?: AlertSummaryAttributeQueryDataResolvers<ContextType>;
  AlertSummaryAttributeForAnalysis?: AlertSummaryAttributeForAnalysisResolvers<ContextType>;
  RelatedAlertsOutput?: RelatedAlertsOutputResolvers<ContextType>;
  RelatedAlertsOutputEdge?: RelatedAlertsOutputEdgeResolvers<ContextType>;
  RelatedAlertsOutputPageInfo?: RelatedAlertsOutputPageInfoResolvers<ContextType>;
  AlertActivityHistoryRecordContent?: AlertActivityHistoryRecordContentResolvers;
  AlertActivityHistoryRecord?: AlertActivityHistoryRecordResolvers<ContextType>;
  AlertActivityHistoryAssigneeChange?: AlertActivityHistoryAssigneeChangeResolvers<ContextType>;
  AlertActivityHistoryStatusChange?: AlertActivityHistoryStatusChangeResolvers<ContextType>;
  AlertActivityHistoryOutputEdge?: AlertActivityHistoryOutputEdgeResolvers<ContextType>;
  AlertActivityHistoryOutputPageInfo?: AlertActivityHistoryOutputPageInfoResolvers<ContextType>;
  AlertActivityHistoryOutput?: AlertActivityHistoryOutputResolvers<ContextType>;
  Alert?: AlertResolvers<ContextType>;
  UpdateAlertStatusByIdOutput?: UpdateAlertStatusByIdOutputResolvers<ContextType>;
  UpdateAlertsAssigneeByIdOutput?: UpdateAlertsAssigneeByIdOutputResolvers<ContextType>;
  AlertsOutput?: AlertsOutputResolvers<ContextType>;
  AlertsOutputEdge?: AlertsOutputEdgeResolvers<ContextType>;
  AlertsOutputPageInfo?: AlertsOutputPageInfoResolvers<ContextType>;
  AlertDelivery?: AlertDeliveryResolvers<ContextType>;
  APIToken?: APITokenResolvers<ContextType>;
  CreateAPITokenOutput?: CreateAPITokenOutputResolvers<ContextType>;
  GetAPITokenOutput?: GetAPITokenOutputResolvers<ContextType>;
  UpdateAPITokenOutput?: UpdateAPITokenOutputResolvers<ContextType>;
  DeleteAPITokenOutput?: DeleteAPITokenOutputResolvers<ContextType>;
  ListAPITokensOutput?: ListAPITokensOutputResolvers<ContextType>;
  Email?: GraphQLScalarType;
  DateTime?: GraphQLScalarType;
  Timestamp?: GraphQLScalarType;
  JSON?: GraphQLScalarType;
  Long?: GraphQLScalarType;
  Error?: ErrorResolvers<ContextType>;
  PageInfo?: PageInfoResolvers<ContextType>;
  PagingData?: PagingDataResolvers<ContextType>;
  Job?: JobResolvers;
  DataModelMapping?: DataModelMappingResolvers<ContextType>;
  DataModel?: DataModelResolvers<ContextType>;
  ListDataModelsResponse?: ListDataModelsResponseResolvers<ContextType>;
  DataLakeDatabaseTable?: DataLakeDatabaseTableResolvers<ContextType>;
  DataLakeQuery?: DataLakeQueryResolvers<ContextType>;
  GetAsyncQueryDownloadUrlOutput?: GetAsyncQueryDownloadUrlOutputResolvers<ContextType>;
  GenerateSqlQuerySnippetOutput?: GenerateSqlQuerySnippetOutputResolvers<ContextType>;
  SummarizeDataLakeQueryJob?: SummarizeDataLakeQueryJobResolvers<ContextType>;
  DataLakeQueriesOutput?: DataLakeQueriesOutputResolvers<ContextType>;
  DataLakeQueryEdge?: DataLakeQueryEdgeResolvers<ContextType>;
  DataLakeQueriesOutputPageInfo?: DataLakeQueriesOutputPageInfoResolvers<ContextType>;
  DataLakeQueryResults?: DataLakeQueryResultsResolvers<ContextType>;
  DataLakeQueryResultsColumnInfo?: DataLakeQueryResultsColumnInfoResolvers<ContextType>;
  DataLakeQueryStats?: DataLakeQueryStatsResolvers<ContextType>;
  DataLakeQueryResultEdge?: DataLakeQueryResultEdgeResolvers<ContextType>;
  DataLakeQueryResultRecordsPageInfo?: DataLakeQueryResultRecordsPageInfoResolvers<ContextType>;
  CancelDataLakeQueryOutput?: CancelDataLakeQueryOutputResolvers<ContextType>;
  ExecuteDataLakeQueryOutput?: ExecuteDataLakeQueryOutputResolvers<ContextType>;
  ExecuteIndicatorSearchQueryOutput?: ExecuteIndicatorSearchQueryOutputResolvers<ContextType>;
  DataLakeDatabase?: DataLakeDatabaseResolvers<ContextType>;
  DataLakeDatabaseTableColumn?: DataLakeDatabaseTableColumnResolvers<ContextType>;
  Destination?: DestinationResolvers<ContextType>;
  DestinationConfig?: DestinationConfigResolvers<ContextType>;
  SqsDestinationConfig?: SqsDestinationConfigResolvers<ContextType>;
  OpsgenieConfig?: OpsgenieConfigResolvers<ContextType>;
  MsTeamsConfig?: MsTeamsConfigResolvers<ContextType>;
  JiraConfig?: JiraConfigResolvers<ContextType>;
  AsanaConfig?: AsanaConfigResolvers<ContextType>;
  CustomWebhookConfig?: CustomWebhookConfigResolvers<ContextType>;
  GithubConfig?: GithubConfigResolvers<ContextType>;
  SlackConfig?: SlackConfigResolvers<ContextType>;
  SnsConfig?: SnsConfigResolvers<ContextType>;
  PagerDutyConfig?: PagerDutyConfigResolvers<ContextType>;
  BulkDownloadResponse?: BulkDownloadResponseResolvers<ContextType>;
  GenerateEnrichedEventResponse?: GenerateEnrichedEventResponseResolvers<ContextType>;
  UploadDetectionsResponse?: UploadDetectionsResponseResolvers<ContextType>;
  DetectionTestMockDefinition?: DetectionTestMockDefinitionResolvers<ContextType>;
  DetectionTestDefinition?: DetectionTestDefinitionResolvers<ContextType>;
  DetectionReportMapping?: DetectionReportMappingResolvers<ContextType>;
  Detection?: DetectionResolvers;
  DetectionsOutput?: DetectionsOutputResolvers<ContextType>;
  DetectionsOutputPageInfo?: DetectionsOutputPageInfoResolvers<ContextType>;
  DetectionsOutputEdge?: DetectionsOutputEdgeResolvers<ContextType>;
  Rule?: RuleResolvers<ContextType>;
  Policy?: PolicyResolvers<ContextType>;
  ListDetectionsResponse?: ListDetectionsResponseResolvers<ContextType>;
  TestDetectionSubRecord?: TestDetectionSubRecordResolvers<ContextType>;
  TestPolicyRecordFunctions?: TestPolicyRecordFunctionsResolvers<ContextType>;
  TestRecord?: TestRecordResolvers;
  TestPolicyRecord?: TestPolicyRecordResolvers<ContextType>;
  TestPolicyResponse?: TestPolicyResponseResolvers<ContextType>;
  TestDetectionRecordFunctions?: TestDetectionRecordFunctionsResolvers<ContextType>;
  TestDetectionRecord?: TestDetectionRecordResolvers<ContextType>;
  TestRuleResponse?: TestRuleResponseResolvers<ContextType>;
  DetectionUpdateError?: DetectionUpdateErrorResolvers<ContextType>;
  BulkUpdateDetectionsResponse?: BulkUpdateDetectionsResponseResolvers<ContextType>;
  DetectionFilter?: DetectionFilterResolvers<ContextType>;
  DetectionFilterStatement?: DetectionFilterStatementResolvers<ContextType>;
  DetectionFiltersOutput?: DetectionFiltersOutputResolvers<ContextType>;
  CreateDetectionFilterOutput?: CreateDetectionFilterOutputResolvers<ContextType>;
  UpdateDetectionFilterOutput?: UpdateDetectionFilterOutputResolvers<ContextType>;
  DeleteDetectionFilterOutput?: DeleteDetectionFilterOutputResolvers<ContextType>;
  ListLookupResponse?: ListLookupResponseResolvers<ContextType>;
  Lookup?: LookupResolvers<ContextType>;
  LookupRefreshConfig?: LookupRefreshConfigResolvers<ContextType>;
  LookupImportDataJob?: LookupImportDataJobResolvers<ContextType>;
  LookupSyncJob?: LookupSyncJobResolvers<ContextType>;
  LookupSyncJobStats?: LookupSyncJobStatsResolvers<ContextType>;
  LookupImportDataJobStats?: LookupImportDataJobStatsResolvers<ContextType>;
  LookupVersion?: LookupVersionResolvers<ContextType>;
  AssociatedLogType?: AssociatedLogTypeResolvers<ContextType>;
  LookupS3ImportConfig?: LookupS3ImportConfigResolvers<ContextType>;
  GlobalHelper?: GlobalHelperResolvers<ContextType>;
  ListGlobalHelpersResponse?: ListGlobalHelpersResponseResolvers<ContextType>;
  HoldingTankTestJob?: HoldingTankTestJobResolvers<ContextType>;
  DraftSchema?: DraftSchemaResolvers<ContextType>;
  HoldingTankTestJobParams?: HoldingTankTestJobParamsResolvers<ContextType>;
  DateRange?: DateRangeResolvers<ContextType>;
  HoldingTankListTasksResponse?: HoldingTankListTasksResponseResolvers<ContextType>;
  HoldingTankInferJob?: HoldingTankInferJobResolvers<ContextType>;
  HoldingTankSchemaInferenceResult?: HoldingTankSchemaInferenceResultResolvers<ContextType>;
  HoldingTankJobStats?: HoldingTankJobStatsResolvers<ContextType>;
  HoldingTankTestJobPrefixStats?: HoldingTankTestJobPrefixStatsResolvers<ContextType>;
  HoldingTankLogTypeTestStats?: HoldingTankLogTypeTestStatsResolvers<ContextType>;
  HoldingTankRawEvent?: HoldingTankRawEventResolvers<ContextType>;
  HoldingTankResults?: HoldingTankResultsResolvers<ContextType>;
  HoldingTankTestSchemasResultsResponse?: HoldingTankTestSchemasResultsResponseResolvers<
    ContextType
  >;
  HoldingTankRawDataResponse?: HoldingTankRawDataResponseResolvers<ContextType>;
  GetSchemaOutput?: GetSchemaOutputResolvers<ContextType>;
  ListSchemasOutput?: ListSchemasOutputResolvers<ContextType>;
  SchemaRecord?: SchemaRecordResolvers<ContextType>;
  SchemaField?: SchemaFieldResolvers<ContextType>;
  SchemaFieldValueValidation?: SchemaFieldValueValidationResolvers<ContextType>;
  GenerateS3SampleUploadResponse?: GenerateS3SampleUploadResponseResolvers<ContextType>;
  InferSchemaResponse?: InferSchemaResponseResolvers<ContextType>;
  GetSampleDataResponse?: GetSampleDataResponseResolvers<ContextType>;
  TestSchemaResult?: TestSchemaResultResolvers<ContextType>;
  TestSchemaResponse?: TestSchemaResponseResolvers<ContextType>;
  TestSchemaStats?: TestSchemaStatsResolvers<ContextType>;
  UnmatchedLog?: UnmatchedLogResolvers<ContextType>;
  MatchedLog?: MatchedLogResolvers<ContextType>;
  TestSchemaData?: TestSchemaDataResolvers<ContextType>;
  RequestLogTypeResponse?: RequestLogTypeResponseResolvers<ContextType>;
  ListAvailableLogTypesResponse?: ListAvailableLogTypesResponseResolvers<ContextType>;
  ListAvailableLogProvidersResponse?: ListAvailableLogProvidersResponseResolvers<ContextType>;
  GetAvailableLogProviderResponse?: GetAvailableLogProviderResponseResolvers<ContextType>;
  AvailableLogProvider?: AvailableLogProviderResolvers<ContextType>;
  AvailableLogProviderDetails?: AvailableLogProviderDetailsResolvers<ContextType>;
  AvailableLogProviderParser?: AvailableLogProviderParserResolvers<ContextType>;
  OrganizationStatsResponse?: OrganizationStatsResponseResolvers<ContextType>;
  ScannedResourceStats?: ScannedResourceStatsResolvers<ContextType>;
  ScannedResources?: ScannedResourcesResolvers<ContextType>;
  OrganizationReportBySeverity?: OrganizationReportBySeverityResolvers<ContextType>;
  ComplianceStatusCounts?: ComplianceStatusCountsResolvers<ContextType>;
  MetricsOutput?: MetricsOutputResolvers<ContextType>;
  Series?: SeriesResolvers<ContextType>;
  SeriesWithBreakdown?: SeriesWithBreakdownResolvers<ContextType>;
  SeriesWithEntityID?: SeriesWithEntityIDResolvers<ContextType>;
  GeneralSettingsConfig?: GeneralSettingsConfigResolvers<ContextType>;
  InfrastructureInfo?: InfrastructureInfoResolvers<ContextType>;
  NetworkingInfo?: NetworkingInfoResolvers<ContextType>;
  StorageInfo?: StorageInfoResolvers<ContextType>;
  SamlConfig?: SamlConfigResolvers<ContextType>;
  ListAnalysisPacksResponse?: ListAnalysisPacksResponseResolvers<ContextType>;
  ListAnalysisPackSourcesResponse?: ListAnalysisPackSourcesResponseResolvers<ContextType>;
  AnalysisPackVersion?: AnalysisPackVersionResolvers<ContextType>;
  AnalysisPackDefinition?: AnalysisPackDefinitionResolvers<ContextType>;
  AnalysisPackTypes?: AnalysisPackTypesResolvers<ContextType>;
  AnalysisPack?: AnalysisPackResolvers<ContextType>;
  AnalysisPackSource?: AnalysisPackSourceResolvers<ContextType>;
  ReplayScope?: ReplayScopeResolvers<ContextType>;
  Replay?: ReplayResolvers<ContextType>;
  ReplayPreview?: ReplayPreviewResolvers<ContextType>;
  ReplaySummary?: ReplaySummaryResolvers<ContextType>;
  StopReplayOutput?: StopReplayOutputResolvers<ContextType>;
  CreateReplayOutput?: CreateReplayOutputResolvers<ContextType>;
  ComputeReplayOutput?: ComputeReplayOutputResolvers<ContextType>;
  ReplayedAlertsOutput?: ReplayedAlertsOutputResolvers<ContextType>;
  MitreMatrixTree?: MitreMatrixTreeResolvers<ContextType>;
  MitreMatrixTreeNode?: MitreMatrixTreeNodeResolvers<ContextType>;
  MitreMatrix?: MitreMatrixResolvers<ContextType>;
  MitreMatrixTacticMapping?: MitreMatrixTacticMappingResolvers<ContextType>;
  MitreMatrixContent?: MitreMatrixContentResolvers<ContextType>;
  MitreTactic?: MitreTacticResolvers<ContextType>;
  MitreTechnique?: MitreTechniqueResolvers<ContextType>;
  ReportSetting?: ReportSettingResolvers<ContextType>;
  ReportSettingAssociation?: ReportSettingAssociationResolvers<ContextType>;
  ReportSettingAssociationMitre?: ReportSettingAssociationMitreResolvers<ContextType>;
  UpdateMitreReportMappingOutput?: UpdateMitreReportMappingOutputResolvers<ContextType>;
  ListResourcesResponse?: ListResourcesResponseResolvers<ContextType>;
  ListComplianceItemsResponse?: ListComplianceItemsResponseResolvers<ContextType>;
  ComplianceItem?: ComplianceItemResolvers<ContextType>;
  ActiveSuppressCount?: ActiveSuppressCountResolvers<ContextType>;
  ResourceSummary?: ResourceSummaryResolvers<ContextType>;
  ResourceDetails?: ResourceDetailsResolvers<ContextType>;
  DeleteRoleOutput?: DeleteRoleOutputResolvers<ContextType>;
  CreateRoleOutput?: CreateRoleOutputResolvers<ContextType>;
  UpdateRoleOutput?: UpdateRoleOutputResolvers<ContextType>;
  Role?: RoleResolvers<ContextType>;
  Schedule?: ScheduleResolvers<ContextType>;
  SavedQuery?: SavedQueryResolvers<ContextType>;
  ListSavedQueriesResponse?: ListSavedQueriesResponseResolvers<ContextType>;
  SqsConfig?: SqsConfigResolvers<ContextType>;
  GcsConfig?: GcsConfigResolvers<ContextType>;
  AWSScanConfig?: AWSScanConfigResolvers<ContextType>;
  LogIntegration?: LogIntegrationResolvers;
  ComplianceIntegration?: ComplianceIntegrationResolvers<ContextType>;
  LogIntegrationAlarms?: LogIntegrationAlarmsResolvers<ContextType>;
  S3PrefixLogTypes?: S3PrefixLogTypesResolvers<ContextType>;
  S3LogIntegration?: S3LogIntegrationResolvers<ContextType>;
  CloudWatchLogIntegration?: CloudWatchLogIntegrationResolvers<ContextType>;
  EventBridgeIntegration?: EventBridgeIntegrationResolvers<ContextType>;
  SqsLogSourceIntegration?: SqsLogSourceIntegrationResolvers<ContextType>;
  GcsLogSourceIntegration?: GcsLogSourceIntegrationResolvers<ContextType>;
  LogPullingIntegration?: LogPullingIntegrationResolvers<ContextType>;
  OAuth2Info?: OAuth2InfoResolvers<ContextType>;
  IntegrationTemplate?: IntegrationTemplateResolvers<ContextType>;
  TerraformTemplateResponse?: TerraformTemplateResponseResolvers<ContextType>;
  IntegrationItemHealthStatus?: IntegrationItemHealthStatusResolvers<ContextType>;
  ComplianceIntegrationHealth?: ComplianceIntegrationHealthResolvers<ContextType>;
  S3LogIntegrationHealth?: S3LogIntegrationHealthResolvers<ContextType>;
  CloudWatchLogIntegrationHealth?: CloudWatchLogIntegrationHealthResolvers<ContextType>;
  EventBridgeIntegrationHealth?: EventBridgeIntegrationHealthResolvers<ContextType>;
  LogPullingIntegrationHealth?: LogPullingIntegrationHealthResolvers<ContextType>;
  SqsLogIntegrationHealth?: SqsLogIntegrationHealthResolvers<ContextType>;
  GcsLogIntegrationHealth?: GcsLogIntegrationHealthResolvers<ContextType>;
  EventBridgeConfig?: EventBridgeConfigResolvers<ContextType>;
  OktaConfig?: OktaConfigResolvers<ContextType>;
  GsuiteConfig?: GsuiteConfigResolvers<ContextType>;
  CrowdstrikeConfig?: CrowdstrikeConfigResolvers<ContextType>;
  DuoConfig?: DuoConfigResolvers<ContextType>;
  SalesforceConfig?: SalesforceConfigResolvers<ContextType>;
  OAuthConfig?: OAuthConfigResolvers<ContextType>;
  M365Config?: M365ConfigResolvers<ContextType>;
  MicrosoftGraphConfig?: MicrosoftGraphConfigResolvers<ContextType>;
  GithubLogSourceOAuth2Config?: GithubLogSourceOAuth2ConfigResolvers<ContextType>;
  GithubLogSourcePatConfig?: GithubLogSourcePatConfigResolvers<ContextType>;
  GithubLogSourceCredentialsConfig?: GithubLogSourceCredentialsConfigResolvers;
  GithubLogSourceConfig?: GithubLogSourceConfigResolvers<ContextType>;
  OnePasswordConfig?: OnePasswordConfigResolvers<ContextType>;
  AtlassianConfig?: AtlassianConfigResolvers<ContextType>;
  ZendeskLogSourceOAuth2Config?: ZendeskLogSourceOAuth2ConfigResolvers<ContextType>;
  ZendeskLogSourceBasicConfig?: ZendeskLogSourceBasicConfigResolvers<ContextType>;
  ZendeskLogSourceApiConfig?: ZendeskLogSourceApiConfigResolvers<ContextType>;
  ZendeskLogSourceCredentialsConfig?: ZendeskLogSourceCredentialsConfigResolvers;
  ZendeskLogSourceConfig?: ZendeskLogSourceConfigResolvers<ContextType>;
  AsanaLogSourceConfig?: AsanaLogSourceConfigResolvers<ContextType>;
  WorkdayConfig?: WorkdayConfigResolvers<ContextType>;
  SnykLogSourceConfig?: SnykLogSourceConfigResolvers<ContextType>;
  PullerConfig?: PullerConfigResolvers<ContextType>;
  EventThresholdAlarm?: EventThresholdAlarmResolvers<ContextType>;
  SeriesMetric?: SeriesMetricResolvers<ContextType>;
  SeriesData?: SeriesDataResolvers<ContextType>;
  SourceMetricsResponse?: SourceMetricsResponseResolvers<ContextType>;
  SendUserFeedbackOutput?: SendUserFeedbackOutputResolvers<ContextType>;
  ResetUserPasswordOutput?: ResetUserPasswordOutputResolvers<ContextType>;
  InviteUserOutput?: InviteUserOutputResolvers<ContextType>;
  UpdateUserOutput?: UpdateUserOutputResolvers<ContextType>;
  DeleteUserOutput?: DeleteUserOutputResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
};

/**
 * @deprecated
 * Use "Resolvers" root object instead. If you wish to get "IResolvers", add "typesPrefix: I" to your config.
 */
export type IResolvers<ContextType = any> = Resolvers<ContextType>;
export type DirectiveResolvers<ContextType = any> = {
  auth?: AuthDirectiveResolver<any, any, ContextType>;
  sensitive?: SensitiveDirectiveResolver<any, any, ContextType>;
  audit?: AuditDirectiveResolver<any, any, ContextType>;
};

/**
 * @deprecated
 * Use "DirectiveResolvers" root object instead. If you wish to get "IDirectiveResolvers", add "typesPrefix: I" to your config.
 */
export type IDirectiveResolvers<ContextType = any> = DirectiveResolvers<ContextType>;

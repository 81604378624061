/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import useUrlParams from 'Hooks/useUrlParams';
import storage from 'Helpers/storage';
import { DataExplorerUrlParams } from './constants';

// A key to help persist the selected database in order to restore it whenever we don't have one
// explicitly specified through the URL
const SELECTED_DATABASE_STORAGE_KEY = 'panther.dataAnalytics.dataExplorer.selectedDatabase';

// A key to help persist sql text within the same session (resets for new sessions)
const SQL_STORAGE_KEY = 'panther.dataAnalytics.dataExplorer.sql';

type State = {
  selectedDatabase: string | null;
  selectedTable: string | null;
  selectedColumn: string | null;
  searchValue: string;
  /** The SQL query in the SQL editor */
  sql: string;
};

type Action<T, P = never> = {
  type: T;
  payload?: P;
};

type SelectDatabaseAction = Action<'SELECT_DATABASE', { database: string }>;
type SelectTableAction = Action<'SELECT_TABLE', { table: string }>;
type SelectColumnAction = Action<'SELECT_COLUMN', { column: string }>;
type SearchAction = Action<'SEARCH_DATABASE', { searchValue: string }>;
type UpdateSQLAction = Action<'UPDATE_SQL', { sql: string }>;

type Actions =
  | SelectDatabaseAction
  | SelectTableAction
  | SelectColumnAction
  | SearchAction
  | UpdateSQLAction;

function reducer(state: State, action: Actions) {
  switch (action.type) {
    case 'SELECT_DATABASE':
      return {
        ...state,
        selectedDatabase: action.payload.database,
        selectedTable: null,
        selectedColumn: null,
        searchValue: '',
      };
    case 'SELECT_TABLE':
      return {
        ...state,
        selectedTable: action.payload.table,
        selectedColumn: null,
        searchValue: '',
      };
    case 'SELECT_COLUMN':
      return { ...state, selectedColumn: action.payload.column };
    case 'SEARCH_DATABASE':
      return { ...state, searchValue: action.payload.searchValue };
    case 'UPDATE_SQL': {
      return { ...state, sql: action.payload.sql };
    }
    default:
      throw new Error();
  }
}

const DataExplorerContext = React.createContext<{
  state: State;
  dispatch: (action: Actions) => void;
}>(undefined);

export const DataExplorerContextProvider: React.FC = ({ children }) => {
  const { urlParams, updateUrlParams } = useUrlParams<DataExplorerUrlParams>();

  const initialState = React.useMemo(
    () => ({
      selectedDatabase: urlParams.selectedDatabase || storage.local.read(SELECTED_DATABASE_STORAGE_KEY) || null, // prettier-ignore
      selectedTable: null,
      selectedColumn: null,
      searchValue: '',
      // If SQL is being generated based on the URL, don't use the persisted SQL.
      // Otherwise, attempt to bootstrap the initial SQL from session storage.
      sql: urlParams?.snippedId ? '' : storage.session.read(SQL_STORAGE_KEY)?.toString() ?? '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const prevState = React.useRef<State>(initialState);
  const [state, dispatch] = React.useReducer<React.Reducer<State, Actions>>(reducer, initialState);

  // sync changes to `selectedDatabase` state to the URL
  React.useEffect(() => {
    if (prevState.current.selectedDatabase !== state.selectedDatabase) {
      updateUrlParams({ selectedDatabase: state.selectedDatabase });
    }
  }, [state.selectedDatabase, prevState, updateUrlParams]);

  // Keeps track of the previous state
  React.useEffect(() => {
    return () => {
      prevState.current = state;
    };
  }, [state]);

  // always store the "last selected database" in the localStorage, to use it when a user
  // "comes back". Of course if a particular `selectedDatabase` is specified in the URL, it takes
  // precedence over the locally stored value (since it will refer to a particular query)
  React.useEffect(() => {
    return () => storage.local.write(SELECTED_DATABASE_STORAGE_KEY, state.selectedDatabase);
  }, [state.selectedDatabase]);

  // Store the most recent SQL query in session storage so that we can restore users' queries
  // when they navigate away from the page by mistake.
  React.useEffect(() => {
    storage.session.write(SQL_STORAGE_KEY, state.sql);
  }, [state.sql]);

  const contextValue = React.useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <DataExplorerContext.Provider value={contextValue}>{children}</DataExplorerContext.Provider>
  );
};

export const useDataExplorerContext = () => React.useContext(DataExplorerContext);

export const withDataExplorerContext = (Component: React.FC) => props => (
  <DataExplorerContextProvider>
    <Component {...props} />
  </DataExplorerContextProvider>
);

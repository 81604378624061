/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { compose } from 'Helpers/compose';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import withSEO from 'Hoc/withSEO';
import { Permission } from 'Generated/schema';
import Page403 from 'Pages/403';
import useAvailableLogTypesForLogPuller from 'Hooks/useAvailableLogTypesForLogPuller';
import MicrosoftGraphLogsSourceWizard, {
  MicrosoftGraphLogsSourceWizardValues,
} from '../MicrosoftGraphLogsSourceWizard';
import { useAddMicrosoftGraphLogSource } from './graphql/addMicrosoftGraphLogSource.generated';

const CreateMicrosoftGraphLogSource: React.FC = () => {
  const { defaultLogTypes } = useAvailableLogTypesForLogPuller();
  const initialValues = React.useMemo(
    () => ({
      integrationLabel: '',
      logTypes: defaultLogTypes,
      tenantId: '',
      clientId: '',
      clientSecret: '',
    }),
    [defaultLogTypes]
  );
  const [addMicrosoftGraphLogSource] = useAddMicrosoftGraphLogSource({
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          logSources: (queryData, { toReference }) => {
            const addedIntegrationCacheRef = toReference(data.addLogPullingIntegration);
            return queryData
              ? [addedIntegrationCacheRef, ...queryData]
              : [addedIntegrationCacheRef];
          },
        },
      });
    },
  });

  const handleSubmit = React.useCallback(
    async (values: MicrosoftGraphLogsSourceWizardValues) => {
      try {
        const { data } = await addMicrosoftGraphLogSource({
          variables: {
            input: {
              integrationLabel: values.integrationLabel,
              pullerConfig: {
                logTypes: values.logTypes,
                microsoftGraph: {
                  tenantId: values.tenantId,
                  clientId: values.clientId,
                  clientSecret: values.clientSecret,
                },
              },
            },
          },
        });
        return data.addLogPullingIntegration;
      } catch (err) {
        return err as Error;
      }
    },
    [addMicrosoftGraphLogSource]
  );

  return <MicrosoftGraphLogsSourceWizard initialValues={initialValues} onSubmit={handleSubmit} />;
};

export default compose(
  withSEO({ title: 'New Microsoft Graph Log Source' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LogSourceModify],
    fallback: <Page403 />,
  })
)(CreateMicrosoftGraphLogSource);

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../../__generated__/schema';

import { MicrosoftGraphLogIntegrationDetails } from '../../../../../../graphql/fragments/MicrosoftGraphLogIntegrationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type AddMicrosoftGraphLogSourceVariables = {
  input: Types.AddLogPullingIntegrationInput;
};

export type AddMicrosoftGraphLogSource = {
  addLogPullingIntegration: MicrosoftGraphLogIntegrationDetails;
};

export const AddMicrosoftGraphLogSourceDocument = gql`
  mutation AddMicrosoftGraphLogSource($input: AddLogPullingIntegrationInput!) {
    addLogPullingIntegration(input: $input) {
      ...MicrosoftGraphLogIntegrationDetails
    }
  }
  ${MicrosoftGraphLogIntegrationDetails}
`;
export type AddMicrosoftGraphLogSourceMutationFn = ApolloReactCommon.MutationFunction<
  AddMicrosoftGraphLogSource,
  AddMicrosoftGraphLogSourceVariables
>;

/**
 * __useAddMicrosoftGraphLogSource__
 *
 * To run a mutation, you first call `useAddMicrosoftGraphLogSource` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMicrosoftGraphLogSource` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMicrosoftGraphLogSource, { data, loading, error }] = useAddMicrosoftGraphLogSource({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddMicrosoftGraphLogSource(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddMicrosoftGraphLogSource,
    AddMicrosoftGraphLogSourceVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddMicrosoftGraphLogSource,
    AddMicrosoftGraphLogSourceVariables
  >(AddMicrosoftGraphLogSourceDocument, baseOptions);
}
export type AddMicrosoftGraphLogSourceHookResult = ReturnType<typeof useAddMicrosoftGraphLogSource>;
export type AddMicrosoftGraphLogSourceMutationResult = ApolloReactCommon.MutationResult<
  AddMicrosoftGraphLogSource
>;
export type AddMicrosoftGraphLogSourceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddMicrosoftGraphLogSource,
  AddMicrosoftGraphLogSourceVariables
>;
export function mockAddMicrosoftGraphLogSource({
  data,
  variables,
  errors,
}: {
  data: AddMicrosoftGraphLogSource;
  variables?: AddMicrosoftGraphLogSourceVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: AddMicrosoftGraphLogSourceDocument, variables },
    result: { data, errors },
  };
}

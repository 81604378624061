/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import TelescopeSVG from 'Assets/telescope.svg';
import BulletedLoading from 'Components/BulletedLoading';
import ErrorBoundary from 'Components/ErrorBoundary';
import FormikCombobox from 'Components/fields/ComboBox';
import FormikAutosave from 'Components/utils/Autosave';
import { Field, Form, Formik } from 'formik';
import { DataLakeQuerySummaryDirection } from 'Generated/schema';
import useAsyncQueryContext from 'Hooks/useAsyncQueryContext';
import useAsyncQueryResults from 'Hooks/useAsyncQueryResults';
import { Box, Flex, Heading, Img, Table, Text } from 'pouncejs';
import { DEFAULT_LARGE_PAGE_SIZE } from 'Source/constants';
import { useColumnSummary } from '../ColumnSummaryContext';

const ColumnSummaryError = ({ error }: { error: any }) => {
  return (
    <Flex minHeight={170} direction="column" justify="center" align="center">
      <Text
        p={4}
        m={4}
        borderRadius="large"
        textAlign="center"
        backgroundColor="pink-700"
        fontSize="medium"
      >
        {error}
      </Text>
    </Flex>
  );
};

const ColumnSummaryEmpty = () => {
  return (
    <Flex direction="column" justify="center" align="center" spacing={4} height={170}>
      <Img src={TelescopeSVG} nativeWidth={65} nativeHeight={65} alt="Telescope" />
      <Heading size="x-small" color="navyblue-100">
        Choose a column to summarize your query results
      </Heading>
    </Flex>
  );
};

const ColumnSummaryLoading = () => {
  return (
    <Flex direction="column" align="center" spacing={4} heigh={170}>
      <BulletedLoading />
      <Heading size="x-small" color="navyblue-100">
        Loading
      </Heading>
    </Flex>
  );
};

const fallbackFormState = {
  column: undefined,
  direction: DataLakeQuerySummaryDirection.Desc,
};

const ColumnSummaryTab = () => {
  const {
    state: { queryStatus },
  } = useAsyncQueryContext();
  const { columnOrder } = useAsyncQueryResults({
    pageSize: DEFAULT_LARGE_PAGE_SIZE,
  });

  const { summarize, ...columnSummaries } = useColumnSummary();

  const summaryData = columnSummaries.summary;
  const isFormDisabled = columnSummaries.status === 'loading' || queryStatus !== 'succeeded';
  const initialValues = columnSummaries.meta || fallbackFormState;

  return (
    <Flex p={6} direction="column" spacing={6}>
      <Flex justify="flex-end">
        <Formik onSubmit={values => summarize(values)} initialValues={initialValues}>
          <Form>
            <FormikAutosave />
            <Flex spacing={2}>
              <Field
                name="column"
                as={FormikCombobox}
                label="Summary Column"
                items={columnOrder ?? []}
                searchable
                showClearSelectionControl={false}
                disabled={isFormDisabled || !columnOrder?.length}
                data-tid="data-explorer-summarize-column-edit"
              />

              <Field
                name="direction"
                as={FormikCombobox}
                label="Sort"
                disabled={isFormDisabled}
                items={[DataLakeQuerySummaryDirection.Desc, DataLakeQuerySummaryDirection.Asc]}
                data-tid="data-explorer-summarize-sort-edit"
                itemToString={item => {
                  if (item === DataLakeQuerySummaryDirection.Asc) {
                    return 'Lowest Count First';
                  }
                  return 'Highest Count First';
                }}
                showClearSelectionControl={false}
              />
            </Flex>
          </Form>
        </Formik>
      </Flex>

      {columnSummaries.status === 'idle' && <ColumnSummaryEmpty />}
      {columnSummaries.status === 'loading' && <ColumnSummaryLoading />}
      {columnSummaries.status === 'error' && <ColumnSummaryError error={columnSummaries.error} />}
      {columnSummaries.status === 'success' && (
        <ErrorBoundary>
          <Table size="small" stickyHeader>
            <Table.Head>
              <Table.Row>
                {summaryData.columnInfo?.order.map(columnName => (
                  <Box
                    key={columnName}
                    as={Table.HeaderCell}
                    textTransform="none"
                    paddingRight={10}
                  >
                    {columnName}
                  </Box>
                ))}
              </Table.Row>
            </Table.Head>

            <Table.Body>
              {summaryData?.rows?.map((row, i) => (
                <Table.Row key={i}>
                  {summaryData.columnInfo?.order.map(columnName => (
                    <Box key={columnName} as={Table.Cell}>
                      {row[columnName] || 'null'}
                    </Box>
                  ))}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </ErrorBoundary>
      )}
    </Flex>
  );
};

export default ColumnSummaryTab;

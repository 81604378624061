/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Card, Flex, Heading, Button } from 'pouncejs';
import { LogStreamTypeEnum } from 'Generated/schema';
import Editor from 'Components/Editor';
import useModal from 'Hooks/useModal';
import LogStreamTypePicker from './LogStreamTypePicker';

const eventLogs = [
  `{ "ip": "10.0.0.1", "username": "user-1@example.com", "country": "France" }`,
  `{ "ip": "10.0.0.2", "username": "user-2@example.com", "country": "France" }`,
  `{ "ip": "10.0.0.3", "username": "user-3@example.com", "country": "France" }`,
];

const cloudwatchExample = `{
  "owner": "111111111111",
  "logGroup": "services/foo/logs",
  "logStream": "111111111111_CloudTrail/logs_us-east-1",
  "messageType": "DATA_MESSAGE",
  "logEvents": [
      {
          "id": "31953106606966983378809025079804211143289615424298221568",
          "timestamp": 1432826855000,
          "message": "{\\"ip\\": \\"10.0.0.1\\", \\"user\\": \\"user-1@example.com\\", \\"country\\": \\"France\\"}"
      },
      {
          "id": "31953106606966983378809025079804211143289615424298221569",
          "timestamp": 1432826855000,
          "message": "{\\"ip\\": \\"10.0.0.2\\", \\"user\\": \\"user-2@example.com\\", \\"country\\": \\"France\\"}"
      },
      {
          "id": "31953106606966983378809025079804211143289615424298221570",
          "timestamp": 1432826855000,
          "message": "{\\"ip\\": \\"10.0.0.3\\", \\"user\\": \\"user-3@example.com\\", \\"country\\": \\"France\\"}"
      }
  ]
}`;

const streamTypesExample: Record<LogStreamTypeEnum, string> = {
  [LogStreamTypeEnum.CloudWatchLogs]: cloudwatchExample,
  [LogStreamTypeEnum.JsonArray]: `[\n\t${eventLogs.join(',\n\t')}\n]`,
  [LogStreamTypeEnum.Lines]: `${eventLogs.join('\n')}`,
};

const SetS3LogStreamTypeModal = ({
  streamType,
  onStreamTypeChange,
}: {
  streamType: LogStreamTypeEnum;
  onStreamTypeChange: (logStreamType: LogStreamTypeEnum) => void;
}) => {
  const [draftStreamType, setDraftStreamType] = React.useState(streamType);
  const { hideModal } = useModal();
  const logStreamTypeExample = streamTypesExample[draftStreamType];

  return (
    <Flex width={760} direction="column" spacing={6}>
      <Heading as="h2" fontSize="medium">
        Select the format of the logs this source is going to receive. If you’re not sure, you can
        leave it as is (Lines). If incorrect, Panther will trigger a system error alert and prompt
        you to fix.
      </Heading>
      <LogStreamTypePicker value={draftStreamType} onChange={setDraftStreamType} />
      <Card variant="dark">
        <Flex
          direction="column"
          spacing={4}
          m={4}
          transition="all 0.15s"
          height={draftStreamType === LogStreamTypeEnum.CloudWatchLogs ? '600px' : '340px'}
          overflow="hidden"
        >
          <Box fontWeight="medium">Input file</Box>
          <Editor
            maxHeight={380}
            readOnly
            minHeight={17}
            hideLineNumbers
            fontSize="small-medium"
            language="json"
            value={logStreamTypeExample || ''}
          />

          <Box fontWeight="medium">Panther Reads</Box>
          <Flex spacing={2} direction="column">
            {eventLogs.map(event => (
              <Editor
                key={event}
                readOnly
                hideLineNumbers
                minHeight={17}
                language="json"
                value={event}
              />
            ))}
          </Flex>
        </Flex>
      </Card>
      <Flex justify="center" mt={2}>
        <Button
          onClick={() => {
            onStreamTypeChange(draftStreamType);
            hideModal();
          }}
        >
          Apply and continue
        </Button>
      </Flex>
    </Flex>
  );
};

export default SetS3LogStreamTypeModal;

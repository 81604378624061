/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Combobox, useSnackbar } from 'pouncejs';
import { UpdateAlertsAssigneeByIdInput, Permission } from 'Generated/schema';
import useCheckPermissions from 'Hooks/useCheckPermissions';
import { useUpdateAlertsAssigneeById } from 'Source/graphql/queries/updateAlertsAssigneeById.generated';
import { AlertSummaryFull } from 'Source/graphql/fragments/AlertSummaryFull.generated';
import { extractErrorMessage, getActorDisplayName } from 'Helpers/utils';
import useAlertAssignableUsers from 'Hooks/useAlertAssignableUsers';
import capitalize from 'lodash/capitalize';
import { UNASSIGNED_USER, ENABLE_ALERT_ASSIGNEES } from 'Source/constants';

interface UpdateAssignedUserDropdown {
  alert: AlertSummaryFull;
  variant?: 'solid' | 'outline';
}
const AssigneeDropdown: React.FC<UpdateAssignedUserDropdown> = ({ alert, variant }) => {
  const { pushSnackbar } = useSnackbar();
  const canModifyAlert = useCheckPermissions(Permission.AlertModify);
  const { assignableUsers } = useAlertAssignableUsers();
  const [updateAlertsAssigneeById] = useUpdateAlertsAssigneeById({
    onCompleted: data => {
      const assignedUser = data.updateAlertsAssigneeById.alerts[0].assignee;
      const verbiage = !assignedUser
        ? UNASSIGNED_USER
        : `assigned to ${getActorDisplayName(assignedUser)}`;
      pushSnackbar({
        variant: 'success',
        title: `Alert has been ${verbiage}`,
      });
    },
    onError: error => {
      pushSnackbar({
        variant: 'error',
        title: `Failed to assign the alert`,
        description: extractErrorMessage(error),
      });
    },
  });
  const assignedUser = alert.assignee ?? UNASSIGNED_USER;

  return (
    ENABLE_ALERT_ASSIGNEES && (
      <Combobox
        label="Assignee"
        aria-label="Change Alert Assignee"
        disabled={!canModifyAlert}
        variant={variant}
        showClearSelectionControl={false}
        required
        value={assignedUser}
        onChange={selectedUser => {
          const input = {
            ids: [alert.id],
            assigneeId:
              // @ts-ignore
              selectedUser === UNASSIGNED_USER || selectedUser === null ? null : selectedUser.id,
          } as UpdateAlertsAssigneeByIdInput;

          return updateAlertsAssigneeById({
            variables: {
              input,
            },
          });
        }}
        items={[UNASSIGNED_USER, ...assignableUsers]}
        itemToString={item => {
          if (typeof item === 'string' || item === null) {
            return capitalize('unassigned');
          }
          return getActorDisplayName(item);
        }}
      />
    )
  );
};

export default React.memo(AssigneeDropdown);

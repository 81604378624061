/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, Img } from 'pouncejs';
import { useFormikContext } from 'formik';
import SuccessStatus from 'Assets/statuses/illustration-success.svg';
import { useWizardContext, WizardPanel } from 'Components/Wizard';
import EventThresholdAlarmConfigurator from 'Components/EventThresholdAlarmConfigurator';
import { CrowdstrikeLogIntegrationDetails } from 'Source/graphql/fragments/CrowdstrikeLogIntegrationDetails.generated';
import { LogPullingIntegration } from 'Generated/schema';
import { FailingPanel, PendingPanel } from 'Components/SourceVerificationPanels';
import FinishSourceSetupActions from 'Pages/Integrations/components/FinishSourceSetupActions';
import { CrowdstrikeLogsSourceWizardValues } from '../CrowdstrikeLogsSourceWizard';

const ValidationPanel = () => {
  const [errorMessage, setErrorMessage] = React.useState('');
  const result = React.useRef<CrowdstrikeLogIntegrationDetails | Error>(null);
  const { currentStepStatus, setCurrentStepStatus,setNavigationAvailability } = useWizardContext(); // prettier-ignore
  const { initialValues, submitForm } = useFormikContext<CrowdstrikeLogsSourceWizardValues>();

  React.useEffect(() => {
    (async () => {
      setNavigationAvailability(false);
      result.current = await (submitForm() as Promise<any>);
      if (result.current instanceof Error) {
        setErrorMessage(result.current.message);
        setNavigationAvailability(true);
        setCurrentStepStatus('FAILING');
      } else {
        setErrorMessage('');
        setCurrentStepStatus('PASSING');
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const source = result.current as LogPullingIntegration;

  if (currentStepStatus === 'PASSING') {
    return (
      <WizardPanel>
        <Flex align="center" direction="column" mx="auto" width={620}>
          <Box width={400}>
            <WizardPanel.Heading
              title="Everything looks good!"
              subtitle={
                initialValues.integrationId
                  ? 'Your Crowdstrike Log Source was successfully updated'
                  : 'Your Crowdstrike Log Source was successfully created'
              }
            />
          </Box>
          <Img nativeWidth={157} nativeHeight={118} mb={8} alt="Checkmark" src={SuccessStatus} />
          <EventThresholdAlarmConfigurator logSource={source} />
          <WizardPanel.Actions>
            <FinishSourceSetupActions integrationId={source.integrationId} />
          </WizardPanel.Actions>
        </Flex>
      </WizardPanel>
    );
  }

  if (currentStepStatus === 'FAILING') {
    return <FailingPanel errorMessage={errorMessage} />;
  }

  return <PendingPanel />;
};

export default ValidationPanel;

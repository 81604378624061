/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { FastField, Field, Form, Formik } from 'formik';
import SubmitButton from 'Components/buttons/SubmitButton';
import { Box, Flex, Text } from 'pouncejs';
import * as Yup from 'yup';
import Radio from 'Components/fields/Radio/Radio';
import FormikTextArea from 'Components/fields/TextArea';

export interface UserFeedbackFormValues {
  featureUsefulness?: number;
  feedbackText: string;
}

export interface UserFeedbackFormProps {
  /** The initial values of the form */
  initialValues: UserFeedbackFormValues;
  /** callback for the submission of the form */
  onSubmit: (values: UserFeedbackFormValues) => void;
}

const validationSchema: Yup.SchemaOf<UserFeedbackFormValues> = Yup.object().shape({
  featureUsefulness: Yup.number().nullable(),
  feedbackText: Yup.string().required(),
});

const usefulnessValues = [...Array(5)].map((_, index) => index + 1);

const UserFeedbackForm: React.FC<UserFeedbackFormProps> = ({ initialValues, onSubmit }) => {
  const getAriaAttributes = (index: number): Record<string, string> => {
    switch (index) {
      case 1:
        return {
          'aria-label': 'Feature is not useful',
          'aria-describedby': 'featureUsefulness-not-useful',
        };
      case 2:
        return {
          'aria-label': 'Feature is somewhat useful',
        };
      case 3:
        return {
          'aria-label': 'Feature is useful',
        };
      case 4:
        return {
          'aria-label': 'Feature is very useful',
        };
      case 5:
        return {
          'aria-label': 'Feature is extremely useful',
          'aria-describedby': 'featureUsefulness-very-useful',
        };
      default:
        return {};
    }
  };

  // Formik has a bug and can't have a boolean/number value. For Formik everything is a string so we're converting the
  // feature usefulness back to a number. Since this bug is local to Formik, we should do that at the Form-component level
  // https://github.com/jaredpalmer/formik/issues/2044
  const handleSubmit = React.useCallback(
    ({ featureUsefulness, ...otherValues }: UserFeedbackFormValues) => {
      return onSubmit({ ...otherValues, featureUsefulness: Number(featureUsefulness) || null });
    },
    [onSubmit]
  );

  return (
    <Formik<UserFeedbackFormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={validationSchema}
    >
      <Form data-tid="user-feedback-form">
        <Box as="fieldset" mb={8}>
          <Text fontSize="medium-large" mb={1}>
            How useful is this feature to you?
          </Text>
          <Flex width="calc(100% + 8px)" ml="-8px" align="center" justify="space-between">
            {usefulnessValues.map(usefulnessValue => (
              <FastField
                as={Radio}
                name="featureUsefulness"
                key={usefulnessValue}
                value={usefulnessValue}
                label={`${usefulnessValue}`}
                {...getAriaAttributes(usefulnessValue)}
              />
            ))}
          </Flex>
          <Flex justify="space-between">
            <Box fontSize="small" id="featureUsefulness-not-useful">
              Not useful
            </Box>
            <Box fontSize="small" id="featureUsefulness-very-useful">
              Very useful
            </Box>
          </Flex>
        </Box>

        <Box mb={8}>
          <Field
            data-tid="user-feedback-text"
            as={FormikTextArea}
            label="How can we make this more useful?"
            placeholder="Tell us more"
            name="feedbackText"
            minRows={4}
            required
          />
        </Box>
        <Flex justify="center">
          <SubmitButton data-tid="submit-user-feedback">Submit</SubmitButton>
        </Flex>
      </Form>
    </Formik>
  );
};

export default UserFeedbackForm;

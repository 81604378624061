/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Alert, Box, Flex, Button, Switch, Card, useSnackbar, SimpleGrid } from 'pouncejs';
import { compose } from 'Helpers/compose';
import withSEO from 'Hoc/withSEO';
import Panel from 'Components/Panel';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import useRouter from 'Hooks/useRouter';
import { extractErrorMessage, formatDatetime } from 'Helpers/utils';
import { ErrorCodeEnum, Permission } from 'Generated/schema';
import Page404 from 'Pages/404';
import Page403 from 'Pages/403';
import urls from 'Source/urls';
import Breadcrumbs from 'Components/Breadcrumbs';
import LinkButton from 'Components/buttons/LinkButton';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import Editor from 'Components/Editor';
import Linkify from 'Components/Linkify';
import { SchemaGenerationProvider } from 'Components/utils/SchemaGenerationContext';
import SampleDataSection from 'Components/forms/DataSchemaForm/SampleDataSection';
import useCloneDataSchema from 'Hooks/useCloneDataSchema';
import DataSchemaDetailsBanner from './DataSchemaDetailsBanner';
import Skeleton from './Skeleton';
import { useGetSchemaDetails } from './graphql/getDataSchemaDetails.generated';
import { useToggleDataSchema } from './graphql/toggleDataSchema.generated';

const DataSchemaDetails: React.FC = () => {
  const { match: { params: { name } } } = useRouter<{ name: string }>(); // prettier-ignore
  const { pushSnackbar } = useSnackbar();

  const { cloneSchema, isCloning } = useCloneDataSchema();
  const { data, loading, error: uncontrolledError } = useGetSchemaDetails({
    variables: { input: { name } },
  });

  const { record: schema, error: controlledError } = data?.getSchema || {};

  const [toggleDataSchema, { loading: toggleLoading }] = useToggleDataSchema({
    onCompleted: ({ toggleSchema: { error, record } }) => {
      if (error) {
        pushSnackbar({
          variant: 'error',
          title: 'Failed to update Schema',
          description: error.message,
        });
      } else {
        pushSnackbar({
          variant: 'success',
          title: `Updated Schema [${record?.name}] successfully`,
        });
      }
    },
    onError: error => {
      pushSnackbar({
        variant: 'error',
        title: 'Failed to update Schema',
        description: extractErrorMessage(error),
      });
    },
  });

  // FIXME: look into missing hook dependencies
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onClone = React.useCallback(() => cloneSchema(name), [cloneSchema]);

  const onToggle = React.useCallback(() => {
    toggleDataSchema({
      variables: {
        input: {
          name: schema.name,
          toggle: !!schema.disabled,
        },
      },
    });
    // FIXME: look into missing hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schema]);

  if (loading || !data) {
    return <Skeleton />;
  }

  if (uncontrolledError) {
    return (
      <Alert
        variant="error"
        title="Couldn't load your data schema"
        description={extractErrorMessage(uncontrolledError)}
      />
    );
  }

  if (controlledError) {
    if (controlledError.code === ErrorCodeEnum.NotFound) {
      return <Page404 />;
    }

    return (
      <Alert
        variant="error"
        title="Couldn't load your data schema"
        description={controlledError.message}
      />
    );
  }
  return (
    <React.Fragment>
      <RoleRestrictedAccess allowedPermissions={[Permission.LogSourceModify]}>
        <Breadcrumbs.Actions>
          <Flex spacing={4} justify="flex-end" align="center">
            {!schema.managed && (
              <>
                <Switch
                  aria-label="Toggle data schema"
                  disabled={toggleLoading}
                  onChange={onToggle}
                  label="Enabled"
                  checked={!schema.disabled}
                />
                <LinkButton
                  data-tid="edit-data-schema"
                  icon="pencil"
                  aria-label="Edit Data Schema"
                  to={urls.data.schemas.edit(schema.name)}
                >
                  Edit Schema
                </LinkButton>
              </>
            )}

            <Button
              data-tid="clone-data-schema"
              icon="copy"
              aria-label="Clone and Edit"
              onClick={onClone}
            >
              {isCloning ? 'Cloning...' : 'Clone & edit'}
            </Button>
          </Flex>
        </Breadcrumbs.Actions>
      </RoleRestrictedAccess>
      <Box as="article" mb={6}>
        <Flex direction="column" spacing={6}>
          <DataSchemaDetailsBanner schema={schema} />
          <Panel title="Details">
            <Card p={4} variant="dark" mb={4} section>
              <SimpleGrid columns={2} spacing={5} fontSize="small-medium">
                <SimpleGrid gap={2} columns={8} spacing={2} as="dl">
                  <Box
                    as="dt"
                    color="navyblue-100"
                    gridColumn="1/3"
                    aria-describedby="schema-created"
                  >
                    Schema Created
                  </Box>
                  <Box as="dd" id="schema-created" gridColumn="3/8">
                    {formatDatetime(schema.createdAt)}
                  </Box>
                  <Box
                    as="dt"
                    color="navyblue-100"
                    gridColumn="1/3"
                    aria-describedby="schema-modified"
                  >
                    Schema Modified
                  </Box>
                  <Box as="dd" id="schema-modified" gridColumn="3/8">
                    {formatDatetime(schema.updatedAt)}
                  </Box>
                </SimpleGrid>
                <SimpleGrid gap={2} columns={8} spacing={2}>
                  <Box
                    as="dt"
                    color="navyblue-100"
                    gridColumn="1/3"
                    aria-describedby="schema-reference"
                  >
                    Reference
                  </Box>
                  <Box as="dd" gridColumn="3/8">
                    {schema.referenceURL ? (
                      <Linkify id="schema-reference">{schema.referenceURL}</Linkify>
                    ) : (
                      <Box fontStyle="italic" color="navyblue-100" id="schema-reference">
                        No reference URL specified
                      </Box>
                    )}
                  </Box>
                </SimpleGrid>
              </SimpleGrid>
            </Card>
            <Card p={6} variant="dark" as="section">
              <Editor
                readOnly
                language="yaml"
                data-testid="data-schema-editor"
                value={schema.spec}
              />
            </Card>
          </Panel>
          <SchemaGenerationProvider
            sessionKey={`SCHEMA_${schema.name + schema.revision}_SESSION_ID`}
            initialSchema={schema.spec}
            readOnly
          >
            <SampleDataSection />
          </SchemaGenerationProvider>
        </Flex>
      </Box>
    </React.Fragment>
  );
};

export default compose(
  withSEO({ title: ({ match }) => match.params.name }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LogSourceRead],
    fallback: <Page403 />,
  })
)(DataSchemaDetails);

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { QueryHookOptions } from '@apollo/client';
import { DEFAULT_SMALL_PAGE_SIZE } from 'Source/constants';
import useRequestParamsWithPagination from 'Hooks/useRequestParamsWithPagination';
import { useListDetections, ListDetections, ListDetectionsVariables } from 'Source/graphql/queries';
import { ListDetectionsInput } from 'Generated/schema';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';

type UseListDetectionsOptions = Omit<
  QueryHookOptions<ListDetections, ListDetectionsVariables>,
  'variables'
>;

// completeness of this list ensured by test. this is to avoid silent failures when ListDetectionsInput is changed
export const listRequestParamNames: (keyof ListDetectionsInput)[] = [
  'tags',
  'page',
  'sortBy',
  'sortDir',
  'packIds',
  'enabled',
  'pageSize',
  'logTypes',
  'severity',
  'createdBy',
  'initialSet',
  'nameContains',
  'analysisTypes',
  'resourceTypes',
  'lastModifiedBy',
  'complianceStatus',
  'scheduledQueries',
];

function useListDetectionsWithRequestParams(options?: UseListDetectionsOptions) {
  const { requestParams, updatePagingParams } = useRequestParamsWithPagination<ListDetectionsInput>(
    { overrides: { nameContains: 'string' } }
  );

  const inputParams = pick(requestParams, ...listRequestParamNames);

  const { loading, error, previousData, data = previousData } = useListDetections({
    variables: {
      input: { ...inputParams, pageSize: DEFAULT_SMALL_PAGE_SIZE },
    },
    ...options,
  });

  return {
    data,
    error,
    loading,
    previousData,
    hasFiltersSet: !isEmpty(requestParams),
    updatePagingParams,
  };
}

export default useListDetectionsWithRequestParams;

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Alert, Box, Card, Flex, Text } from 'pouncejs';
import { DEFAULT_LARGE_PAGE_SIZE } from 'Source/constants';
import { extractErrorMessage } from 'Helpers/utils';
import { compose } from 'Helpers/compose';
import { ListSavedQueriesInput, Permission } from 'Generated/schema';
import useInfiniteScroll from 'Hooks/useInfiniteScroll';
import useRequestParamsWithoutPagination from 'Hooks/useRequestParamsWithoutPagination';
import TablePlaceholder from 'Components/TablePlaceholder';
import NoResultsFound from 'Components/NoResultsFound';
import ErrorBoundary from 'Components/ErrorBoundary';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import Page403 from 'Pages/403';
import withSEO from 'Hoc/withSEO';
import { SelectAllCheckbox, useSelect, withSelectContext } from 'Components/utils/SelectContext';
import Panel from 'Components/Panel';
import { useListSavedQueries } from 'Source/graphql/queries';
import SavedQueryCard from 'Components/cards/SavedQueryCard';
import ListSavedQueriesActions from 'Pages/ListSavedQueries/ListSavedQueriesActions';
import ListSavedQueriesBreadcrumbFilters from 'Pages/ListSavedQueries/ListSavedQueriesBreadcrumbFilters';
import useOverrideShiftClickSelection from 'Hooks/useOverrideShiftClickSelection';
import ListSavedQueriesPageSkeleton from './Skeleton';

const ListSavedQueries = () => {
  useOverrideShiftClickSelection();
  const { requestParams } = useRequestParamsWithoutPagination<ListSavedQueriesInput>({
    overrides: { contains: 'string' },
  });
  const { checkIfSelected, setAllSelectables } = useSelect();
  const { loading, error, previousData, data = previousData, fetchMore } = useListSavedQueries({
    variables: {
      input: {
        pageSize: DEFAULT_LARGE_PAGE_SIZE,
        ...requestParams,
      },
    },
  });

  const savedQueriesItems = React.useMemo(() => {
    return data?.listSavedQueries.savedQueries || [];
  }, [data]);

  const lastEvaluatedKey = data?.listSavedQueries.lastEvaluatedKey || null;
  const hasNextPage = !!lastEvaluatedKey;

  const savedQueriesIds = React.useMemo(() => savedQueriesItems.map(a => a.id), [
    savedQueriesItems,
  ]);

  React.useEffect(() => {
    setAllSelectables(savedQueriesIds);
  }, [savedQueriesIds, setAllSelectables]);

  const { sentinelRef } = useInfiniteScroll<HTMLDivElement>({
    loading,
    threshold: 500,
    onLoadMore: () => {
      fetchMore({
        variables: {
          input: {
            ...requestParams,
            pageSize: DEFAULT_LARGE_PAGE_SIZE,
            exclusiveStartKey: lastEvaluatedKey,
          },
        },
      });
    },
  });

  if (loading && !data) {
    return <ListSavedQueriesPageSkeleton />;
  }

  const hasError = Boolean(error);

  return (
    <ErrorBoundary>
      {hasError && (
        <Box mb={6}>
          <Alert
            variant="error"
            title="Couldn't load your saved queries"
            description={
              extractErrorMessage(error) ||
              'There was an error when performing your request, please contact support@runpanther.io'
            }
          />
        </Box>
      )}
      <ListSavedQueriesBreadcrumbFilters />
      <Panel
        title={
          <Flex align="center" spacing={3} ml={1}>
            <SelectAllCheckbox selectionItems={savedQueriesIds} />
            <Text>Saved Queries</Text>
          </Flex>
        }
        actions={<ListSavedQueriesActions />}
      >
        <Card as="section" position="relative" data-testid="saved-queries-list">
          <Box position="relative">
            <Flex direction="column" spacing={2}>
              {savedQueriesItems.length ? (
                savedQueriesItems.map(savedQuery => (
                  <SavedQueryCard
                    key={savedQuery.id}
                    savedQuery={savedQuery}
                    selectionEnabled
                    isSelected={checkIfSelected(savedQuery.id)}
                  />
                ))
              ) : (
                <Box my={8}>
                  <NoResultsFound title="No Saved Queries found" />
                </Box>
              )}
            </Flex>
            {hasNextPage && (
              <Box py={8} ref={sentinelRef}>
                <TablePlaceholder rowCount={10} />
              </Box>
            )}
          </Box>
        </Card>
      </Panel>
    </ErrorBoundary>
  );
};

export default compose(
  withSEO({ title: 'Saved Queries' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.DataAnalyticsRead],
    fallback: <Page403 />,
  }),
  withSelectContext(),
  React.memo
)(ListSavedQueries);

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import Breadcrumbs from 'Components/Breadcrumbs';
import ErrorBoundary from 'Components/ErrorBoundary';
import { compose } from 'Helpers/compose';
import Page403 from 'Pages/403';
import { extractErrorMessage } from 'Helpers/utils';
import withSEO from 'Hoc/withSEO';
import useRouter from 'Hooks/useRouter';
import useUrlParams from 'Hooks/useUrlParams';
import invert from 'lodash/invert';
import {
  Box,
  Card,
  Divider,
  Flex,
  Heading,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Alert,
  Icon,
  IconProps,
  Text,
} from 'pouncejs';
import { LogSourceType } from 'Source/constants';
import { useGetLogIntegration } from 'Source/graphql/queries/getLogIntegration.generated';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import useGetLogSourceHealthMetrics from 'Hooks/useGetLogSourceHealthMetrics';
import { Permission } from 'Generated/schema';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import { BasicInfoMeta, DetailsPageSkeleton, DetailsPageTab, InformationPanel } from './components';
import HealthPanel from './HealthPanel';
import OverviewPanel from './OverviewPanel';
import SchemasPanel from './SchemasPanel';
import { SourceIntegration } from './components/InformationPanel';
import LogSourceDetailsOptions from './LogSourceDetailsOptions';

interface UrlParams {
  tab?: 'overview' | 'health' | 'schemas';
}

const tabToIndex: Record<UrlParams['tab'], number> = {
  overview: 0,
  health: 1,
  schemas: 2,
};

const indexToTab = invert(tabToIndex) as Record<number, UrlParams['tab']>;

const LogSourceDetailsPage = () => {
  const { match } = useRouter<{ id: string; type: LogSourceType }>();
  const {
    urlParams: { tab },
    updateUrlParams,
  } = useUrlParams<UrlParams>();

  const { data, loading, error } = useGetLogIntegration({
    variables: { id: match.params.id },
  });
  const { unhealthyMetrics } = useGetLogSourceHealthMetrics(data?.getLogIntegration);

  if (error) {
    return (
      <Alert
        variant="error"
        title="We can't display this content right now"
        description={extractErrorMessage(error)}
      />
    );
  }

  if (loading && !data) {
    return <DetailsPageSkeleton />;
  }

  const source = data.getLogIntegration;

  const healthIconProps: IconProps = source.isHealthy
    ? { type: 'check-circle', color: 'green-400' }
    : { type: 'alert-circle-filled', color: 'pink-600' };

  const schemaIconProps: IconProps =
    source.logTypes.length > 0
      ? { type: 'check-circle', color: 'green-400' }
      : { type: 'alert-circle-filled', color: 'yellow-600' };

  return (
    <ErrorBoundary>
      <Tabs
        index={tabToIndex[tab] || 0}
        onChange={index => updateUrlParams({ tab: indexToTab[index] })}
      >
        <Flex spacing={5}>
          <Box as="section">
            <Card p={4} width={269}>
              <Box as="header">
                <Heading size="small" fontWeight="medium" wordBreak="break-word">
                  {source.integrationLabel}
                </Heading>
                {source.isPantherManaged && (
                  <Text as="h4" color="orange-200" mt={2} fontSize="medium" fontWeight="medium">
                    Managed by Panther
                  </Text>
                )}
                <Divider my={6} color="navyblue-300" />
                <TabList>
                  <DetailsPageTab
                    title="Overview"
                    description="Summary of data ingestion for this source"
                    width={1}
                    mb={4}
                  />
                  <DetailsPageTab
                    title="Health"
                    description="System health status and alerts related to this source"
                    width={1}
                    mb={4}
                    icon={
                      <Icon
                        size="large"
                        type={healthIconProps.type}
                        color={healthIconProps.color}
                      />
                    }
                  />
                  <DetailsPageTab
                    title="Schemas"
                    description="Schemas attached to this source"
                    width={1}
                    icon={
                      <Icon
                        size="large"
                        type={schemaIconProps.type}
                        color={schemaIconProps.color}
                      />
                    }
                  />
                </TabList>
              </Box>
            </Card>

            <Card p={4} width={269} mt={5}>
              <Box as="header">
                <Heading as="h2" fontWeight="bold" fontSize="x-large" color="navyblue-100">
                  Basic Info
                </Heading>
                <BasicInfoMeta source={source as SourceIntegration} />
              </Box>
            </Card>
          </Box>

          <Box width={1} position="relative">
            <TabPanels>
              <TabPanel lazy unmountWhenInactive>
                <Card p={6} as="section">
                  <InformationPanel source={source as SourceIntegration} />
                  <OverviewPanel
                    integrationId={source.integrationId}
                    lastEventReceived={source.lastEventReceived}
                  />
                </Card>
              </TabPanel>
              <TabPanel lazy unmountWhenInactive>
                <Card p={6} as="section">
                  <HealthPanel
                    integrationId={data.getLogIntegration.integrationId}
                    unhealthyMetrics={unhealthyMetrics}
                  />
                </Card>
              </TabPanel>
              <TabPanel lazy unmountWhenInactive>
                <Card as="section">
                  <SchemasPanel source={source as SourceIntegration} />
                </Card>
              </TabPanel>
            </TabPanels>
          </Box>
        </Flex>
      </Tabs>
      {source.isEditable && (
        <RoleRestrictedAccess allowedPermissions={[Permission.LogSourceModify]}>
          <Breadcrumbs.Actions>
            <Flex spacing={4} justify="flex-end">
              <LogSourceDetailsOptions source={source} />
            </Flex>
          </Breadcrumbs.Actions>
        </RoleRestrictedAccess>
      )}
    </ErrorBoundary>
  );
};

export default compose(
  withSEO({ title: 'Log Source Page' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LogSourceRead, Permission.LogSourceModify],
    fallback: <Page403 />,
  })
)(LogSourceDetailsPage);

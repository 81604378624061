/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import EnrichmentSourceDetails from 'Pages/Enrichment/Sources/EnrichmentSourceDetails';
import ListEnrichmentSources from 'Pages/Enrichment/Sources/ListEnrichmentSources';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import urls from 'Source/urls';

const EnrichmentProvidersRoutes: React.FC = () => {
  return (
    <Switch>
      <Redirect
        exact
        from={urls.enrichment.sources.list()}
        to={urls.configure.enrichmentProviders.list()}
      />
      <Route
        exact
        path={urls.configure.enrichmentProviders.list()}
        component={ListEnrichmentSources}
      />

      <Redirect
        exact
        from={urls.enrichment.sources.details(':id')}
        to={urls.configure.enrichmentProviders.details(':id')}
      />
      <Route
        exact
        path={urls.configure.enrichmentProviders.details(':id')}
        component={EnrichmentSourceDetails}
      />
    </Switch>
  );
};

export default EnrichmentProvidersRoutes;

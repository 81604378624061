/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */
import React from 'react';
import { AbstractButton, Badge, Box, Flex, Link, Text } from 'pouncejs';
import { Link as RRLink } from 'react-router-dom';
import urls from 'Source/urls';
import { useFormikContext } from 'formik';
import { JobStatus } from 'Generated/schema';
import { useSchemasManagementContext } from 'Pages/Integrations/LogSources/S3/SchemasManagement/SchemasManagementContext';
import FlatBadge from 'Components/badges/FlatBadge';
import LogTypeTestResults from './LogTypeTestResults';
import { S3PrefixLogTypesFormValues } from '../SchemasManagement';

interface LogTypeCardProps {
  logType: string;
  prefix: string;
  matches: number;
  isNewPrefix?: boolean;
}

const LogTypeCard: React.FC<LogTypeCardProps> = ({ prefix, logType, matches, isNewPrefix }) => {
  const { initialValues } = useFormikContext<S3PrefixLogTypesFormValues>();
  const { schemaTestJob } = useSchemasManagementContext();
  const [showTestResults, setShowTestResults] = React.useState(false);

  const hasRanTest = schemaTestJob?.status !== JobStatus.Running;

  // Searching if prefix exist on initial values
  const existingPrefixIndex = initialValues.s3PrefixLogTypes.findIndex(
    ({ prefix: existingPrefix }) => existingPrefix === prefix
  );
  // Searching if logType exist on initial prefix
  const isLogTypeInInitialPrefix =
    existingPrefixIndex !== -1 &&
    initialValues.s3PrefixLogTypes[existingPrefixIndex].logTypes.find(
      initialLogType => initialLogType === logType
    );
  const isDraft = schemaTestJob?.testParameters.newSchemas.some(schema => schema.name === logType);
  return (
    <Flex direction="column" spacing={4} mx={6} backgroundColor="navyblue-600" p={4}>
      <Flex spacing={2} justify="space-between">
        <Flex spacing={2} as="h4" fontWeight="medium" maxWidth="60%" wordBreak="break-word">
          {isDraft ? (
            <Flex spacing={2} color="blue-200" align="center">
              <Text>{logType}</Text>
              <Badge color="orange-200">
                <Box fontWeight="bold" color="white">
                  Draft
                </Box>
              </Badge>
            </Flex>
          ) : (
            <>
              <Link
                as={RRLink}
                aria-label={`Link to ${logType} schema`}
                to={`${urls.data.schemas.details(logType)}`}
                external
              >
                {logType}
              </Link>
              {/* Display this badge if prefix exists but this is a new log type */}
              {!isNewPrefix && !isLogTypeInInitialPrefix && (
                <FlatBadge backgroundColor="navyblue-700" color="blue-300">
                  NEW
                </FlatBadge>
              )}
            </>
          )}
        </Flex>
        <Flex spacing={2} align="center" fontWeight="bold">
          <Text color={matches === 0 ? 'orange-300' : 'green-400'}>{matches}</Text>
          <Text>Matched Events</Text>
          <AbstractButton
            fontSize="small"
            fontWeight="bold"
            textDecoration="underline"
            color="blue-200"
            _hover={{ color: 'blue-100' }}
            disabled={!hasRanTest || matches === 0}
            onClick={() => setShowTestResults(v => !v)}
          >
            {showTestResults ? 'hide' : 'show'}
          </AbstractButton>
        </Flex>
      </Flex>
      {showTestResults && <LogTypeTestResults logType={logType} prefix={prefix} />}
    </Flex>
  );
};

export default LogTypeCard;

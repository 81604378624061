/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import withSEO from 'Hoc/withSEO';
import { compose } from 'Helpers/compose';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { Permission } from 'Generated/schema';
import Page403 from 'Pages/403';
import Page404 from 'Pages/404';
import { ENABLE_WORKDAY_PULLER, WorkdayMethodEnum } from 'Source/constants';
import withFeatureFlag from 'Hoc/withFeatureFlag';
import { useAddWorkdayLogSource } from './graphql/addWorkdayLogSource.generated';
import WorkdayLogSourceWizard, { WorkdayLogsSourceWizardValues } from './WorkdayLogSourceWizard';

const CreateWorkdayLogSource = () => {
  const initialValues = React.useMemo(
    () => ({
      integrationLabel: '',
      tenantHostName: '',
      tenantName: '',
      workdayMethod: WorkdayMethodEnum.Activity,
      activityClientId: '',
      activityClientSecret: '',
      activityRefreshToken: '',
      signOnCustomReport: '',
      signOnAccountName: '',
      integrationUsername: '',
      integrationPassword: '',
    }),
    []
  );

  const [addWorkdayLogSource] = useAddWorkdayLogSource({
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          logSources: (queryData, { toReference }) => {
            const addedIntegrationCacheRef = toReference(data.addLogPullingIntegration);
            return queryData
              ? [addedIntegrationCacheRef, ...queryData]
              : [addedIntegrationCacheRef];
          },
        },
      });
    },
  });

  const handleSubmit = React.useCallback(
    async (values: WorkdayLogsSourceWizardValues) => {
      try {
        const { data } = await addWorkdayLogSource({
          variables: {
            input: {
              integrationLabel: values.integrationLabel,
              pullerConfig: {
                logTypes: [values.workdayMethod],
                workday: {
                  tenantHostName: values.tenantHostName,
                  tenantName: values.tenantName,
                  activityClientId: values.activityClientId,
                  activityClientSecret: values.activityClientSecret,
                  activityRefreshToken: values.activityRefreshToken,
                  signOnCustomReport: values.signOnCustomReport,
                  signOnAccountName: values.signOnAccountName,
                  integrationUsername: values.integrationUsername,
                  integrationPassword: values.integrationPassword,
                },
              },
            },
          },
        });
        return data.addLogPullingIntegration;
      } catch (err) {
        return err as Error;
      }
    },
    [addWorkdayLogSource]
  );

  return <WorkdayLogSourceWizard initialValues={initialValues} onSubmit={handleSubmit} />;
};

export default compose(
  withSEO({ title: 'New Workday Source' }),
  withFeatureFlag({ featureFlags: [ENABLE_WORKDAY_PULLER], fallback: <Page404 /> }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LogSourceModify],
    fallback: <Page403 />,
  })
)(CreateWorkdayLogSource);

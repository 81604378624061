/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex } from 'pouncejs';
import Navigation from 'Components/Navigation';
import Header from 'Components/Header';
import { ENABLE_NEW_NAVIGATION, HEADER_HEIGHT } from 'Source/constants';
import TopNavigation from 'Components/Navigation/TopNavigation';
import SideNavigation from 'Components/Navigation/SideNavigation';

const Layout: React.FC = ({ children }) => {
  return ENABLE_NEW_NAVIGATION ? (
    <Flex direction="column" height="100vh" width="100vw" bg="navyblue-600">
      <TopNavigation />
      <Flex height={`calc(100vh - ${HEADER_HEIGHT})`}>
        <SideNavigation />
        <Box as="main" flex="0 1 100%" overflow="hidden">
          <Flex direction="column" height="100%">
            <Box overflow="auto" flex="1 1 auto" px={4}>
              <Box width={1214} mx="auto">
                <Header />
                {children}
              </Box>
              <Box id="secondary-page-content" position="relative" />
            </Box>
            <Box id="footer" as="footer" width="100%" />
          </Flex>
        </Box>
      </Flex>
    </Flex>
  ) : (
    <Flex height="100vh" bg="navyblue-600" width="100vw">
      <Navigation />
      <Box as="main" flex="0 1 100%" bg="navyblue-600">
        <Flex direction="column" height="100vh">
          <Box overflow="auto" flex="1 1 auto" px={4}>
            <Box width={1214} mx="auto">
              <Header />
              {children}
            </Box>
            <Box id="secondary-page-content" position="relative" />
          </Box>
          <Box id="footer" as="footer" width="100%" />
        </Flex>
      </Box>
    </Flex>
  );
};

export default Layout;

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Link as RRLink } from 'react-router-dom';
import {
  Dropdown,
  DropdownButton,
  DropdownMenu,
  DropdownItem,
  DropdownLink,
  Box,
  Flex,
} from 'pouncejs';
import {
  S3LogIntegration,
  EventBridgeIntegration,
  LogPullingIntegration,
  SqsLogSourceIntegration,
  Permission,
  CloudWatchLogIntegration,
  GcsLogSourceIntegration,
} from 'Generated/schema';
import useModal from 'Hooks/useModal';
import useCopyToClipboard from 'Hooks/useCopyToClipboard';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import { checkIfSourceIsAuthorized } from 'Helpers/utils';
import urls from 'Source/urls';
import SetEventThresholdAlarmModal from 'Components/modals/SetEventThresholdAlarmModal';
import DeleteLogSourceModal from 'Components/modals/DeleteLogSourceModal';
import { LogTransportMethodsEnum } from 'Source/constants';
import OptionsButton from 'Components/buttons/OptionsButton';
import StackDeletionNotice from './StackDeletionNotice';

interface LogSourceCardOptionsProps {
  source:
    | S3LogIntegration
    | EventBridgeIntegration
    | LogPullingIntegration
    | SqsLogSourceIntegration
    | CloudWatchLogIntegration
    | GcsLogSourceIntegration;
}

const LogSourceCardOptions: React.FC<LogSourceCardOptionsProps> = ({ source }) => {
  const { showModal } = useModal();
  const copyToClipboard = useCopyToClipboard();
  let description: React.ReactNode;
  let castedSource: LogSourceCardOptionsProps['source'];
  let logSourceEditUrl: string;
  let authorizationUrl: string;
  let schemaManagementUrl: string;

  switch (source.integrationType) {
    case LogTransportMethodsEnum.eventbridge: {
      castedSource = source as EventBridgeIntegration;
      logSourceEditUrl = urls.integrations.logSources.edit(
        source.integrationId,
        LogTransportMethodsEnum.eventbridge
      );
      break;
    }
    case LogTransportMethodsEnum.logPulling: {
      castedSource = source as LogPullingIntegration;
      const { pullerType } = castedSource;
      logSourceEditUrl = urls.integrations.logSources.edit(castedSource.integrationId, pullerType);
      authorizationUrl =
        !checkIfSourceIsAuthorized(castedSource) &&
        urls.integrations.logSources.authorize(castedSource.integrationId, pullerType);
      break;
    }
    case LogTransportMethodsEnum.sqs: {
      castedSource = source as SqsLogSourceIntegration;
      logSourceEditUrl = urls.integrations.logSources.edit(
        source.integrationId,
        LogTransportMethodsEnum.sqs
      );
      break;
    }
    case LogTransportMethodsEnum.cloudwatch: {
      castedSource = source as CloudWatchLogIntegration;
      description = <StackDeletionNotice awsAccountId={castedSource.awsAccountId} />;
      logSourceEditUrl = urls.integrations.logSources.edit(
        source.integrationId,
        LogTransportMethodsEnum.cloudwatch
      );
      break;
    }
    case LogTransportMethodsEnum.gcs: {
      castedSource = source as GcsLogSourceIntegration;
      logSourceEditUrl = urls.integrations.logSources.edit(
        source.integrationId,
        LogTransportMethodsEnum.gcs
      );
      break;
    }
    case LogTransportMethodsEnum.s3:
    default: {
      castedSource = source as S3LogIntegration;
      description = <StackDeletionNotice awsAccountId={castedSource.awsAccountId} />;
      logSourceEditUrl = urls.integrations.logSources.edit(
        source.integrationId,
        LogTransportMethodsEnum.s3
      );
      schemaManagementUrl = urls.integrations.logSources
        .schemasManagement(source.integrationId)
        .home();
    }
  }

  return (
    <Dropdown>
      <DropdownButton as={OptionsButton} />
      <DropdownMenu minWidth="175px">
        <DropdownItem onSelect={() => copyToClipboard(source.integrationId)}>
          Copy Source ID
        </DropdownItem>
        <RoleRestrictedAccess allowedPermissions={[Permission.LogSourceModify]}>
          <DropdownItem
            onSelect={() => {
              return showModal(<SetEventThresholdAlarmModal logSource={castedSource} />, {
                title: 'Configure Event Threshold Alarm',
                showCloseButton: true,
              });
            }}
          >
            Alarm Configuration
          </DropdownItem>
          {source.isEditable && (
            <React.Fragment>
              {!!authorizationUrl && (
                <DropdownLink as={RRLink} to={authorizationUrl}>
                  Authorize
                </DropdownLink>
              )}
              {!!schemaManagementUrl && (
                <DropdownLink as={RRLink} to={schemaManagementUrl}>
                  Schema Configuration
                </DropdownLink>
              )}
              <DropdownLink as={RRLink} to={logSourceEditUrl}>
                Edit
              </DropdownLink>
              <DropdownItem
                onSelect={() => {
                  return showModal(
                    <DeleteLogSourceModal source={castedSource} description={description} />,
                    {
                      showCloseButton: true,
                      title: (
                        <Flex ml={8}>
                          Are you sure you want to delete{' '}
                          <Box as="b" ml={1}>
                            {source?.integrationLabel}
                          </Box>
                          ?
                        </Flex>
                      ),
                    }
                  );
                }}
              >
                Delete
              </DropdownItem>
            </React.Fragment>
          )}
        </RoleRestrictedAccess>
      </DropdownMenu>
    </Dropdown>
  );
};

export default React.memo(LogSourceCardOptions);

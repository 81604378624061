/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import urls from 'Source/urls';
import Overview from 'Pages/Dashboard/Overview';
import useRouter from 'Hooks/useRouter';
import useUrlParams from 'Hooks/useUrlParams';
import { activeDashboardUrls } from './constants';

const getRedirectRouteFromTab = (tab?: string): string => {
  switch (tab) {
    case 'cloudSecurity':
      return urls.dashboard.health();
    case 'data':
      return urls.dashboard.data();
    default:
      return urls.dashboard.overview();
  }
};

const cleanSearchParams = (search: string): string => {
  const searchParams = new URLSearchParams(search);

  if (searchParams.has('tab')) {
    searchParams.delete('tab');
  }
  return searchParams.toString();
};

const DashboardRoutes: React.FC = () => {
  const { location } = useRouter();
  const {
    urlParams: { tab },
  } = useUrlParams<{ tab?: string }>();

  return (
    <Switch>
      <Redirect
        exact
        from={urls.overview.home()}
        to={{
          pathname: getRedirectRouteFromTab(tab),
          search: tab ? cleanSearchParams(location.search) : location.search,
        }}
      />
      <Redirect exact from={urls.dashboard.home()} to={urls.dashboard.overview()} />
      <Route exact path={activeDashboardUrls} component={Overview} />
    </Switch>
  );
};

export default DashboardRoutes;

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { AbstractButton, Flex, IconButton } from 'pouncejs';
import { s3PrefixLogTypesInitialValues } from 'Pages/Integrations/LogSources/S3/S3LogSourceWizard';
import { S3PrefixLogTypesInput } from 'Generated/schema';
import usePrevious from 'Hooks/usePrevious';
import S3PrefixSchemaFields from './S3PrefixSchemaFields';

interface S3PrefixSchemaFieldsListProps {
  logTypes: string[];
}

export const S3PrefixSchemaFieldsList = ({ logTypes }: S3PrefixSchemaFieldsListProps) => {
  const { values, setFieldValue } = useFormikContext<{
    s3PrefixLogTypes: [S3PrefixLogTypesInput];
  }>();
  const ref = React.useRef(null);
  const prevLogTypes = usePrevious(values.s3PrefixLogTypes);

  React.useLayoutEffect(() => {
    if (
      ref.current?.scrollIntoView &&
      prevLogTypes &&
      prevLogTypes.length < values.s3PrefixLogTypes.length
    ) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [values.s3PrefixLogTypes.length, prevLogTypes]);

  return (
    <Flex direction="column" spacing={4}>
      <FieldArray
        name="s3PrefixLogTypes"
        render={arrayHelpers => {
          return values.s3PrefixLogTypes.map((_, index, array) => {
            return (
              <S3PrefixSchemaFields
                key={index}
                index={index}
                logTypes={logTypes}
                actions={
                  array.length > 1 ? (
                    <IconButton
                      size="medium"
                      icon="close-outline"
                      variantColor="navyblue-500"
                      variantBorderStyle="circle"
                      aria-label={`Remove prefix ${index}`}
                      onClick={() => arrayHelpers.remove(index)}
                    />
                  ) : null
                }
              />
            );
          });
        }}
      />
      <AbstractButton
        ref={ref}
        aria-label="Add prefix"
        color="blue-200"
        fontSize="small-medium"
        fontWeight="medium"
        mx={1}
        onClick={() =>
          setFieldValue(
            `s3PrefixLogTypes.${values.s3PrefixLogTypes.length}`,
            s3PrefixLogTypesInitialValues
          )
        }
      >
        Add another Prefix & Schemas combination
      </AbstractButton>
    </Flex>
  );
};

export default React.memo(S3PrefixSchemaFieldsList);

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, Heading, Card } from 'pouncejs';
import { ALERT_TYPE_COLOR_MAP } from 'Source/constants';
import SeverityBadge from 'Components/badges/SeverityBadge';
import { AlertOriginPolicy, AlertType } from 'Generated/schema';
import BulletedValue from 'Components/BulletedValue';
import UpdateAlertDropdown from 'Components/dropdowns/UpdateAlertDropdown';
import { AlertSummaryFull } from 'Source/graphql/fragments/AlertSummaryFull.generated';
import { AlertDetails } from 'Pages/AlertDetails';
import LimitItemDisplay from 'Components/LimitItemDisplay';
import { alertTypeToString } from 'Helpers/utils';

interface PolicyAlertDetailsBannerProps {
  alert: AlertDetails['alert'];
}

const PolicyAlertDetailsBanner: React.FC<PolicyAlertDetailsBannerProps> = ({ alert }) => {
  const policy = alert.origin as AlertOriginPolicy;
  return (
    <Card as="article" p={6}>
      <Flex as="header" align="center">
        <Heading fontWeight="bold" wordBreak="break-word" flexShrink={1} mr={100}>
          {alert.title || alert.id}
        </Heading>
        <Flex spacing={2} flexShrink={0} ml="auto">
          <Box>
            <UpdateAlertDropdown
              variant="outline"
              alert={alert as AlertSummaryFull}
              dropdownFlexDirection="row"
            />
          </Box>
        </Flex>
      </Flex>
      <Flex as="dl" fontSize="small-medium" pt={5} spacing={8} wrap="wrap" alignItems="center">
        <Box>
          <SeverityBadge severity={alert.severity} />
        </Box>
        <Flex>
          <Box color="navyblue-100" as="dt" pr={2}>
            Alert Type
          </Box>
          <Box as="dd" fontWeight="bold" color={ALERT_TYPE_COLOR_MAP[AlertType.Policy]}>
            {alertTypeToString(alert.type, { useSingular: true })}
          </Box>
        </Flex>
        <Flex>
          <Box color="navyblue-100" as="dt" pr={2}>
            Alert ID
          </Box>
          <Box as="dd" fontWeight="bold">
            {alert.id}
          </Box>
        </Flex>
        <Flex>
          <Box color="navyblue-100" as="dt" pr={2}>
            Resource Types
          </Box>
          <Flex as="dd" align="center" spacing={6}>
            {policy.resourceTypes.length ? (
              <LimitItemDisplay limit={3}>
                {policy.resourceTypes.map(resourceType => (
                  <BulletedValue key={resourceType} value={resourceType} />
                ))}
              </LimitItemDisplay>
            ) : (
              <Box as="span" fontSize="medium" value="All Resources" />
            )}
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};

export default PolicyAlertDetailsBanner;

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  AlertDestinationsRoutes,
  alertDestinationsUrls,
  CloudAccountsRoutes,
  cloudAccountsUrls,
  CloudResourcesRoutes,
  cloudResourcesUrls,
  EnrichmentProvidersRoutes,
  enrichmentProvidersRoutesUrls,
  LogSourcesRoutes,
  logSourcesUrls,
  LookupTablesRoutes,
  lookupTablesUrls,
  SchemasRoutes,
  schemasUrls,
} from '.';

const ConfigureRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path={alertDestinationsUrls} component={AlertDestinationsRoutes} />
      <Route exact path={cloudAccountsUrls} component={CloudAccountsRoutes} />
      <Route exact path={cloudResourcesUrls} component={CloudResourcesRoutes} />
      <Route exact path={enrichmentProvidersRoutesUrls} component={EnrichmentProvidersRoutes} />
      <Route exact path={logSourcesUrls} component={LogSourcesRoutes} />
      <Route exact path={lookupTablesUrls} component={LookupTablesRoutes} />
      <Route exact path={schemasUrls} component={SchemasRoutes} />
    </Switch>
  );
};

export default ConfigureRoutes;

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { Flex, Text } from 'pouncejs';
import LinkButton from 'Components/buttons/LinkButton';
import urls from 'Source/urls';
import React from 'react';
import {
  CloudWatchLogIntegration,
  EventBridgeIntegration,
  GcsLogSourceIntegration,
  LogPullingIntegration,
  S3LogIntegration,
  SqsLogSourceIntegration,
} from 'Generated/schema';
import { LogTransportMethodsEnum } from 'Source/constants';

interface AttachSchemaLinkProps {
  source:
    | S3LogIntegration
    | LogPullingIntegration
    | EventBridgeIntegration
    | CloudWatchLogIntegration
    | SqsLogSourceIntegration
    | GcsLogSourceIntegration;
}
const AttachSchemaLink: React.FC<AttachSchemaLinkProps> = ({ source }) => {
  return (
    <Flex spacing={4} align="center">
      <Text color="white" pt={1} fontSize="small">
        There is currently no Schema attached to this Source. <br />
        You can attach an existing schema or create a new one
      </Text>
      <LinkButton
        aria-label={`link to schemas tab on log source operations page for source ${source.integrationLabel}`}
        to={`${urls.integrations.logSources.details(
          source.integrationId,
          LogTransportMethodsEnum.s3
        )}?tab=schemas`}
        size="small"
      >
        Attach Schema
      </LinkButton>
    </Flex>
  );
};

export default AttachSchemaLink;

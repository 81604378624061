/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { AbstractButton, Box, Divider, Flex, Heading, SimpleGrid, Text } from 'pouncejs';
import ErrorBoundary from 'Components/ErrorBoundary';
import { FastField, Field, FormikErrors, useFormikContext } from 'formik';
import FormikTextInput from 'Components/fields/TextInput';
import { WizardPanel } from 'Components/Wizard';
import logo from 'Assets/logProviders/s3.svg';
import FormikRadio from 'Components/fields/Radio';
import { LogStreamTypeEnum } from 'Generated/schema';
import EditIntegrationActions from 'Pages/Integrations/components/EditIntegrationActions';
import useModal from 'Hooks/useModal';
import S3PrefixSchemaFieldsList from 'Components/forms/common/S3PrefixLogTypesFields';
import SetS3LogStreamTypeModal, {
  LogStreamTypePicker,
} from 'Components/modals/SetS3LogStreamTypeModal';
import { S3LogSourceWizardValues } from '../S3LogSourceWizard';
import { S3PrefixInformationModal } from './S3PrefixInformationModal';

const FormSection = ({
  title,
  description,
  children,
}: {
  title: string;
  description: string | React.ReactNode;
  children: React.ReactNode;
}) => (
  <Flex direction="column" spacing={4} mb={2}>
    <Heading as="h2" color="teal-200" fontSize="x-large" fontWeight="medium">
      {title}
    </Heading>
    <Text color="white-100" fontSize="medium">
      {description}
    </Text>
    {children}
    <Divider width={1} mx="auto" my={4} color="navyblue-300" />
  </Flex>
);

export const s3PrefixLogTypesInitialValues = {
  prefix: '',
  excludedPrefixes: [],
  logTypes: [],
};

const canContinueWizard = (errors: FormikErrors<S3LogSourceWizardValues>) => {
  if (
    errors.integrationLabel ||
    errors.awsAccountId ||
    errors.s3Bucket ||
    errors.logStreamType ||
    errors.kmsKey ||
    (Array.isArray(errors?.s3PrefixLogTypes) &&
      errors.s3PrefixLogTypes.some(
        s3PrefixLogTypesObject =>
          s3PrefixLogTypesObject?.logTypes ||
          s3PrefixLogTypesObject?.prefix ||
          s3PrefixLogTypesObject?.excludedPrefixes
      ))
  ) {
    return false;
  }
  return true;
};

const S3SourceConfigurationPanel: React.FC = () => {
  const { initialValues, values, dirty, errors, status, setFieldValue } = useFormikContext<
    S3LogSourceWizardValues
  >();
  const { showModal } = useModal();
  const editMode = !!initialValues.integrationId;
  const handleLogStreamTypeChange = React.useCallback(
    (value: LogStreamTypeEnum) => setFieldValue('logStreamType', value),
    [setFieldValue]
  );

  return (
    <WizardPanel>
      <Box width={655} m="auto">
        <WizardPanel.Heading
          title={editMode ? 'Update your source' : 'Configure your source'}
          subtitle={
            editMode
              ? 'Feel free to make any changes to your log source'
              : 'We need to know where to get your logs from'
          }
          logo={logo}
          logoProps={{
            nativeWidth: 38,
            nativeHeight: 38,
          }}
          divider={null}
          subtitleProps={{ color: 'gray-300', fontWeight: 'normal' }}
        />

        <ErrorBoundary>
          <FormSection
            title="Basic Information"
            description="Fill in the name which you want your source to have in panther and where your logs are going to be imported from"
          >
            <Field
              name="integrationLabel"
              as={FormikTextInput}
              label="Source Name"
              placeholder="A nickname for your AWS S3 source"
              required
            />
            <SimpleGrid columns={2} spacing={4}>
              <Field
                name="awsAccountId"
                as={FormikTextInput}
                label="AWS Account ID"
                placeholder="Account ID that the S3 log bucket lives in"
                disabled={editMode}
                required
              />
              <Field
                name="s3Bucket"
                as={FormikTextInput}
                label="AWS Bucket Name"
                placeholder="The S3 bucket that holds the logs"
                disabled={editMode}
                required
              />
            </SimpleGrid>
            <Field
              name="kmsKey"
              as={FormikTextInput}
              label="KMS Key (optional)"
              placeholder="For encrypted logs, add the KMS ARN for decryption"
            />
          </FormSection>
          <FormSection
            title="Logs Stream Type"
            description={
              <Text as="span">
                Select the format of the logs this source is going to receive. If you’re not sure,
                you can leave it as is (Lines). If incorrect, Panther will trigger a system error
                alert and prompt you to fix.
                <AbstractButton
                  aria-label="Toggle log stream type example"
                  color="blue-200"
                  fontWeight="medium"
                  mx={1}
                  onClick={() =>
                    showModal(
                      <SetS3LogStreamTypeModal
                        onStreamTypeChange={handleLogStreamTypeChange}
                        streamType={values.logStreamType}
                      />,
                      {
                        showCloseButton: true,
                        title: 'Logs Stream Type examples',
                      }
                    )
                  }
                >
                  See examples
                </AbstractButton>
              </Text>
            }
          >
            <LogStreamTypePicker
              value={values.logStreamType}
              onChange={handleLogStreamTypeChange}
            />
          </FormSection>
          <FormSection
            title="S3 Prefixes & Schemas - Optional"
            description={
              <Text as="span">
                If you’ve already created the schemas that will parse data from this source and you
                already know the S3 prefix to read data from, you can define that here. You can also
                skip this part and define them after you’ve onboarded the source.
                <AbstractButton
                  aria-label="Learn more about S3 Prefixes and Schemas"
                  color="blue-200"
                  fontWeight="medium"
                  mx={1}
                  onClick={() =>
                    showModal(<S3PrefixInformationModal />, {
                      showCloseButton: true,
                      title: 'S3 Prefixes & Schemas options',
                    })
                  }
                >
                  See all options
                </AbstractButton>
              </Text>
            }
          >
            <Flex direction="column" spacing={4}>
              <Flex spacing={4}>
                <FastField
                  as={FormikRadio}
                  name="logTypesAttached"
                  value="later"
                  label="Add prefix(es) & Schema(s) later"
                />
                <FastField
                  as={FormikRadio}
                  name="logTypesAttached"
                  value="now"
                  label="Add prefix(es) and/or schemas now"
                />
              </Flex>
              {values.logTypesAttached === 'now' && (
                <S3PrefixSchemaFieldsList logTypes={status.availableLogTypes} />
              )}
            </Flex>
          </FormSection>
        </ErrorBoundary>
      </Box>
      {editMode ? (
        <EditIntegrationActions />
      ) : (
        <WizardPanel.Actions>
          <WizardPanel.ActionNext disabled={!dirty || !canContinueWizard(errors)}>
            Continue Setup
          </WizardPanel.ActionNext>
        </WizardPanel.Actions>
      )}
    </WizardPanel>
  );
};

export default S3SourceConfigurationPanel;

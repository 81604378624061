/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Card, Flex, Heading, Text, Link, Img } from 'pouncejs';
import { IS_SNOWFLAKE } from 'Source/constants';
import ErrorBoundary from 'Components/ErrorBoundary';
import SnowflakeLogo from 'Assets/snowflake-logo.svg';
import TableList from './TableList';
import DatabaseSelector from './DatabaseSelector';
import { useDataExplorerContext } from '../DataExplorerContext';
import ColumnList from './ColumnList';
import Search from './Search';

const Browser: React.FC = () => {
  const {
    state: { selectedDatabase, selectedTable },
  } = useDataExplorerContext();

  return (
    <Card as="aside" overflow="hidden" position="relative">
      <ErrorBoundary>
        <Flex direction="column" height="100%">
          <Box as="header" px={6} py={5}>
            <Heading size="x-small" mb={4}>
              Select Database
            </Heading>
            <Box mb={6} as="section">
              <DatabaseSelector />
            </Box>
            <Box as="section">
              {!!selectedDatabase && (
                <Heading size="x-small" mb={3}>
                  {selectedTable ? 'Columns' : 'Tables'}
                </Heading>
              )}
              <Search />
            </Box>
          </Box>
          <Box
            flexGrow={1}
            overflowY="hidden"
            width="100%"
            height="100%"
            pt={2}
            pb={IS_SNOWFLAKE ? 40 : 2}
            // @ts-ignore We need a custom color here
            backgroundColor="#2c3c57"
            fontSize="small-medium"
          >
            {!selectedDatabase && (
              <Text color="gray-300" textAlign="center" mt={100}>
                Nothing selected yet
              </Text>
            )}
            {!!selectedDatabase && !selectedTable && (
              <ErrorBoundary>
                <TableList />
              </ErrorBoundary>
            )}
            {!!selectedDatabase && selectedTable && (
              <ErrorBoundary>
                <ColumnList />
              </ErrorBoundary>
            )}
          </Box>
          {IS_SNOWFLAKE && (
            <Box position="absolute" bottom={0} width={1} height={40}>
              <Link external href={'https://docs.snowflake.com/en/sql-reference/constructs.html'}>
                <Flex
                  backgroundColor="navyblue-500"
                  width="100%"
                  height="100%"
                  justify="center"
                  align="center"
                >
                  <Text fontSize="x-small" fontWeight="bold" color="navyblue-200" mr={2}>
                    Powered by
                  </Text>
                  <Img alt="Snowflake DB" nativeHeight={17} src={SnowflakeLogo} />
                </Flex>
              </Link>
            </Box>
          )}
        </Flex>
      </ErrorBoundary>
    </Card>
  );
};

export default Browser;

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../__generated__/schema';

import { AlertDetailsFull } from '../../../../../graphql/fragments/AlertDetailsFull.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetNonDeliveredAlertsVariables = {
  id: Types.Scalars['ID'];
  input?: Types.Maybe<Types.GetRelatedAlertsInput>;
};

export type GetNonDeliveredAlerts = {
  alert: Pick<Types.Alert, 'id'> & {
    origin: {
      alerts: {
        edges: Array<{
          node: {
            origin:
              | Pick<
                  Types.AlertOriginRule,
                  'ruleId' | 'logTypes' | 'scheduledQueries' | 'eventsMatched' | 'dedupString'
                >
              | Pick<
                  Types.AlertOriginPolicy,
                  'policyId' | 'resourceTypes' | 'resourceId' | 'policySourceId' | 'dedupString'
                >
              | (Pick<Types.AlertOriginSystemError, 'type' | 'relatedComponent'> & {
                  relatedEntity?: Types.Maybe<
                    | (Pick<Types.S3LogIntegration, 'integrationId'> & {
                        alarms: {
                          eventThreshold?: Types.Maybe<
                            Pick<Types.EventThresholdAlarm, 'minutesThreshold'>
                          >;
                        };
                      })
                    | (Pick<Types.EventBridgeIntegration, 'integrationId'> & {
                        alarms: {
                          eventThreshold?: Types.Maybe<
                            Pick<Types.EventThresholdAlarm, 'minutesThreshold'>
                          >;
                        };
                      })
                    | (Pick<Types.LogPullingIntegration, 'integrationId'> & {
                        alarms: {
                          eventThreshold?: Types.Maybe<
                            Pick<Types.EventThresholdAlarm, 'minutesThreshold'>
                          >;
                        };
                      })
                    | (Pick<Types.SqsLogSourceIntegration, 'integrationId'> & {
                        alarms: {
                          eventThreshold?: Types.Maybe<
                            Pick<Types.EventThresholdAlarm, 'minutesThreshold'>
                          >;
                        };
                      })
                    | (Pick<Types.CloudWatchLogIntegration, 'integrationId'> & {
                        alarms: {
                          eventThreshold?: Types.Maybe<
                            Pick<Types.EventThresholdAlarm, 'minutesThreshold'>
                          >;
                        };
                      })
                    | Pick<Types.Destination, 'outputId' | 'displayName'>
                  >;
                });
          } & AlertDetailsFull;
        }>;
        pageInfo: Pick<Types.RelatedAlertsOutputPageInfo, 'endCursor' | 'hasNextPage'>;
      };
    };
  };
};

export const GetNonDeliveredAlertsDocument = gql`
  query GetNonDeliveredAlerts($id: ID!, $input: GetRelatedAlertsInput) {
    alert(id: $id) {
      id
      origin {
        ... on AlertOriginSystemError {
          alerts(input: $input) {
            edges {
              node {
                ...AlertDetailsFull
                origin {
                  ... on AlertOriginPolicy {
                    policyId
                    resourceTypes
                    resourceId
                    policySourceId
                    dedupString
                  }
                  ... on AlertOriginRule {
                    ruleId
                    logTypes
                    scheduledQueries
                    eventsMatched
                    dedupString
                  }
                  ... on AlertOriginSystemError {
                    type
                    relatedComponent
                    relatedEntity {
                      ... on S3LogIntegration {
                        integrationId
                        alarms {
                          eventThreshold {
                            minutesThreshold
                          }
                        }
                      }
                      ... on EventBridgeIntegration {
                        integrationId
                        alarms {
                          eventThreshold {
                            minutesThreshold
                          }
                        }
                      }
                      ... on LogPullingIntegration {
                        integrationId
                        alarms {
                          eventThreshold {
                            minutesThreshold
                          }
                        }
                      }
                      ... on SqsLogSourceIntegration {
                        integrationId
                        alarms {
                          eventThreshold {
                            minutesThreshold
                          }
                        }
                      }
                      ... on CloudWatchLogIntegration {
                        integrationId
                        alarms {
                          eventThreshold {
                            minutesThreshold
                          }
                        }
                      }
                      ... on Destination {
                        outputId
                        displayName
                      }
                    }
                  }
                }
              }
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          }
        }
      }
    }
  }
  ${AlertDetailsFull}
`;

/**
 * __useGetNonDeliveredAlerts__
 *
 * To run a query within a React component, call `useGetNonDeliveredAlerts` and pass it any options that fit your needs.
 * When your component renders, `useGetNonDeliveredAlerts` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNonDeliveredAlerts({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetNonDeliveredAlerts(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetNonDeliveredAlerts,
    GetNonDeliveredAlertsVariables
  >
) {
  return ApolloReactHooks.useQuery<GetNonDeliveredAlerts, GetNonDeliveredAlertsVariables>(
    GetNonDeliveredAlertsDocument,
    baseOptions
  );
}
export function useGetNonDeliveredAlertsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetNonDeliveredAlerts,
    GetNonDeliveredAlertsVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetNonDeliveredAlerts, GetNonDeliveredAlertsVariables>(
    GetNonDeliveredAlertsDocument,
    baseOptions
  );
}
export type GetNonDeliveredAlertsHookResult = ReturnType<typeof useGetNonDeliveredAlerts>;
export type GetNonDeliveredAlertsLazyQueryHookResult = ReturnType<
  typeof useGetNonDeliveredAlertsLazyQuery
>;
export type GetNonDeliveredAlertsQueryResult = ApolloReactCommon.QueryResult<
  GetNonDeliveredAlerts,
  GetNonDeliveredAlertsVariables
>;
export function mockGetNonDeliveredAlerts({
  data,
  variables,
  errors,
}: {
  data: GetNonDeliveredAlerts;
  variables?: GetNonDeliveredAlertsVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetNonDeliveredAlertsDocument, variables },
    result: { data, errors },
  };
}

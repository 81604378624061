/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Alert, Box, Flex } from 'pouncejs';
import { Permission, DataModel, ListDataModelsInput } from 'Generated/schema';
import Panel from 'Components/Panel';
import ErrorBoundary from 'Components/ErrorBoundary';
import { SelectAllCheckbox, useSelect, withSelectContext } from 'Components/utils/SelectContext';
import { TableControlsPagination } from 'Components/utils/TableControls';
import { extractErrorMessage } from 'Helpers/utils';
import withSEO from 'Hoc/withSEO';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import Page403 from 'Pages/403';
import useRequestParamsWithPagination from 'Hooks/useRequestParamsWithPagination';
import { compose } from 'Helpers/compose';
import { useListDataModels } from 'Source/graphql/queries';
import useOverrideShiftClickSelection from 'Hooks/useOverrideShiftClickSelection';
import EmptyDataFallback from './EmptyDataFallback';
import DataModelCard from './DataModelCard';
import ListDataModelsSkeleton from './Skeleton';
import ListDataModelActions from './ListDataModelActions';

const ListDataModels = () => {
  useOverrideShiftClickSelection();
  const { requestParams, updatePagingParams } = useRequestParamsWithPagination<ListDataModelsInput>(
    { overrides: { nameContains: 'string' } }
  );
  const { checkIfSelected, setAllSelectables } = useSelect<DataModel>();

  const { loading, error, previousData, data = previousData } = useListDataModels({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      input: requestParams,
    },
  });

  const dataModels = React.useMemo(() => {
    return data?.listDataModels?.models || [];
  }, [data]);

  const pagingData = data?.listDataModels?.paging;

  React.useEffect(() => {
    setAllSelectables(dataModels);
  }, [dataModels, setAllSelectables]);

  if (loading && !data) {
    return <ListDataModelsSkeleton />;
  }

  return (
    <Box mb={6}>
      <ErrorBoundary>
        <Panel
          data-tracking-page="list-data-models"
          title={
            <Flex align="center" spacing={3} ml={1}>
              {dataModels.length > 0 && (
                <SelectAllCheckbox data-tid="data-models-select-all" selectionItems={dataModels} />
              )}
              <Box as="span">Data Models</Box>
            </Flex>
          }
          actions={<ListDataModelActions />}
        >
          {error && (
            <Alert
              variant="error"
              title="Couldn't load your data models"
              description={
                extractErrorMessage(error) ||
                'There was an error while attempting to list your data models'
              }
            />
          )}
          {dataModels.length > 0 ? (
            <Flex direction="column" spacing={2}>
              {dataModels.map(dataModel => (
                <DataModelCard
                  isSelected={checkIfSelected(dataModel)}
                  key={dataModel.id}
                  dataModel={dataModel}
                  selectionEnabled
                />
              ))}
            </Flex>
          ) : (
            <EmptyDataFallback />
          )}
        </Panel>
        {dataModels.length > 0 && (
          <Box my={5}>
            <TableControlsPagination
              page={pagingData.thisPage}
              totalPages={pagingData.totalPages}
              onPageChange={updatePagingParams}
            />
          </Box>
        )}
      </ErrorBoundary>
    </Box>
  );
};

export default compose(
  withSEO({ title: 'Data Models' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LogSourceRead, Permission.LogSourceModify],
    fallback: <Page403 />,
  }),
  withSelectContext({ getItemKey: (item: DataModel) => item.id })
)(ListDataModels);

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../__generated__/schema';

import { AlertSummaryFull } from '../fragments/AlertSummaryFull.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type UpdateAlertsAssigneeByIdVariables = {
  input: Types.UpdateAlertsAssigneeByIdInput;
};

export type UpdateAlertsAssigneeById = {
  updateAlertsAssigneeById: { alerts: Array<AlertSummaryFull> };
};

export const UpdateAlertsAssigneeByIdDocument = gql`
  mutation UpdateAlertsAssigneeById($input: UpdateAlertsAssigneeByIdInput!) {
    updateAlertsAssigneeById(input: $input) {
      alerts {
        ...AlertSummaryFull
      }
    }
  }
  ${AlertSummaryFull}
`;
export type UpdateAlertsAssigneeByIdMutationFn = ApolloReactCommon.MutationFunction<
  UpdateAlertsAssigneeById,
  UpdateAlertsAssigneeByIdVariables
>;

/**
 * __useUpdateAlertsAssigneeById__
 *
 * To run a mutation, you first call `useUpdateAlertsAssigneeById` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAlertsAssigneeById` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAlertsAssigneeById, { data, loading, error }] = useUpdateAlertsAssigneeById({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAlertsAssigneeById(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateAlertsAssigneeById,
    UpdateAlertsAssigneeByIdVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateAlertsAssigneeById, UpdateAlertsAssigneeByIdVariables>(
    UpdateAlertsAssigneeByIdDocument,
    baseOptions
  );
}
export type UpdateAlertsAssigneeByIdHookResult = ReturnType<typeof useUpdateAlertsAssigneeById>;
export type UpdateAlertsAssigneeByIdMutationResult = ApolloReactCommon.MutationResult<
  UpdateAlertsAssigneeById
>;
export type UpdateAlertsAssigneeByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateAlertsAssigneeById,
  UpdateAlertsAssigneeByIdVariables
>;
export function mockUpdateAlertsAssigneeById({
  data,
  variables,
  errors,
}: {
  data: UpdateAlertsAssigneeById;
  variables?: UpdateAlertsAssigneeByIdVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: UpdateAlertsAssigneeByIdDocument, variables },
    result: { data, errors },
  };
}

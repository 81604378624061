/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, Heading, Card, Text } from 'pouncejs';
import { AlertType, AlertOriginRule } from 'Generated/schema';
import SeverityBadge from 'Components/badges/SeverityBadge';
import BulletedValue from 'Components/BulletedValue';
import UpdateAlertDropdown from 'Components/dropdowns/UpdateAlertDropdown';
import { AlertSummaryFull } from 'Source/graphql/fragments/AlertSummaryFull.generated';
import { AlertDetails } from 'Pages/AlertDetails';
import LimitItemDisplay from 'Components/LimitItemDisplay';
import { alertTypeToString } from 'Helpers/utils';
import { ALERT_TYPE_COLOR_MAP } from 'Source/constants';

interface RuleAlertDetailsBannerProps {
  alert: AlertDetails['alert'];
  disableUpdateAlert?: boolean;
}

const RuleAlertDetailsBanner: React.FC<RuleAlertDetailsBannerProps> = ({
  alert,
  disableUpdateAlert = false,
}) => {
  const alertTitle = alertTypeToString(alert.type, { useSingular: true });

  const originData = alert.origin as AlertOriginRule;
  const isScheduled = [AlertType.ScheduledRule, AlertType.ScheduledRuleError].includes(alert.type);

  return (
    <Card as="article" p={6}>
      <Flex as="header" align="center">
        <Heading fontWeight="bold" wordBreak="break-word" flexShrink={1} mr={100}>
          {alert.title || alert.id}
        </Heading>
        <Flex spacing={2} as="ul" flexShrink={0} ml="auto">
          {!disableUpdateAlert && (
            <Box as="li">
              <UpdateAlertDropdown
                variant="outline"
                alert={alert as AlertSummaryFull}
                dropdownFlexDirection="row"
              />
            </Box>
          )}
        </Flex>
      </Flex>
      <Flex as="dl" fontSize="small-medium" pt={5} spacing={8} wrap="wrap" alignItems="center">
        <Box>
          <SeverityBadge severity={alert.severity} />
        </Box>
        <Flex>
          <Box color="navyblue-100" as="dt" pr={2}>
            Alert Type
          </Box>
          <Box as="dd" fontWeight="bold" color={ALERT_TYPE_COLOR_MAP[alert.type]}>
            {alertTitle}
          </Box>
        </Flex>
        <Flex>
          <Box color="navyblue-100" as="dt" pr={2}>
            Alert ID
          </Box>
          <Box as="dd" fontWeight="bold">
            {alert.id}
          </Box>
        </Flex>
        {isScheduled ? (
          <Flex>
            <Box color="navyblue-100" as="dt" pr={2}>
              Scheduled Queries
            </Box>
            <Flex as="dd" align="center" spacing={3}>
              <LimitItemDisplay limit={1}>
                {originData.scheduledQueries.map(queryName => (
                  <Text fontSize="medium" fontWeight="bold" key={queryName}>
                    {queryName}
                  </Text>
                ))}
              </LimitItemDisplay>
            </Flex>
          </Flex>
        ) : (
          <Flex>
            <Box color="navyblue-100" as="dt" pr={2}>
              Log Types
            </Box>
            <Flex as="dd" align="center" spacing={6}>
              <LimitItemDisplay limit={3}>
                {originData.logTypes.map(logType => (
                  <BulletedValue key={logType} value={logType} />
                ))}
              </LimitItemDisplay>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Card>
  );
};

export default RuleAlertDetailsBanner;

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import {
  Dropdown,
  DropdownLink,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
  Flex,
  Box,
} from 'pouncejs';
import { Link as RRLink } from 'react-router-dom';
import useModal from 'Hooks/useModal';
import urls from 'Source/urls';
import DeleteLogSourceModal from 'Components/modals/DeleteLogSourceModal';
import useRouter from 'Hooks/useRouter';
import { LogSourceType } from 'Source/constants';
import { LogIntegration } from 'Generated/schema';
import OptionsButton from 'Components/buttons/OptionsButton';

interface LogSourceDetailsOptionsProps {
  source: Pick<LogIntegration, 'integrationId' | 'integrationLabel'>;
}

const LogSourceDetailsOptions: React.FC<LogSourceDetailsOptionsProps> = ({ source }) => {
  const { showModal } = useModal();
  const { history, match } = useRouter();

  return (
    <Dropdown>
      <DropdownButton
        data-tid="log-source-options-dropdown"
        as={OptionsButton}
        icon="settings-alt"
        size="large"
        variant="solid"
        variantBorderStyle="square"
      />
      <DropdownMenu>
        <DropdownLink
          as={RRLink}
          to={urls.integrations.logSources.edit(
            source.integrationId,
            match.params.type as LogSourceType
          )}
          data-tid="edit-role"
        >
          Edit Log Source
        </DropdownLink>
        <DropdownItem
          onSelect={() => {
            return showModal(
              <DeleteLogSourceModal
                source={source}
                onSuccess={() => history.push(urls.integrations.logSources.list())}
              />,
              {
                showCloseButton: true,
                title: (
                  <Flex ml={8}>
                    Are you sure you want to delete{' '}
                    <Box as="b" ml={1}>
                      {source?.integrationLabel}
                    </Box>
                    ?
                  </Flex>
                ),
              }
            );
          }}
        >
          Delete Log Source
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default React.memo(LogSourceDetailsOptions);

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { FlexProps, Link } from 'pouncejs';
import React from 'react';
import NavLinkContent from './NavLinkContent';
import { NavLinkBaseProps } from './NavLink';

type NavLinkExternalProps = NavLinkBaseProps & {
  href: string;
};

const NavLinkExternal = ({
  icon,
  label,
  href,
  isSecondary,
  ...flexProps
}: NavLinkExternalProps & FlexProps) => {
  return (
    <Link external href={href}>
      <NavLinkContent icon={icon} label={label} isSecondary={isSecondary} {...flexProps} />
    </Link>
  );
};

export default NavLinkExternal;

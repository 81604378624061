/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useListUsers } from 'Source/graphql/queries/listUsers.generated';
import { Permission } from 'Generated/schema';
import keyBy from 'lodash/keyBy';

/**
 * Only users with `AlertModify` should be able to have an alert assigned to them
 */
const useAlertAssignableUsers = () => {
  const { data, loading } = useListUsers();
  const assignableUsers = React.useMemo(() => {
    const assignableUserList =
      data?.users.filter(user => user.role.permissions.includes(Permission.AlertModify)) ?? [];

    return assignableUserList;
  }, [data]);

  const assignableUserIds = React.useMemo(() => assignableUsers?.map(user => user.id), [
    assignableUsers,
  ]);

  const userMap = React.useMemo(() => keyBy(assignableUsers, 'id'), [assignableUsers]);
  return { assignableUsers, userMap, assignableUserIds, loadingUsers: loading };
};

export default useAlertAssignableUsers;

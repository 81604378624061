/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import useRouter from 'Hooks/useRouter';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import urls from 'Source/urls';
import {
  CreateComplianceSource,
  EditComplianceSource,
  ListComplianceSources,
} from 'Pages/Integrations/CloudAccounts';

const CloudAccountsRoutes: React.FC = () => {
  const { location } = useRouter();

  return (
    <Switch>
      <Redirect
        exact
        from={urls.integrations.cloudAccounts.list()}
        to={{ pathname: urls.configure.cloudAccounts.list(), search: location.search }}
      />
      <Route exact path={urls.configure.cloudAccounts.list()} component={ListComplianceSources} />

      <Redirect
        exact
        from={urls.integrations.cloudAccounts.create()}
        to={urls.configure.cloudAccounts.create()}
      />
      <Route
        exact
        path={urls.configure.cloudAccounts.create()}
        component={CreateComplianceSource}
      />

      <Redirect
        exact
        from={urls.integrations.cloudAccounts.edit(':id')}
        to={urls.configure.cloudAccounts.edit(':id')}
      />
      <Redirect
        exact
        from={`${urls.integrations.cloudAccounts.list()}:id`}
        to={urls.configure.cloudAccounts.edit(':id')}
      />
      <Redirect
        exact
        from={`${urls.configure.cloudAccounts.list()}:id`}
        to={urls.configure.cloudAccounts.edit(':id')}
      />
      <Route
        exact
        path={urls.configure.cloudAccounts.edit(':id')}
        component={EditComplianceSource}
      />
    </Switch>
  );
};

export default CloudAccountsRoutes;

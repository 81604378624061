/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Alert, Box, Card, Flex } from 'pouncejs';
import { extractErrorMessage } from 'Helpers/utils';
import { DetectionTypeEnum } from 'Generated/schema';
import { TableControlsPagination } from 'Components/utils/TableControls';
import useRequestParamsWithPagination from 'Hooks/useRequestParamsWithPagination';
import ErrorBoundary from 'Components/ErrorBoundary';
import RuleCard from 'Components/cards/RuleCard';
import { RuleSummary } from 'Source/graphql/fragments/RuleSummary.generated';
import { DEFAULT_SMALL_PAGE_SIZE } from 'Source/constants';
import useRouter from 'Hooks/useRouter';
import { useListDetections } from 'Source/graphql/queries';
import EmptyDataFallback from '../EmptyDataFallback';
import ItemsSkeleton from '../ItemsSkeleton';

const ListPackScheduledRules: React.FC = () => {
  const { match } = useRouter<{ id: string }>();
  const {
    requestParams: { page },
    updatePagingParams,
  } = useRequestParamsWithPagination();

  const { loading, error, previousData, data = previousData } = useListDetections({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      input: {
        page,
        packIds: [match.params.id],
        pageSize: DEFAULT_SMALL_PAGE_SIZE,
        analysisTypes: [DetectionTypeEnum.ScheduledRule],
      },
    },
  });

  if (loading && !data) {
    return <ItemsSkeleton />;
  }

  if (error) {
    return (
      <Box mb={6}>
        <Alert
          variant="error"
          title="Couldn't load your scheduled rules"
          description={
            extractErrorMessage(error) ||
            'There was an error when performing your request, please contact support@runpanther.io'
          }
        />
      </Box>
    );
  }

  // Get query results while protecting against exceptions
  const detectionItems = data.detections.detections;
  const pagingData = data.detections.paging;

  if (!detectionItems.length) {
    return <EmptyDataFallback message="No Scheduled Rules on Pack" />;
  }

  //  Check how many active filters exist by checking how many columns keys exist in the URL
  return (
    <ErrorBoundary>
      <Card as="section" position="relative" p={4}>
        <Box position="relative">
          <Flex direction="column" spacing={2}>
            {detectionItems.map(detection => (
              <RuleCard key={detection.id} rule={detection as RuleSummary} />
            ))}
          </Flex>
        </Box>
      </Card>
      <Box my={5}>
        <TableControlsPagination
          page={pagingData.thisPage}
          totalPages={pagingData.totalPages}
          onPageChange={updatePagingParams}
        />
      </Box>
    </ErrorBoundary>
  );
};

export default ListPackScheduledRules;

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, Card } from 'pouncejs';
import ErrorBoundary from 'Components/ErrorBoundary';
import { Field, useFormikContext, FastField } from 'formik';
import SensitiveTextInput from 'Components/fields/SensitiveTextInput';
import { WizardPanel } from 'Components/Wizard';
import EditIntegrationActions from 'Pages/Integrations/components/EditIntegrationActions';
import FormikTextInput from 'Components/fields/TextInput';
import { WorkdayMethodEnum } from 'Source/constants';
import FormikRadio from 'Components/fields/Radio';
import { WorkdayLogsSourceWizardValues } from './WorkdayLogSourceWizard';

const CredentialsPanel = () => {
  const { initialValues, values, dirty, isValid } = useFormikContext<
    WorkdayLogsSourceWizardValues
  >();

  const isActivityMethod = values.workdayMethod === WorkdayMethodEnum.Activity;
  const isSignOnAttemptMethod = values.workdayMethod === WorkdayMethodEnum.SignOnAttempt;
  const editMode = !!initialValues.integrationId;

  return (
    <WizardPanel>
      <Box width={660} m="auto">
        <WizardPanel.Heading title="Fill in the form below with your credentials" divider={null} />
        <ErrorBoundary>
          <Flex flexDirection="column" spacing={4}>
            <Card variant="dark" p={4}>
              <FastField
                as={FormikRadio}
                name="workdayMethod"
                value={WorkdayMethodEnum.Activity}
                label="Activity Logs"
                aria-describedby="activity-logs"
              />
              {isActivityMethod && (
                <Flex flexDirection="column" spacing={4} mx={44} my={5}>
                  <Field
                    name="activityClientId"
                    label="Client Id"
                    as={FormikTextInput}
                    placeholder="Your Workday Client Id"
                  />
                  <Field
                    name="activityClientSecret"
                    label="Client Secret"
                    shouldMask={editMode}
                    as={SensitiveTextInput}
                    placeholder={
                      editMode
                        ? 'Information is hidden. New values will override the existing.'
                        : 'Your Workday Client Secret'
                    }
                  />
                  <Field
                    name="activityRefreshToken"
                    label="Refresh Token"
                    shouldMask={editMode}
                    as={SensitiveTextInput}
                    placeholder={
                      editMode
                        ? 'Information is hidden. New values will override the existing.'
                        : 'Your Workday Refresh Token'
                    }
                  />
                </Flex>
              )}
            </Card>
            <Card variant="dark" p={4}>
              <FastField
                as={FormikRadio}
                name="workdayMethod"
                value={WorkdayMethodEnum.SignOnAttempt}
                label="Sign On Attempt Logs"
                aria-describedby="activity-logs"
              />
              {isSignOnAttemptMethod && (
                <Flex flexDirection="column" spacing={4} mx={44} my={5}>
                  <Field
                    name="signOnCustomReport"
                    label="Sign On Custom Report"
                    as={FormikTextInput}
                    placeholder="Your Workday Sign On Custom Report"
                  />
                  <Field
                    name="signOnAccountName"
                    label="Sign On Account Name"
                    as={FormikTextInput}
                    placeholder="Your Workday Sign On Account Name"
                  />
                  <Field
                    name="integrationUsername"
                    label="Integration Username"
                    as={FormikTextInput}
                    placeholder="Your Workday Integration Username"
                  />
                  <Field
                    name="integrationPassword"
                    label="Integration Password"
                    shouldMask={editMode}
                    as={SensitiveTextInput}
                    placeholder={
                      editMode
                        ? 'Information is hidden. New values will override the existing.'
                        : 'Your Workday Integration Password'
                    }
                  />
                </Flex>
              )}
            </Card>
          </Flex>
        </ErrorBoundary>
      </Box>
      {editMode ? (
        <EditIntegrationActions />
      ) : (
        <WizardPanel.Actions>
          <WizardPanel.ActionNext disabled={!dirty || !isValid}>
            Continue Setup
          </WizardPanel.ActionNext>
        </WizardPanel.Actions>
      )}
    </WizardPanel>
  );
};

export default CredentialsPanel;

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import useRouter from 'Hooks/useRouter';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import urls from 'Source/urls';
import CreateLookupPage from 'Pages/Enrichment/Lookups/CreateLookup';
import EditLookupPage from 'Pages/Enrichment/Lookups/EditLookup';
import ListLookupsPage from 'Pages/Enrichment/Lookups/ListLookups';

const LookupTablesRoutes: React.FC = () => {
  const { location } = useRouter();

  return (
    <Switch>
      <Redirect
        exact
        from={urls.enrichment.home()}
        to={{ pathname: urls.configure.lookupTables.list(), search: location.search }}
      />
      <Redirect
        exact
        from={urls.enrichment.lookupTables.list()}
        to={{ pathname: urls.configure.lookupTables.list(), search: location.search }}
      />
      <Route exact path={urls.configure.lookupTables.list()} component={ListLookupsPage} />

      <Redirect
        exact
        from={urls.enrichment.lookupTables.create()}
        to={urls.configure.lookupTables.create()}
      />
      <Route exact path={urls.configure.lookupTables.create()} component={CreateLookupPage} />

      <Redirect
        exact
        from={urls.enrichment.lookupTables.edit(':id')}
        to={urls.configure.lookupTables.edit(':id')}
      />
      <Redirect
        exact
        from={`${urls.enrichment.lookupTables.list()}:id`}
        to={urls.configure.lookupTables.edit(':id')}
      />
      <Redirect
        exact
        from={`${urls.configure.lookupTables.list()}:id`}
        to={urls.configure.lookupTables.edit(':id')}
      />
      <Route exact path={urls.configure.lookupTables.edit(':id')} component={EditLookupPage} />
    </Switch>
  );
};

export default LookupTablesRoutes;

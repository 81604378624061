/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Divider,
  Box,
  Text,
  Link,
  AbstractButton,
  Flex,
  Img,
  Card,
} from 'pouncejs';
import useAuth from 'Hooks/useAuth';
import { UserInfo } from 'Components/utils/AuthContext';
import { getActorDisplayName, isCognitoSamlUser } from 'Helpers/utils';
import PantherIcon from 'Assets/panther-minimal-logo.svg';
import { ENABLE_NEW_NAVIGATION, PANTHER_CONFIG } from 'Source/constants';
import useModal from 'Hooks/useModal';
import ProfileSettingsModal from 'Components/modals/ProfileSettingsModal';

const DEFAULT_INITIALS = '??';

const getUserName = (userInfo?: UserInfo) => {
  if (!userInfo) {
    return DEFAULT_INITIALS;
  }

  if (userInfo.givenName && userInfo.familyName) {
    return `${userInfo.givenName[0]}. ${userInfo.familyName}`;
  }
  if (userInfo.givenName) {
    return userInfo.givenName;
  }
  return userInfo.email;
};

const getUserInitials = (userInfo?: UserInfo) => {
  if (!userInfo) {
    return DEFAULT_INITIALS;
  }

  if (userInfo.givenName && userInfo.familyName) {
    return `${userInfo.givenName[0]}${userInfo.familyName[0]}`;
  }

  if (userInfo.givenName) {
    return userInfo.givenName.slice(0, 2);
  }

  if (userInfo.familyName) {
    return userInfo.familyName.slice(0, 2);
  }

  return userInfo.email.slice(0, 2);
};

const ProfileIcon: React.FC = () => {
  const { userInfo, signOut } = useAuth();
  const { showModal } = useModal();

  return (
    <Popover>
      {({ isOpen, close: closePopover }) => (
        <React.Fragment>
          <PopoverTrigger as={AbstractButton} aria-label="Toggle User Menu" width="100%">
            <Flex
              spacing={2}
              align="center"
              justifyContent={ENABLE_NEW_NAVIGATION ? 'center' : 'flex-start'}
              width={ENABLE_NEW_NAVIGATION ? 50 : 'auto'}
            >
              <Box
                width={ENABLE_NEW_NAVIGATION ? 28 : 40}
                display="flex"
                transition="background-color 0.1s linear"
                borderRadius="circle"
                justifyContent="center"
                alignItems="center"
                fontSize="small"
                fontWeight="bold"
                height={ENABLE_NEW_NAVIGATION ? 28 : 40}
                backgroundColor={isOpen ? 'violet-400' : 'violet-500'}
                flexShrink={0}
                _hover={{ backgroundColor: 'violet-400' }}
              >
                {getUserInitials(userInfo).toUpperCase()}
              </Box>
              {!ENABLE_NEW_NAVIGATION && (
                <Box fontWeight="bold" truncated>
                  {getUserName(userInfo)}
                </Box>
              )}
            </Flex>
          </PopoverTrigger>
          <PopoverContent alignment={ENABLE_NEW_NAVIGATION ? 'bottom-right' : 'right-bottom'}>
            <Card
              mx={ENABLE_NEW_NAVIGATION ? 0 : 20}
              my={ENABLE_NEW_NAVIGATION ? 3 : -12}
              shadow="dark300"
              minWidth={240}
              fontSize="medium"
              overflow="hidden"
            >
              <Box p={6} position="relative">
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  width="100%"
                  height="5px"
                  background="linear-gradient(-225deg, rgb(209, 152, 46) 0%, rgb(146, 99, 14) 100%)"
                />
                <Text data-testid="user-display-name">{getActorDisplayName(userInfo)}</Text>
                {!!userInfo && (
                  <Link external href={`mailto:${userInfo.email}`}>
                    {userInfo.email}
                  </Link>
                )}
                <Divider mt={6} mb={3} color="navyblue-200" />
                <Box mb={2} mx={-2}>
                  <AbstractButton
                    disabled={isCognitoSamlUser(userInfo)}
                    p={2}
                    onClick={() => {
                      showModal(<ProfileSettingsModal />, {
                        showCloseButton: true,
                        // @ts-ignore
                        'aria-label': 'Profile & Account Settings',
                      });
                      closePopover();
                    }}
                  >
                    Profile Settings
                  </AbstractButton>
                </Box>
                <Box mx={-2}>
                  <AbstractButton p={2} onClick={() => signOut({ global: true, onError: alert })}>
                    Log Out
                  </AbstractButton>
                </Box>
              </Box>
              <Flex
                as="footer"
                justify="center"
                backgroundColor="navyblue-600"
                p={3}
                mt={-2}
                fontSize="small"
                fontStyle="italic"
              >
                <Img
                  src={PantherIcon}
                  alt="Panther logo"
                  nativeWidth={16}
                  nativeHeight={16}
                  mr={2}
                />
                Version:&nbsp;<b>{PANTHER_CONFIG.PANTHER_VERSION}</b>
              </Flex>
            </Card>
          </PopoverContent>
        </React.Fragment>
      )}
    </Popover>
  );
};

export default React.memo(ProfileIcon);

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import urls from 'Source/urls';

export const bulkUploaderUrls = [
  // current
  urls.build.bulkUploader(),
  // deprecated
  urls.analysis.bulkUploader(),
];

export const dataModelsUrls = [
  // current
  urls.build.dataModels.create(),
  urls.build.dataModels.details(':id'),
  urls.build.dataModels.edit(':id'),
  urls.build.dataModels.list(),
  // deprecated
  urls.data.dataModels.create(),
  urls.data.dataModels.details(':id'),
  urls.data.dataModels.edit(':id'),
  urls.data.dataModels.list(),
];

export const detectionsUrls = [
  // current
  urls.build.home(),
  urls.build.detections.list(),
  urls.build.detections.create(),
  urls.build.detections.policies.list(),
  urls.build.detections.policies.details(':id'),
  urls.build.detections.policies.edit(':id'),
  urls.build.detections.rules.list(),
  urls.build.detections.rules.details(':id'),
  urls.build.detections.rules.edit(':id'),
  urls.build.detections.rules.replays(':detectionId', ':replayId', ':alertId'),
  '/build/detections/rules/:id/replay',
  '/build/detections/rules/:id/replay/:replayId/alert',
  // deprecated
  urls.detections.home(),
  urls.detections.list(),
  urls.detections.create(),
  urls.analysis.policies.list(),
  urls.analysis.policies.details(':id'),
  urls.analysis.policies.edit(':id'),
  urls.analysis.rules.list(),
  urls.analysis.rules.details(':id'),
  urls.analysis.rules.edit(':id'),
  urls.replays.alerts(':detectionId', ':replayId', ':alertId'),
  '/detections/rules/:id/replay',
  '/detections/rules/:id/replay/:replayId/alert',
];

export const helpersUrls = [
  // current
  urls.build.globalHelpers.create(),
  urls.build.globalHelpers.edit(':id'),
  urls.build.globalHelpers.list(),
  `${urls.build.globalHelpers.list()}:id`,
  // deprecated
  urls.data.globalHelpers.create(),
  urls.data.globalHelpers.edit(':id'),
  urls.data.globalHelpers.list(),
  `${urls.data.globalHelpers.list()}:id`,
];

export const mitreAttackUrls = [
  // current
  urls.build.mitreMatrix(),
  urls.build.mitreMapping({}),
  // deprecated
  urls.reports.home(),
  urls.reports.mitreMatrix(),
  urls.reports.mitreMapping({}),
];

export const packsUrls = [
  // current
  urls.build.packs.list(),
  urls.build.packs.details(':id'),
  urls.build.packs.packSources.home(),
  urls.build.packs.packSources.list(),
  urls.build.packs.packSources.create(),
  urls.build.packs.packSources.details(),
  urls.build.packs.packSources.edit(':id'),
  // deprecated
  urls.analysis.home(),
  urls.analysis.packs.list(),
  urls.analysis.packs.details(':id'),
  urls.analysis.packs.packSources.home(),
  urls.analysis.packs.packSources.list(),
  urls.analysis.packs.packSources.create(),
  urls.analysis.packs.packSources.details(),
  urls.analysis.packs.packSources.edit(':id'),
];

export const buildUrlsGroup = [
  ...bulkUploaderUrls,
  ...dataModelsUrls,
  ...detectionsUrls,
  ...helpersUrls,
  ...mitreAttackUrls,
  ...packsUrls,
];

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import useRouter from 'Hooks/useRouter';
import DataExplorer from 'Pages/DataExplorer';
import IndicatorSearch from 'Pages/IndicatorSearch';
import ListSavedQueries from 'Pages/ListSavedQueries';
import QueryHistory from 'Pages/QueryHistory';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import urls from 'Source/urls';

const InvestigateRoutes: React.FC = () => {
  const { location } = useRouter();

  return (
    <Switch>
      <Redirect exact from={urls.investigate.home()} to={urls.investigate.dataExplorer()} />
      <Redirect
        exact
        from={urls.data.dataExplorer()}
        to={{ pathname: urls.investigate.dataExplorer(), search: location.search }}
      />
      <Route exact path={urls.investigate.dataExplorer()} component={DataExplorer} />
      <Redirect
        exact
        from={urls.data.savedQueries.list()}
        to={{ pathname: urls.investigate.savedQueries.list(), search: location.search }}
      />
      <Route exact path={urls.investigate.savedQueries.list()} component={ListSavedQueries} />
      <Redirect
        exact
        from={urls.data.indicatorSearch()}
        to={{ pathname: urls.investigate.indicatorSearch(), search: location.search }}
      />
      <Route exact path={urls.investigate.indicatorSearch()} component={IndicatorSearch} />
      <Redirect
        exact
        from={urls.data.queryHistory()}
        to={{ pathname: urls.investigate.queryHistory(), search: location.search }}
      />
      <Route exact path={urls.investigate.queryHistory()} component={QueryHistory} />
    </Switch>
  );
};

export default InvestigateRoutes;

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import urls from 'Source/urls';
import { Permission } from 'Generated/schema';
import { NavigationLink } from 'Components/Navigation';
import SecondaryNavigationMenu from '../SecondaryNavigationMenu';

export const settingsNavLinks: NavigationLink[] = [
  {
    to: urls.settings.general.home(),
    icon: 'settings-line',
    label: 'General',
    permissions: [
      Permission.GeneralSettingsRead,
      Permission.GeneralSettingsModify,
      Permission.PolicyRead,
      Permission.PolicyModify,
      Permission.RuleRead,
      Permission.RuleModify,
    ],
  },
  {
    to: urls.settings.roles.list(),
    icon: 'organization',
    label: 'Roles',
    permissions: [Permission.UserRead, Permission.UserModify],
  },
  {
    to: urls.settings.users(),
    icon: 'user',
    label: 'Users',
    permissions: [Permission.UserRead, Permission.UserModify],
  },
  {
    to: urls.settings.apiTokens.list(),
    icon: 'key',
    label: 'API Tokens',
    permissions: [Permission.OrganizationApiTokenRead, Permission.OrganizationApiTokenModify],
  },
];

const SettingsNavigation: React.FC = () => {
  return <SecondaryNavigationMenu links={settingsNavLinks} />;
};

export default React.memo(SettingsNavigation);

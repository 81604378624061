/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Text, Flex, Button } from 'pouncejs';
import useModal from 'Hooks/useModal';

type ConfirmModalVariants = 'destructive' | 'continue';

export interface ConfirmModalProps {
  subtitle: React.ReactNode;
  onClose?: () => void;
  onConfirm?: () => void;
  variant?: ConfirmModalVariants;
  textAlign?: 'center' | 'left' | 'right';
  cancelBtnText?: string;
  confirmBtnText?: string;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  subtitle,
  onConfirm,
  onClose,
  variant = 'continue',
  textAlign = 'center',
  cancelBtnText = 'Cancel',
  confirmBtnText = 'Confirm',
}) => {
  const { hideModal } = useModal();

  const handleConfirm = () => {
    hideModal();
    onConfirm();
  };

  // Close the modal automatically after the confirmation action is taken
  // If `false`, it is up to the `onClose` prop provided to close the modal.
  const isOptimistic = !onClose;

  return (
    <Box maxWidth={700}>
      <Text mb={8} textAlign={textAlign} id="modal-subtitle">
        {subtitle}
      </Text>
      <Flex justify="flex-end" spacing={3}>
        <Button
          variant="outline"
          variantColor="navyblue-300"
          onClick={isOptimistic ? hideModal : onClose}
        >
          {cancelBtnText}
        </Button>
        <Button
          variantColor={variant === 'destructive' ? 'pink-700' : undefined}
          onClick={isOptimistic ? handleConfirm : onConfirm}
        >
          {variant === 'continue' ? 'Continue' : confirmBtnText}
        </Button>
      </Flex>
    </Box>
  );
};

export default ConfirmModal;

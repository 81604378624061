/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../__generated__/schema';

import { AlertSummaryFull } from '../../../../../graphql/fragments/AlertSummaryFull.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetHighPriorityAlertsVariables = {
  alertsInput: Types.AlertsInput;
  detectionErrorsInput: Types.AlertsInput;
  systemErrorsInput: Types.AlertsInput;
};

export type GetHighPriorityAlerts = {
  alerts: { edges: Array<{ node: AlertSummaryFull }> };
  detectionErrors: { edges: Array<{ node: AlertSummaryFull }> };
  systemErrors: { edges: Array<{ node: AlertSummaryFull }> };
};

export const GetHighPriorityAlertsDocument = gql`
  query GetHighPriorityAlerts(
    $alertsInput: AlertsInput!
    $detectionErrorsInput: AlertsInput!
    $systemErrorsInput: AlertsInput!
  ) {
    alerts: alerts(input: $alertsInput) {
      edges {
        node {
          ...AlertSummaryFull
        }
      }
    }
    detectionErrors: alerts(input: $detectionErrorsInput) {
      edges {
        node {
          ...AlertSummaryFull
        }
      }
    }
    systemErrors: alerts(input: $systemErrorsInput) {
      edges {
        node {
          ...AlertSummaryFull
        }
      }
    }
  }
  ${AlertSummaryFull}
`;

/**
 * __useGetHighPriorityAlerts__
 *
 * To run a query within a React component, call `useGetHighPriorityAlerts` and pass it any options that fit your needs.
 * When your component renders, `useGetHighPriorityAlerts` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHighPriorityAlerts({
 *   variables: {
 *      alertsInput: // value for 'alertsInput'
 *      detectionErrorsInput: // value for 'detectionErrorsInput'
 *      systemErrorsInput: // value for 'systemErrorsInput'
 *   },
 * });
 */
export function useGetHighPriorityAlerts(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetHighPriorityAlerts,
    GetHighPriorityAlertsVariables
  >
) {
  return ApolloReactHooks.useQuery<GetHighPriorityAlerts, GetHighPriorityAlertsVariables>(
    GetHighPriorityAlertsDocument,
    baseOptions
  );
}
export function useGetHighPriorityAlertsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetHighPriorityAlerts,
    GetHighPriorityAlertsVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetHighPriorityAlerts, GetHighPriorityAlertsVariables>(
    GetHighPriorityAlertsDocument,
    baseOptions
  );
}
export type GetHighPriorityAlertsHookResult = ReturnType<typeof useGetHighPriorityAlerts>;
export type GetHighPriorityAlertsLazyQueryHookResult = ReturnType<
  typeof useGetHighPriorityAlertsLazyQuery
>;
export type GetHighPriorityAlertsQueryResult = ApolloReactCommon.QueryResult<
  GetHighPriorityAlerts,
  GetHighPriorityAlertsVariables
>;
export function mockGetHighPriorityAlerts({
  data,
  variables,
  errors,
}: {
  data: GetHighPriorityAlerts;
  variables?: GetHighPriorityAlertsVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetHighPriorityAlertsDocument, variables },
    result: { data, errors },
  };
}

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import urls from 'Source/urls';
import { Box, Card, IconButton, Popover, PopoverContent, PopoverTrigger } from 'pouncejs';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import { Permission } from 'Generated/schema';
import NavLink from 'Components/Navigation/NavLink';
import { NavigationLink } from 'Components/Navigation';

type SettingsMenuLink = Omit<NavigationLink, 'icon'>;

const settingsNavLinks: SettingsMenuLink[] = [
  {
    to: urls.settings.general.home(),
    label: 'General',
    permissions: [
      Permission.GeneralSettingsRead,
      Permission.GeneralSettingsModify,
      Permission.PolicyRead,
      Permission.PolicyModify,
      Permission.RuleRead,
      Permission.RuleModify,
    ],
  },
  {
    to: urls.settings.users(),
    label: 'Users',
    permissions: [Permission.UserRead, Permission.UserModify],
  },
  {
    to: urls.settings.roles.list(),
    label: 'User Roles',
    permissions: [Permission.UserRead, Permission.UserModify],
  },
  {
    to: urls.settings.api.list(),
    label: 'API Tokens',
    permissions: [Permission.OrganizationApiTokenRead, Permission.OrganizationApiTokenModify],
  },
  {
    to: urls.settings.api.playground(),
    label: 'API Playground',
    permissions: [Permission.OrganizationApiTokenRead, Permission.OrganizationApiTokenModify],
  },
];

const SettingsMenu: React.FC = () => {
  return (
    <Popover>
      {({ close: closePopover }) => (
        <React.Fragment>
          <PopoverTrigger
            as={IconButton}
            id="settings-menu"
            icon="settings-alt"
            variantBorderStyle="circle"
            variantColor="navyblue-700"
            padding="10px"
            data-tid="settings-menu-dropdown-button"
            aria-label="Toggle Settings Menu"
          />
          <PopoverContent alignment="bottom-left">
            <Card as="ul" my={1} px={3} py={2} shadow="dark300" minWidth={180}>
              {settingsNavLinks.map(link => {
                return (
                  <Box as="li" key={link.label}>
                    <RoleRestrictedAccess allowedPermissions={link.permissions}>
                      <NavLink label={link.label} to={link.to} isSecondary onClick={closePopover} />
                    </RoleRestrictedAccess>
                  </Box>
                );
              })}
            </Card>
          </PopoverContent>
        </React.Fragment>
      )}
    </Popover>
  );
};

export default React.memo(SettingsMenu);

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import withSEO from 'Hoc/withSEO';
import { Alert, useSnackbar } from 'pouncejs';
import useRouter from 'Hooks/useRouter';
import DataSchemaForm from 'Components/forms/DataSchemaForm';
import { appendDataSchemaPrefix, stripDataSchemaPrefix, extractErrorMessage } from 'Helpers/utils';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { Permission, ErrorCodeEnum } from 'Generated/schema';
import { compose } from 'Helpers/compose';
import Page403 from 'Pages/403';
import Page404 from 'Pages/404';
import { useGetSchemaDetails } from 'Pages/DataSchemaDetails/graphql/getDataSchemaDetails.generated';
import { SchemaGenerationProvider } from 'Components/utils/SchemaGenerationContext';
import urls from 'Source/urls';
import { useUpdateDataSchema } from './graphql/updateDataSchema.generated';

const EditDataSchema: React.FC = () => {
  const { match: { params: { name } } ,history} = useRouter<{ name: string }>(); // prettier-ignore
  const { data, error: uncontrolledError } = useGetSchemaDetails({
    variables: { input: { name } },
  });

  const { pushSnackbar } = useSnackbar();

  const { record: dataSchema, error: controlledError } = data?.getSchema || {};
  const initialValues = React.useMemo(
    () => ({
      revision: dataSchema?.revision,
      name: stripDataSchemaPrefix(dataSchema?.name) || 'Loading...',
      spec: dataSchema?.spec || '# Loading...',
      description: dataSchema?.description || '',
      referenceURL: dataSchema?.referenceURL || '',
    }),
    [dataSchema]
  );

  // If the dataSchema is managed fallback to the schemas list page
  React.useEffect(() => {
    if (dataSchema?.managed) {
      history.push(urls.data.schemas.list());
    }
  }, [dataSchema, history]);

  const [updateDataSchema] = useUpdateDataSchema({
    onCompleted: ({ putUserSchema: { error: schemaError } }) => {
      if (schemaError) {
        pushSnackbar({
          variant: 'error',
          title: schemaError.message,
        });
      } else {
        pushSnackbar({
          variant: 'success',
          title: 'Successfully updated data schema!',
        });
      }
    },
    onError: error => {
      pushSnackbar({
        variant: 'error',
        title: extractErrorMessage(error),
      });
    },
  });

  if (controlledError) {
    if (controlledError.code === ErrorCodeEnum.NotFound) {
      return <Page404 />;
    }

    return (
      <Alert
        variant="error"
        title="Couldn't load your data schema"
        description={extractErrorMessage(uncontrolledError)}
      />
    );
  }

  if (controlledError) {
    if (controlledError.code === ErrorCodeEnum.NotFound) {
      return <Page404 />;
    }
  }

  return (
    <SchemaGenerationProvider
      initialSchema={initialValues.spec}
      sessionKey={`SCHEMA_${dataSchema?.name + dataSchema?.revision}_SESSION_ID`}
    >
      <DataSchemaForm
        initialValues={initialValues}
        onSubmit={values =>
          updateDataSchema({
            variables: {
              input: {
                revision: dataSchema?.revision,
                name: appendDataSchemaPrefix(values.name),
                description: values.description,
                referenceURL: values.referenceURL,
                spec: values.spec,
              },
            },
          })
        }
      />
    </SchemaGenerationProvider>
  );
};

export default compose(
  withSEO({ title: 'Edit Data Schema' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LogSourceModify],
    fallback: <Page403 />,
  })
)(EditDataSchema);

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import useRouter from 'Hooks/useRouter';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { DetectionTypeEnum } from 'Generated/schema';
import urls from 'Source/urls';
import CreateDetectionPage from 'Pages/CreateDetection';
import EditPolicyPage from 'Pages/EditPolicy';
import EditRulePage from 'Pages/EditRule';
import ListDetectionsPage from 'Pages/ListDetections';
import PolicyDetailsPage from 'Pages/PolicyDetails';
import ReplayedAlertDetailsPage from 'Pages/ReplayedAlertDetails';
import RuleDetailsPage from 'Pages/RuleDetails';

const DetectionsRoutes: React.FC = () => {
  const { location } = useRouter();

  return (
    <Switch>
      {/* home + list */}
      <Redirect
        exact
        from={urls.detections.home()}
        to={{ pathname: urls.build.detections.list(), search: location.search }}
      />
      <Redirect
        exact
        from={urls.build.home()}
        to={{ pathname: urls.build.detections.list(), search: location.search }}
      />
      <Redirect
        exact
        from={urls.analysis.policies.list()}
        to={{
          pathname: urls.build.detections.list(),
          search: '?analysisTypes[]=POLICY&page=1&sortBy=lastModified&sortDir=descending',
        }}
      />
      <Redirect
        exact
        from={urls.build.detections.policies.list()}
        to={{
          pathname: urls.build.detections.list(),
          search: '?analysisTypes[]=POLICY&page=1&sortBy=lastModified&sortDir=descending',
        }}
      />
      <Redirect
        exact
        from={urls.analysis.rules.list()}
        to={{
          pathname: urls.build.detections.list(),
          search: '?analysisTypes[]=RULE&page=1&sortBy=lastModified&sortDir=descending',
        }}
      />
      <Redirect
        exact
        from={urls.build.detections.rules.list()}
        to={{
          pathname: urls.build.detections.list(),
          search: '?analysisTypes[]=RULE&page=1&sortBy=lastModified&sortDir=descending',
        }}
      />
      <Redirect
        exact
        from={urls.detections.list()}
        to={{ pathname: urls.build.detections.list(), search: location.search }}
      />
      <Route exact path={urls.build.detections.list()} component={ListDetectionsPage} />
      {/* Create */}
      <Redirect
        exact
        from={urls.detections.create()}
        to={{ pathname: urls.build.detections.create(), search: location.search }}
      />
      <Route exact path={urls.build.detections.create()} component={CreateDetectionPage} />
      {/* Details */}
      <Redirect
        exact
        from={urls.detections.details(':id', ':type' as DetectionTypeEnum)}
        to={{
          pathname: urls.build.detections.details(':id', ':type' as DetectionTypeEnum),
          search: location.search,
        }}
      />
      {/* Policies Details */}
      <Redirect
        exact
        from={urls.analysis.policies.details(':id')}
        to={{ pathname: urls.build.detections.policies.details(':id'), search: location.search }}
      />
      <Route
        exact
        path={urls.build.detections.policies.details(':id')}
        component={PolicyDetailsPage}
      />
      {/* Policies Edit */}
      <Redirect
        exact
        from={urls.analysis.policies.edit(':id')}
        to={{ pathname: urls.build.detections.policies.edit(':id'), search: location.search }}
      />
      <Route exact path={urls.build.detections.policies.edit(':id')} component={EditPolicyPage} />
      {/* Rules Details */}
      <Redirect
        exact
        from={urls.analysis.rules.details(':id')}
        to={{ pathname: urls.build.detections.rules.details(':id'), search: location.search }}
      />
      <Route exact path={urls.build.detections.rules.details(':id')} component={RuleDetailsPage} />
      {/* Rules Edit */}
      <Redirect
        exact
        from={urls.analysis.rules.edit(':id')}
        to={{ pathname: urls.build.detections.rules.edit(':id'), search: location.search }}
      />
      <Route exact path={urls.build.detections.rules.edit(':id')} component={EditRulePage} />
      {/* Rules Replay */}
      <Redirect
        exact
        from="/detections/rules/:id/replay"
        to={{ pathname: urls.build.detections.rules.edit(':id'), search: '?section=functions' }}
      />
      <Redirect
        exact
        from="/build/detections/rules/:id/replay"
        to={{ pathname: urls.build.detections.rules.edit(':id'), search: '?section=functions' }}
      />
      <Redirect
        exact
        from="/detections/rules/:id/replay/:replayId/alert"
        to={{ pathname: urls.build.detections.rules.edit(':id'), search: '?section=functions' }}
      />
      <Redirect
        exact
        from={urls.replays.alerts(':detectionId', ':replayId', ':alertId')}
        to={{
          pathname: urls.build.detections.rules.replays(':detectionId', ':replayId', ':alertId'),
          search: '?section=functions',
        }}
      />
      <Route
        exact
        path={urls.build.detections.rules.replays(':detectionId', ':replayId', ':alertId')}
        component={ReplayedAlertDetailsPage}
      />
    </Switch>
  );
};

export default DetectionsRoutes;

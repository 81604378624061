/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, Img } from 'pouncejs';
import { useFormikContext } from 'formik';
import { useWizardContext, WizardPanel } from 'Components/Wizard';
import { AsanaLogsSourceWizardValues } from 'Pages/Integrations/LogSources/Asana/AsanaWizard/AsanaWizard';
import SuccessStatus from 'Assets/statuses/illustration-success.svg';
import EventThresholdAlarmConfigurator from 'Components/EventThresholdAlarmConfigurator';
import { AsanaLogIntegrationDetails } from 'Source/graphql/fragments/AsanaLogIntegrationDetails.generated';
import { PendingPanel, FailingPanel } from 'Components/SourceVerificationPanels';
import FinishSourceSetupActions from 'Pages/Integrations/components/FinishSourceSetupActions';

const ValidationPanel: React.FC = () => {
  const [errorMessage, setErrorMessage] = React.useState('');
  const result = React.useRef<AsanaLogIntegrationDetails | Error>(null);
  const { currentStepStatus, setCurrentStepStatus, setNavigationAvailability } = useWizardContext();
  const { initialValues, submitForm } = useFormikContext<AsanaLogsSourceWizardValues>();

  React.useEffect(() => {
    (async () => {
      setNavigationAvailability(false);
      result.current = await (submitForm() as Promise<any>);
      if (result.current instanceof Error) {
        setErrorMessage(result.current.message);
        setCurrentStepStatus('FAILING');
        setNavigationAvailability(true);
      } else {
        setErrorMessage('');
        setCurrentStepStatus('PASSING');
      }
    })();
  }, [setCurrentStepStatus, setNavigationAvailability, submitForm]);

  if (currentStepStatus === 'PENDING') {
    return <PendingPanel />;
  }

  if (currentStepStatus === 'FAILING') {
    return <FailingPanel errorMessage={errorMessage} />;
  }
  // success
  const source = result.current as AsanaLogIntegrationDetails;
  return (
    <WizardPanel>
      <Flex align="center" direction="column" mx="auto" width={620}>
        <Box width={400}>
          <WizardPanel.Heading
            title="Everything looks good!"
            subtitle={
              initialValues.integrationId
                ? 'Your Asana Log Source was successfully updated'
                : 'Your Asana Log Source was successfully created'
            }
            divider={null}
            subtitleProps={{ color: 'gray-300', fontWeight: 'normal' }}
          />
        </Box>
        <Img nativeWidth={157} nativeHeight={118} mb={8} alt="Checkmark" src={SuccessStatus} />
        <EventThresholdAlarmConfigurator logSource={source} />
        <WizardPanel.Actions>
          <FinishSourceSetupActions integrationId={source.integrationId} />
        </WizardPanel.Actions>
      </Flex>
    </WizardPanel>
  );
};

export default ValidationPanel;

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import Breadcrumbs from 'Components/Breadcrumbs';
import { Box, Flex } from 'pouncejs';
import useRouter from 'Hooks/useRouter';
import { dashboardUrlsGroup } from 'Pages/Dashboard';

/** Array containing urls without header */
const EXCLUDED_URLS = dashboardUrlsGroup;

const Header = () => {
  const {
    location: { pathname },
  } = useRouter();
  if (EXCLUDED_URLS.includes(pathname)) {
    return null;
  }

  return (
    <Flex id="main-header" as="header" width={1} align="center" justify="space-between" py={6}>
      <Box py={14}>
        <Breadcrumbs />
      </Box>
    </Flex>
  );
};

export default Header;

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex } from 'pouncejs';
import urls from 'Source/urls';

import { Permission } from 'Generated/schema';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import useRouter from 'Hooks/useRouter';
import { isActiveMenu } from '../utils';
import { SecondaryNavKeyEnum } from '../constants';
import NavGroup from '../NavGroup';
import {
  BuildNavigation,
  ConfigureNavigation,
  DashboardNavigation,
  InvestigateNavigation,
} from '../SecondaryNavigations';
import NavLink from '../NavLink';

const SideNavigation = () => {
  const {
    location: { pathname },
  } = useRouter();

  const [isDashboardMenuActive, setIsDashboardMenuActive] = React.useState<boolean>(
    isActiveMenu(pathname, SecondaryNavKeyEnum.DASHBOARD_NAV_KEY)
  );
  const [isInvestigateMenuActive, setIsInvestigateMenuActive] = React.useState<boolean>(
    isActiveMenu(pathname, SecondaryNavKeyEnum.INVESTIGATE_NAV_KEY)
  );
  const [isBuildMenuActive, setIsBuildMenuActive] = React.useState<boolean>(
    isActiveMenu(pathname, SecondaryNavKeyEnum.BUILD_NAV_KEY)
  );
  const [isConfigureMenuActive, setIsConfigureMenuActive] = React.useState<boolean>(
    isActiveMenu(pathname, SecondaryNavKeyEnum.CONFIGURE_NAV_KEY)
  );

  return (
    <Flex
      as="aside"
      boxShadow="dark50"
      position="sticky"
      zIndex={10}
      minWidth="min-content"
      height="100%"
      pt={48}
      backgroundColor="navyblue-700"
      borderRight="1px solid"
      borderColor="navyblue-350"
      overflowY="auto"
      // This CSS property removes layout shifts when the scrollbar is not displayed.
      // At the time of writing is not implemented in Safari, but only a small percentage
      // of our users use Safari. So most users will get this enhancement, and Safari will
      // work fine.
      // https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-gutter
      //
      // `scrollbar-gutter` is not supported by `pounce` props or in the `sx` prop, so applying it
      // with inline style is the only way to go.
      style={{ scrollbarGutter: 'stable both-edges' }}
    >
      <Flex as="nav" direction="column" width={220} height="100%" aria-label="Main" pb={2}>
        <Flex direction="column" as="ul" flex="1 0 auto" px={4}>
          <RoleRestrictedAccess allowedPermissions={[Permission.SummaryRead]}>
            <Box as="li" mb={2}>
              <NavGroup
                active={isDashboardMenuActive}
                icon="policy"
                label="Dashboard"
                onSelect={() => setIsDashboardMenuActive(!isDashboardMenuActive)}
              >
                <DashboardNavigation />
              </NavGroup>
            </Box>
          </RoleRestrictedAccess>
          <RoleRestrictedAccess allowedPermissions={[Permission.AlertRead, Permission.AlertModify]}>
            <Box as="li" mb={2}>
              <NavLink
                icon="alert-circle"
                to={urls.alerts.list({ disableDefaultParams: false })}
                label="Alerts"
              />
            </Box>
          </RoleRestrictedAccess>
          <RoleRestrictedAccess allowedPermissions={[Permission.DataAnalyticsRead]}>
            <Box as="li" mb={2}>
              <NavGroup
                active={isInvestigateMenuActive}
                icon="data"
                label="Investigate"
                onSelect={() => setIsInvestigateMenuActive(!isInvestigateMenuActive)}
              >
                <InvestigateNavigation />
              </NavGroup>
            </Box>
          </RoleRestrictedAccess>
          <RoleRestrictedAccess
            allowedPermissions={[
              Permission.PolicyRead,
              Permission.PolicyModify,
              Permission.RuleRead,
              Permission.RuleModify,
            ]}
          >
            <Box as="li" mb={2}>
              <NavGroup
                active={isBuildMenuActive}
                icon="detection"
                label="Build"
                onSelect={() => setIsBuildMenuActive(!isBuildMenuActive)}
              >
                <BuildNavigation />
              </NavGroup>
            </Box>
          </RoleRestrictedAccess>
          <RoleRestrictedAccess
            allowedPermissions={[
              Permission.CloudsecSourceRead,
              Permission.CloudsecSourceModify,
              Permission.DestinationRead,
              Permission.DestinationModify,
              Permission.LogSourceRead,
              Permission.LogSourceModify,
              Permission.LookupRead,
              Permission.LookupModify,
              Permission.ResourceRead,
              Permission.ResourceModify,
            ]}
          >
            <Box as="li" mb={2}>
              <NavGroup
                active={isConfigureMenuActive}
                icon="integrations"
                label="Configure"
                onSelect={() => setIsConfigureMenuActive(!isConfigureMenuActive)}
              >
                <ConfigureNavigation />
              </NavGroup>
            </Box>
          </RoleRestrictedAccess>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default React.memo(SideNavigation);

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import withSEO from 'Hoc/withSEO';
import { compose } from 'Helpers/compose';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { LogStreamTypeEnum, Permission } from 'Generated/schema';
import useUrlParams from 'Hooks/useUrlParams';
import Page403 from 'Pages/403';
import useAvailableLogTypesForProvider from 'Hooks/useAvailableLogTypesForProvider';
import S3LogSourceWizard, {
  S3LogSourceWizardValues,
  s3PrefixLogTypesInitialValues,
} from '../S3LogSourceWizard';
import { useAddS3LogSource } from './graphql/addS3LogSource.generated';

const CreateS3LogSource: React.FC = () => {
  const { urlParams: { logProviderId } } = useUrlParams<{ logProviderId?: string }>(); // prettier-ignore
  const { availableLogTypes, defaultLogTypes } = useAvailableLogTypesForProvider(logProviderId);

  const initialValues = React.useMemo<S3LogSourceWizardValues>(
    () => ({
      integrationLabel: '',
      awsAccountId: '',
      s3Bucket: '',
      kmsKey: '',
      managedBucketNotifications: true,
      s3PrefixLogTypes: [{ ...s3PrefixLogTypesInitialValues, logTypes: defaultLogTypes ?? [] }],
      logProcessingRole: '',
      logStreamType: LogStreamTypeEnum.Lines,
      logTypesAttached: defaultLogTypes.length > 0 ? 'now' : 'later',
    }),
    [defaultLogTypes]
  );

  const [addS3LogSource] = useAddS3LogSource({
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          logSources: (queryData, { toReference }) => {
            const addedIntegrationCacheRef = toReference(data.addS3LogIntegration);
            return queryData
              ? [addedIntegrationCacheRef, ...queryData]
              : [addedIntegrationCacheRef];
          },
        },
      });
    },
  });

  const handleSubmit = React.useCallback(async (values: S3LogSourceWizardValues) => {
    try {
      const { data } = await addS3LogSource({
        variables: {
          input: {
            logProviderId: logProviderId ?? null,
            integrationLabel: values.integrationLabel,
            awsAccountId: values.awsAccountId,
            s3Bucket: values.s3Bucket,
            s3PrefixLogTypes:
              values.logTypesAttached === 'now'
                ? values.s3PrefixLogTypes
                : [s3PrefixLogTypesInitialValues],
            kmsKey: values.kmsKey,
            managedBucketNotifications: values.managedBucketNotifications,
            logProcessingRole: values.logProcessingRole,
            logStreamType: values.logStreamType,
          },
        },
      });
      return data.addS3LogIntegration;
    } catch (err) {
      return err as Error;
    }
    // FIXME: look into missing hook dependencies or using a ref
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S3LogSourceWizard
      initialValues={initialValues}
      availableLogTypes={availableLogTypes}
      onSubmit={handleSubmit}
    />
  );
};

export default compose(
  withSEO({ title: 'New S3 Source' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LogSourceModify],
    fallback: <Page403 />,
  })
)(CreateS3LogSource);

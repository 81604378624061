/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import FormikMultiCombobox from 'Components/fields/MultiComboBox';
import FormikTextInput from 'Components/fields/TextInput';
import { Field, useFormikContext } from 'formik';
import { Box, Flex, FormHelperText, AbstractButton, Button } from 'pouncejs';
import { S3PrefixLogTypesInput } from 'Generated/schema';

interface S3PrefixSchemaFieldsProps {
  index: number;
  logTypes: string[];
  actions?: React.ReactNode;
}

const S3PrefixSchemaFields = ({ index, logTypes, actions }: S3PrefixSchemaFieldsProps) => {
  const { values, setFieldValue } = useFormikContext<{
    s3PrefixLogTypes: [S3PrefixLogTypesInput];
  }>();

  const [showSchemasInputField, setShowSchemasInputField] = React.useState(
    values.s3PrefixLogTypes[index].logTypes?.length > 0
  );

  const [showExclusionsInputField, setShowExclusionsInputField] = React.useState(
    values.s3PrefixLogTypes[index].excludedPrefixes?.length > 0
  );

  const handleToggleSchemasField = React.useCallback(() => {
    setFieldValue(`s3PrefixLogTypes.${index}.logTypes`, []);
    setShowSchemasInputField(value => !value);
  }, [index, setFieldValue]);

  const handleToggleExclusionsField = React.useCallback(() => {
    setFieldValue(`s3PrefixLogTypes.${index}.excludedPrefixes`, []);
    setShowExclusionsInputField(value => !value);
  }, [index, setFieldValue]);

  return (
    <Box key={index} position="relative">
      <Flex
        p={4}
        backgroundColor="navyblue-500"
        data-testid={`s3-prefix-${index}`}
        direction="column"
        spacing={6}
      >
        <Box>
          <Field
            name={`s3PrefixLogTypes.${index}.prefix`}
            label="S3 Prefix"
            placeholder="Limit logs to objects that start with matching characters"
            as={FormikTextInput}
            aria-describedby={`prefix-${index}-helper-text`}
          />
          <FormHelperText
            id={`prefix-${index}-helper-text`}
            mt={1}
            fontSize="small-medium"
            fontStyle="italic"
          >
            Leave blank to create a wildcard (*) prefix and allow ingestion of all files in bucket.
          </FormHelperText>
        </Box>
        {showExclusionsInputField && (
          <Box>
            <Field
              name={`s3PrefixLogTypes.${index}.excludedPrefixes`}
              label="Exclusion Filters - Optional"
              placeholder="Exclude logs to objects that start with matching characters"
              items={[]}
              as={FormikMultiCombobox}
              aria-describedby={`exclusion-prefix-${index}-helper-text`}
              allowAdditions
              searchable
            />
            <Flex justify="space-between" mt={1}>
              <FormHelperText
                id={`exclusion-prefix-${index}-helper-text`}
                fontSize="small-medium"
                fontStyle="italic"
              >
                Indicate which S3 Prefixes to exclude
              </FormHelperText>
              <AbstractButton
                color="red-300"
                fontSize="small-medium"
                onClick={handleToggleExclusionsField}
              >
                Remove Field
              </AbstractButton>
            </Flex>
          </Box>
        )}
        {showSchemasInputField && (
          <Box>
            <Field
              name={`s3PrefixLogTypes.${index}.logTypes`}
              label="Schemas - Optional"
              placeholder="The types of logs that are collected"
              items={logTypes}
              as={FormikMultiCombobox}
              aria-describedby={`log-types-${index}-helper-text`}
              searchable
              required
            />
            <Flex justify="space-between" mt={1}>
              <FormHelperText
                id={`log-types-${index}-helper-text`}
                fontSize="small-medium"
                fontStyle="italic"
              >
                Select schemas Panther should use to parse S3 objects matching the S3 Prefix Filter
              </FormHelperText>
              <AbstractButton
                color="red-300"
                fontSize="small-medium"
                onClick={handleToggleSchemasField}
              >
                Remove Field
              </AbstractButton>
            </Flex>
          </Box>
        )}
        <Flex spacing={2}>
          {!showExclusionsInputField && (
            <Button
              icon="add-circle"
              size="medium"
              variantColor="navyblue-300"
              onClick={handleToggleExclusionsField}
            >
              Add Exclusion Filters
            </Button>
          )}
          {!showSchemasInputField && (
            <Button
              icon="add-circle"
              size="medium"
              variantColor="navyblue-300"
              onClick={handleToggleSchemasField}
            >
              Add Schemas
            </Button>
          )}
        </Flex>
      </Flex>
      <Flex position="absolute" left="100%" ml={4} top={0} bottom={0} align="center" my={0}>
        {actions}
      </Flex>
    </Box>
  );
};

export default React.memo(S3PrefixSchemaFields);

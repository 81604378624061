/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration, { DurationUnitType } from 'dayjs/plugin/duration';
import type { ValidationError } from 'jsonschema';
import { YAMLException } from 'js-yaml';
import groupBy from 'lodash/groupBy';
import * as Yup from 'yup';

import {
  ActiveSuppressCount,
  AlertType,
  ComplianceStatusCounts,
  LogPullingIntegration,
  OrganizationReportBySeverity,
  Permission,
  ScannedResources,
  DetectionTestDefinition,
  User,
  SortDirEnum,
} from 'Generated/schema';
import { UserDetails } from 'Source/graphql/fragments/UserDetails.generated';
import { UserFormValues } from 'Components/forms/UserForm';
import { UserInfo } from 'Components/utils/AuthContext';
import {
  CHECK_IF_HASH_REGEX,
  DEFAULT_CRON_EXPRESSION,
  INCLUDE_DIGITS_REGEX,
  INCLUDE_LOWERCASE_REGEX,
  INCLUDE_SPECIAL_CHAR_REGEX,
  INCLUDE_UPPERCASE_REGEX,
  PANTHER_SAML_USER_PREFIX,
  SOURCE_LABEL_REGEX,
  PANTHER_CONFIG,
  S3_OBJECT_PATH_REGEX,
} from 'Source/constants';
import sum from 'lodash/sum';
import { ErrorResponse } from 'apollo-link-error';
import { ApolloError, ServerError } from '@apollo/client';
import isString from 'lodash/isString';
import isArray from 'lodash/isArray';
import { ListLogSources } from 'Pages/Integrations/LogSources/ListLogSources';
import { ActorTeaser } from 'Source/graphql/fragments/ActorTeaser.generated';
import { UserTeaser } from 'Source/graphql/fragments/UserTeaser.generated';
import { APITokenTeaser } from 'Source/graphql/fragments/APITokenTeaser.generated';
import schemaBlueprint from 'Public/schemas/customlogs_v0_schema.json';

export const isMobile = /Mobi|Android/i.test(navigator.userAgent);
export const isMac = navigator.userAgent.includes('Mac OS X');
export const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

// Generate a new secret code that contains metadata of issuer and user email
export const formatSecretCode = (code: string, email: string): string => {
  const issuer = 'Panther';
  return `otpauth://totp/${issuer}:${email}?secret=${code}&issuer=${issuer}`;
};

export const getArnRegexForService = (awsService: string) => {
  return new RegExp(`arn:aws:${awsService.toLowerCase()}:([a-z]){2}-([a-z])+-[0-9]:\\d{12}:.+`);
};

// Derived from https://github.com/3nvi/panther/blob/master/deployments/bootstrap.yml#L557-L563
export const yupPasswordValidationSchema = Yup.string()
  .required()
  .min(12, 'Password must contain at least 12 characters')
  .matches(INCLUDE_UPPERCASE_REGEX, 'Password must contain at least 1 uppercase character')
  .matches(INCLUDE_LOWERCASE_REGEX, 'Password must contain at least 1 lowercase character')
  .matches(INCLUDE_SPECIAL_CHAR_REGEX, 'Password must contain at least 1 symbol')
  .matches(INCLUDE_DIGITS_REGEX, 'Password must contain  at least 1 number');

export const yupIntegrationLabelValidation = Yup.string()
  .required('This field is required')
  .matches(SOURCE_LABEL_REGEX, 'Can only include alphanumeric characters, dashes and spaces')
  .max(32, 'Must be at most 32 characters');

export const yupWebhookValidation = Yup.string().url('Must be a valid webhook URL');
/**
 * checks whether the input is a valid UUID
 */
export const isGuid = (str: string) =>
  /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(str);

/**
 * caps the first letter of a string
 */
export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

interface YupIntegrationLabelUniquenessProps {
  existingLabel: string;
  existingSources: ListLogSources['logSources'];
  methodName?: 'SQS' | 'S3' | 'Log Pulling' | 'Eventbridge' | 'GCS';
}

export const yupIntegrationLabelUniqueness = ({
  existingLabel,
  existingSources,
  methodName = 'Log Pulling',
}: YupIntegrationLabelUniquenessProps) => {
  return yupIntegrationLabelValidation.test(
    'mutex',
    `You already have a ${methodName} source with the same label`,
    newIntegrationLabel => {
      return !existingSources?.find(
        ({ integrationLabel }) =>
          existingLabel !== newIntegrationLabel && integrationLabel === newIntegrationLabel
      );
    }
  );
};

export const yupS3PrefixLogTypesValidation = () =>
  Yup.array()
    .of(
      Yup.object().shape({
        prefix: Yup.string()
          .test(
            'mutex',
            "'*' is not an acceptable value, leave empty if you want to include everything",
            prefix => {
              return !prefix || !prefix.includes('*');
            }
          )
          .test('mutex', "S3 prefix should not start with '/'", prefix => {
            return !prefix || !prefix.startsWith('/');
          }),
        excludedPrefixes: Yup.array()
          .of(Yup.string())
          // eslint-disable-next-line func-names
          .test('mutex', 'Excluded prefixes should start with included prefix', function (
            excludedPrefixArray
          ) {
            if (!this.parent.prefix) {
              return true;
            }
            // Check if every excluded prefix starts with the defined prefix and is not equal to it
            return excludedPrefixArray.every(
              e => e.startsWith(this.parent.prefix) && e !== this.parent.prefix
            );
          })
          .unique(`Excluded prefixes should be unique`)
          .required(),
        logTypes: Yup.array().of(Yup.string()),
      })
    )
    .unique(`S3 prefixes should be unique`, 'prefix')
    .required()
    .min(1);

/**
 * Given a server-received DateTime string, creates a proper display text for it. We manually
 * calculate the offset cause there is no available format-string that can display the UTC offset
 * as a single digit (all of them display it either as 03:00 or as 0300) and require string
 * manipulation which is harder
 * */
export const formatDatetime = (datetime: string, verbose = false, useUTC = false) => {
  // get the offset minutes and calculate the hours from them
  const utcOffset = dayjs(datetime).utcOffset() / 60;

  const suffix = useUTC
    ? 'UTC'
    : `G[M]T${utcOffset > 0 ? '+' : ''}${utcOffset !== 0 ? utcOffset : ''}`;
  const format = verbose ? `dddd, DD MMMM YYYY, HH:mm (${suffix})` : `YYYY-MM-DD HH:mm ${suffix}`;

  // properly format the date
  return (useUTC ? dayjs.utc(datetime) : dayjs(datetime)).format(format);
};

/** Slice text to 7 characters, mostly used for hashIds */
export const shortenId = (id: string) => id.slice(0, 7);

/** Checking if string is a proper hash */
export const isHash = (str: string) => CHECK_IF_HASH_REGEX.test(str);

/** Converts minutes integer to representative string i.e. 15 -> 15min,  120 -> 2h */
export const minutesToString = (minutes: number) =>
  minutes < 60 ? `${minutes}min` : `${minutes / 60}h`;

/** Converts seconds to an `h m s` format */
export const secondsToHMS = (seconds: number) => {
  if (seconds < 60) {
    return `${seconds}s`;
  }

  if (seconds < 3600) {
    return `${Math.floor(seconds / 60)}m ${seconds % 60}s`;
  }

  return `${Math.floor(seconds / 3600)}h ${Math.floor((seconds % 3600) / 60)}m ${seconds % 60}s`;
};

/** Converts seconds number to representative string i.e. 15 -> 15sec,  7200 -> 2 hours */
export const secondsToString = (seconds: number) => {
  if (seconds > 60 * 60 * 24 * 30 * 12) {
    return `${(seconds / (60 * 60 * 24 * 30 * 12)).toLocaleString()} years`;
  }
  if (seconds > 60 * 60 * 24 * 30) {
    return `${(seconds / (60 * 60 * 24 * 30)).toLocaleString()} months`;
  }
  if (seconds > 60 * 60 * 24) {
    return `${(seconds / (60 * 60 * 24)).toLocaleString()} days`;
  }
  if (seconds > 60 * 60) {
    return `${(seconds / (60 * 60)).toLocaleString()} hours`;
  }
  if (seconds > 60) {
    return `${(seconds / 60).toLocaleString()} min`;
  }
  return `${seconds.toLocaleString()} sec`;
};

/** Converts bytes number to representative string i.e. 1024 -> 1 KB */
export const formatBytes = (bytes: number) => {
  if (bytes < 1) {
    return `${Math.max(bytes, 0)} B`;
  }
  const k = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(2))} ${sizes[i]}`;
};

/**
 * Given a server-received DateTime string, creates a proper time-ago display text for it.
 * */
export const getElapsedTime = (unixTimestamp: number) => {
  dayjs.extend(relativeTime);
  return dayjs.unix(unixTimestamp).fromNow();
};

type ElapsedTimeDurationOptions = { unit?: DurationUnitType; withSuffix?: boolean };
/**
 * Given a server-received duration integer creates a proper humanize display text for it.
 * */
export const getElapsedTimeDuration = (
  value: number,
  { unit = 'seconds', withSuffix }: ElapsedTimeDurationOptions = {}
) => {
  dayjs.extend(duration);
  dayjs.extend(relativeTime);
  return dayjs.duration(value, unit).humanize(withSuffix);
};

/**
 * makes sure that it properly formats a JSON struct in order to be properly displayed within the
 * editor
 * @param code valid JSON
 * @returns String
 */
export const formatJSON = (code: { [key: string]: unknown }) => JSON.stringify(code, null, '\t');

/**
 * makes sure that it properly formats a stringified JSON struct in order to be properly displayed within the
 * editor
 * @param str Any string that
 * @returns String
 */
export const formatStringifiedJSONSafe = (str: string) => {
  try {
    return formatJSON(JSON.parse(str));
  } catch (err) {
    return str;
  }
};

/**
 * sums up the total number of items based on the active/suppresed count breakdown that the API
 * exposes
 */
export const getComplianceItemsTotalCount = (totals: ActiveSuppressCount) => {
  return (
    totals.active.pass +
    totals.active.fail +
    totals.active.error +
    totals.suppressed.pass +
    totals.suppressed.fail +
    totals.suppressed.error
  );
};

/**
 * @param value A dictionary or object value that should be encoded
 * @return The Base64 string of the value provided
 */
export const safeBase64Encode = <T,>(value: T) => {
  return btoa(unescape(encodeURIComponent(JSON.stringify(value))));
};

/**
 * @param base64String A Base64 string that needs to be decoded
 * @return The Base64 string of the value provided
 */
export const safeBase64Decode = <T,>(base64String: string): T => {
  return JSON.parse(decodeURIComponent(escape(window.atob(base64String))));
};

/**
 * sums up the total number of policies based on the severity and compliance status count breakdown
 * that the API exposes. With this function we can choose to aggregate only the failing policies
 * for a severity or even all of them, simply by passing the corresponding array of statuses to
 * aggregate.
 *
 * For example:
 * countPoliciesBySeverityAndStatus([], 'critical', ['fail', 'error']) would count the critical
 * policies that are either failing or erroring
 */
export const countPoliciesBySeverityAndStatus = (
  data: OrganizationReportBySeverity,
  severity: keyof OrganizationReportBySeverity,
  complianceStatuses: (keyof ComplianceStatusCounts)[]
) => {
  return sum(complianceStatuses.map(complianceStatus => data[severity][complianceStatus]));
};

/**
 * sums up the total number of resources based on the compliance status count breakdown
 * that the API exposes. With this function we can choose to aggregate only the failing resources
 * or even all of them, simply by passing the corresponding array of statuses to
 * aggregate.
 *
 * For example:
 * countResourcesByStatus([], ['fail', 'error']) would count the resources that are either failing
 * or erroring
 */
export const countResourcesByStatus = (
  data: ScannedResources,
  complianceStatuses: (keyof ComplianceStatusCounts)[]
) => {
  // aggregates the list of "totals" for each resourceType. The "total" for a resource type is the
  // aggregation of ['fail', 'error', ...] according to the parameter passed by the user
  return sum(
    data.byType.map(({ count }) =>
      sum(complianceStatuses.map(complianceStatus => count[complianceStatus]))
    )
  );
};

/**
 * A function that takes the whole GraphQL error as a payload and returns the message that should
 * be shown to the user
 */
export const extractErrorMessage = (error: ApolloError | ErrorResponse) => {
  // If there is a network error show something (we are already showing the network-error-modal though)
  if (error.networkError) {
    const { statusCode } = error.networkError as ServerError;
    if (statusCode === 502) {
      return 'Request timed out';
    }

    if (statusCode === 422) {
      return 'Unable to forward request to the underlying services. Please try again';
    }

    if ('result' in error.networkError) {
      return error.networkError.result.message || error.networkError.message;
    }

    return error.networkError.message;
  }

  // If there are no networkErrors or graphQL errors, then show the fallback
  if (!error.graphQLErrors || !error.graphQLErrors.length) {
    return 'A unpredicted server error has occurred';
  }

  // isolate the first GraphQL error. Currently all of our APIs return a single error. If we ever
  // return multiple, we should handle that for all items within the `graphQLErrors` key
  const { extensions, message } = error.graphQLErrors[0];

  const statusCode = extensions?.statusCode as number | null;
  switch (statusCode) {
    case 401:
    case 403:
      return capitalize(message) || 'You are not authorized to perform this request';
    case 404:
      return capitalize(message) || "The resource you requested couldn't be found on our servers";
    default:
      return capitalize(message);
  }
};

// Copies a text to clipboard, with fallback for Safari and old-Edge
export const copyTextToClipboard = (text: string) => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text);
  } else {
    const container = document.querySelector('[role="dialog"] [role="document"]') || document.body;
    const textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    textArea.style.position = 'fixed'; // avoid scrolling to bottom
    container.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand('copy');
    container.removeChild(textArea);
  }
};

/**
 * Parses an S3 protocol URL and returns the bucket and key
 * e.g. s3://test_bucket/key_example.jsonl
 */
export const parseS3Url = (s3Url: string) => {
  const s3UrlRegex = S3_OBJECT_PATH_REGEX;
  const match = s3Url.match(s3UrlRegex);
  if (!match) {
    throw new Error(`Please provide a properly formatted S3 url`);
  }
  return {
    bucket: match[1],
    key: match[2],
  };
};

/**
 * A function that takes a text and returns a valid slug for it. Useful for filename and url
 * creation
 *
 * @param {String} text A string to parse
 * @returns {String} A slugified string
 */
export function slugify(text: string) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

export const isNumber = (value: string) => /^-{0,1}\d+$/.test(value);

/**
 * A function that returns true if the string consists of only non-whitespace characters
 * @param {string} value A string to test
 */
export const hasNoWhitespaces = (value: string) => /^\S+$/.test(value);

export const toStackNameFormat = (val: string) => val.replace(/ |_/g, '-').toLowerCase();

/*
Given a list of permissions, returns the actual permissions that should be activated. For
example, if `ModifyUser` is active, then `ReadUser` should automatically be active as well
 */
export const expandPermissions = (rolePermissions: Permission[]) => {
  const expandedPermissions = new Set(rolePermissions);
  rolePermissions
    .filter(r => r.includes('Modify'))
    .forEach(rolePermission => {
      expandedPermissions.add(rolePermission.replace('Modify', 'Read') as Permission);
    });

  return [...expandedPermissions]; // convert `Set` back to `Array`
};

/**
 * Check whether a Cognito user is coming from SAML
 */

export const isCognitoSamlUser = (userInfo?: Partial<UserInfo> & Pick<UserInfo, 'identities'>) => {
  if (!userInfo) {
    return false;
  }

  const { identities } = userInfo;
  if (!identities) {
    return false;
  }
  // For reference and backwards compatible reasons identities may come up as a string from Cognito or the localstorage cache.
  // thus we need to ensure that everything works as expected.
  let parsedIdenties;
  try {
    if (isString(identities)) {
      parsedIdenties = JSON.parse(identities);
    } else if (isArray(identities)) {
      parsedIdenties = identities;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
  return parsedIdenties.some(identity => identity?.providerName === PANTHER_SAML_USER_PREFIX);
};

/**
 * Check whether a user is coming from SAML
 */
export const isSamlUser = (
  user: Partial<User | UserDetails | UserFormValues> &
    Pick<User | UserDetails | UserFormValues, 'id'>
) => {
  const { id } = user;
  return id?.startsWith(PANTHER_SAML_USER_PREFIX);
};

/*
Given a user, returns a human readable string to show for the user's name
*/
export const getActorDisplayName = (actor: ActorTeaser) => {
  if (!actor) {
    return 'Unknown';
  }

  if ('familyName' in actor) {
    const user = actor as UserTeaser;
    if (user.givenName && user.familyName) {
      return `${user.givenName} ${user.familyName}`;
    }
    return user.givenName || user.familyName || user.email;
  }

  return (actor as APITokenTeaser).name;
};

/**
 * Generates a random HEX color
 */
export const generateRandomColor = () => Math.floor(Math.random() * 16777215).toString(16);

/**
 * Converts a rem measurement (i.e. `0.29rem`) to pixels. Returns the number of pixels
 */
export const remToPx = (rem: string) => {
  return parseFloat(rem) * parseFloat(getComputedStyle(document.documentElement).fontSize);
};

/**
 * Appends a trailing slash if missing from a url.
 *
 * @param {String} url A URL to check
 * @returns {String} A URL with a trailing slash
 */
export const addTrailingSlash = (url: string) => {
  return url.endsWith('/') ? url : `${url}/`;
};

/**
 * Generating an OAuth link for an integration
 */
export const generateIntegrationOAuthLink = (integrationId: string, redirectUri: string) => {
  // Success Redirect url for successful authorization
  const successUrl = encodeURIComponent(`${window.location.origin}${redirectUri}`);
  // Public endpoint for authorization an oauth app via the backend
  return `https://${PANTHER_CONFIG.PUBLIC_CORE_API_HOST}/sources-oauth2/authorize?id=${integrationId}&success_url=${successUrl}`;
};

/**
 * Checks whether a log source is missing OAuth authorization
 */
export const checkIfSourceIsAuthorized = (source: Pick<LogPullingIntegration, 'oauth2'>) => {
  if (!source) {
    return false;
  }

  return !source.oauth2?.mustAuthorize;
};

/**
 * OAuth redirect url needed for whitelisting in some integration i.e. Box
 */
export const OAuthRedirectUrl = `https://${PANTHER_CONFIG.PUBLIC_CORE_API_HOST}/sources-oauth2/token_redirect`;

/**
 * Interface for Indicator Search parameters for used in IndicatorSearch and DataExplorer
 */
export interface IndicatorUrlParams {
  snippedId: string;
  databaseName: string;
  tableName: string;
  endTime: string;
  startTime: string;
  indicatorName: string;
  i: string[];
  logType: string;
}

/**
 * Strips hashes and query params from a URI, returning the pathname
 *
 * @param {String} uri A relative URI
 * @returns {String} The same URI stripped of hashes and query params
 */
export const getPathnameFromURI = (uri: string) => uri.split(/[?#]/)[0];

export const getCurrentYear = () => {
  return dayjs().format('YYYY');
};

export const getGraphqlSafeDateRange = ({
  days = 0,
  hours = 0,
}: {
  days?: number;
  hours?: number;
}) => {
  const utcNow = dayjs.utc();
  const utcDaysAgo = utcNow.subtract(days, 'day').subtract(hours, 'hour');

  // the `startOf` and `endOf` help us have "constant" inputs for a few minutes, when we are using
  // those values as inputs to a GraphQL query. Of course there are edge cases.
  return [
    utcDaysAgo.startOf('hour').format('YYYY-MM-DDTHH:mm:ss[Z]'),
    utcNow.endOf('hour').format('YYYY-MM-DDTHH:mm:ss[Z]'),
  ];
};

// FIXME: Remove function when we have better alert filtering
export const getGraphqlSafeDateRangeFuture = ({
  days = 0,
  hours = 0,
}: {
  days?: number;
  hours?: number;
}) => {
  const utcNow = dayjs.utc();
  const utcFuture = utcNow.add(1, 'day');
  const utcDaysAgo = utcNow.subtract(days, 'day').subtract(hours, 'hour');

  // the `startOf` and `endOf` help us have "constant" inputs for a few minutes, when we are using
  // those values as inputs to a GraphQL query. Of course there are edge cases.
  return [
    utcDaysAgo.startOf('hour').format('YYYY-MM-DDTHH:mm:ss[Z]'),
    utcFuture.endOf('hour').format('YYYY-MM-DDTHH:mm:ss[Z]'),
  ];
};

export const formatNumber = (num: number): string => {
  return new Intl.NumberFormat().format(num);
};

export const abbreviateNumber = (num: number): string => {
  let abbreviation = `${num}`;

  const abbreviations = [
    [1000, 'k'],
    [1000000, 'm'],
    [1000000000, 'b'],
  ] as const;

  abbreviations.forEach(([divisor, suffix]) => {
    if (num >= divisor) {
      const value = num / divisor;
      const truncatedValue = num % divisor === 0 ? value.toFixed(0) : value.toFixed(1);
      abbreviation = [truncatedValue, suffix].join('');
    }
  });

  return abbreviation;
};

/**
 *
 * Downloads the blob after converting string to blob if needed as a file
 *
 * @param data Blob or string data to download
 * @param filename The name to save it under, along  with the extension. i.e. file.csv
 *
 */
export const downloadData = (data: Blob | string, filename: string) => {
  let blob;
  if (data instanceof Blob) {
    blob = data;
  } else {
    const extension = filename.split('.')[1];
    blob = new Blob([data], {
      type: `text/${extension};charset=utf-8`,
    });
  }
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();

  window.URL.revokeObjectURL(url);
};

/**
 *
 * Converting base64 string data to blob
 *
 * @param base64 The base64 data to convert.
 *
 */
export const base64ZipToBlob = (base64: string) => {
  const binaryString = window.atob(base64);
  const binaryLen = binaryString.length;

  const ab = new ArrayBuffer(binaryLen);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < binaryLen; i += 1) {
    ia[i] = binaryString.charCodeAt(i);
  }
  return new Blob([ab]);
};

/**
 * Helper function that return key from Enumaration value
 * @param object
 * @param value
 */
export function getEnumKeyByValue(object: { [key: string]: string }, value: string) {
  return Object.keys(object).find(key => object[key] === value);
}

/**
 * Helper function that returns proper text for Alert Type
 */
export const alertTypeToString = (type: AlertType, { useSingular = false } = {}) => {
  switch (type) {
    case AlertType.Rule:
      return useSingular ? 'Rule Match' : 'Rule Matches';
    case AlertType.RuleError:
      return useSingular ? 'Rule Error' : 'Rule Errors';
    case AlertType.Policy:
      return useSingular ? 'Policy Failure' : 'Policy Failures';
    case AlertType.SystemError:
      return useSingular ? 'System Error' : 'System Errors';
    case AlertType.ScheduledRule:
      return useSingular ? 'Scheduled Rule Match' : 'Scheduled Rule Matches';
    case AlertType.ScheduledRuleError:
    default:
      return useSingular ? 'Scheduled Rule Error' : 'Scheduled Rule Errors';
  }
};

export const cleanUpEmptyDetectionTestMocks = (
  tests: DetectionTestDefinition[]
): DetectionTestDefinition[] => {
  return tests.map(({ mocks = [], ...rest }) => ({
    ...rest,
    mocks: mocks.filter(mock => !!mock.objectName),
  }));
};

/**
 * Add values to cron expression
 */
export const formatCronExpression = (values: {
  minutes?: string;
  hours?: string;
  days?: string;
  months?: string;
  weekDays?: string;
}) => {
  return `${values.minutes} ${values.hours} ${values.days} ${values.months} ${values.weekDays}`;
};

/**
 * Get default cron values
 * @param cronExpression
 */

export const splitCronValues = (cronExpression = DEFAULT_CRON_EXPRESSION) => {
  const [minutes, hours, days, months, weekDays] = cronExpression.split(' ');
  return [minutes, hours, days, months, weekDays];
};

/**
 * Converts a word to its plural form
 *
 * @returns {String} pluralized word
 *
 * @example
 * toPlural('example'); // => 'examples'
 * toPlural('example', 10); // => 'examples'
 * toPlural('example', 1); // => 'example'
 * toPlural('example', 'examplez', 10); // => 'examplez'
 * toPlural('example', 'examplez', 1); // => 'example'
 */
function toPlural(word: string): string;
function toPlural(word: string, count: number): string;
function toPlural(word: string, pluralForm: string, count: number): string;
function toPlural(word: string, pluralFormOrCount?: number | string, count?: number) {
  const plrl = typeof pluralFormOrCount === 'string' ? pluralFormOrCount : undefined;
  const cnt = typeof pluralFormOrCount === 'number' ? pluralFormOrCount : count;

  const pluralForm = plrl || `${word}s`;

  return cnt === 1 ? word : pluralForm;
}
export { toPlural };

/**
 * Compares two semver version
 * returns 1 if a version is bigger than b
 * returns -1 if a version is smaller than b
 * returns 0 if a version is equal to b
 * @returns Number [1,0,-1]
 */
export function compareSemanticVersion(a: string, b: string) {
  const av = a.match(/([0-9]+|[^0-9]+)/g);
  const bv = b.match(/([0-9]+|[^0-9]+)/g);
  for (;;) {
    let ia = av.shift();
    let ib = bv.shift();
    if (typeof ia === 'undefined' && typeof ib === 'undefined') {
      return 0;
    }
    if (typeof ia === 'undefined') {
      ia = '';
    }
    if (typeof ib === 'undefined') {
      ib = '';
    }

    const ian = parseInt(ia, 10);
    const ibn = parseInt(ib, 10);
    if (Number.isNaN(ian) || Number.isNaN(ibn)) {
      // non-numeric comparison
      if (ia < ib) {
        return -1;
      }
      if (ia > ib) {
        return 1;
      }
    } else {
      if (ian < ibn) {
        return -1;
      }
      if (ian > ibn) {
        return 1;
      }
    }
  }
}

type SortingType = { sortBy?: unknown; sortDir?: SortDirEnum };

export type WrapSortingFormValues<T extends SortingType> = Omit<T, 'sortBy' | 'sortDir'> & {
  sorting?: string;
};

export interface SortingOption<T extends SortingType> {
  opt: string;
  resolution: Pick<T, 'sortBy' | 'sortDir'>;
}

/**
 * Since `sortBy` and `sortDir` parameters are not responding to any form
 * values we should extract them from the `sorting` combobox value.
 */
export const extractSortingOptions = <T,>(
  values: WrapSortingFormValues<T>,
  sortingOptions: SortingOption<T>[]
) => {
  const { sorting, ...rest } = values;
  const sortingParams = sortingOptions.find(param => param.opt === sorting);
  return {
    ...rest,
    ...(sortingParams?.resolution ?? { sortBy: null, sortDir: null }),
  };
};

/**
 * Since `sorting` is not responding to some Input key we shall extract
 * this information from `sortBy` and `sortDir` parameters in order to align the
 * combobox values.
 */
export const wrapSortingOptions = <T extends SortingType>(
  params: T,
  sortingOptions: SortingOption<T>[]
): WrapSortingFormValues<T> => {
  const { sortBy, sortDir, ...rest } = params;
  const option = sortingOptions.find(
    param => param.resolution.sortBy === sortBy && param.resolution.sortDir === sortDir
  );

  return {
    ...(option ? { sorting: option.opt } : {}),
    ...rest,
  };
};

export const tryOrNoop = (fn: () => any) => {
  try {
    fn();
  } catch (err) {
    // noop
  }
};

// Schema utils
export const DATA_SCHEMA_ID_PREFIX = 'Custom.';
export const stripDataSchemaPrefix = (name = '') => name.replace(DATA_SCHEMA_ID_PREFIX, '');
export const appendDataSchemaPrefix = (name = '') => `${DATA_SCHEMA_ID_PREFIX}${name}`;

export type DataSchemaErrors = Record<string, (ValidationError | YAMLException)[]>;

export const validateDataSchema = async (schema: string): Promise<DataSchemaErrors> => {
  const { Validator } = await import(/* webpackChunkName: "json-schema-validation" */ 'jsonschema');
  const { default: yaml } = await import(
    /* webpackChunkName: "json-schema-validation" */ 'js-yaml'
  );
  try {
    const validator = new Validator();
    const schemaAsObject = yaml.load(schema);
    const result = validator.validate(schemaAsObject, schemaBlueprint as any, {
      propertyName: 'root',
    });

    if (!result.errors.length) {
      return {};
    }
    // Removes un-necessary errors that are bloating the UI
    const withoutSchemaAllOfErrors = result.errors.filter(err => err.name !== 'allOf');

    // Group errors by their associated field
    const errorsByField = groupBy(withoutSchemaAllOfErrors, err => err.property);
    return errorsByField;
  } catch (err) {
    const yamlError = err as YAMLException;
    return {
      [yamlError.name]: [{ name: yamlError.name, message: yamlError.message }],
    };
  }
};

export const dataSchemaNameValidation = (existingLogTypes: string[]) => {
  return (
    Yup.string()
      // When we create schema in AWS Glue to query in Athena.
      // A table name cannot be longer than 255 characters.
      // 255 - SCHEMA_ID_PREFIX.length
      .max(248)
      .matches(/[^-_\s]+$/, 'Must not contain underscore, dash and whitespace')
      .matches(/^[A-Z]/, 'Must start with a capital letter. For example: Kubernetes')
      .matches(
        /^([A-Z][A-Za-z0-9]+)(\.[A-Z][A-Za-z0-9]+){0,5}$/,
        'Every word must start with a capital letter followed by alphanumeric characters and a dot can be used as a word delimiter. For example: Custom.Kubernetes.Audit'
      )
      .test(
        'mutex',
        `You already have a Data Schema with the same name`,
        (name: string) => !existingLogTypes?.includes(appendDataSchemaPrefix(name))
      )
      .required()
  );
};

export const dataSchemaValidation = (existingLogTypes: string[]) => {
  return Yup.object({
    name: dataSchemaNameValidation(existingLogTypes),
    revision: Yup.number(),
    description: Yup.string(),
    referenceURL: Yup.string().url(),
    spec: Yup.string().required(),
  });
};

/**
 * FileReader Utils
 */
export const fileToText = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = e => resolve(e.target.result as string);
    reader.onerror = error => reject(error);
  });

export const stripNullValuesFromJSON = (data: Record<string, unknown>) =>
  Object.keys(data)
    .filter(key => !(data[key] === null || data[key] === undefined))
    .reduce((acc, k) => {
      acc[k] = data[k];
      return acc;
    }, {});

export const typedMemo: <T>(c: T) => T = React.memo;

export const countLineBreaks = (str: string) => {
  /* counts \n */
  try {
    return str.match(/[^\n]*\n[^\n]*/gi).length;
  } catch (e) {
    return 0;
  }
};

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import urls from 'Source/urls';
import { Box, Card, IconButton, Popover, PopoverContent, PopoverTrigger } from 'pouncejs';
import { PANTHER_DOCS_LINK } from 'Source/constants';
import NavLink, { NavLinkExternal } from 'Components/Navigation/NavLink';

const HelpMenu: React.FC = () => {
  return (
    <Popover>
      {({ close: closePopover }) => (
        <React.Fragment>
          <PopoverTrigger
            as={IconButton}
            id="help-menu"
            icon="help-alt"
            variantBorderStyle="circle"
            variantColor="navyblue-700"
            padding="10px"
            data-tid="help-menu-dropdown-button"
            aria-label="Toggle Help Menu"
          />
          <PopoverContent alignment="bottom-left">
            <Card as="ul" my={1} px={3} py={2} shadow="dark300" minWidth={180}>
              <Box as="li">
                <NavLink
                  label="Support"
                  to={urls.account.support()}
                  isSecondary
                  onClick={closePopover}
                />
              </Box>
              <Box as="li" mt="auto">
                <NavLinkExternal
                  label={'Documentation'}
                  href={PANTHER_DOCS_LINK}
                  isSecondary
                  onClick={closePopover}
                />
              </Box>
            </Card>
          </PopoverContent>
        </React.Fragment>
      )}
    </Popover>
  );
};

export default React.memo(HelpMenu);

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import useUrlParams, { UseUrlParamsProps } from 'Hooks/useUrlParams';

function useRequestParamsWithPagination<AvailableParams extends { page?: number }>(
  parsingOptions?: UseUrlParamsProps<AvailableParams>
) {
  const { urlParams, updateUrlParams, setUrlParams } = useUrlParams<Partial<AvailableParams>>(
    parsingOptions
  );

  // This is our typical function that updates the parameters with the addition of resetting the
  // page to `1`. The only time where we don't wanna do that, is when changing pages. In this
  // scenario, we want to change the params but not reset the page.
  const updateRequestParamsAndResetPaging = React.useCallback(
    (newParams: Partial<AvailableParams>) => {
      updateUrlParams({ ...urlParams, ...newParams, page: 1 }, true);
    },
    // FIXME: look into hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [urlParams]
  );

  // This is a similar function like the above but instead of updating the existing params with the
  // new parameters, it clears all the parameters and just sets the parameters passed as an argument
  const setRequestParamsAndResetPaging = React.useCallback(
    (newParams: Partial<AvailableParams>) => {
      setUrlParams({ ...newParams, page: 1 }, true);
    },
    // FIXME: look into hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [urlParams]
  );

  // This is the function to call whenever a page changes. The difference lies in the value of the
  // `page` value
  const updatePagingParams = React.useCallback(
    (newPage: AvailableParams['page']) => {
      updateUrlParams({ ...urlParams, page: newPage }, true);
    },
    // FIXME: look into hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [urlParams]
  );

  return React.useMemo(
    () => ({
      requestParams: urlParams,
      updateRequestParamsAndResetPaging,
      setRequestParamsAndResetPaging,
      updatePagingParams,
    }),
    // FIXME: look into hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [urlParams]
  );
}
export default useRequestParamsWithPagination;

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { ENABLE_REPORTS } from 'Source/constants';
import urls from 'Source/urls';
import { Permission } from 'Generated/schema';
import SecondaryNavigationMenu from '../SecondaryNavigationMenu';
import { NavigationLink } from '../constants';

const buildNavLinks: NavigationLink[] = [
  {
    to: urls.build.detections.list(),
    icon: 'lookups',
    label: 'Detections',
    permissions: [
      Permission.RuleRead,
      Permission.PolicyRead,
      Permission.RuleModify,
      Permission.PolicyModify,
    ],
  },
  {
    to: urls.build.globalHelpers.list(),
    icon: 'source-code',
    label: 'Helpers',
    permissions: [
      Permission.RuleRead,
      Permission.PolicyRead,
      Permission.RuleModify,
      Permission.PolicyModify,
    ],
  },
  {
    to: `${urls.build.packs.list({ disableDefaultParams: false })}`,
    icon: 'packs',
    label: 'Packs',
    permissions: [
      Permission.RuleRead,
      Permission.PolicyRead,
      Permission.RuleModify,
      Permission.PolicyModify,
    ],
  },
  {
    to: urls.build.bulkUploader(),
    icon: 'multiple-upload',
    label: 'Bulk Uploader',
    permissions: [Permission.RuleModify, Permission.PolicyModify],
  },
  {
    to: urls.build.dataModels.list(),
    icon: 'data',
    label: 'Data Models',
    permissions: [Permission.LogSourceRead, Permission.LogSourceModify],
  },
];

const mitreReportLink: NavigationLink = {
  to: urls.build.mitreMatrix(),
  icon: 'organization',
  label: 'MITRE ATT&CK®',
  permissions: [
    Permission.RuleRead,
    Permission.PolicyRead,
    Permission.RuleModify,
    Permission.PolicyModify,
  ],
};

export const buildNavigationsLinks: NavigationLink[] = ENABLE_REPORTS
  ? [...buildNavLinks, mitreReportLink]
  : buildNavLinks;

const BuildNavigation: React.FC = () => {
  return <SecondaryNavigationMenu links={buildNavigationsLinks} />;
};

export default React.memo(BuildNavigation);

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { LogSourceType } from 'Source/constants';
import { LogPullerTypeEnum } from 'Generated/schema';
import urls from 'Source/urls';
import {
  CreateComplianceSource,
  EditComplianceSource,
  ListComplianceSources,
} from './CloudAccounts';
import { CreateDestination, EditDestination, ListDestinations } from './Destinations';
import {
  CreateLogSourceMapper,
  EditLogSourceMapper,
  LogSourceDetailsPage,
  ListLogSources,
  LogSourceOnboarding,
  OAuthAuthorization,
  SchemasManagement,
} from './LogSources';

const IntegrationRoutes = () => (
  <Switch>
    <Redirect
      exact
      from={urls.integrations.home()}
      to={urls.integrations.logSources.list({ disableDefaultParams: false })}
    />
    {/* ******************* DESTINATIONS ***************************** */}
    <Route exact path={urls.integrations.destinations.list()} component={ListDestinations} />
    <Route exact path={urls.integrations.destinations.create()} component={CreateDestination} />
    <Route exact path={urls.integrations.destinations.edit(':id')} component={EditDestination} />
    {/* ******************* LOGSOURCES ***************************** */}
    <Route exact path={urls.integrations.logSources.list()} component={ListLogSources} />
    <Route exact path={urls.integrations.logSources.create()} component={LogSourceOnboarding} />
    <Route
      exact
      path={urls.integrations.logSources.create(':type' as LogSourceType)}
      component={CreateLogSourceMapper}
    />
    <Route
      exact
      path={urls.integrations.logSources.edit(':id', ':type' as LogSourceType)}
      component={EditLogSourceMapper}
    />
    <Route
      exact
      path={urls.integrations.logSources.authorize(':id', ':type' as LogPullerTypeEnum)}
      component={OAuthAuthorization}
    />
    <Route
      exact
      path={urls.integrations.logSources.details(':id', ':type' as LogSourceType)}
      component={LogSourceDetailsPage}
    />
    <Route
      path={urls.integrations.logSources.schemasManagement(':id').home()}
      component={SchemasManagement}
    />
    {/* ******************* CLOUDACCOUNTS ***************************** */}
    <Route exact path={urls.integrations.cloudAccounts.list()} component={ListComplianceSources} />
    <Route
      exact
      path={urls.integrations.cloudAccounts.create()}
      component={CreateComplianceSource}
    />
    <Route
      exact
      path={urls.integrations.cloudAccounts.edit(':id')}
      component={EditComplianceSource}
    />

    <Redirect
      exact
      from={`${urls.integrations.logSources.list()}:type`}
      to={urls.integrations.logSources.list({ disableDefaultParams: false })}
    />
  </Switch>
);

export default IntegrationRoutes;

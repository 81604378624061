/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import urls from 'Source/urls';
import { Permission } from 'Generated/schema';
import SecondaryNavigationMenu from '../SecondaryNavigationMenu';
import { NavigationLink } from '../constants';

export const investigateNavLinks: NavigationLink[] = [
  {
    to: urls.investigate.dataExplorer(),
    icon: 'data-explorer',
    label: 'Data Explorer',
    permissions: [Permission.DataAnalyticsRead],
  },
  {
    to: urls.investigate.savedQueries.list(),
    icon: 'save',
    label: 'Saved Queries',
    permissions: [Permission.DataAnalyticsRead],
  },
  {
    to: urls.investigate.indicatorSearch(),
    icon: 'search',
    label: 'Indicator Search',
    permissions: [Permission.DataAnalyticsRead],
  },
  {
    to: urls.investigate.queryHistory(),
    icon: 'history',
    label: 'Query History',
    permissions: [Permission.DataAnalyticsRead],
  },
];

const InvestigateNavigation: React.FC = () => {
  return <SecondaryNavigationMenu links={investigateNavLinks} />;
};

export default React.memo(InvestigateNavigation);

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Card, Flex, Heading } from 'pouncejs';
import BarChart from 'Components/charts/BarChart';
import DifferenceText from './DifferenceText';

interface AlertSummaryProps {
  totalAlerts: number;
  totalAlertsDiff: number;
}

const AlertSummary: React.FC<AlertSummaryProps> = ({ totalAlerts, totalAlertsDiff }) => {
  // FIXME: Fix rules-of-hooks violation (hook used after an early return). We'll probably need to
  // do some refactoring to the component to accomplish this.
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const alertsChartData = React.useMemo(
    () => [
      {
        label: 'Current Period',
        value: Number(totalAlerts), // removes zero decimals (i.e. 1.00 -> 1)
        config: {
          height: 24,
          showLabel: true,
          color: 'red-300' as const,
        },
      },
      {
        label: 'Previous Period',
        value: Number(totalAlerts - totalAlertsDiff),
        config: {
          height: 24,
          showLabel: true,
          color: 'navyblue-200' as const,
        },
      },
    ],
    [totalAlerts, totalAlertsDiff]
  );

  return (
    <Card variant="dark" px={6} pt={6} pb={4}>
      <Flex direction="column" align="center" justify="space-between">
        <Box width="100%" textAlign="center">
          <Box id="total-alerts" fontWeight="bold">
            Total Alerts
          </Box>
          <Flex
            direction="column"
            align="center"
            justify="center"
            borderRadius="medium"
            my={3}
            pb={2}
            backgroundColor="navyblue-600"
          >
            <Heading
              as="h2"
              size="3x-large"
              color="red-300"
              fontWeight="bold"
              aria-describedby="total-alerts"
              data-testid="alerts-current-period"
            >
              {totalAlerts}
            </Heading>
            <DifferenceText diff={totalAlertsDiff} />
          </Flex>
        </Box>
        <Box width="100%">
          <BarChart data={alertsChartData} withDividers />
        </Box>
      </Flex>
    </Card>
  );
};

export default AlertSummary;

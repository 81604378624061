/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import useRouter from 'Hooks/useRouter';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import urls from 'Source/urls';
import AnalysisPackDetails from 'Pages/AnalysisPackDetails';
import CreatePackSourcePage from 'Pages/CreatePackSource';
import EditPackSourcePage from 'Pages/EditPackSource';
import ListAnalysisPacks from 'Pages/ListAnalysisPacks';

const PacksRoutes: React.FC = () => {
  const { location } = useRouter();

  return (
    <Switch>
      <Redirect
        exact
        from={urls.analysis.home()}
        to={urls.build.packs.list({ disableDefaultParams: false })}
      />
      <Redirect
        exact
        from={urls.analysis.packs.list()}
        to={{ pathname: urls.build.packs.list(), search: location.search }}
      />
      <Route exact path={urls.build.packs.list()} component={ListAnalysisPacks} />
      <Redirect
        exact
        from={urls.analysis.packs.packSources.home()}
        to={urls.build.packs.packSources.list()}
      />
      <Redirect
        exact
        from={urls.build.packs.packSources.home()}
        to={urls.build.packs.packSources.list()}
      />
      <Redirect
        exact
        from={urls.analysis.packs.details(':id')}
        to={{ pathname: urls.build.packs.details(':id'), search: location.search }}
      />
      <Route exact path={urls.build.packs.details(':id')} component={AnalysisPackDetails} />
      <Redirect
        exact
        from={urls.analysis.packs.packSources.details()}
        to={urls.build.packs.packSources.list()}
      />
      <Redirect
        exact
        from={urls.build.packs.packSources.details()}
        to={urls.build.packs.packSources.list()}
      />
      <Redirect
        exact
        from={urls.analysis.packs.packSources.create()}
        to={urls.build.packs.packSources.create()}
      />
      <Route exact path={urls.build.packs.packSources.create()} component={CreatePackSourcePage} />
      <Redirect
        exact
        from={urls.analysis.packs.packSources.edit(':id')}
        to={{ pathname: urls.build.packs.packSources.edit(':id'), search: location.search }}
      />
      <Route exact path={urls.build.packs.packSources.edit(':id')} component={EditPackSourcePage} />
    </Switch>
  );
};

export default PacksRoutes;

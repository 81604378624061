/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Card } from 'pouncejs';
import TimeSeriesChart, { TimeSeriesData } from 'Components/charts/TimeSeriesChart';
import { SeriesWithBreakdown } from 'Generated/schema';
import { useMetricsFiltersContext } from 'Components/utils/MetricsFiltersContext';

interface EventsByLogTypeTabProps {
  eventsProcessedPerLogType: SeriesWithBreakdown[];
}

const EventsByLogTypeTab: React.FC<EventsByLogTypeTabProps> = ({ eventsProcessedPerLogType }) => {
  const { resultProjectionStartDate } = useMetricsFiltersContext();

  const chartData = React.useMemo<TimeSeriesData>(() => {
    return {
      timestamps: Object.keys(eventsProcessedPerLogType[0]?.breakdown ?? []),
      series: eventsProcessedPerLogType.map(series => ({
        label: series.label,
        values: Object.values(series.breakdown),
      })),
    };
  }, [eventsProcessedPerLogType]);

  return (
    <Card variant="dark" data-testid="events-by-log-type-chart" height={289} py={5} pl={4}>
      <TimeSeriesChart
        chartId="events-by-log-type"
        data={chartData}
        zoomable
        projectionStartDate={resultProjectionStartDate}
      />
    </Card>
  );
};

export default React.memo(EventsByLogTypeTab);

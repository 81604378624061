/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import useModal from 'Hooks/useModal';
import UserFeedbackForm, { UserFeedbackFormValues } from 'Components/forms/UserFeedbackForm';
import useRouter from 'Hooks/useRouter';
import { Flex, Heading, Img, Button, useSnackbar, Box } from 'pouncejs';
import { extractErrorMessage } from 'Helpers/utils';
import SuccessImg from 'Assets/illustrations/success.svg';
import { useSendUserFeedback } from './graphql/sendUserFeedback.generated';

const initialValues: UserFeedbackFormValues = {
  featureUsefulness: null,
  feedbackText: '',
};

export interface UserFeedbackModalProps {
  /* the name of the feature that the user is submitting feedback for */
  featureName: string;
}

const UserFeedbackModal: React.FC<UserFeedbackModalProps> = ({ featureName }) => {
  const { hideModal } = useModal();
  const { pushSnackbar } = useSnackbar();
  const { location } = useRouter();

  const [sendUserFeedback, { data }] = useSendUserFeedback({
    onError: error => {
      pushSnackbar({
        variant: 'error',
        title: 'Failed to send feedback',
        description: extractErrorMessage(error),
      });
    },
  });

  const handleSubmit = React.useCallback(
    (values: UserFeedbackFormValues) => {
      return sendUserFeedback({
        variables: {
          input: {
            feature: {
              name: featureName,
            },
            feedback: {
              text: values.feedbackText,
              usefulness: values.featureUsefulness,
              locationUrl: location.pathname,
              screenWidth: window.innerWidth,
            },
          },
        },
      });
    },
    [featureName, sendUserFeedback, location.pathname]
  );

  if (data?.sendUserFeedback.id) {
    return (
      <Flex width={450} direction="column" align="center" justify="center">
        <Heading as="h2" size="small" mb={8}>
          Feedback received. Thank you!
        </Heading>
        <Img
          alt="Success illustration"
          src={SuccessImg}
          nativeWidth="auto"
          nativeHeight={125}
          mb={10}
        />
        <Button onClick={hideModal}>Close</Button>
      </Flex>
    );
  }

  return (
    <Box width={450}>
      <UserFeedbackForm initialValues={initialValues} onSubmit={handleSubmit} />
    </Box>
  );
};

export default UserFeedbackModal;

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Card, Flex } from 'pouncejs';
import TimeSeriesChart, { TimeSeriesData } from 'Components/charts/TimeSeriesChart';
import { capitalize } from 'Helpers/utils';

import { SeriesWithBreakdown } from 'Generated/schema';
import NoDataFound from 'Components/NoDataFound';
import { useMetricsFiltersContext } from 'Components/utils/MetricsFiltersContext';

interface AlertsBySeverityProps {
  alertsPerSeverity: SeriesWithBreakdown[];
}

const tooltipOptions = { hideZeros: false };

const AlertsBySeverity: React.FC<AlertsBySeverityProps> = ({ alertsPerSeverity }) => {
  const { resultProjectionStartDate } = useMetricsFiltersContext();
  const timeSeriesData = React.useMemo<TimeSeriesData>(() => {
    if (!alertsPerSeverity) {
      return null;
    }
    return {
      timestamps: Object.keys(alertsPerSeverity[0]?.breakdown ?? []),
      series: alertsPerSeverity.map(series => ({
        label: capitalize(series.label.toLowerCase()),
        values: Object.values(series.breakdown),
      })),
    };
  }, [alertsPerSeverity]);

  return (
    <Card variant="dark" ml={5} py={6} pl={6}>
      {timeSeriesData?.series?.length ? (
        <Flex data-testid="alert-by-severity-chart" height="100%">
          <TimeSeriesChart
            chartId="alerts-by-severity"
            data={timeSeriesData}
            zoomable
            title="Severity"
            projectionStartDate={resultProjectionStartDate}
            tooltipOptions={tooltipOptions}
          />
        </Flex>
      ) : (
        <NoDataFound title="No alerts are present in the system" />
      )}
    </Card>
  );
};

export default React.memo(AlertsBySeverity);

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex } from 'pouncejs';
import logo from 'Assets/workday-logo.svg';
import ErrorBoundary from 'Components/ErrorBoundary';
import { Field, useFormikContext } from 'formik';
import FormikTextInput from 'Components/fields/TextInput';
import { WizardPanel } from 'Components/Wizard';
import EditIntegrationActions from 'Pages/Integrations/components/EditIntegrationActions';
import { WorkdayLogsSourceWizardValues } from './WorkdayLogSourceWizard';

const ConfigurationPanel = () => {
  const { initialValues, errors, values } = useFormikContext<WorkdayLogsSourceWizardValues>();

  const editMode = !!initialValues.integrationId;
  const shouldDisableNextStep =
    !!errors.integrationLabel ||
    !values.integrationLabel ||
    !!errors.tenantName ||
    !values.tenantName ||
    !!errors.tenantHostName ||
    !values.tenantHostName;

  return (
    <WizardPanel>
      <Box width={500} m="auto">
        <WizardPanel.Heading
          title={editMode ? 'Update Workday Logs source' : 'Let’s start with the basics'}
          subtitle={
            editMode
              ? 'Feel free to make any changes to your Workday log source'
              : 'We need to know where to get your logs from'
          }
          logo={logo}
        />
        <ErrorBoundary>
          <Flex direction="column" spacing={4} width={1}>
            <Field
              name="integrationLabel"
              as={FormikTextInput}
              label="Name"
              placeholder="A nickname for your Workday log source"
              required
            />
            <Field
              name="tenantName"
              label="Tenant Name"
              as={FormikTextInput}
              placeholder="Your Workday tenant name"
              required
            />
            <Field
              name="tenantHostName"
              label="Tenant Host Name"
              as={FormikTextInput}
              placeholder="Your Workday tenant host name"
              required
            />
          </Flex>
        </ErrorBoundary>
      </Box>
      {editMode ? (
        <EditIntegrationActions />
      ) : (
        <WizardPanel.Actions>
          <WizardPanel.ActionNext disabled={shouldDisableNextStep}>
            Continue Setup
          </WizardPanel.ActionNext>
        </WizardPanel.Actions>
      )}
    </WizardPanel>
  );
};

export default ConfigurationPanel;

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../../__generated__/schema';

import { MicrosoftGraphLogIntegrationDetails } from '../../../../../../graphql/fragments/MicrosoftGraphLogIntegrationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type UpdateMicrosoftGraphLogSourceVariables = {
  input: Types.UpdateLogPullingIntegrationInput;
};

export type UpdateMicrosoftGraphLogSource = {
  updateLogPullingIntegration: MicrosoftGraphLogIntegrationDetails;
};

export const UpdateMicrosoftGraphLogSourceDocument = gql`
  mutation UpdateMicrosoftGraphLogSource($input: UpdateLogPullingIntegrationInput!) {
    updateLogPullingIntegration(input: $input) {
      ...MicrosoftGraphLogIntegrationDetails
    }
  }
  ${MicrosoftGraphLogIntegrationDetails}
`;
export type UpdateMicrosoftGraphLogSourceMutationFn = ApolloReactCommon.MutationFunction<
  UpdateMicrosoftGraphLogSource,
  UpdateMicrosoftGraphLogSourceVariables
>;

/**
 * __useUpdateMicrosoftGraphLogSource__
 *
 * To run a mutation, you first call `useUpdateMicrosoftGraphLogSource` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMicrosoftGraphLogSource` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMicrosoftGraphLogSource, { data, loading, error }] = useUpdateMicrosoftGraphLogSource({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMicrosoftGraphLogSource(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateMicrosoftGraphLogSource,
    UpdateMicrosoftGraphLogSourceVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateMicrosoftGraphLogSource,
    UpdateMicrosoftGraphLogSourceVariables
  >(UpdateMicrosoftGraphLogSourceDocument, baseOptions);
}
export type UpdateMicrosoftGraphLogSourceHookResult = ReturnType<
  typeof useUpdateMicrosoftGraphLogSource
>;
export type UpdateMicrosoftGraphLogSourceMutationResult = ApolloReactCommon.MutationResult<
  UpdateMicrosoftGraphLogSource
>;
export type UpdateMicrosoftGraphLogSourceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateMicrosoftGraphLogSource,
  UpdateMicrosoftGraphLogSourceVariables
>;
export function mockUpdateMicrosoftGraphLogSource({
  data,
  variables,
  errors,
}: {
  data: UpdateMicrosoftGraphLogSource;
  variables?: UpdateMicrosoftGraphLogSourceVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: UpdateMicrosoftGraphLogSourceDocument, variables },
    result: { data, errors },
  };
}

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Alert, Box, SimpleGrid } from 'pouncejs';
import { ListResourcesInput, Permission } from 'Generated/schema';
import { TableControlsPagination } from 'Components/utils/TableControls';
import Panel from 'Components/Panel';
import { extractErrorMessage } from 'Helpers/utils';
import { compose } from 'Helpers/compose';
import useRequestParamsWithPagination from 'Hooks/useRequestParamsWithPagination';
import isEmpty from 'lodash/isEmpty';
import withSEO from 'Hoc/withSEO';
import ErrorBoundary from 'Components/ErrorBoundary';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import Page403 from 'Pages/403';
import NoResultsFound from 'Components/NoResultsFound';
import ListResourcesPageEmptyDataFallback from './EmptyDataFallback';
import ListResourcesPageSkeleton, { PlaceholderSkeleton } from './Skeleton';
import { useListResources } from './graphql/listResources.generated';
import ResourceCard from './ResourceCard';
import ListResourceFilters from './ListResourceFilters';

const ListResources = () => {
  const { requestParams, updatePagingParams } = useRequestParamsWithPagination<ListResourcesInput>({
    overrides: { idContains: 'string' },
  });

  const { loading, previousData, data = previousData, error } = useListResources({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      input: requestParams,
    },
  });
  if (loading && !data) {
    return <ListResourcesPageSkeleton />;
  }

  if (error) {
    return (
      <Box mb={6}>
        <Alert
          variant="error"
          title="Couldn't load your connected resources"
          description={
            extractErrorMessage(error) ||
            'There was an error when performing your request, please contact support@runpanther.io'
          }
        />
      </Box>
    );
  }

  const resourceItems = data.resources.resources;

  const pagingData = data.resources.paging;

  if (!resourceItems.length && isEmpty(requestParams)) {
    return <ListResourcesPageEmptyDataFallback />;
  }

  return (
    <ErrorBoundary>
      <Panel
        title="Cloud Resources"
        actions={<ListResourceFilters />}
        data-testid="cloud-resources"
      >
        {loading ? (
          <PlaceholderSkeleton />
        ) : (
          <SimpleGrid columns={1} gap={2}>
            {resourceItems.length ? (
              resourceItems.map(resource => <ResourceCard resource={resource} key={resource.id} />)
            ) : (
              <Box my={8}>
                <NoResultsFound />
              </Box>
            )}
          </SimpleGrid>
        )}
      </Panel>
      <Box my={6}>
        <TableControlsPagination
          page={pagingData.thisPage}
          totalPages={pagingData.totalPages}
          onPageChange={updatePagingParams}
        />
      </Box>
    </ErrorBoundary>
  );
};

export default compose(
  withSEO({ title: 'Resources' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.ResourceRead, Permission.ResourceModify],
    fallback: <Page403 />,
  })
)(ListResources);

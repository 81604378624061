/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import urls from 'Source/urls';
import { Permission } from 'Generated/schema';
import SecondaryNavigationMenu from '../SecondaryNavigationMenu';
import { NavigationLink } from '../constants';

export const configureNavLinks: NavigationLink[] = [
  {
    to: urls.configure.logSources.list({ disableDefaultParams: false }),
    icon: 'log-source',
    label: 'Log Sources',
    permissions: [Permission.LogSourceRead, Permission.LogSourceModify],
  },
  {
    to: urls.configure.cloudAccounts.list(),
    icon: 'cloud-security',
    label: 'Cloud Accounts',
    permissions: [Permission.CloudsecSourceRead, Permission.CloudsecSourceModify],
  },
  {
    to: urls.configure.alertDestinations.list(),
    icon: 'output',
    label: 'Alert Destinations',
    permissions: [Permission.DestinationRead, Permission.DestinationModify],
  },
  {
    to: urls.configure.cloudResources.list(),
    icon: 'resource',
    label: 'Cloud Resources',
    permissions: [Permission.ResourceRead, Permission.ResourceModify],
  },
  {
    to: urls.configure.schemas.list(),
    icon: 'schemas',
    label: 'Schemas',
    permissions: [Permission.LogSourceRead, Permission.LogSourceModify],
  },
  {
    to: urls.configure.lookupTables.list(),
    icon: 'lookups',
    label: 'Lookup Tables',
    permissions: [Permission.LookupRead, Permission.LookupModify],
  },
  {
    to: urls.configure.enrichmentProviders.home(),
    icon: 'integrations',
    label: 'Enrichment Providers',
    permissions: [Permission.LookupRead, Permission.LookupModify],
  },
];

const ConfigureNavigation: React.FC = () => {
  return <SecondaryNavigationMenu links={configureNavLinks} />;
};

export default React.memo(ConfigureNavigation);

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Flex, Box, Card, Alert, Heading, Text } from 'pouncejs';
import useRequestParamsWithoutPagination from 'Hooks/useRequestParamsWithoutPagination';
import { DEFAULT_LARGE_PAGE_SIZE } from 'Source/constants';
import { AlertsInput } from 'Generated/schema';
import ErrorBoundary from 'Components/ErrorBoundary';
import NoResultsFound from 'Components/NoResultsFound';
import { extractErrorMessage } from 'Helpers/utils';
import EmptyBoxImg from 'Assets/illustrations/empty-box.svg';
import AlertCard from 'Components/cards/AlertCard';
import TablePlaceholder from 'Components/TablePlaceholder';
import useInfiniteScroll from 'Hooks/useInfiniteScroll';
import ListAlertFilters from 'Pages/ListAlerts/ListAlertFilters';
import isEmpty from 'lodash/isEmpty';
import { SelectAllCheckbox, withSelectContext, useSelect } from 'Components/utils/SelectContext';
import ListAlertDefaultUpdater from 'Pages/ListAlerts/ListAlertsActions/ListAlertDefaultUpdater';
import { useListAlertsForPolicy } from '../graphql/listAlertsForPolicy.generated';
import Skeleton from './Skeleton';
import { PolicyDetailsPageUrlParams } from '../PolicyDetails';

const POLICY_ALERTS_EXCLUDED_FILTERS = [
  'types',
  'resourceTypes',
  'logTypes',
  'severities',
  'eventCountMin',
  'eventCountMax',
] as const;

interface ListPolicyAlertsProps {
  policyId: string;
}

const ListPolicyAlerts: React.FC<ListPolicyAlertsProps> = ({ policyId }) => {
  const { requestParams } = useRequestParamsWithoutPagination<
    Omit<AlertsInput, 'policyId'> & PolicyDetailsPageUrlParams
  >({ overrides: { nameContains: 'string' } });

  // Omit the actual tab section as it exists on the url params
  const { section, ...filterParams } = requestParams;

  const {
    error,
    previousData,
    data = previousData,
    loading,
    fetchMore,
    variables,
  } = useListAlertsForPolicy({
    variables: {
      input: {
        ...filterParams,
        detectionId: policyId,
        pageSize: DEFAULT_LARGE_PAGE_SIZE,
      },
    },
  });

  const alerts = React.useMemo(() => data?.alerts.edges.map(edge => edge.node) || [], [data]);
  const alertIds = React.useMemo(() => alerts.map(a => a.id), [alerts]);

  const { checkIfSelected, selection } = useSelect();

  const { sentinelRef } = useInfiniteScroll<HTMLDivElement>({
    loading,
    threshold: 500,
    onLoadMore: () => {
      fetchMore({
        variables: {
          input: {
            ...variables.input,
            cursor: data.alerts.pageInfo.endCursor,
          },
        },
      });
    },
  });

  if (loading && !data) {
    return <Skeleton />;
  }

  if (error) {
    return (
      <Box mb={6}>
        <Alert
          variant="error"
          title="Couldn't load your policy failures"
          description={
            extractErrorMessage(error) ||
            " An unknown error occurred and we couldn't load the policy failures from the server"
          }
        />
      </Box>
    );
  }

  const hasAnyAlerts = alerts.length > 0;
  const areFiltersApplied = !isEmpty(filterParams);
  const hasMoreAlerts = data.alerts.pageInfo.hasNextPage;

  if (!hasAnyAlerts && !areFiltersApplied) {
    return (
      <Flex
        justify="center"
        align="center"
        direction="column"
        my={8}
        spacing={8}
        data-testid="list-alerts-empty-fallback"
      >
        <img alt="Empty Box Illustration" src={EmptyBoxImg} width="auto" height={200} />
        <Heading size="small" color="navyblue-100">
          Nothing to show here
        </Heading>
      </Flex>
    );
  }

  return (
    <ErrorBoundary>
      <Flex width="100%" pt={6} px={6}>
        <Flex width="100%" spacing={2} justify="space-between">
          <Flex align="center" spacing={3} ml={1}>
            <SelectAllCheckbox selectionItems={alertIds} />
            <Text>Alerts</Text>
          </Flex>
          {selection.length ? (
            <ListAlertDefaultUpdater />
          ) : (
            <ListAlertFilters excludedFields={POLICY_ALERTS_EXCLUDED_FILTERS} />
          )}
        </Flex>
      </Flex>
      <Card as="article" p={6}>
        {hasAnyAlerts ? (
          <Flex direction="column" spacing={2}>
            {alerts.map(alert => (
              <AlertCard
                hidePolicyButton
                key={alert.id}
                alert={alert}
                selectionEnabled
                isSelected={checkIfSelected(alert.id)}
              />
            ))}
          </Flex>
        ) : (
          <Box my={8}>
            <NoResultsFound />
          </Box>
        )}
        {hasMoreAlerts && (
          <Box mt={8} ref={sentinelRef}>
            <TablePlaceholder rowCount={10} rowHeight={6} />
          </Box>
        )}
      </Card>
    </ErrorBoundary>
  );
};

export default withSelectContext()(ListPolicyAlerts);

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useSnackbar, Text, Box } from 'pouncejs';
import { LogIntegration } from 'Generated/schema';
import { useDeleteLogSource } from './graphql/deleteLogSource.generated';
import ConfirmModal from '../ConfirmModal';

export interface DeleteLogSourceModalProps {
  source: Pick<LogIntegration, 'integrationId' | 'integrationLabel'>;
  description?: string | React.ReactNode;
  onSuccess?: () => void;
}

const DeleteLogSourceModal: React.FC<DeleteLogSourceModalProps> = ({
  source,
  description,
  onSuccess = () => {},
}) => {
  const sourceDisplayName = source.integrationLabel;
  const { pushSnackbar } = useSnackbar();
  const [deleteLogSource] = useDeleteLogSource({
    variables: {
      id: source.integrationId,
    },
    update: cache => {
      cache.evict({ id: cache.identify(source) });
      cache.gc();
    },
    onCompleted: () => {
      onSuccess();

      pushSnackbar({
        variant: 'success',
        title: `Successfully deleted source: ${sourceDisplayName}`,
      });
    },
    onError: () => {
      pushSnackbar({
        variant: 'error',
        title: `Failed to delete source: ${sourceDisplayName}`,
      });
    },
  });

  return (
    <ConfirmModal
      textAlign="left"
      subtitle={
        <Box width={600} fontSize="large">
          <Text width={552} fontSize="large">
            {description && (
              <Text as="span" mb={6}>
                {description}
              </Text>
            )}
            <Text as="span">
              Note that deleting a Log Source will stop the collection of future events, but any
              collected data will remain accesible in Data Explorer
            </Text>
          </Text>
        </Box>
      }
      variant="destructive"
      onConfirm={deleteLogSource}
      confirmBtnText="Yes, Delete"
    />
  );
};

export default DeleteLogSourceModal;

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import urls from 'Source/urls';

export const activeDashboardUrls = [
  urls.dashboard.overview(),
  urls.dashboard.health(),
  urls.dashboard.data(),
];

export const dashboardUrlsGroup = [
  // current
  urls.dashboard.home(),
  ...activeDashboardUrls,
  // deprecated
  urls.overview.home(),
];

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */
import React from 'react';
import { Alert, Box, Button, Card, Flex, Heading, Text } from 'pouncejs';
import S3UploadPanel from 'Components/S3UploadPanel';
import { useGetSampleData } from 'Components/forms/DataSchemaForm/SampleDataSection/graphql/getSampleData.generated';
import { extractErrorMessage } from 'Helpers/utils';
import { useSchemaGeneration } from 'Components/utils/SchemaGenerationContext';
import TablePlaceholder from 'Components/TablePlaceholder';
import useUrlParams from 'Hooks/useUrlParams';
import SampleDataMain, { SampleDataUrlParams } from './SampleDataMain';

const SampleDataSection: React.FC = () => {
  const { sessionId, setSessionId, reset, readOnly } = useSchemaGeneration();
  const { updateUrlParams } = useUrlParams<SampleDataUrlParams>();

  const { data, loading, error } = useGetSampleData({
    skip: !sessionId,
    variables: {
      input: {
        sessionId,
      },
    },
  });

  const onSuccessfulUpload = React.useCallback(
    (generatedSessionId: string) => {
      updateUrlParams({ section: 'raw' });
      return setSessionId(generatedSessionId);
    },
    [setSessionId, updateUrlParams]
  );

  const sampleDataError = data?.getSampleData.error;

  if (loading) {
    return (
      <Card mt={4} p={4}>
        <TablePlaceholder rowCount={8} />
      </Card>
    );
  }

  if (error || sampleDataError) {
    const errDescription = sampleDataError
      ? sampleDataError.message
      : extractErrorMessage(error) ||
        'There was an error when performing your request, please contact support@runpanther.io';

    return (
      <Card mt={4} p={4}>
        <Alert
          variant="error"
          title="Couldn't load your sample data"
          description={errDescription}
        />
        <Flex mt={4} justify="center">
          <Button variant="outline" onClick={reset}>
            Reset
          </Button>
        </Flex>
      </Card>
    );
  }

  return (
    <Card mt={4}>
      {data ? (
        <SampleDataMain />
      ) : (
        <Box py={8}>
          <S3UploadPanel
            header={
              <Box as="header" mb={10} textAlign="center">
                <Heading size="small" fontWeight="medium">
                  {readOnly ? 'Test Schema against sample logs' : 'Infer Schema from Sample Logs'}
                </Heading>
                <Text fontSize="medium" mt={2} color="gray-300">
                  Upload some sample logs to {readOnly ? 'test' : 'create'} the schema
                </Text>
              </Box>
            }
            maxSizeInMB={50}
            sessionId={sessionId}
            onSuccess={onSuccessfulUpload}
          />
        </Box>
      )}
    </Card>
  );
};

export default React.memo(SampleDataSection);

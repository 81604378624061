/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { IconProps } from 'pouncejs';
import { Permission } from 'Generated/schema';

export type NavigationLink = {
  to: string;
  icon?: IconProps['type'];
  label: string;
  permissions?: Permission[];
};

export enum SecondaryNavKeyEnum {
  DASHBOARD_NAV_KEY = '/dashboard',
  INVESTIGATE_NAV_KEY = '/investigate',
  BUILD_NAV_KEY = '/build',
  CONFIGURE_NAV_KEY = '/configure',
  SETTINGS_NAV_KEY = '/settings',
}

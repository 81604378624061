/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import urls from 'Source/urls';
import { Permission } from 'Generated/schema';
import SecondaryNavigationMenu from '../SecondaryNavigationMenu';
import { NavigationLink } from '../constants';

export const dashboardNavLinks: NavigationLink[] = [
  {
    to: urls.dashboard.overview(),
    icon: 'dashboard-alt',
    label: 'Overview',
    permissions: [Permission.SummaryRead],
  },
  {
    to: urls.dashboard.health(),
    icon: 'health',
    label: 'Health',
    permissions: [Permission.SummaryRead],
  },
  {
    to: urls.dashboard.data(),
    icon: 'data',
    label: 'Data',
    permissions: [Permission.SummaryRead],
  },
];

const DashboardNavigation: React.FC = () => {
  return <SecondaryNavigationMenu links={dashboardNavLinks} />;
};

export default React.memo(DashboardNavigation);

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Card, Theme } from 'pouncejs';
import { SeriesWithEntityID } from 'Generated/schema';
import NoDataFound from 'Components/NoDataFound';
import BarChart from 'Components/charts/BarChart';
import { toPlural } from 'Helpers/utils';
import urls from 'Source/urls';

// Default color values for alertsPerRule
const barColors: (keyof Theme['colors'])[] = [
  'cyan-400',
  'magenta-500',
  'yellow-500',
  'red-300',
  'blue-500',
];

interface MostActiveRulesProps {
  alertsPerRule: SeriesWithEntityID[];
}

const MostActiveRules: React.FC<MostActiveRulesProps> = ({ alertsPerRule }) => {
  const rulesData = React.useMemo(() => {
    if (!alertsPerRule) {
      return null;
    }

    return (
      alertsPerRule
        // Displaying only 5 bars, this list is sorted so top alertsPerRule should first
        .slice(0, 5)
        // Adding fixed colors to bars for visual reasons
        .map((alertPerRule, i) => ({
          label: alertPerRule.label,
          value: Number(alertPerRule.value), // removes zero decimals (i.e. 1.00 -> 1)
          legendLinkTo: urls.analysis.rules.details(alertPerRule.entityId),
          config: {
            color: barColors[i],
            gap: 2,
            height: 30,
            justifyValue: 'start' as const,
            formatValue: (value: number) => `${value} ${toPlural('Alert', value)}`,
            dataTestIdPrefix: 'active-detection-',
          },
        }))
    );
  }, [alertsPerRule]);

  return (
    <Card p={6} backgroundColor="navyblue-500">
      {rulesData?.length ? (
        <BarChart data={rulesData} showLegend title="Detections" />
      ) : (
        <NoDataFound title="Your logs haven't matched any rule yet" />
      )}
    </Card>
  );
};

export default React.memo(MostActiveRules);

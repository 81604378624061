/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { Box, IconProps, FlexProps } from 'pouncejs';
import React from 'react';
import useRouter from 'Hooks/useRouter';
import { addTrailingSlash, getPathnameFromURI } from 'Helpers/utils';
import { Link as RRLink } from 'react-router-dom';
import NavLinkContent from './NavLinkContent';

export type NavLinkBaseProps = {
  icon?: IconProps['type'];
  label: string;
  isSecondary?: boolean;
};

type NavLinkProps = NavLinkBaseProps & {
  to: string;
};

const NavLink = ({ icon, label, to, isSecondary, ...flexProps }: NavLinkProps & FlexProps) => {
  const { location } = useRouter();
  const pathname = addTrailingSlash(location.pathname);
  const destination = addTrailingSlash(getPathnameFromURI(to));
  const isActive = pathname.startsWith(destination);

  return (
    <Box as={RRLink} to={to} aria-current={isActive ? 'page' : undefined}>
      <NavLinkContent
        icon={icon}
        label={label}
        isSecondary={isSecondary}
        isActive={isActive}
        {...flexProps}
      />
    </Box>
  );
};

export default NavLink;

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, Img } from 'pouncejs';
import { Link as RRLink } from 'react-router-dom';

import PantherEnterpriseLogo from 'Assets/panther-plain-logo.svg';
import { HEADER_HEIGHT } from 'Source/constants';
import { HelpMenu, ProfileInfo, SettingsMenu } from './SecondaryMenus';

const TopNavigationMenu = () => {
  return (
    <Flex
      as="header"
      width="100%"
      height={HEADER_HEIGHT}
      padding={4}
      position="relative"
      zIndex={10}
      minWidth="min-content"
      justifyContent="space-between"
      alignItems="center"
      backgroundColor="navyblue-700"
      borderBottom="1px solid"
      borderColor="navyblue-350"
    >
      <Box as={RRLink} to="/" px={2}>
        <Img
          src={PantherEnterpriseLogo}
          alt="Panther home"
          nativeWidth="auto"
          nativeHeight={28}
          display="block"
        />
      </Box>
      <Flex
        as="nav"
        aria-label="Top Navigation Menu"
        alignItems="center"
        alignContent="center"
        spacing={2}
        px={16}
      >
        <SettingsMenu />
        <HelpMenu />
        <ProfileInfo />
      </Flex>
    </Flex>
  );
};

export default React.memo(TopNavigationMenu);

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import omit from 'lodash/omit';
import { Alert } from 'pouncejs';
import { Formik } from 'formik';
import * as Yup from 'yup';
import useRouter from 'Hooks/useRouter';
import { useGetS3LogSource } from 'Source/graphql/queries/getS3LogSource.generated';
import {
  dataSchemaValidation,
  extractErrorMessage,
  yupS3PrefixLogTypesValidation,
} from 'Helpers/utils';
import SchemasManagementSkeleton from 'Pages/Integrations/LogSources/S3/SchemasManagement/SchemasManagementSkeleton/SchemasManagementSkeleton';
import { s3PrefixLogTypesInitialValues } from 'Pages/Integrations/LogSources/S3/S3LogSourceWizard';
import useAvailableLogTypesForProvider from 'Hooks/useAvailableLogTypesForProvider';
import { compose } from 'Helpers/compose';
import withSEO from 'Hoc/withSEO';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import Page403 from 'Pages/403/403';
import {
  Permission,
  LogStreamTypeEnum,
  PutUserSchemaInput,
  S3PrefixLogTypes,
} from 'Generated/schema';
import FormSessionRestoration from 'Components/utils/FormSessionRestoration';
import SchemasManagementRoutes from './SchemasManagementRoutes';
import { SchemasManagementProvider } from './SchemasManagementContext';
import useSchemasManagementFormSubmit from './useSchemasManagementFormSubmit';
import { useListTestTasks } from './SchemasManagementContext/graphql/listTestTasks.generated';

export interface S3PrefixLogTypesFormValues {
  s3PrefixLogTypes: Omit<S3PrefixLogTypes, '__typename'>[];
  draftSchemas: PutUserSchemaInput[];
  logStreamType: LogStreamTypeEnum;
}

const SchemasManagement: React.FC = () => {
  const { match } = useRouter<{ id: string }>();

  const sourceId = match.params.id;
  const { data: testTasks, loading: testTasksLoading } = useListTestTasks({
    variables: {
      sourceId,
    },
  });

  const lastTestJob = testTasks?.listTestTasks.tasks.length && testTasks.listTestTasks.tasks[0];
  const { data, loading, error } = useGetS3LogSource({
    variables: { id: sourceId },
  });

  const { handleSubmit, loadingSchema } = useSchemasManagementFormSubmit(data);

  const { availableLogTypes } = useAvailableLogTypesForProvider(
    data?.getS3LogIntegration?.logProviderId
  );

  const schemaManagementValidation: Yup.SchemaOf<S3PrefixLogTypesFormValues> = React.useMemo(
    () =>
      Yup.object().shape({
        s3PrefixLogTypes: yupS3PrefixLogTypesValidation(),
        logStreamType: Yup.string().oneOf(Object.keys(LogStreamTypeEnum)).required(),
        draftSchemas: Yup.array()
          .of(dataSchemaValidation(availableLogTypes))
          .unique(`You already have a Data Schema with the same name`, 'name'),
      }),
    [availableLogTypes]
  );

  const initialValues: S3PrefixLogTypesFormValues = React.useMemo(
    () => ({
      s3PrefixLogTypes: data?.getS3LogIntegration.s3PrefixLogTypes.map(prefix =>
        // Allow for deep comparison of the prefixes from the API and initial values
        omit(prefix, '__typename')
      ) ?? [s3PrefixLogTypesInitialValues],
      draftSchemas: [],
      logStreamType: data?.getS3LogIntegration.logStreamType,
    }),
    [data]
  );

  const formStatus = React.useMemo(
    () => ({ availableLogTypes, s3BucketName: data?.getS3LogIntegration.s3Bucket }),
    [availableLogTypes, data]
  );

  if (error) {
    return (
      <Alert
        variant="error"
        title="We can't display this content right now"
        description={extractErrorMessage(error)}
      />
    );
  }

  if (loading || testTasksLoading) {
    return <SchemasManagementSkeleton />;
  }

  return (
    <Formik<S3PrefixLogTypesFormValues>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={schemaManagementValidation}
      onSubmit={handleSubmit}
      initialStatus={formStatus}
    >
      <FormSessionRestoration sessionId={`schema-management-${sourceId}`}>
        <SchemasManagementProvider sourceId={sourceId} initialSchemaTestJob={lastTestJob}>
          <SchemasManagementRoutes savingSchemaName={loadingSchema} />
        </SchemasManagementProvider>
      </FormSessionRestoration>
    </Formik>
  );
};

export default compose(
  withSEO({ title: 'Schema Management' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LogSourceModify, Permission.LogSourceRawDataRead],
    logic: 'AND',
    fallback: <Page403 />,
  })
)(SchemasManagement);

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import useRouter from 'Hooks/useRouter';
import queryString from 'query-string';
import omitBy from 'lodash/omitBy';
import mapValues from 'lodash/mapValues';
import { LocationDescriptor } from 'history';

export const defaultQueryStringOptions = {
  arrayFormat: 'bracket' as const,
  parseNumbers: true,
  parseBooleans: true,
};

type Scalar = 'string' | 'number' | 'boolean';

export interface UseUrlParamsProps<T = Record<string, unknown>> {
  // Parses URL params matching numbers to their corresponding number types
  parseNumbers?: boolean;
  // Parses URL params matching boolean values as booleans
  parseBooleans?: boolean;
  // Overrides expected parsing and forcefully sets it to a particular Scalar
  overrides?: Partial<Record<keyof T, Scalar>>;
}

/**
 * Given a URL param override & its value, we retrive the new value for this URL param
 */
const coerceScalar = (value: unknown, override: Scalar) => {
  // intentionally comparing with `==` to catch both `null` and `undefined`
  // eslint-disable-next-line eqeqeq
  if (value == undefined) {
    return value;
  }

  switch (override) {
    case 'string':
      return String(value);
    case 'boolean':
      return Boolean(value);
    case 'number':
      return Number(value);
    default:
      return value;
  }
};

function useUrlParams<T extends { [key: string]: any }>(parsingOptions?: UseUrlParamsProps<T>) {
  const { history, location } = useRouter();

  /*
   * Generating queryStringOptions using default values and passed parameters
   */
  const queryStringOptions = React.useMemo(
    () => ({
      ...defaultQueryStringOptions,
      ...parsingOptions,
    }),
    [parsingOptions]
  );

  /**
   * parses the query params of a URL and returns an object with params in the correct typo
   */
  const urlParams = React.useMemo(() => {
    const params = queryString.parse(location.search, queryStringOptions);

    return mapValues(params, (value, key) => {
      const override = queryStringOptions?.overrides?.[key];
      if (Array.isArray(value)) {
        return value.map(elem => coerceScalar(elem, override));
      }

      return coerceScalar(value, override);
    }) as T;
  }, [location.search, queryStringOptions]);

  /**
   * Stringifies an object and replaces the query params of a URL with the ones passed
   */
  const setUrlParams = (params: T, push = false) => {
    // Remove any falsy value apart from the value `0` (number) and the value `false` (boolean)
    const cleanedQueryParams = omitBy(params, v => !v && !['number', 'boolean'].includes(typeof v));

    const newLocation: LocationDescriptor<any> = {
      ...location,
      search: queryString.stringify(cleanedQueryParams, queryStringOptions),
    };

    if (push) {
      history.push(newLocation);
    } else {
      history.replace(newLocation);
    }
  };

  /**
   * Stringifies an object and extends the current query params of the URL with the ones passed
   */
  const updateUrlParams = (params: Partial<T>, push = false) => {
    setUrlParams({ ...urlParams, ...params }, push);
  };

  /*
   * Stringifies URL parameters
   */
  const stringifyUrlParams = React.useCallback(
    (parameters: T) => {
      return queryString.stringify(parameters, queryStringOptions);
    },
    [queryStringOptions]
  );

  return React.useMemo(
    () => ({
      urlParams,
      setUrlParams,
      updateUrlParams,
      stringifyUrlParams,
    }),
    // FIXME: look into hook dependencies
    // This one will be tricky to remove the eslint warning, since the 4 items in context
    // can change for various reasons (the full `location` object causes an infinite
    // render loop if included). Right now, we want to make sure they're fresh when the
    // query parameters and location state change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history.location.search, location.state]
  );
}

export default useUrlParams;
export { defaultQueryStringOptions as queryStringOptions };

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetDataMetricsVariables = {
  input: Types.MetricsInput;
};

export type GetDataMetrics = {
  metrics: Pick<Types.MetricsOutput, 'totalBytesIngested' | 'totalBytesQueried'> & {
    eventsProcessedPerLogType: Array<
      Pick<Types.SeriesWithBreakdown, 'label' | 'value' | 'breakdown'>
    >;
    bytesProcessedPerSource: Array<
      Pick<Types.SeriesWithBreakdown, 'label' | 'value' | 'breakdown'>
    >;
    latencyPerLogType?: Types.Maybe<Array<Pick<Types.Series, 'label' | 'value'>>>;
    bytesIngestedPerSource?: Types.Maybe<Array<Pick<Types.Series, 'label' | 'value'>>>;
    bytesQueriedPerSource: Array<Pick<Types.SeriesWithBreakdown, 'label' | 'value' | 'breakdown'>>;
  };
};

export const GetDataMetricsDocument = gql`
  query GetDataMetrics($input: MetricsInput!) {
    metrics(input: $input) {
      eventsProcessedPerLogType {
        label
        value
        breakdown
      }
      bytesProcessedPerSource {
        label
        value
        breakdown
      }
      latencyPerLogType {
        label
        value
      }
      bytesIngestedPerSource {
        label
        value
      }
      bytesQueriedPerSource {
        label
        value
        breakdown
      }
      totalBytesIngested
      totalBytesQueried
    }
  }
`;

/**
 * __useGetDataMetrics__
 *
 * To run a query within a React component, call `useGetDataMetrics` and pass it any options that fit your needs.
 * When your component renders, `useGetDataMetrics` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataMetrics({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDataMetrics(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetDataMetrics, GetDataMetricsVariables>
) {
  return ApolloReactHooks.useQuery<GetDataMetrics, GetDataMetricsVariables>(
    GetDataMetricsDocument,
    baseOptions
  );
}
export function useGetDataMetricsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDataMetrics, GetDataMetricsVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetDataMetrics, GetDataMetricsVariables>(
    GetDataMetricsDocument,
    baseOptions
  );
}
export type GetDataMetricsHookResult = ReturnType<typeof useGetDataMetrics>;
export type GetDataMetricsLazyQueryHookResult = ReturnType<typeof useGetDataMetricsLazyQuery>;
export type GetDataMetricsQueryResult = ApolloReactCommon.QueryResult<
  GetDataMetrics,
  GetDataMetricsVariables
>;
export function mockGetDataMetrics({
  data,
  variables,
  errors,
}: {
  data: GetDataMetrics;
  variables?: GetDataMetricsVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetDataMetricsDocument, variables },
    result: { data, errors },
  };
}

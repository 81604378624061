/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../../__generated__/schema';

import { DropboxLogIntegrationDetails } from '../../../../../../graphql/fragments/DropboxLogIntegrationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type UpdateDropboxLogSourceVariables = {
  input: Types.UpdateLogPullingIntegrationInput;
};

export type UpdateDropboxLogSource = { updateLogPullingIntegration: DropboxLogIntegrationDetails };

export const UpdateDropboxLogSourceDocument = gql`
  mutation UpdateDropboxLogSource($input: UpdateLogPullingIntegrationInput!) {
    updateLogPullingIntegration(input: $input) {
      ...DropboxLogIntegrationDetails
    }
  }
  ${DropboxLogIntegrationDetails}
`;
export type UpdateDropboxLogSourceMutationFn = ApolloReactCommon.MutationFunction<
  UpdateDropboxLogSource,
  UpdateDropboxLogSourceVariables
>;

/**
 * __useUpdateDropboxLogSource__
 *
 * To run a mutation, you first call `useUpdateDropboxLogSource` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDropboxLogSource` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDropboxLogSource, { data, loading, error }] = useUpdateDropboxLogSource({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDropboxLogSource(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateDropboxLogSource,
    UpdateDropboxLogSourceVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateDropboxLogSource, UpdateDropboxLogSourceVariables>(
    UpdateDropboxLogSourceDocument,
    baseOptions
  );
}
export type UpdateDropboxLogSourceHookResult = ReturnType<typeof useUpdateDropboxLogSource>;
export type UpdateDropboxLogSourceMutationResult = ApolloReactCommon.MutationResult<
  UpdateDropboxLogSource
>;
export type UpdateDropboxLogSourceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateDropboxLogSource,
  UpdateDropboxLogSourceVariables
>;
export function mockUpdateDropboxLogSource({
  data,
  variables,
  errors,
}: {
  data: UpdateDropboxLogSource;
  variables?: UpdateDropboxLogSourceVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: UpdateDropboxLogSourceDocument, variables },
    result: { data, errors },
  };
}

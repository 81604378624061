/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { FastField, Form, Formik } from 'formik';
import { Box, Flex, Text, useSnackbar } from 'pouncejs';
import { AlertStatus, UpdateAlertsAssigneeByIdInput } from 'Generated/schema';
import FormikCombobox from 'Components/fields/ComboBox';
import { capitalize, extractErrorMessage, getActorDisplayName } from 'Helpers/utils';
import SubmitButton from 'Components/buttons/SubmitButton';
import { useSelect } from 'Components/utils/SelectContext';
import { useUpdateAlertStatusById } from 'Source/graphql/queries';
import { useUpdateAlertsAssigneeById } from 'Source/graphql/queries/updateAlertsAssigneeById.generated';
import useAlertAssignableUsers from 'Hooks/useAlertAssignableUsers';
import { UNASSIGNED_USER, ENABLE_ALERT_ASSIGNEES } from 'Source/constants';

const initialValues = {
  assignedUser: null,
  status: null,
};

interface ListAlertBulkUpdateFormValues {
  status: AlertStatus;
  assignedUser: string;
}

interface ListAlertUpdateStatusFormValues {
  status: AlertStatus;
}

interface ListAlertUpdateAssigneeFormValues {
  assignedUser: string;
}

export const alertStatusOptions = Object.values(AlertStatus);
export const filterItemToString = (item: AlertStatus) =>
  capitalize(item === AlertStatus.Closed ? 'Invalid' : item.toLowerCase());

const ListAlertDefaultUpdater: React.FC = () => {
  const { selection, resetSelection } = useSelect();
  const { pushSnackbar } = useSnackbar();
  const { assignableUserIds, userMap } = useAlertAssignableUsers();

  const [updateAlertsAssigneeById] = useUpdateAlertsAssigneeById({
    onCompleted: data => {
      const assignedUser = data.updateAlertsAssigneeById.alerts[0].assignee;
      resetSelection();
      const verbiage = !assignedUser
        ? 'unassigned'
        : `assigned to ${getActorDisplayName(assignedUser)}`;
      pushSnackbar({
        variant: 'success',
        title: `Selected alerts have been ${verbiage}`,
      });
    },
    onError: error => {
      pushSnackbar({
        variant: 'error',
        title: `Failed to assign the alerts`,
        description: extractErrorMessage(error),
      });
    },
  });

  const [updateAlertStatusById] = useUpdateAlertStatusById({
    onCompleted: data => {
      const { status } = data.updateAlertStatusById.alerts[0];
      resetSelection();
      pushSnackbar({
        variant: 'success',
        title: `${data.updateAlertStatusById.alerts.length} Alert(s) set to ${capitalize(
          (status === AlertStatus.Closed ? 'INVALID' : status).toLowerCase()
        )}`,
      });
    },
    onError: error => {
      pushSnackbar({
        variant: 'error',
        title: `Failed to bulk update alert(s) status`,
        description: extractErrorMessage(error),
      });
    },
  });

  const handleBulkUpdateStatus = React.useCallback(
    (values: ListAlertUpdateStatusFormValues) => {
      return updateAlertStatusById({
        variables: { input: { status: values.status, ids: selection } },
      });
    },
    [selection, updateAlertStatusById]
  );

  const handleBulkUpdateAssignee = React.useCallback(
    (values: ListAlertUpdateAssigneeFormValues) => {
      const input = {
        ids: selection,
        assigneeId: values.assignedUser !== UNASSIGNED_USER ? values.assignedUser : null,
      } as UpdateAlertsAssigneeByIdInput;
      return updateAlertsAssigneeById({
        variables: {
          input,
        },
      });
    },
    [selection, updateAlertsAssigneeById]
  );

  const handleSubmit = React.useCallback(
    (values: ListAlertBulkUpdateFormValues) => {
      const callsToMake = [];
      if (values.assignedUser) {
        callsToMake.push(handleBulkUpdateAssignee(values));
      }
      if (values.status) {
        callsToMake.push(handleBulkUpdateStatus(values));
      }

      return Promise.all(callsToMake);
    },
    [handleBulkUpdateAssignee, handleBulkUpdateStatus]
  );

  return (
    <Formik<ListAlertBulkUpdateFormValues> initialValues={initialValues} onSubmit={handleSubmit}>
      <Form>
        <Flex align="center" spacing={4} mr={4}>
          <Text>{selection.length} Selected</Text>
          {ENABLE_ALERT_ASSIGNEES && (
            <Box width={150}>
              <FastField
                name="assignedUser"
                as={FormikCombobox}
                items={[UNASSIGNED_USER, ...assignableUserIds]}
                itemToString={item => {
                  if (item.toLowerCase() === UNASSIGNED_USER.toLowerCase() || item === null) {
                    return 'Unassigned';
                  }
                  return getActorDisplayName(userMap[item]);
                }}
                label="Assignee"
                data-testid="alert-listing-status-filtering"
                placeholder="Select Assignees"
              />
            </Box>
          )}
          <Box width={150}>
            <FastField
              data-tid="mass-alert-status-update-combobox"
              name="status"
              as={FormikCombobox}
              items={alertStatusOptions}
              itemToString={filterItemToString}
              label="Status"
              placeholder="Select statuses"
            />
          </Box>
          <SubmitButton data-tid="mass-alert-assignee-update" variantColor="violet-400">
            Apply
          </SubmitButton>
        </Flex>
      </Form>
    </Formik>
  );
};

export default React.memo(ListAlertDefaultUpdater);

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import useRouter from 'Hooks/useRouter';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import urls from 'Source/urls';
import {
  CreateLogSourceMapper,
  EditLogSourceMapper,
  ListLogSources,
  LogSourceDetailsPage,
  LogSourceOnboarding,
  OAuthAuthorization,
  SchemasManagement,
} from 'Pages/Integrations/LogSources';
import { LogSourceType } from 'Source/constants';
import { LogPullerTypeEnum } from 'Generated/schema';

const LogSourcesRoutes: React.FC = () => {
  const { location } = useRouter();

  return (
    <Switch>
      <Redirect
        exact
        from={urls.integrations.home()}
        to={urls.configure.logSources.list({ disableDefaultParams: false })}
      />
      <Redirect
        exact
        from={urls.configure.home()}
        to={urls.configure.logSources.list({ disableDefaultParams: false })}
      />
      <Redirect
        exact
        from={urls.integrations.logSources.list()}
        to={{ pathname: urls.configure.logSources.list(), search: location.search }}
      />
      <Route exact path={urls.configure.logSources.list()} component={ListLogSources} />

      <Redirect
        exact
        from={urls.integrations.logSources.create()}
        to={{ pathname: urls.configure.logSources.create(), search: location.search }}
      />
      <Route exact path={urls.configure.logSources.create()} component={LogSourceOnboarding} />

      <Redirect
        exact
        from={urls.integrations.logSources.create(':type' as LogSourceType)}
        to={urls.configure.logSources.create(':type' as LogSourceType)}
      />
      <Route
        exact
        path={urls.configure.logSources.create(':type' as LogSourceType)}
        component={CreateLogSourceMapper}
      />

      <Redirect
        exact
        from={urls.integrations.logSources.edit(':id', ':type' as LogSourceType)}
        to={urls.configure.logSources.edit(':id', ':type' as LogSourceType)}
      />
      <Route
        exact
        path={urls.configure.logSources.edit(':id', ':type' as LogSourceType)}
        component={EditLogSourceMapper}
      />

      <Redirect
        exact
        from={urls.integrations.logSources.authorize(':id', ':type' as LogPullerTypeEnum)}
        to={urls.configure.logSources.authorize(':id', ':type' as LogPullerTypeEnum)}
      />
      <Route
        exact
        path={urls.configure.logSources.authorize(':id', ':type' as LogPullerTypeEnum)}
        component={OAuthAuthorization}
      />

      <Redirect
        exact
        from={urls.integrations.logSources.details(':id', ':type' as LogSourceType)}
        to={{
          pathname: urls.configure.logSources.details(':id', ':type' as LogSourceType),
          search: location.search,
        }}
      />
      <Route
        exact
        path={urls.configure.logSources.details(':id', ':type' as LogSourceType)}
        component={LogSourceDetailsPage}
      />

      <Redirect
        exact
        from={urls.integrations.logSources.schemasManagement(':id').home()}
        to={urls.configure.logSources.schemasManagement(':id').home()}
      />
      <Route
        exact
        path={urls.configure.logSources.schemasManagement(':id').home()}
        component={SchemasManagement}
      />

      <Redirect
        exact
        from={`${urls.integrations.logSources.list()}:type`}
        to={urls.configure.logSources.list({ disableDefaultParams: false })}
      />
      <Redirect
        exact
        from={`${urls.configure.logSources.list()}:type`}
        to={urls.configure.logSources.list({ disableDefaultParams: false })}
      />
    </Switch>
  );
};

export default LogSourcesRoutes;

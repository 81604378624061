/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import dayjs from 'dayjs';
import { Link as RRLink } from 'react-router-dom';
import { Box, Flex, Spinner, Text, useSnackbar } from 'pouncejs';
import { SourceIntegration } from 'Pages/Integrations/LogSources/LogSourceDetailsPage/components/InformationPanel';
import { DEFAULT_SMALL_PAGE_SIZE, LogTransportMethodsEnum } from 'Source/constants';
import { Permission, S3LogIntegration, UpdateS3LogIntegrationInput } from 'Generated/schema';
import Panel from 'Components/Panel';
import { extractErrorMessage } from 'Helpers/utils';
import useCheckPermissions from 'Hooks/useCheckPermissions';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import urls from 'Source/urls';
import EmptyDataFallback from './EmptyDataFallback';
import LogTypeCard from './LogTypeCard';
import S3PrefixesLogTypes from './S3PrefixesLogTypes';
import { useUpdateS3LogSource } from '../../S3/EditS3LogSource/graphql/updateS3LogSource.generated';
import { useViewRawData } from '../../S3/SchemasManagement/graphql/viewRawData.generated';

const SchemasPanel = ({ source }: { source: SourceIntegration }) => {
  const { logTypes, integrationId: sourceId, integrationType } = source;
  const isS3 = integrationType === LogTransportMethodsEnum.s3;
  const { pushSnackbar } = useSnackbar();
  const userIsAllowedToViewRawData = useCheckPermissions(Permission.LogSourceRawDataRead);

  // TODO: Remove this once the BE returns a flag that indicates if the
  // source has raw data.
  const { data: rawData, loading: rawDataLoading, refetch } = useViewRawData({
    skip: !isS3 || !userIsAllowedToViewRawData,
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        sourceId,
        filters: {
          from: dayjs().utc().startOf('day').subtract(1, 'month').toISOString(),
          to: dayjs().utc().endOf('day').toISOString(),
          excludedPrefixes: [],
          contains: '',
          s3Prefix: '',
        },
        pageSize: DEFAULT_SMALL_PAGE_SIZE,
        streamType: (source as S3LogIntegration).logStreamType,
      },
    },
  });

  const hasRawData = rawData?.holdingTankRawData?.data?.length > 0;

  const [updateLogSource, { loading }] = useUpdateS3LogSource({
    onCompleted: data => {
      pushSnackbar({
        title: `Source ${data.updateS3LogIntegration.integrationLabel} has been updated`,
        variant: 'success',
      });
    },
    onError: error => {
      pushSnackbar({
        title: 'Source failed to be updated',
        description: extractErrorMessage(error) || 'An unknown error occurred',
        variant: 'error',
      });
    },
  });

  const handleUpdateLogSource = React.useCallback(
    (values: Partial<UpdateS3LogIntegrationInput>) => {
      const s3Source = source as S3LogIntegration;
      updateLogSource({
        variables: {
          input: {
            integrationId: s3Source.integrationId,
            integrationLabel: s3Source.integrationLabel,
            s3Bucket: s3Source.s3Bucket,
            kmsKey: s3Source.kmsKey || null,
            logProcessingRole: s3Source.logProcessingRole,
            managedBucketNotifications: s3Source.managedBucketNotifications,
            logStreamType: s3Source.logStreamType,
            s3PrefixLogTypes: s3Source.s3PrefixLogTypes,
            ...values,
          },
        },
      });
    },
    [source, updateLogSource]
  );

  if (isS3) {
    const s3Source = source as S3LogIntegration;

    return (
      <Panel
        title={
          <Flex spacing={2} align="center">
            <Text fontSize="x-large">Schemas</Text>
            {loading && <Spinner size="small" />}
          </Flex>
        }
        actions={
          source.isEditable && logTypes.length !== 0 ? (
            <RoleRestrictedAccess
              allowedPermissions={[Permission.LogSourceModify, Permission.LogSourceRawDataRead]}
              logic="AND"
            >
              <Box
                as={RRLink}
                px={4}
                py={4}
                borderRadius="medium"
                fontSize="medium"
                backgroundColor="navyblue-200"
                aria-label="link to schema"
                to={urls.integrations.logSources.schemasManagement(sourceId).home()}
              >
                Edit Schemas
              </Box>
            </RoleRestrictedAccess>
          ) : null
        }
      >
        {logTypes.length === 0 ? (
          <EmptyDataFallback
            source={s3Source}
            onSourceUpdate={handleUpdateLogSource}
            loading={loading}
            hasRawData={hasRawData}
            rawDataLoading={rawDataLoading}
            onRawDataRefresh={() => refetch()}
          />
        ) : (
          s3Source.s3PrefixLogTypes.map(s3PrefixLogTypesElement => {
            return (
              <Box
                backgroundColor="navyblue-500"
                key={s3PrefixLogTypesElement.prefix}
                pb={4}
                mt={4}
              >
                <S3PrefixesLogTypes
                  s3BucketName={s3Source.s3Bucket}
                  sourceId={sourceId}
                  {...s3PrefixLogTypesElement}
                />
              </Box>
            );
          })
        )}
      </Panel>
    );
  }

  return (
    <Panel title={<Text fontSize="large">Schemas</Text>}>
      <Box mt={4} minHeight={200}>
        {logTypes.map(logType => (
          <LogTypeCard key={logType} sourceId={sourceId} logType={logType} />
        ))}
      </Box>
    </Panel>
  );
};

export default SchemasPanel;

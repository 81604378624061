/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */
import React from 'react';
import { Alert, Flex, Grid, SimpleGrid, Text } from 'pouncejs';
import { HoldingTankTestJobDetails } from 'Source/graphql/fragments/HoldingTankTestJobDetails.generated';
import { formatBytes } from 'Helpers/utils';
import dayjs from 'dayjs';
import { JobStatus } from 'Generated/schema';
import LinkButton from 'Components/buttons/LinkButton';
import urls from 'Source/urls';
import useRouter from 'Hooks/useRouter';

const TestReportOverview: React.FC<{
  schemaTestJob: HoldingTankTestJobDetails;
}> = ({ schemaTestJob }) => {
  const { match } = useRouter<{ id: string; testReport: string }>();
  const jobCounters = React.useMemo(() => {
    if (!schemaTestJob) {
      return null;
    }
    return {
      totalPrefixes: schemaTestJob.testParameters.s3PrefixLogTypes.length,
      totalLogTypes: schemaTestJob.testParameters.s3PrefixLogTypes.reduce((acc, { logTypes }) => {
        return acc + logTypes.length;
      }, 0),
      totalMatched: schemaTestJob.testResults.reduce((acc, { logTypeMatches }) => {
        const matches = logTypeMatches.reduce((logTypeAcc, { matched }) => {
          return logTypeAcc + matched;
        }, 0);
        return acc + matches;
      }, 0),
      totalUnmatched: schemaTestJob.testResults.reduce((acc, { unmatched }) => {
        return acc + unmatched;
      }, 0),
      // totalErrors: schemaTestJob.testResults.reduce((acc, { errored }) => {
      //   return acc + errored;
      // }, 0),
    };
  }, [schemaTestJob]);
  return (
    <>
      {!match.params.testReport && schemaTestJob?.status === JobStatus.Succeeded && (
        <Alert
          title="Test is completed, you can now see the full report."
          variant="info"
          actions={
            <LinkButton
              to={urls.integrations.logSources
                .schemasManagement(match.params.id)
                .testReport(schemaTestJob.id)}
            >
              View Report
            </LinkButton>
          }
        />
      )}
      <Grid templateColumns="2fr 1fr 1fr" gap={5}>
        <SimpleGrid backgroundColor="navyblue-500" p={4}>
          <SimpleGrid columns={2}>
            <Flex direction="column" spacing={2} color="navyblue-100">
              <Text>Test Started Date</Text>
              <Text>Events Date Start</Text>
              <Text>Events Date End</Text>
              <Text>Stream Type</Text>
              <Text>Schemas Tested</Text>
              <Text>Data Scanned</Text>
            </Flex>
            <Flex direction="column" spacing={2}>
              <Text>{dayjs(schemaTestJob?.createdAt).utc().format('MM/DD/YY - HH:mm:ss[Z]')}</Text>
              <Text>
                {dayjs(schemaTestJob?.testParameters.filters.from)
                  .utc()
                  .format('MM/DD/YY - HH:mm:ss[Z]')}
              </Text>
              <Text>
                {dayjs(schemaTestJob?.testParameters.filters.to)
                  .utc()
                  .format('MM/DD/YY - HH:mm:ss[Z]')}
              </Text>
              <Text>{schemaTestJob?.testParameters.streamType}</Text>
              <Text>
                {jobCounters
                  ? `${jobCounters.totalLogTypes} total, ${jobCounters.totalPrefixes} prefixes`
                  : 'N/A'}
              </Text>
              <Text>{formatBytes(schemaTestJob?.stats.totalSizeBytes)}</Text>
            </Flex>
          </SimpleGrid>
        </SimpleGrid>
        <Flex direction="column" align="center" justify="center" backgroundColor="navyblue-500">
          <Text color="green-400" fontSize="6x-large">
            {jobCounters?.totalMatched ?? 'N/A'}
          </Text>
          <Text>Matched Events</Text>
        </Flex>
        <Flex direction="column" align="center" justify="center" backgroundColor="navyblue-500">
          <Text color="orange-200" fontSize="6x-large">
            {jobCounters?.totalUnmatched ?? 'N/A'}
          </Text>
          <Text>Unmatched Events</Text>
        </Flex>
        {/* <Flex direction="column" align="center" justify="center" backgroundColor="navyblue-500">
          <Text color="red-200" fontSize="6x-large">
            {jobCounters?.totalErrors ?? 'N/A'}
          </Text>
          <Text>Errors</Text>
        </Flex> */}
      </Grid>
    </>
  );
};

export default TestReportOverview;

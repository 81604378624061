/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  BulkUploaderRoutes,
  bulkUploaderUrls,
  DataModelsRoutes,
  dataModelsUrls,
  DetectionsRoutes,
  detectionsUrls,
  HelpersRoutes,
  helpersUrls,
  MitreAttackRoutes,
  mitreAttackUrls,
  PacksRoutes,
  packsUrls,
} from '.';

const BuildRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path={bulkUploaderUrls} component={BulkUploaderRoutes} />
      <Route exact path={dataModelsUrls} component={DataModelsRoutes} />
      <Route exact path={detectionsUrls} component={DetectionsRoutes} />
      <Route exact path={helpersUrls} component={HelpersRoutes} />
      <Route exact path={mitreAttackUrls} component={MitreAttackRoutes} />
      <Route exact path={packsUrls} component={PacksRoutes} />
    </Switch>
  );
};

export default BuildRoutes;

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetSourceMetricsVariables = {
  input: Types.SourceMetricsInput;
};

export type GetSourceMetrics = {
  sourceMetrics: {
    bytesProcessedWithDimensions?: Types.Maybe<
      Pick<Types.SeriesData, 'timestamps'> & {
        series: Array<Pick<Types.SeriesMetric, 'label' | 'values'>>;
      }
    >;
    eventsProcessedWithDimensions?: Types.Maybe<
      Pick<Types.SeriesData, 'timestamps'> & {
        series: Array<Pick<Types.SeriesMetric, 'label' | 'values'>>;
      }
    >;
    classificationFailuresWithDimensions?: Types.Maybe<
      Pick<Types.SeriesData, 'timestamps'> & {
        series: Array<Pick<Types.SeriesMetric, 'label' | 'values'>>;
      }
    >;
  };
};

export const GetSourceMetricsDocument = gql`
  query GetSourceMetrics($input: SourceMetricsInput!) {
    sourceMetrics(input: $input) {
      bytesProcessedWithDimensions {
        series {
          label
          values
        }
        timestamps
      }
      eventsProcessedWithDimensions {
        series {
          label
          values
        }
        timestamps
      }
      classificationFailuresWithDimensions {
        series {
          label
          values
        }
        timestamps
      }
    }
  }
`;

/**
 * __useGetSourceMetrics__
 *
 * To run a query within a React component, call `useGetSourceMetrics` and pass it any options that fit your needs.
 * When your component renders, `useGetSourceMetrics` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSourceMetrics({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSourceMetrics(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetSourceMetrics, GetSourceMetricsVariables>
) {
  return ApolloReactHooks.useQuery<GetSourceMetrics, GetSourceMetricsVariables>(
    GetSourceMetricsDocument,
    baseOptions
  );
}
export function useGetSourceMetricsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSourceMetrics, GetSourceMetricsVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetSourceMetrics, GetSourceMetricsVariables>(
    GetSourceMetricsDocument,
    baseOptions
  );
}
export type GetSourceMetricsHookResult = ReturnType<typeof useGetSourceMetrics>;
export type GetSourceMetricsLazyQueryHookResult = ReturnType<typeof useGetSourceMetricsLazyQuery>;
export type GetSourceMetricsQueryResult = ApolloReactCommon.QueryResult<
  GetSourceMetrics,
  GetSourceMetricsVariables
>;
export function mockGetSourceMetrics({
  data,
  variables,
  errors,
}: {
  data: GetSourceMetrics;
  variables?: GetSourceMetricsVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetSourceMetricsDocument, variables },
    result: { data, errors },
  };
}

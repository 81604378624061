/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import storage from 'Helpers/storage';
import { __DEV__, USER_INFO_STORAGE_KEY, PANTHER_CONFIG } from 'Source/constants';
import { UserInfo } from 'Components/utils/AuthContext';
import { getActorDisplayName } from 'Helpers/utils';
import * as Sentry from '@sentry/browser';

interface ErrorData {
  extras?: {
    [key: string]: any;
  };
  fingerprint?: string[];
}

// Get the currently active user without relying to React
const getErrorUser = (): Sentry.User | undefined => {
  // Fetch the user from the localstorage. This helps identify users even when they got logged out
  // because of a need to re-authenticate
  const user = storage.local.read<UserInfo>(USER_INFO_STORAGE_KEY);
  if (!user) {
    return undefined;
  }

  return {
    id: user.id,
    name: getActorDisplayName(user),
    email: user.email,
  };
};

/**
 * Logs an error to sentry. Accepts *optional* additional arguments for easier debugging
 */
export const logError = (error: Error, { extras, fingerprint }: ErrorData = {}) => {
  Sentry.captureException(error, {
    // we are re-assigning the user to account for the fact that it might have changed from the time
    // Sentry was initialized.
    user: getErrorUser(),
    // If we have any additional unstructured data we add it here
    extra: extras,
    // group errors together based on a set of parameters
    fingerprint,
  });
};

/**
 * Initializes Sentry in order to catch unhandled exceptions that our code doesn't already handle
 */
export const initializeErrorTracking = () => {
  const sentryDsn = PANTHER_CONFIG.SENTRY_DSN;
  const sentryRelease = PANTHER_CONFIG.PANTHER_VERSION;
  const sentryEnvironment = PANTHER_CONFIG.SENTRY_ENVIRONMENT;

  Sentry.init({
    dsn: sentryDsn,
    enabled: !__DEV__,
    release: sentryEnvironment === 'dev' ? null : sentryRelease, // don't set a release for dev environments
    environment: sentryEnvironment,
    // Leave this as is. The value `7` should not change. Read the following links to understand why
    // https://github.com/getsentry/sentry-javascript/issues/2539#issuecomment-616638746
    // https://github.com/getsentry/sentry/blob/262eef0b3d6bf4964dceb15865c34a15265a1bae/src/sentry/static/sentry/app/bootstrap/initializeSdk.tsx#L21
    normalizeDepth: 7,
  });

  // Empty tags are not allowed that's why we conditionally add some
  Sentry.setTags({
    type: 'web',
    // Add deployment information as tags
    aws_account_id: PANTHER_CONFIG.AWS_ACCOUNT_ID,
    aws_partition: PANTHER_CONFIG.AWS_PARTITION,
    aws_region: PANTHER_CONFIG.AWS_REGION,
    commit_sha: PANTHER_CONFIG.PANTHER_COMMIT,
    data_lake: PANTHER_CONFIG.PANTHER_DATA_LAKE_TYPE,
    debug_enabled: PANTHER_CONFIG.DEBUG,
    ...(PANTHER_CONFIG.AWS_ACCOUNT_ALIAS && {
      aws_account_alias: PANTHER_CONFIG.AWS_ACCOUNT_ALIAS,
    }),
    ...(PANTHER_CONFIG.AWS_ORG_ID && { aws_org_id: PANTHER_CONFIG.AWS_ORG_ID }),
    ...(PANTHER_CONFIG.COMPANY_DISPLAY_NAME &&
      sentryEnvironment !== 'dev' && {
        customer_name: PANTHER_CONFIG.COMPANY_DISPLAY_NAME,
      }),
  });

  Sentry.setUser(getErrorUser());
};

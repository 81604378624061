/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import urls from 'Source/urls';
import { ENABLE_NEW_NAVIGATION } from 'Source/constants';
import ListAPITokensPage from './ListAPITokens';
import CreateAPITokenPage from './CreateAPIToken';
import EditAPITokenPage from './EditAPIToken';
import APITokenPlaygroundPage from './APITokenPlayground';

const APITokenRoutes: React.FC = () => {
  return ENABLE_NEW_NAVIGATION ? (
    <Switch>
      <Redirect exact from={urls.settings.apiTokens.list()} to={urls.settings.api.list()} />
      <Redirect exact from={urls.settings.apiTokens.list()} to={urls.settings.api.list()} />
      <Redirect exact from={urls.settings.apiTokens.create()} to={urls.settings.api.create()} />
      <Redirect
        exact
        from={urls.settings.apiTokens.edit(':id')}
        to={urls.settings.api.edit(':id')}
      />
      <Redirect
        exact
        from={urls.settings.apiTokens.playground()}
        to={urls.settings.api.playground()}
      />
      <Redirect exact from={urls.settings.api.home()} to={urls.settings.api.list()} />
      <Route exact path={urls.settings.api.list()} component={ListAPITokensPage} />
      <Route exact path={urls.settings.api.create()} component={CreateAPITokenPage} />
      <Route exact path={urls.settings.api.edit(':id')} component={EditAPITokenPage} />
      <Route exact path={urls.settings.api.playground()} component={APITokenPlaygroundPage} />
      <Redirect exact from={urls.settings.apiTokens.details(':id')} to={urls.settings.api.list()} />
      <Redirect exact from={urls.settings.api.details(':id')} to={urls.settings.api.list()} />
    </Switch>
  ) : (
    <Switch>
      <Route exact path={urls.settings.apiTokens.list()} component={ListAPITokensPage} />
      <Route exact path={urls.settings.apiTokens.create()} component={CreateAPITokenPage} />
      <Route exact path={urls.settings.apiTokens.edit(':id')} component={EditAPITokenPage} />
      <Route exact path={urls.settings.apiTokens.playground()} component={APITokenPlaygroundPage} />
      <Redirect
        exact
        from={urls.settings.apiTokens.details(':id')}
        to={urls.settings.apiTokens.list()}
      />
    </Switch>
  );
};

export default APITokenRoutes;

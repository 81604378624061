/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useSnackbar } from 'pouncejs';
import Page404 from 'Pages/404';
import useRouter from 'Hooks/useRouter';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { LogStreamTypeEnum, Permission } from 'Generated/schema';
import Page403 from 'Pages/403';
import withSEO from 'Hoc/withSEO';
import useAvailableLogTypesForProvider from 'Hooks/useAvailableLogTypesForProvider';
import { extractErrorMessage } from 'Helpers/utils';
import { compose } from 'Helpers/compose';
import { useGetS3LogSource } from 'Source/graphql/queries/getS3LogSource.generated';
import { LogTransportMethodsEnum } from 'Source/constants';
import urls from 'Source/urls';
import S3LogSourceWizard, {
  s3PrefixLogTypesInitialValues,
  S3LogSourceWizardValues,
} from '../S3LogSourceWizard';
import { useUpdateS3LogSource } from './graphql/updateS3LogSource.generated';

const EditS3LogSource: React.FC = () => {
  const { pushSnackbar } = useSnackbar();
  const { match, history } = useRouter<{ id: string }>();
  const { data, error: getError } = useGetS3LogSource({
    variables: { id: match.params.id },
    onError: error => {
      pushSnackbar({
        title: extractErrorMessage(error) || 'An unknown error occurred',
        variant: 'error',
      });
    },
  });

  // If the source is not editable fallback to the source details page
  React.useEffect(() => {
    if (data?.getS3LogIntegration && !data.getS3LogIntegration.isEditable) {
      history.push(
        urls.integrations.logSources.details(match.params.id, LogTransportMethodsEnum.s3)
      );
    }
  }, [data, history, match]);

  const [updateLogSource] = useUpdateS3LogSource();

  const initialValues = React.useMemo<S3LogSourceWizardValues>(
    () => ({
      integrationId: match.params.id,
      initialStackName: data?.getS3LogIntegration?.stackName,
      awsAccountId: data?.getS3LogIntegration?.awsAccountId ?? 'Loading...',
      integrationLabel: data?.getS3LogIntegration?.integrationLabel ?? 'Loading...',
      s3Bucket: data?.getS3LogIntegration?.s3Bucket ?? 'Loading...',
      s3PrefixLogTypes: data?.getS3LogIntegration.s3PrefixLogTypes ?? [
        s3PrefixLogTypesInitialValues,
      ],
      kmsKey: data?.getS3LogIntegration?.kmsKey ?? '',
      managedBucketNotifications: data?.getS3LogIntegration?.managedBucketNotifications ?? false,
      logProcessingRole: data?.getS3LogIntegration?.logProcessingRole ?? '',
      logStreamType: data?.getS3LogIntegration?.logStreamType ?? LogStreamTypeEnum.Lines,
      logTypesAttached: data?.getS3LogIntegration.s3PrefixLogTypes.some(
        s => s.logTypes.length || s.prefix || s.excludedPrefixes.length
      )
        ? 'now'
        : 'later',
    }),
    // FIXME: look into missing hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const { availableLogTypes } = useAvailableLogTypesForProvider(
    data?.getS3LogIntegration?.logProviderId
  );

  const handleSubmit = React.useCallback(async (values: S3LogSourceWizardValues) => {
    try {
      const resp = await updateLogSource({
        variables: {
          input: {
            integrationId: values.integrationId,
            integrationLabel: values.integrationLabel,
            s3Bucket: values.s3Bucket,
            s3PrefixLogTypes:
              values.logTypesAttached === 'now'
                ? values.s3PrefixLogTypes
                : [s3PrefixLogTypesInitialValues],
            kmsKey: values.kmsKey || null,
            logProcessingRole: values.logProcessingRole,
            managedBucketNotifications: values.managedBucketNotifications,
            logStreamType: values.logStreamType,
          },
        },
      });
      return resp.data.updateS3LogIntegration;
    } catch (err) {
      return err as Error;
    }
    // FIXME: look into missing hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // we optimistically assume that an error in "get" is a 404. We don't have any other info
  if (getError) {
    return <Page404 />;
  }

  return (
    <S3LogSourceWizard
      initialValues={initialValues}
      availableLogTypes={availableLogTypes}
      onSubmit={handleSubmit}
    />
  );
};

export default compose(
  withSEO({ title: 'Edit S3 Log Source' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LogSourceModify],
    fallback: <Page403 />,
  })
)(EditS3LogSource);

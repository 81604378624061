/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Actor: ['User', 'APIToken'],
    AlertOrigin: [
      'AlertOriginRule',
      'AlertOriginPolicy',
      'AlertOriginSystemError',
      'AlertOriginReplayedRule',
    ],
    SystemErrorRelatedEntity: [
      'S3LogIntegration',
      'EventBridgeIntegration',
      'LogPullingIntegration',
      'SqsLogSourceIntegration',
      'CloudWatchLogIntegration',
      'GcsLogSourceIntegration',
      'Destination',
    ],
    AlertActivityHistoryRecordContent: [
      'AlertActivityHistoryAssigneeChange',
      'AlertActivityHistoryStatusChange',
    ],
    Job: [
      'UpdateAlertStatusByFilterJob',
      'SummarizeDataLakeQueryJob',
      'LookupImportDataJob',
      'LookupSyncJob',
      'HoldingTankTestJob',
      'HoldingTankInferJob',
    ],
    Detection: ['Rule', 'Policy'],
    TestRecord: ['TestPolicyRecord', 'TestDetectionRecord'],
    LogIntegration: [
      'S3LogIntegration',
      'CloudWatchLogIntegration',
      'EventBridgeIntegration',
      'SqsLogSourceIntegration',
      'GcsLogSourceIntegration',
      'LogPullingIntegration',
    ],
    GithubLogSourceCredentialsConfig: ['GithubLogSourceOAuth2Config', 'GithubLogSourcePatConfig'],
    ZendeskLogSourceCredentialsConfig: [
      'ZendeskLogSourceOAuth2Config',
      'ZendeskLogSourceBasicConfig',
      'ZendeskLogSourceApiConfig',
    ],
  },
};
export default result;

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import urls from 'Source/urls';
import CreateGlobalHelperPage from 'Pages/CreateGlobalHelper';
import EditGlobalHelper from 'Pages/EditGlobalHelper';
import ListGlobalHelpersPage from 'Pages/ListGlobalHelpers';

const HelpersRoutes: React.FC = () => {
  return (
    <Switch>
      <Redirect exact from={urls.data.globalHelpers.list()} to={urls.build.globalHelpers.list()} />
      <Route exact path={urls.build.globalHelpers.list()} component={ListGlobalHelpersPage} />
      <Redirect
        exact
        from={urls.data.globalHelpers.create()}
        to={urls.build.globalHelpers.create()}
      />
      <Route exact path={urls.build.globalHelpers.create()} component={CreateGlobalHelperPage} />
      <Redirect
        exact
        from={urls.data.globalHelpers.edit(':id')}
        to={urls.build.globalHelpers.edit(':id')}
      />
      <Route exact path={urls.build.globalHelpers.edit(':id')} component={EditGlobalHelper} />
      <Redirect
        exact
        from={`${urls.data.globalHelpers.list()}:id`}
        to={urls.build.globalHelpers.edit(':id')}
      />
      <Redirect
        exact
        from={`${urls.build.globalHelpers.list()}:id`}
        to={urls.build.globalHelpers.edit(':id')}
      />
    </Switch>
  );
};

export default HelpersRoutes;

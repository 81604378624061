/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box } from 'pouncejs';
import { useMitreContext } from 'Pages/Reports/MitreMatrix/MitreContext';
import findIndex from 'lodash/findIndex';

const rowStart = 36;
const rowHeight = 36;
const colWidth = 32;
const colStart = 321;

const MitreMatrixGridOverlay = () => {
  const { activeMatrix, activeTactic, activeTechnique } = useMitreContext();

  const activeTacticIndex = React.useMemo<number>(
    () => findIndex(activeMatrix?.content.tactics, { id: activeTactic?.id }),
    [activeMatrix, activeTactic]
  );

  const activeTechniqueIndex = React.useMemo<number>(() => {
    const mapping = activeMatrix?.mappings.find(m => m.tacticId === activeTactic?.id);
    if (!mapping) {
      return 0;
    }
    return mapping.techniqueIds?.indexOf(activeTechnique?.id) || 0;
  }, [activeMatrix, activeTactic, activeTechnique]);

  const haloStyles = React.useMemo(
    () => ({
      top: `${rowStart + rowHeight * activeTacticIndex}px`,
      left: `${colStart + colWidth * activeTechniqueIndex}px`,
      right: 0,
      bottom: 0,
      transition: 'top 200ms, left 200ms',
    }),
    [activeTacticIndex, activeTechniqueIndex]
  );

  return (
    <Box top={0} left={0} right={0} bottom={0} position="absolute" pointerEvents="none" zIndex={2}>
      {activeTechnique && (
        <Box style={haloStyles} position="absolute" width="36px" height="36px">
          <Box
            style={{ borderRadius: 14 }}
            top={-5}
            left={-5}
            right={-5}
            bottom={-5}
            position="absolute"
            boxShadow="0 0 10px black"
            borderWidth={8}
            borderStyle="solid"
            borderColor="blue-300"
          />
        </Box>
      )}
    </Box>
  );
};

export default React.memo(MitreMatrixGridOverlay);

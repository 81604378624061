/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import orderBy from 'lodash/orderBy';
import { SortDirEnum } from 'Generated/schema';
import useUrlParams from 'Hooks/useUrlParams';
import { ListLogSources } from './graphql/listLogSources.generated';
import {
  ListLogSourceInlineFilterValues,
  ListLogSourceDropdownFilterValues,
  ListLogSourceSortFieldsEnum,
} from './ListLogSourceActions/ListLogSourceFilters';

type FilterUrlParams = ListLogSourceInlineFilterValues & ListLogSourceDropdownFilterValues;

const useLogSourceFiltering = (data: ListLogSources) => {
  const { urlParams } = useUrlParams<FilterUrlParams>({
    parseNumbers: false,
    parseBooleans: false,
    overrides: {
      contains: 'string',
    },
  });
  return React.useMemo(() => {
    if (!data?.logSources?.length) {
      return [];
    }
    const { sortDir, sortBy, status, contains, logTypes } = urlParams;
    let logSources = [...data.logSources];

    if (contains) {
      logSources = logSources.filter(({ integrationLabel }) =>
        integrationLabel.toLowerCase().includes(contains.toLowerCase())
      );
    }

    if (status) {
      logSources = logSources.filter(s => s.isHealthy === (status === 'Healthy'));
    }

    if (logTypes && Array.isArray(logTypes)) {
      logSources = logSources.filter(source => {
        return logTypes.some(logType => source.logTypes.includes(logType));
      });
    }

    switch (sortBy) {
      case ListLogSourceSortFieldsEnum.health: {
        return orderBy(
          logSources,
          [l => !l.isHealthy, l => l.createdAtTime],
          [sortDir === SortDirEnum.Ascending ? 'asc' : 'desc', 'desc']
        );
      }
      case ListLogSourceSortFieldsEnum.dateCreated:
      default:
        return orderBy(
          logSources,
          l => l.createdAtTime,
          sortDir === SortDirEnum.Ascending ? 'asc' : 'desc'
        );
    }
  }, [data, urlParams]);
};

export default useLogSourceFiltering;

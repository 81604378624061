/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type DataLakeQuerySummaryVariables = {
  jobId: Types.Scalars['ID'];
};

export type DataLakeQuerySummary = {
  dataLakeQuerySummary: Pick<
    Types.SummarizeDataLakeQueryJob,
    'id' | 'status' | 'message' | 'rows'
  > & { columnInfo?: Types.Maybe<Pick<Types.DataLakeQueryResultsColumnInfo, 'order' | 'types'>> };
};

export const DataLakeQuerySummaryDocument = gql`
  query DataLakeQuerySummary($jobId: ID!) {
    dataLakeQuerySummary(jobId: $jobId) {
      id
      status
      message
      columnInfo {
        order
        types
      }
      rows
    }
  }
`;

/**
 * __useDataLakeQuerySummary__
 *
 * To run a query within a React component, call `useDataLakeQuerySummary` and pass it any options that fit your needs.
 * When your component renders, `useDataLakeQuerySummary` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataLakeQuerySummary({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useDataLakeQuerySummary(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    DataLakeQuerySummary,
    DataLakeQuerySummaryVariables
  >
) {
  return ApolloReactHooks.useQuery<DataLakeQuerySummary, DataLakeQuerySummaryVariables>(
    DataLakeQuerySummaryDocument,
    baseOptions
  );
}
export function useDataLakeQuerySummaryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DataLakeQuerySummary,
    DataLakeQuerySummaryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<DataLakeQuerySummary, DataLakeQuerySummaryVariables>(
    DataLakeQuerySummaryDocument,
    baseOptions
  );
}
export type DataLakeQuerySummaryHookResult = ReturnType<typeof useDataLakeQuerySummary>;
export type DataLakeQuerySummaryLazyQueryHookResult = ReturnType<
  typeof useDataLakeQuerySummaryLazyQuery
>;
export type DataLakeQuerySummaryQueryResult = ApolloReactCommon.QueryResult<
  DataLakeQuerySummary,
  DataLakeQuerySummaryVariables
>;
export function mockDataLakeQuerySummary({
  data,
  variables,
  errors,
}: {
  data: DataLakeQuerySummary;
  variables?: DataLakeQuerySummaryVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: DataLakeQuerySummaryDocument, variables },
    result: { data, errors },
  };
}

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import useRouter from 'Hooks/useRouter';
import { useFormikContext } from 'formik';
import { Button, useSnackbar, Flex } from 'pouncejs';

import { JobStatus, PutUserSchemaInput } from 'Generated/schema';
import { appendDataSchemaPrefix, extractErrorMessage } from 'Helpers/utils';
import BulletedLoading from 'Components/BulletedLoading';
import useModal from 'Hooks/useModal';
import InferSchemaModal from 'Pages/Integrations/LogSources/S3/SchemasManagement/modals/InferSchemaModal/InferSchemaModal';
import { NewSchemaFormValues } from 'Pages/Integrations/LogSources/S3/SchemasManagement/modals/InferSchemaModal/InferSchemaForm';
import { useInferSchemaFromRawData } from '../../graphql/inferSchemaFromRawData.generated';
import { useSchemasManagementContext } from '../../SchemasManagementContext';
import { useCancelHoldingTankJob } from '../../graphql/cancelHoldingTankJob.generated';
import { S3PrefixLogTypesFormValues } from '../../SchemasManagement';

const InferSchemaButton: React.FC<{
  disabled: boolean;
  setInferSchemaError: (error: string) => void;
}> = ({ disabled, setInferSchemaError }) => {
  const { values, setFieldValue, status } = useFormikContext<S3PrefixLogTypesFormValues>();
  const { showModal } = useModal();
  const { rawDataFilters, setInferSchemaJob, inferSchemaJob } = useSchemasManagementContext();
  const [schemaFormValues, setSchemaFormValues] = React.useState<NewSchemaFormValues>();
  const { match } = useRouter<{ id: string }>();
  const { pushSnackbar } = useSnackbar();

  const [inferSchemaFromRawData, { loading: inferSchemaLoading }] = useInferSchemaFromRawData({
    onCompleted: testData => {
      setInferSchemaJob(testData.inferSchemaFromRawData);
    },
    onError: error => {
      pushSnackbar({
        variant: 'error',
        title: `Failed to infer schema from raw data`,
        description: extractErrorMessage(error),
      });
    },
  });

  const [cancelJob, { loading: cancelJobLoading }] = useCancelHoldingTankJob({
    onError: cancelError => {
      pushSnackbar({
        variant: 'error',
        title: "Couldn't cancel schema inferring operation",
        description: extractErrorMessage(cancelError),
      });
    },
    onCompleted: () => {
      setInferSchemaJob(null);
    },
  });

  const inferSchema = React.useCallback(
    (val: NewSchemaFormValues) => {
      setInferSchemaError('');
      inferSchemaFromRawData({
        variables: {
          input: {
            sourceId: match.params.id,
            streamType: values.logStreamType,
            filters: rawDataFilters,
          },
        },
      });
      setSchemaFormValues(val);
    },
    [
      inferSchemaFromRawData,
      setInferSchemaError,
      rawDataFilters,
      values.logStreamType,
      match.params.id,
    ]
  );

  React.useEffect(
    () => {
      if (
        !inferSchemaJob ||
        inferSchemaJob.status === JobStatus.Cancelled ||
        inferSchemaJob.status === JobStatus.Running
      ) {
        return;
      }
      if (
        inferSchemaJob.status === JobStatus.Succeeded &&
        inferSchemaJob.schemaInferenceResult?.spec &&
        !inferSchemaJob.schemaInferenceResult.error
      ) {
        const draftSchema: PutUserSchemaInput = {
          spec: inferSchemaJob.schemaInferenceResult?.spec,
          name: schemaFormValues.name,
        };
        const newSchemaName = appendDataSchemaPrefix(draftSchema.name);
        setFieldValue('draftSchemas', [...values.draftSchemas, draftSchema]);

        // If the prefix exists append the new schema to the existing prefix,
        // otherwise create a new prefix and append the schema to it
        const existingPrefixIndex = values.s3PrefixLogTypes.findIndex(
          s => s.prefix === schemaFormValues.prefix
        );
        if (existingPrefixIndex !== -1) {
          setFieldValue(`s3PrefixLogTypes.${existingPrefixIndex}.logTypes`, [
            ...values.s3PrefixLogTypes[existingPrefixIndex].logTypes,
            newSchemaName,
          ]);
        } else {
          setFieldValue('s3PrefixLogTypes', [
            ...values.s3PrefixLogTypes,
            {
              prefix: schemaFormValues.prefix,
              excludedPrefixes: [],
              logTypes: [newSchemaName],
            },
          ]);
        }
        // Once we are done with the infer process remove it from the context
        setInferSchemaJob(null);
      } else {
        setInferSchemaError(
          inferSchemaJob.schemaInferenceResult?.error ||
            'An unexpected error occurred while trying to infer schema from samples.'
        );
      }
    },
    // This hook should run only once when the async infer schema job is finished
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inferSchemaJob]
  );

  const isDisabled =
    disabled || cancelJobLoading || inferSchemaLoading || values.draftSchemas?.some(s => !s.name);

  return inferSchemaJob?.status === JobStatus.Running ? (
    <Flex align="center" spacing={4}>
      <Button
        variantColor="pink-700"
        onClick={() =>
          cancelJob({
            variables: {
              id: inferSchemaJob.id,
            },
          })
        }
        disabled={cancelJobLoading}
        loading={cancelJobLoading}
        icon="stop"
      >
        Stop Inferring
      </Button>
      This can take up to 15 minutes
      <BulletedLoading />
    </Flex>
  ) : (
    <Button
      disabled={isDisabled}
      loading={inferSchemaLoading}
      onClick={() => {
        return showModal(
          <InferSchemaModal
            initialPrefix={rawDataFilters.s3Prefix}
            initialExcludedPrefixes={rawDataFilters.excludedPrefixes}
            availableLogTypes={status.availableLogTypes}
            onSuccess={inferSchema}
          />,
          {
            title: <Flex ml={8}>Infer New Schema</Flex>,
            showCloseButton: true,
          }
        );
      }}
    >
      Infer Schema
    </Button>
  );
};

export default InferSchemaButton;

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Flex } from 'pouncejs';
import FadeInTrail from 'Components/utils/FadeInTrail';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess/RoleRestrictedAccess';
import NavLink from '../NavLink';
import { NavigationLink } from '../constants';

interface SecondaryNavigationMenuProps {
  links: NavigationLink[];
}

const SecondaryNavigationMenu: React.FC<SecondaryNavigationMenuProps> = ({ links }) => {
  return (
    <Flex direction="column" as="ul">
      <FadeInTrail as="li">
        {links.map(({ to, icon, label, permissions }) => {
          return permissions?.length ? (
            <RoleRestrictedAccess key={label} allowedPermissions={permissions}>
              <NavLink icon={icon} label={label} to={to} isSecondary />
            </RoleRestrictedAccess>
          ) : (
            <NavLink key={label} icon={icon} label={label} to={to} isSecondary />
          );
        })}
      </FadeInTrail>
    </Flex>
  );
};

export default SecondaryNavigationMenu;

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Card, Grid } from 'pouncejs';
import { SeriesWithBreakdown } from 'Generated/schema';
import TimeSeriesChart, { TimeSeriesData } from 'Components/charts/TimeSeriesChart';
import { useMetricsFiltersContext } from 'Components/utils/MetricsFiltersContext';
import VolumeSummary from '../../VolumeSummary';

interface DataQueriedTabProps {
  bytesQueriedPerSource: SeriesWithBreakdown[];
  totalBytesQueried: number;
}

const DataQueriedTab: React.FC<DataQueriedTabProps> = ({
  bytesQueriedPerSource,
  totalBytesQueried,
}) => {
  const {
    filters: { fromDate, toDate },
    resultProjectionStartDate,
  } = useMetricsFiltersContext();

  const chartData = React.useMemo<TimeSeriesData>(() => {
    return {
      timestamps: Object.keys(bytesQueriedPerSource[0]?.breakdown ?? []),
      series: bytesQueriedPerSource.map(series => ({
        label: series.label,
        values: Object.values(series.breakdown),
        color: 'yellow-300' as const,
      })),
    };
  }, [bytesQueriedPerSource]);

  return (
    <Grid templateColumns="1fr 3fr" gap={4}>
      <VolumeSummary
        totalBytes={totalBytesQueried}
        title="Total Volume Queried"
        fromDate={fromDate}
        toDate={toDate}
        color="yellow-300"
      />
      <Card variant="dark" height={289} py={5} pl={4}>
        <TimeSeriesChart
          projectionStartDate={resultProjectionStartDate}
          chartId="data-queried"
          data={chartData}
          scaleControls={false}
          zoomable
          hideLegend
          units="bytes"
        />
      </Card>
    </Grid>
  );
};

export default React.memo(DataQueriedTab);

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import ListAlertControls from 'Pages/ListAlerts/ListAlertControls';
import React from 'react';
import { Alert, Box, Card, Flex, TabList, Tabs } from 'pouncejs';
import dayjs from 'dayjs';
import { BorderedTab, BorderTabDivider } from 'Components/BorderedTab';
import { DEFAULT_LARGE_PAGE_SIZE, ENABLE_ALERTS_GSI_LIST } from 'Source/constants';
import { extractErrorMessage, getGraphqlSafeDateRangeFuture, slugify } from 'Helpers/utils';
import { compose } from 'Helpers/compose';
import { AlertListingType, AlertsInput, AlertType, Permission } from 'Generated/schema';
import useInfiniteScroll from 'Hooks/useInfiniteScroll';
import useRequestParamsWithoutPagination from 'Hooks/useRequestParamsWithoutPagination';
import TablePlaceholder from 'Components/TablePlaceholder';
import NoResultsFound from 'Components/NoResultsFound';
import ErrorBoundary from 'Components/ErrorBoundary';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import Page403 from 'Pages/403';
import isEmpty from 'lodash/isEmpty';
import withSEO from 'Hoc/withSEO';
import isEqual from 'lodash/isEqual';
import AlertCard from 'Components/cards/AlertCard';
import { useSelect, withSelectContext } from 'Components/utils/SelectContext/SelectContext';
import useCheckPermissions from 'Hooks/useCheckPermissions';
import useOverrideShiftClickSelection from 'Hooks/useOverrideShiftClickSelection';
import { useListAlerts } from './graphql/listAlerts.generated';
import ListAlertBreadcrumbFilters from './ListAlertBreadcrumbFilters';
import ListAlertsPageSkeleton from './Skeleton';
import ListAlertsPageEmptyDataFallback from './EmptyDataFallback';

const defaultSortingFn = (a: string, b: string) => a.localeCompare(b);

export const ALERT_TABS_TYPES = [AlertType.Rule, AlertType.Policy, AlertType.ScheduledRule].sort(
  defaultSortingFn
);

export const DETECTION_ERRORS_TABS_TYPES = [AlertType.RuleError, AlertType.ScheduledRuleError].sort(
  defaultSortingFn
);

export const SYSTEM_ERRORS_TABS_TYPES = [AlertType.SystemError].sort(defaultSortingFn);

// FIXME GSI: remove after monthly partitions are active
export const TABS_BY_TYPE = [
  ALERT_TABS_TYPES,
  DETECTION_ERRORS_TABS_TYPES,
  SYSTEM_ERRORS_TABS_TYPES,
];

export const TABS_BY_LISTING_TYPE = [
  AlertListingType.Alert,
  AlertListingType.DetectionError,
  AlertListingType.SystemError,
];

export const TAB_LABELS = ['Alerts', 'Detection Errors', 'System Errors'];

const EMPTY_ARRAY = [];

const ListAlerts = () => {
  useOverrideShiftClickSelection();
  const { requestParams, updateRequestParams } = useRequestParamsWithoutPagination<AlertsInput>({
    overrides: { nameContains: 'string' },
  });

  const { resetSelection, checkIfSelected, setAllSelectables } = useSelect();
  const canUserModifyAlerts = useCheckPermissions(Permission.AlertModify);

  const { types = EMPTY_ARRAY, logSources = EMPTY_ARRAY, type } = requestParams;

  React.useEffect(() => {
    let shouldRedirect;

    if (ENABLE_ALERTS_GSI_LIST) {
      shouldRedirect = !TABS_BY_LISTING_TYPE.some(listingType => listingType === type);
    } else {
      // Types should match 1 by the assigned types per tab, otherwise we should set back the Alerts tab
      // Order is insignificant thus we need to sort the results
      const orderedTypes = types.sort(defaultSortingFn);
      shouldRedirect = !TABS_BY_TYPE.some(tabsPerType => isEqual(orderedTypes, tabsPerType));
    }

    if (shouldRedirect) {
      updateRequestParams({
        type: ENABLE_ALERTS_GSI_LIST ? AlertListingType.Alert : undefined,
        types: ENABLE_ALERTS_GSI_LIST ? undefined : ALERT_TABS_TYPES,
      });
    }

    return () => {
      resetSelection();
    };
    // FIXME: Look into missing effect dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [types, type, updateRequestParams]);

  // Default effect to update URL parameters to set the filter to 7 days if
  // the date range is empty.
  //
  // FIXME: Remove function when we have better alert filtering
  React.useEffect(() => {
    // If the URL had no date range at all, we need to update
    if (!requestParams.createdAtBefore && !requestParams.createdAtAfter) {
      // Add 1 day into the future to not miss any alerts for a given workday
      const [utcDaysAgo, utcFuture] = getGraphqlSafeDateRangeFuture({ days: 7 });
      updateRequestParams({ createdAtAfter: utcDaysAgo, createdAtBefore: utcFuture });
    }

    // Reset the date picker on nav away
    return () => {
      resetSelection();
    };
    // FIXME: Look into missing effect dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestParams]);

  // Clean up logsources when user is not in system errors tab
  React.useEffect(() => {
    if (
      !types.includes(AlertType.SystemError) &&
      type !== AlertListingType.SystemError &&
      logSources.length
    ) {
      updateRequestParams({ logSources: [] }); // Empty logSources
    }
  }, [types, logSources, updateRequestParams, type]);

  const { loading, error, previousData, data = previousData, fetchMore } = useListAlerts({
    variables: {
      input: {
        ...requestParams,
        pageSize: DEFAULT_LARGE_PAGE_SIZE,
      },
    },
  });

  const alerts = React.useMemo(() => data?.alerts.edges.map(edge => edge.node) || [], [data]);
  const alertIds = React.useMemo(() => alerts.map(a => a.id), [alerts]);
  const allAlertsLoaded = React.useMemo(() => data?.alerts?.pageInfo.hasNextPage === false, [data]);
  const hasNextPage = data?.alerts?.pageInfo.hasNextPage;

  React.useEffect(() => {
    setAllSelectables(alerts.map(x => x.id));
  }, [alerts, setAllSelectables]);

  const activeTabIndex = React.useMemo(() => {
    if (ENABLE_ALERTS_GSI_LIST) {
      return TABS_BY_LISTING_TYPE.findIndex(listingType => listingType === type);
    }

    const orderedTypes = types.sort(defaultSortingFn);
    return TABS_BY_TYPE.findIndex(tabTypes => isEqual(tabTypes, orderedTypes));
  }, [types, type]);

  const changeTab = React.useCallback(
    tabIndex => {
      updateRequestParams({
        type: ENABLE_ALERTS_GSI_LIST ? TABS_BY_LISTING_TYPE[tabIndex] : undefined,
        types: ENABLE_ALERTS_GSI_LIST ? undefined : TABS_BY_TYPE[tabIndex],
        // Since subtypes are dependent on the type chosen, clear them whenever
        // we change the type
        subtypes: [],
      });
    },
    [updateRequestParams]
  );

  const { sentinelRef } = useInfiniteScroll<HTMLDivElement>({
    loading,
    threshold: 500,
    onLoadMore: () => {
      fetchMore({
        variables: {
          input: {
            ...requestParams,
            pageSize: DEFAULT_LARGE_PAGE_SIZE,
            cursor: data?.alerts.pageInfo.endCursor ?? null,
          },
        },
      });
    },
  });

  const humanReadableDateRange = React.useMemo(
    () =>
      `${dayjs(requestParams.createdAtAfter).format('MM/DD/YYYY HH:mm')} - ${dayjs(
        requestParams.createdAtBefore
      ).format('MM/DD/YYYY HH:mm')}`,
    [requestParams.createdAtAfter, requestParams.createdAtBefore]
  );

  if (loading && !data) {
    return <ListAlertsPageSkeleton />;
  }

  if (!alerts.length && isEmpty(requestParams)) {
    return <ListAlertsPageEmptyDataFallback />;
  }

  const hasError = Boolean(error);
  return (
    <ErrorBoundary>
      {hasError && (
        <Box mb={6}>
          <Alert
            variant="error"
            title="Couldn't load your alerts"
            description={
              extractErrorMessage(error) ||
              'There was an error when performing your request, please contact support@runpanther.io'
            }
          />
        </Box>
      )}
      <ListAlertBreadcrumbFilters />
      <Tabs index={activeTabIndex} onChange={changeTab}>
        <Card as="section" data-tracking-page="list-alerts" position="relative">
          <Box px={2}>
            <TabList>
              {TAB_LABELS.map(tabLabel => (
                <BorderedTab key={slugify(tabLabel)}>{tabLabel}</BorderedTab>
              ))}
            </TabList>
          </Box>
          <BorderTabDivider />
          <ListAlertControls
            alertIds={alertIds}
            allLoaded={allAlertsLoaded}
            collectionLabel={TAB_LABELS[activeTabIndex]}
          />

          <Card as="section" aria-label="Alerts" position="relative" p={6} pt={0}>
            <Box mb={0} p={3} pl={0}>
              Showing results between {humanReadableDateRange}
            </Box>

            {loading ? (
              <TablePlaceholder rowCount={10} />
            ) : (
              <Box data-testid="alerts-section" position="relative">
                <Flex direction="column" spacing={2}>
                  {alerts.length ? (
                    alerts.map(alert => (
                      <AlertCard
                        key={alert.id}
                        alert={alert}
                        selectionEnabled={canUserModifyAlerts}
                        isSelected={checkIfSelected(alert.id)}
                      />
                    ))
                  ) : (
                    <Box data-testid="alerts-section" my={8}>
                      <NoResultsFound />
                    </Box>
                  )}
                </Flex>

                {hasNextPage && (
                  <Box py={8} ref={sentinelRef}>
                    <TablePlaceholder rowCount={10} />
                  </Box>
                )}
              </Box>
            )}
          </Card>
        </Card>
      </Tabs>
    </ErrorBoundary>
  );
};

export default compose(
  withSEO({ title: 'Alerts & Errors' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.AlertRead],
    fallback: <Page403 />,
  }),
  withSelectContext(),
  React.memo
)(ListAlerts);

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import urls from 'Source/urls';
import useRouter from 'Hooks/useRouter';
import LinkButton from 'Components/buttons/LinkButton';
import { Flex, Text } from 'pouncejs';
import { LogSourceType } from 'Source/constants';

interface FinishSourceSetupActionsProps {
  integrationId: string;
}

const FinishSourceSetupActions = ({ integrationId }: FinishSourceSetupActionsProps) => {
  const { match, location } = useRouter<{ id: string; type: LogSourceType }>();
  const editMode = location.pathname.endsWith('/edit/');

  if (editMode) {
    return (
      <LinkButton to={`${urls.integrations.logSources.details(integrationId, match.params.type)}`}>
        Back to Log Source
      </LinkButton>
    );
  }

  return (
    <Flex direction="column" spacing={6} align="center">
      <Text fontWeight="medium" textAlign="center">
        You can now visit your log source where you can monitor ingestion and make edits by clicking
        the button below. Although set up is complete, please keep in mind that{' '}
        <Text as="span" color="yellow-500">
          it may take a few minutes
        </Text>{' '}
        for data to be imported from your source.
      </Text>
      <LinkButton to={`${urls.integrations.logSources.details(integrationId, match.params.type)}`}>
        View Log Source
      </LinkButton>
    </Flex>
  );
};

export default FinishSourceSetupActions;

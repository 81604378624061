/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import TablePlaceholder from 'Components/TablePlaceholder';
import { Box, Flex, Alert } from 'pouncejs';
import AlertCard from 'Components/cards/AlertCard';
import NoResultsFound from 'Components/NoResultsFound';
import { DEFAULT_LARGE_PAGE_SIZE } from 'Source/constants';
import { Alert as AlertType } from 'Generated/schema';
import useInfiniteScroll from 'Hooks/useInfiniteScroll';
import { AlertDetails } from 'Pages/AlertDetails';
import { extractErrorMessage } from 'Helpers/utils';
import { useGetNonDeliveredAlerts } from './graphql/getNonDeliveredAlerts.generated';

interface SystemErrorAlertNonDeliveredAlertsProps {
  alert: AlertDetails['alert'];
}

const SystemErrorAlertNonDeliveredAlerts: React.FC<SystemErrorAlertNonDeliveredAlertsProps> = ({
  alert,
}) => {
  const { previousData, data = previousData, loading, fetchMore, error } = useGetNonDeliveredAlerts(
    {
      variables: { id: alert.id, input: { pageSize: DEFAULT_LARGE_PAGE_SIZE } },
    }
  );

  const nonDeliveredAlerts = React.useMemo(() => {
    return (
      data?.alert.origin.alerts.edges.map(edge => {
        return { ...edge?.node } as AlertType;
      }) || []
    );
  }, [data]);

  const { sentinelRef } = useInfiniteScroll<HTMLDivElement>({
    loading,
    threshold: 500,
    onLoadMore: () => {
      fetchMore({
        variables: {
          id: alert.id,
          input: {
            pageSize: DEFAULT_LARGE_PAGE_SIZE,
            cursor: data?.alert.origin.alerts.pageInfo.endCursor ?? null,
          },
        },
      });
    },
  });

  const hasNextPage = data?.alert.origin.alerts.pageInfo.hasNextPage;
  const hasError = Boolean(error);

  return (
    <Box>
      {loading ? (
        <TablePlaceholder rowCount={10} />
      ) : (
        <Box position="relative">
          {hasError && (
            <Box mb={6}>
              <Alert
                variant="error"
                title="Couldn't load your alerts"
                description={
                  extractErrorMessage(error) ||
                  'There was an error when performing your request, please contact support@runpanther.io'
                }
              />
            </Box>
          )}
          <Flex direction="column" spacing={2}>
            {nonDeliveredAlerts.length ? (
              nonDeliveredAlerts.map(nonDeliveredAlert => (
                <AlertCard
                  selectionEnabled={false}
                  isSelected={false}
                  key={nonDeliveredAlert.id}
                  alert={nonDeliveredAlert}
                />
              ))
            ) : (
              <Box my={8}>
                <NoResultsFound />
              </Box>
            )}
          </Flex>

          {hasNextPage && (
            <Box py={8} ref={sentinelRef}>
              <TablePlaceholder rowCount={10} />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default SystemErrorAlertNonDeliveredAlerts;

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Grid, Flex, IconButton, Card, Heading } from 'pouncejs';
import { DEFAULT_LARGE_PAGE_SIZE, IS_SNOWFLAKE } from 'Source/constants';
import TablePlaceholder from 'Components/TablePlaceholder';
import useAsyncQueryContext from 'Hooks/useAsyncQueryContext';
import useAsyncQueryResults from 'Hooks/useAsyncQueryResults';
import useNavigationKeys from 'Hooks/useNavigationKeys';
import AsyncQueryStatus from 'Components/AsyncQueryStatus';
import useAsyncQueryTimer from 'Hooks/useAsyncQueryTimer';
import JsonViewer, { EventNodeActions, JsonViewerHeader } from 'Components/JsonViewer';
import ElapsedTime from 'Components/ElapsedTime';
import { toPlural } from 'Helpers/utils';
import DataScanned from 'Components/DataScanned';
import ResultsTable from './ResultsTable';
import ResultsActionBar from './ResultsActionBar';

export type ViewSelections = 'tabular' | 'json';
export interface ResultsFilterValues {
  selectedColumns?: string[];
}

const ResultsTab = () => {
  const { timeElapsed: timer } = useAsyncQueryTimer();
  const [view, setView] = React.useState<ViewSelections>('tabular');
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const {
    results,
    columnOrder,
    hasNextPage,
    infiniteScrollRef,
    fetchNextPage,
    loading,
    stats,
  } = useAsyncQueryResults({
    pageSize: DEFAULT_LARGE_PAGE_SIZE,
  });
  const { state: { queryStatus, globalErrorMessage, queryId } } = useAsyncQueryContext(); // prettier-ignore

  const size = results ? results.length : 0;

  const goToNextPage = () => {
    const newIndex = size - 1 === selectedIndex ? 0 : selectedIndex + 1;
    // When we are approximately in the middle of the paginated results load
    // the next page asynchronously
    if (newIndex >= size / 2 && hasNextPage && !loading) {
      fetchNextPage();
    }
    setSelectedIndex(newIndex);
  };

  const goToPrevPage = () => {
    const newIndex = selectedIndex === 0 ? size - 1 : selectedIndex - 1;
    setSelectedIndex(newIndex);
  };

  useNavigationKeys({
    disabled: view === 'tabular',
    nextCallback: goToNextPage,
    previousCallback: goToPrevPage,
  });

  const isPristine = queryStatus === null;
  const isProvisioning = queryStatus === 'provisioning';
  const isRunning = queryStatus === 'running';
  const hasErrored = queryStatus === 'errored';

  React.useEffect(() => {
    if (!queryId && !view) {
      setView('tabular');
    }
    // FIXME: look into missing hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryId]);

  const setDataView = React.useCallback(
    index => {
      setView('json');
      setSelectedIndex(index);
    },
    // FIXME: look into missing hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [results]
  );

  const bytesScanned = stats?.bytesScanned ?? null;
  const timeElapsed = stats?.executionTime ?? timer;

  if (isPristine) {
    return (
      <Card>
        <AsyncQueryStatus.Pristine />
      </Card>
    );
  }

  if (hasErrored) {
    return (
      <Card mx={10}>
        <AsyncQueryStatus.Errored errorMessage={globalErrorMessage} />
      </Card>
    );
  }

  if (isProvisioning) {
    return (
      <Card>
        <AsyncQueryStatus.Provisioning />
      </Card>
    );
  }

  if (isRunning) {
    return (
      <Card>
        <AsyncQueryStatus.Running timeElapsed={timeElapsed} />
      </Card>
    );
  }

  if (!results?.length) {
    return (
      <Card>
        <AsyncQueryStatus.Empty timeElapsed={timeElapsed} bytesScanned={bytesScanned} />
      </Card>
    );
  }

  const selectedRecord = results[selectedIndex];
  return (
    <Card as="section" width={1}>
      <Grid p={6} pb={4} alignItems="center" templateColumns="1fr 2fr 1fr">
        <Heading size="x-small" as="h4">
          {view === 'tabular' ? (
            <Box>{IS_SNOWFLAKE ? '' : 'Results'}</Box>
          ) : (
            <Flex justify="flex-start" align="center" spacing={2}>
              <IconButton
                active
                onClick={() => setView('tabular')}
                variant="ghost"
                variantBorderStyle="circle"
                size="medium"
                variantColor="blue-400"
                icon="arrow-back"
                aria-label="Switch to tab view"
              />
              <Box>
                {size} {toPlural('Record', size)}
              </Box>
            </Flex>
          )}
        </Heading>

        <Flex justify="center" align="center" spacing={2}>
          <Card variant="dark">
            <ElapsedTime ms={timeElapsed} />
          </Card>

          {bytesScanned >= 0 && (
            <Card variant="dark">
              <DataScanned bytes={bytesScanned} />
            </Card>
          )}
        </Flex>

        <Flex justify="flex-end">
          <ResultsActionBar results={results} viewSelection={view} />
        </Flex>
      </Grid>
      <Grid
        m={6}
        visuallyHidden={view !== 'tabular'}
        overflow="auto"
        maxHeight="max(calc(100vh - 950px),600px)"
        maxWidth="calc(100vw - 372px)"
        willChange="scroll"
      >
        <ResultsTable records={results} columnOrder={columnOrder} setDataView={setDataView} />
        {hasNextPage && (
          <Box mt={4} ref={infiniteScrollRef}>
            <TablePlaceholder rowCount={10} rowHeight={6} style={{ width: '100%', height: 300 }} />
          </Box>
        )}
      </Grid>
      <Box hidden={view === 'tabular'}>
        <JsonViewer
          data={selectedRecord}
          header={
            <JsonViewerHeader>
              <Flex justify="center" align="center" spacing={2}>
                <IconButton
                  onClick={goToPrevPage}
                  variant="ghost"
                  variantBorderStyle="circle"
                  size="medium"
                  variantColor="blue-400"
                  icon="arrow-back"
                  aria-label="Go to previous"
                />
                <Box>
                  {selectedIndex + 1} of {size}
                </Box>
                <IconButton
                  onClick={goToNextPage}
                  variant="ghost"
                  variantBorderStyle="circle"
                  size="medium"
                  variantColor="blue-400"
                  icon="arrow-forward"
                  aria-label="Go to next"
                />
              </Flex>
            </JsonViewerHeader>
          }
          actions={actionProps => (
            <EventNodeActions {...actionProps} dateCreated={selectedRecord.p_event_time} />
          )}
        />
      </Box>
    </Card>
  );
};

export default ResultsTab;

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { ApolloError } from '@apollo/client';
import { DataLakeQuerySummaryDirection, JobStatus } from 'Generated/schema';
import useAsyncQueryContext from 'Hooks/useAsyncQueryContext';
import usePrevious from 'Hooks/usePrevious';
import pick from 'lodash/pick';
import React from 'react';
import {
  DataLakeQuerySummary,
  useDataLakeQuerySummary,
} from '../graphql/dataLakeQuerySummary.generated';
import { useSummarizeDataLakeQuery } from '../graphql/summarizeDataLakeQuery.generated';

interface ColumnSummaryData {
  status: 'idle' | 'loading' | 'success' | 'error';
  summary: Pick<DataLakeQuerySummary['dataLakeQuerySummary'], 'columnInfo' | 'rows'> | null;
  error: ApolloError | string | null;
  meta: { column: string; direction: DataLakeQuerySummaryDirection } | null;
}

type Actions =
  | {
      // Kicks off an execution of a query summary and sets the status to "loading"
      // Should only be possible to do if the status is not already "loading"
      type: 'SUMMARIZE';
      payload: ColumnSummaryData['meta'];
    }
  | {
      type: 'SUMMARIZE_SUCCESS';
      payload: { summary: ColumnSummaryData['summary'] };
    }
  | {
      type: 'SUMMARIZE_ERROR';
      payload: { error: ApolloError | string };
    };

type ColumnSummaryContextValue = ColumnSummaryData & {
  summarize: (args: { column: string; direction: DataLakeQuerySummaryDirection }) => void;
};

const initialState: ColumnSummaryContextValue = {
  status: 'idle',
  summary: null,
  error: null,
  meta: null,
  summarize: () => {},
};

const ColumnSummaryContext = React.createContext<ColumnSummaryContextValue>(initialState);
export const ColumnSummaryProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    state: { queryId },
  } = useAsyncQueryContext();

  const [state, dispatch] = React.useReducer(
    (prevState: ColumnSummaryData, action: Actions): ColumnSummaryData => {
      switch (action.type) {
        case 'SUMMARIZE': {
          // If we're currently loading, do nothing, this is an invalid usage
          // of this action.
          if (prevState.status === 'loading') {
            return prevState;
          }

          return {
            error: null,
            status: 'loading',
            summary: null,
            meta: action.payload,
          };
        }
        case 'SUMMARIZE_SUCCESS': {
          // You can only go from loading -> success, any other type of
          // transition is invalid usage of this action and a bug.
          if (prevState.status !== 'loading') {
            return prevState;
          }

          return {
            ...prevState,
            status: 'success',
            summary: action.payload.summary,
          };
        }
        case 'SUMMARIZE_ERROR': {
          // You can only go from loading -> error, any other type of
          // transition into "error" is invalid usage of this action and a bug.
          if (prevState.status !== 'loading') {
            return prevState;
          }

          return {
            ...prevState,
            status: 'error',
            error: action.payload.error,
          };
        }
        default:
          return prevState;
      }
    },
    initialState
  );

  const summarize = React.useCallback(
    (filters: { column: string; direction: DataLakeQuerySummaryDirection }) => {
      if (!queryId) {
        return;
      }
      dispatch({ type: 'SUMMARIZE', payload: filters });
    },
    [queryId]
  );

  const [summarizeQuery, { data: querySummaryJob }] = useSummarizeDataLakeQuery();

  const { data: jobData, error: jobError, stopPolling } = useDataLakeQuerySummary({
    skip: !querySummaryJob?.summarizeDataLakeQuery?.id,
    variables: {
      jobId: querySummaryJob?.summarizeDataLakeQuery?.id,
    },
    pollInterval: 300,
    // We always want the latest job execution details, so don't use the cache at all.
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const previousStatus = usePrevious(state.status);
  React.useEffect(() => {
    // If we've gone INTO the "loading" state, we kick off the "summarize" action
    if (state.status === 'loading' && previousStatus !== 'loading') {
      summarizeQuery({
        variables: {
          input: {
            id: queryId,
            column: state.meta?.column,
            direction: state.meta?.direction,
          },
        },
      });
    }
  }, [
    summarizeQuery,
    querySummaryJob,
    queryId,
    state.status,
    state.meta?.column,
    state.meta?.direction,
    previousStatus,
  ]);

  React.useEffect(() => {
    const status = jobData?.dataLakeQuerySummary?.status;
    if (jobError || status === JobStatus.Failed) {
      const failedMessage = jobData?.dataLakeQuerySummary?.message;
      stopPolling();
      dispatch({
        type: 'SUMMARIZE_ERROR',
        payload: { error: failedMessage || JSON.stringify(jobError) || 'An unknown error occured' },
      });
      return;
    }

    if (status === JobStatus.Succeeded) {
      stopPolling();
      dispatch({
        type: 'SUMMARIZE_SUCCESS',
        payload: {
          summary: pick(jobData?.dataLakeQuerySummary, ['rows', 'columnInfo']),
        },
      });
      return;
    }

    if (status !== JobStatus.Running) {
      stopPolling();
    }
  }, [jobData, jobError, stopPolling]);

  const columnSummary = React.useMemo(
    () => ({
      ...state,
      summarize,
    }),
    [state, summarize]
  );

  return (
    <ColumnSummaryContext.Provider value={columnSummary}>{children}</ColumnSummaryContext.Provider>
  );
};

export const useColumnSummary = () => {
  const ctx = React.useContext(ColumnSummaryContext);
  return ctx;
};

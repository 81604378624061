/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import urls from 'Source/urls';

export const investigateUrlsGroup = [
  // current
  urls.investigate.home(),
  urls.investigate.dataExplorer(),
  urls.investigate.savedQueries.list(),
  urls.investigate.indicatorSearch(),
  urls.investigate.queryHistory(),
  // deprecated
  urls.data.dataExplorer(),
  urls.data.savedQueries.list(),
  urls.data.indicatorSearch(),
  urls.data.queryHistory(),
];

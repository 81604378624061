/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Flex, Box, Text, Radio } from 'pouncejs';
import { LogStreamTypeEnum } from 'Generated/schema';

const streamTypes: Record<LogStreamTypeEnum, { title: string; description: string }> = {
  [LogStreamTypeEnum.Lines]: { title: 'Lines', description: 'Events are line delimited.' },
  [LogStreamTypeEnum.CloudWatchLogs]: {
    title: 'CloudWatch Logs',
    description: 'Events are delivered to S3 from CloudWatch Logs.',
  },
  [LogStreamTypeEnum.JsonArray]: {
    title: 'JSON Array',
    description: 'Events are in JSON Array format.',
  },
};

interface LogStreamTypePickerProps {
  value: LogStreamTypeEnum;
  onChange: (value: LogStreamTypeEnum) => void;
}
const logStreamTypeEnumItems = Object.keys(streamTypes);

const LogStreamTypePicker = ({ value, onChange }: LogStreamTypePickerProps) => {
  return (
    <Flex spacing={4} width={1} align="center" justify="space-between">
      {logStreamTypeEnumItems.map(streamType => (
        <Box key={streamType}>
          <Radio
            value={streamType}
            checked={value === streamType}
            onChange={() => onChange(LogStreamTypeEnum[streamType])}
            label={streamTypes[streamType].title}
            aria-describedby={`stream-type-${streamType}-description`}
          />
          <Text
            fontSize="x-small"
            fontStyle="italic"
            pl={10}
            id={`stream-type-${streamType}-description`}
          >
            {streamTypes[streamType].description}
          </Text>
        </Box>
      ))}
    </Flex>
  );
};

export default React.memo(LogStreamTypePicker);

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import dayjs from 'dayjs';
import { Box } from 'pouncejs';

import { formatDatetime, getActorDisplayName, secondsToString } from 'Helpers/utils';
import { LogTransportMethodsEnum, LogSourceType } from 'Source/constants';
import {
  LogPullerTypeEnum,
  LogPullingIntegration,
  S3LogIntegration,
  EventBridgeIntegration,
  CloudWatchLogIntegration,
  SqsLogSourceIntegration,
  GcsLogSourceIntegration,
} from 'Generated/schema';
import asanaLogo from 'Assets/logProviders/asana.svg';
import duoLogo from 'Assets/duo-minimal-logo.svg';
import sqsLogo from 'Assets/sqs-minimal-logo.svg';
import s3Logo from 'Assets/logProviders/s3.svg';
import pantherMinimalLogo from 'Assets/panther-minimal-logo.svg';
import eventBridgeLogo from 'Assets/eventbridge-minimal-logo.svg';
import cloudwatchLogo from 'Assets/logProviders/cloudwatch.svg';
import gcpLogo from 'Assets/logProviders/gcp.svg';
import oktaLogo from 'Assets/okta-minimal-logo.svg';
import boxLogo from 'Assets/box-logo.svg';
import slackLogo from 'Assets/slack-minimal-logo.svg';
import zoomLogo from 'Assets/logProviders/zoom.svg';
import gsuiteLogo from 'Assets/gsuite-minimal-logo.svg';
import crowdstrikeLogo from 'Assets/crowdstrike-minimal-logo.svg';
import salesforceLogo from 'Assets/salesforce-minimal-logo.svg';
import githubLogo from 'Assets/github-minimal-logo.svg';
import zendeskLogo from 'Assets/zendesk-minimal-logo.svg';
import onePasswordLogo from 'Assets/logProviders/onepassword.svg';
import atlassianLogo from 'Assets/logProviders/atlassian.svg';
import workdayLogo from 'Assets/logProviders/workday.svg';
import m365Logo from 'Assets/m365-minimal-logo.svg';
import snykLogo from 'Assets/logProviders/snyk.svg';
import dropboxLogo from 'Assets/logProviders/dropbox.svg';
import microsoftGraphLogo from 'Assets/logProviders/microsoftgraph.svg';

export interface SourceMeta {
  label: string;
  value: React.ReactNode;
}

export type SourceIntegrationType =
  | S3LogIntegration
  | LogPullingIntegration
  | EventBridgeIntegration
  | CloudWatchLogIntegration
  | SqsLogSourceIntegration
  | GcsLogSourceIntegration;

// Source utils
export const getLogPullingMeta = (source: LogPullingIntegration) => {
  switch (source.pullerType) {
    case LogPullerTypeEnum.Asana:
      return [{ label: 'Organization ID', value: source.pullerConfig.asana.organizationId }];
    case LogPullerTypeEnum.Atlassian:
      return [{ label: 'Organization', value: source.pullerConfig.atlassian.organization }];
    case LogPullerTypeEnum.Crowdstrike:
      return [{ label: 'SQS Queue URL', value: source.pullerConfig.crowdstrike.queueUrl }];
    case LogPullerTypeEnum.Github:
      return [{ label: 'Github Organization', value: source.pullerConfig.github.organization }];
    case LogPullerTypeEnum.Gsuite:
      return [{ label: 'Applications', value: source.pullerConfig.gsuite.applications.join(', ') }];
    case LogPullerTypeEnum.Okta:
      return [{ label: 'Okta Domain', value: source.pullerConfig.okta.domain }];
    case LogPullerTypeEnum.Zendesk:
      return [{ label: 'Zendesk Subdomain', value: source.pullerConfig.zendesk.subdomain }];
    case LogPullerTypeEnum.Workday:
      return [
        { label: 'Tenant Host Name', value: source.pullerConfig.workday.tenantHostName },
        { label: 'Tenant Name', value: source.pullerConfig.workday.tenantName },
      ];
    case LogPullerTypeEnum.Snyk:
      return [{ label: 'Organization ID', value: source.pullerConfig.snyk?.orgId }];
    case LogPullerTypeEnum.Zoom:
    case LogPullerTypeEnum.Box:
    case LogPullerTypeEnum.Duo:
    case LogPullerTypeEnum.M365:
    case LogPullerTypeEnum.Microsoftgraph:
    case LogPullerTypeEnum.Onepassword:
    case LogPullerTypeEnum.Salesforce:
    case LogPullerTypeEnum.Slack:
    default:
      return [];
  }
};

export const getSourceMeta = (source, enriched = true): SourceMeta[] => {
  const minutesThreshold = source?.alarms?.eventThreshold?.minutesThreshold;
  const createdAt = formatDatetime(source.createdAtTime);
  const createdAtElement = source.createdBy ? (
    <Box>
      {createdAt} <br /> By {getActorDisplayName(source.createdBy)}
    </Box>
  ) : (
    createdAt
  );

  let metaArray = [];
  const commonMetaHeader = [
    {
      label: 'Source ID',
      value: source.integrationId,
    },
  ];
  const commonMetaFooter = [
    {
      label: 'Alarm Configuration',
      value: minutesThreshold
        ? `Alert will be sent if no events processed after ${secondsToString(
            minutesThreshold * 60
          )}`
        : null,
    },
    { label: 'Date Created', value: createdAtElement },
    { label: 'Last Modified', value: formatDatetime(source.lastModified || source.createdAtTime) },
  ];

  switch (source.integrationType) {
    case LogTransportMethodsEnum.s3:
      metaArray = [
        { label: 'AWS Account ID', value: source.awsAccountId },
        { label: 'KMS Key', value: source.kmsKey },
        { label: 'S3 Bucket', value: source.s3Bucket },
        { label: 'IAM Role ARN', value: source.logProcessingRole },
        { label: 'Stream Type', value: source.logStreamType },
      ];
      break;
    case LogTransportMethodsEnum.cloudwatch:
      metaArray = [
        { label: 'AWS Account ID', value: source.awsAccountId },
        { label: 'Log Group Name', value: source.logGroup },
        { label: 'S3 Bucket', value: source.s3Bucket },
        { label: 'IAM Role ARN', value: source.logProcessingRole },
        { label: 'Pattern Filter', value: source.filterPattern },
      ];
      break;
    case LogTransportMethodsEnum.sqs:
      metaArray = [
        { label: 'SQS Queue URL', value: source.sqsConfig.queueUrl },
        {
          label: 'Allowed Principal ARNs',
          value: source.sqsConfig.allowedPrincipalArns?.join(', '),
        },
        { label: 'Allowed Source ARNs', value: source.sqsConfig.allowedSourceArns?.join(', ') },
      ];
      break;
    case LogTransportMethodsEnum.gcs:
      metaArray = [
        { label: 'GCS Bucket', value: source.gcsConfig.gcsBucket },
        { label: 'Pub/Sub Subscription ID', value: source.gcsConfig.subscriptionId },
        { label: 'Service Account Email', value: source.gcsConfig.userEmail },
      ];
      break;
    case LogTransportMethodsEnum.logPulling:
      metaArray = [...getLogPullingMeta(source)];
      break;
    default:
      metaArray = [];
  }

  return enriched ? [...commonMetaHeader, ...metaArray, ...commonMetaFooter] : metaArray;
};

export const getLogSourceType = (source: SourceIntegrationType): LogSourceType => {
  switch (source.__typename) {
    case 'SqsLogSourceIntegration':
      return LogTransportMethodsEnum.sqs;
    case 'S3LogIntegration':
      return LogTransportMethodsEnum.s3;
    case 'EventBridgeIntegration':
      return LogTransportMethodsEnum.eventbridge;
    case 'CloudWatchLogIntegration':
      return LogTransportMethodsEnum.cloudwatch;
    case 'GcsLogSourceIntegration':
      return LogTransportMethodsEnum.gcs;
    case 'LogPullingIntegration':
      return source.pullerType;
    default:
      return null;
  }
};

export const getLogSourceLogo = (source: SourceIntegrationType) => {
  const logSourceType = getLogSourceType(source);
  const logos: Record<LogSourceType, any> = {
    'amazon-eventbridge': eventBridgeLogo,
    'aws-cloudwatch-logs': cloudwatchLogo,
    'aws-s3':
      source.isPantherManaged && source.logTypes.includes('Panther.Audit')
        ? pantherMinimalLogo
        : s3Logo,
    'aws-sqs': sqsLogo,
    'gcp-gcs': gcpLogo,
    atlassian: atlassianLogo,
    box: boxLogo,
    crowdstrike: crowdstrikeLogo,
    github: githubLogo,
    gsuite: gsuiteLogo,
    m365: m365Logo,
    okta: oktaLogo,
    onepassword: onePasswordLogo,
    salesforce: salesforceLogo,
    slack: slackLogo,
    workday: workdayLogo,
    zendesk: zendeskLogo,
    zoom: zoomLogo,
    asana: asanaLogo,
    duo: duoLogo,
    snyk: snykLogo,
    dropbox: dropboxLogo,
    microsoftgraph: microsoftGraphLogo,
  };

  return logos[logSourceType] || null;
};

export const isLogSourceType = (
  source: SourceIntegrationType,
  integrationType: SourceIntegrationType['integrationType'],
  pullerType?: LogPullingIntegration['pullerType']
): boolean => {
  if (integrationType === 'log-pulling' && pullerType) {
    return (source as LogPullingIntegration).pullerType === pullerType;
  }
  return source.integrationType === integrationType;
};

export const schemaGenerationDatePickerItems = {
  [dayjs().utc().subtract(1, 'months').startOf('day').toISOString()]: 'Last month',
  [dayjs().utc().subtract(15, 'days').startOf('day').toISOString()]: 'Last 15 days',
  [dayjs().utc().subtract(1, 'weeks').startOf('day').toISOString()]: 'Last week',
  [dayjs().utc().subtract(3, 'days').startOf('day').toISOString()]: 'Last 3 days',
  [dayjs().utc().subtract(1, 'days').startOf('day').toISOString()]: 'Last 24h',
};

export const schemaGenerationDatePickerValues = Object.keys(schemaGenerationDatePickerItems);

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { Box, Icon, FlexProps, Flex } from 'pouncejs';
import React from 'react';
import { NavLinkBaseProps } from './NavLink';

type NavLinkContentProps = NavLinkBaseProps & {
  isActive?: boolean;
};

const NavLinkContent = ({
  icon,
  label,
  isSecondary,
  isActive,
  ...flexProps
}: NavLinkContentProps & FlexProps) => {
  const activeColor = isSecondary || isActive ? 'blue-400' : 'navyblue-500';
  const backgroundColor = isActive ? 'blue-400' : 'transparent';

  return (
    <Flex
      as="span"
      align="center"
      borderRadius="small"
      color="white-200"
      fontSize={isSecondary ? 'small-medium' : 'medium'}
      px={isSecondary ? 2 : 4}
      py={3}
      backgroundColor={backgroundColor}
      _hover={{
        backgroundColor: activeColor,
      }}
      _focus={{
        backgroundColor: activeColor,
      }}
      transition="background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms"
      truncated
      {...flexProps}
    >
      {icon && (
        <Icon type={icon} size={isSecondary ? 'small' : 'medium'} mr={isSecondary ? 4 : 3} ml={3} />
      )}
      <Box as="span">{label}</Box>
    </Flex>
  );
};

export default NavLinkContent;

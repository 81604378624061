/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import pick from 'lodash/pick';
import React from 'react';
import { useGetReplayedAlerts } from 'Source/graphql/queries/getReplayedAlerts.generated';
import { Flex, Box, Heading, Alert } from 'pouncejs';
import useRequestParamsWithoutPagination from 'Hooks/useRequestParamsWithoutPagination';
import { ReplayedAlertsInput } from 'Generated/schema';
import ListAlertFilters from 'Pages/ListAlerts/ListAlertFilters';
import useInfiniteScroll from 'Hooks/useInfiniteScroll';
import ErrorBoundary from 'Components/ErrorBoundary';
import { extractErrorMessage } from 'Helpers/utils';
import NoResultsFound from 'Components/NoResultsFound';
import TablePlaceholder from 'Components/TablePlaceholder';
import ReplayedAlertCard from './ReplayedAlertCard';

export type ReplayedAlertListProps = {
  replayId: string;
  detectionId: string;
};

type ReplayedAlertsURLParams = Omit<Omit<ReplayedAlertsInput, 'replayId'>, 'pageSize'>;

const PAGE_SIZE = 5;
const URL_PARAM_KEYS: Array<keyof ReplayedAlertsInput> = [
  'types',
  'cursor',
  'sortDir',
  'logTypes',
  'severities',
  'nameContains',
  'eventCountMin',
  'eventCountMax',
  'createdAtAfter',
  'createdAtBefore',
];

const ReplayedAlertList: React.FC<ReplayedAlertListProps> = ({ replayId, detectionId }) => {
  const { requestParams } = useRequestParamsWithoutPagination<ReplayedAlertsInput>({
    overrides: { nameContains: 'string' },
  });

  const relevantParams = React.useMemo((): ReplayedAlertsURLParams => {
    return pick(requestParams, URL_PARAM_KEYS);
  }, [requestParams]);

  const { previousData, data = previousData, fetchMore, loading, error } = useGetReplayedAlerts({
    variables: {
      input: {
        ...relevantParams,
        replayId,
        pageSize: PAGE_SIZE,
      },
    },
  });

  const { sentinelRef } = useInfiniteScroll<HTMLDivElement>({
    loading,
    onLoadMore: () =>
      fetchMore({
        variables: {
          input: {
            ...relevantParams,
            replayId,
            cursor: data?.replayedAlerts.cursor ?? null,
            pageSize: PAGE_SIZE,
          },
        },
      }),
  });

  const alerts = React.useMemo(() => data?.replayedAlerts.alerts || [], [data]);
  const cursor = data?.replayedAlerts.cursor;

  const hasAlerts = alerts && alerts.length !== 0;

  return (
    <>
      <Flex pb={6} alignItems="center">
        <Box style={{ flexGrow: 1 }}>
          <Heading size="x-small">Replay Alerts</Heading>
        </Box>
        <ListAlertFilters excludedFields={['resourceTypes', 'logSources', 'statuses']} />
      </Flex>
      {!!error && (
        <Box mb={6}>
          <Alert
            variant="error"
            title="Couldn't load your alerts"
            description={
              extractErrorMessage(error) ||
              'There was an error when performing your request, please contact support@runpanther.io'
            }
          />
        </Box>
      )}
      {!hasAlerts && loading && (
        <Box py={8}>
          <TablePlaceholder rowCount={10} />
        </Box>
      )}
      {hasAlerts && (
        <ErrorBoundary>
          <Box>
            {alerts.map(alert => (
              <Box pb={2} key={alert.id}>
                <ReplayedAlertCard alert={alert} replayId={replayId} detectionId={detectionId} />
              </Box>
            ))}
          </Box>
          {cursor && (
            <Box py={4} ref={sentinelRef}>
              <TablePlaceholder rowCount={10} />
            </Box>
          )}
        </ErrorBoundary>
      )}
      {!hasAlerts && !loading && (
        <Box my={8}>
          <NoResultsFound />
        </Box>
      )}
    </>
  );
};

export default React.memo(ReplayedAlertList);

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import useRouter from 'Hooks/useRouter';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import urls from 'Source/urls';
import CreateDataModelPage from 'Pages/CreateDataModel';
import EditDataModelPage from 'Pages/EditDataModel';
import ListDataModelsPage from 'Pages/ListDataModels';

const DataModelsRoutes: React.FC = () => {
  const { location } = useRouter();

  return (
    <Switch>
      <Redirect
        exact
        from={urls.data.dataModels.list()}
        to={{ pathname: urls.build.dataModels.list(), search: location.search }}
      />
      <Route exact path={urls.build.dataModels.list()} component={ListDataModelsPage} />

      <Redirect exact from={urls.data.dataModels.create()} to={urls.build.dataModels.create()} />
      <Route exact path={urls.build.dataModels.create()} component={CreateDataModelPage} />

      <Redirect
        exact
        from={urls.data.dataModels.edit(':id')}
        to={urls.build.dataModels.edit(':id')}
      />
      <Route exact path={urls.build.dataModels.edit(':id')} component={EditDataModelPage} />

      <Redirect
        exact
        from={urls.data.dataModels.details(':id')}
        to={urls.build.dataModels.edit(':id')}
      />
      <Redirect
        exact
        from={urls.build.dataModels.details(':id')}
        to={urls.build.dataModels.edit(':id')}
      />
    </Switch>
  );
};

export default DataModelsRoutes;

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Text, Flex, Button, Alert, Combobox, Box } from 'pouncejs';
import useModal from 'Hooks/useModal';
import {
  schemaGenerationDatePickerItems,
  schemaGenerationDatePickerValues,
} from 'Pages/Integrations/utils';

const TestSchemasModal = ({ onTestSchemas }: { onTestSchemas: (from: string) => void }) => {
  const { hideModal } = useModal();
  const [from, setFrom] = React.useState(schemaGenerationDatePickerValues[0]);

  return (
    <Flex maxWidth={462} spacing={5} direction="column">
      <Text mb={8}>Test how your schemas perform during a selected time period</Text>
      <Alert
        description="The test may take up to 15 minutes to finish, and until then you
will not be able to edit schemas, prefixes or the stream type
selected in this flow. Stopping the test will also give you a report
with the matched/unmatched events until the time you stopped"
        variant="info"
      />
      <Flex justify="flex-end" spacing={3}>
        <Box maxWidth={160}>
          <Combobox
            items={schemaGenerationDatePickerValues}
            showClearSelectionControl={false}
            itemToString={item => schemaGenerationDatePickerItems[item]}
            label="Time Period"
            onChange={value => setFrom(value)}
            value={from}
          />
        </Box>
        <Button
          onClick={() => {
            onTestSchemas(from);
            hideModal();
          }}
        >
          Start Test
        </Button>
      </Flex>
    </Flex>
  );
};

export default TestSchemasModal;

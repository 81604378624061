/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Collapse, Flex, IconButton, Text } from 'pouncejs';

import { S3PrefixLogTypes } from 'Generated/schema';
import LimitItemDisplay from 'Components/LimitItemDisplay';
import LogTypeCard from './LogTypeCard';

interface S3PrefixesLogTypesProps extends S3PrefixLogTypes {
  sourceId: string;
  s3BucketName: string;
}

const S3PrefixesLogTypes: React.FC<S3PrefixesLogTypesProps> = ({
  sourceId,
  prefix,
  excludedPrefixes,
  logTypes,
  s3BucketName,
}) => {
  const [open, setOpen] = React.useState(true);
  const toggle = React.useCallback(() => setOpen(v => !v), [setOpen]);

  return (
    <React.Fragment>
      <Flex p={4} px={6} justify="space-between" align="center">
        <Flex spacing={4} align="center">
          <Box minWidth={36}>
            <IconButton
              variant="solid"
              variantBorderStyle="circle"
              variantColor="blue-300"
              icon={open ? 'caret-up' : 'caret-down'}
              onClick={toggle}
              size="medium"
              aria-label={open ? 'Hide prefixes' : 'Show prefixes'}
            />
          </Box>
          <Text as="span" color="gray-300" fontSize="large">
            S3://{s3BucketName}/
            <Text color="white" as="span">
              {prefix}
            </Text>
          </Text>
          {Boolean(excludedPrefixes.length) && (
            <>
              <Text fontStyle="italic" fontSize="medium">
                - Exclusions:
              </Text>
              <Flex maxWidth="70%" spacing={2} gap={2} align="center" wrap="wrap">
                <LimitItemDisplay limit={4}>
                  {excludedPrefixes.map(excludedPrefix => {
                    return (
                      <Box
                        my={1}
                        key={excludedPrefix}
                        borderColor="navyblue-300"
                        backgroundColor="navyblue-400"
                        borderWidth={2}
                        borderStyle="solid"
                        px={2}
                        fontSize="small"
                      >
                        S3://{s3BucketName}/{excludedPrefix}
                      </Box>
                    );
                  })}
                </LimitItemDisplay>
              </Flex>
            </>
          )}
        </Flex>
      </Flex>

      <Collapse open={open}>
        {logTypes.length ? (
          <>
            {logTypes.map(logType => {
              return (
                <Box px={4} key={logType}>
                  <LogTypeCard logType={logType} sourceId={sourceId} />
                </Box>
              );
            })}
          </>
        ) : (
          <Flex backgroundColor="navyblue-400" justify="center" align="center" p={4} mx={6} mb={2}>
            <Text fontStyle="italic" color="gray-400">
              No schemas for prefix
            </Text>
          </Flex>
        )}
      </Collapse>
    </React.Fragment>
  );
};

export default S3PrefixesLogTypes;

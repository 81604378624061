/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Heading, Box, Text, SimpleGrid, Flex, FormHelperText } from 'pouncejs';
import * as Yup from 'yup';
import { Formik, FastField } from 'formik';
import FormikTextInput from 'Components/fields/TextInput';
import FormikMultiCombobox from 'Components/fields/MultiComboBox';
import SubmitButton from 'Components/buttons/SubmitButton';
import { DATA_SCHEMA_ID_PREFIX, dataSchemaNameValidation } from 'Helpers/utils';

interface InferSchemaFormProps {
  initialPrefix: string;
  initialExcludedPrefixes: string[];
  availableLogTypes: string[];
  onSubmit: (values: NewSchemaFormValues) => void;
}

export interface NewSchemaFormValues {
  name: string;
  prefix: string;
  excludedPrefixes: string[];
}

const InferSchemaForm: React.FC<InferSchemaFormProps> = ({
  initialPrefix,
  availableLogTypes,
  initialExcludedPrefixes,
  onSubmit,
}) => {
  const initialValues: NewSchemaFormValues = {
    prefix: initialPrefix,
    excludedPrefixes: initialExcludedPrefixes,
    name: '',
  };

  const validationSchema = Yup.object({
    prefix: Yup.string(),
    name: dataSchemaNameValidation(availableLogTypes),
    excludedPrefixes: Yup.array()
      .of(Yup.string())
      // eslint-disable-next-line func-names
      .test('mutex', 'Excluded prefixes should start with included prefix', function (
        excludedPrefixArray
      ) {
        if (!this.parent.prefix) {
          return true;
        }
        // Check if every excluded prefix starts with the defined prefix and is not equal to it
        return excludedPrefixArray.every(
          e => e.startsWith(this.parent.prefix) && e !== this.parent.prefix
        );
      })
      .unique(`Excluded prefixes should be unique`)
      .required(),
    description: Yup.string(),
    referenceURL: Yup.string().url(),
  });

  return (
    <Formik<NewSchemaFormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <SimpleGrid columns={1} spacing={4}>
        <Heading as="h3" fontSize="x-large" color="teal-200" fontWeight="medium">
          Fill in new schema name
        </Heading>
        <Text fontSize="small-medium">
          Before inferring the schema we need you to add the schema name that will be used to create
          a draft. This will not be saved to either the source or your schemas until you click on
          save changes. You will be able to add more information to the schema, after it has been
          saved, through the schemas section.
        </Text>
        <FastField
          as={FormikTextInput}
          name="name"
          label="New Schema Name"
          prefix={DATA_SCHEMA_ID_PREFIX}
          placeholder={`Must start with \`${DATA_SCHEMA_ID_PREFIX}\` followed by a capital letter`}
          required
        />
        <Heading as="h3" fontSize="x-large" color="teal-200" fontWeight="medium" mt={4}>
          Select S3 prefix
        </Heading>
        <Text fontSize="small-medium">
          Based on the filters you have chosen panther has created a suggested S3 Prefix for this
          schema to parse data from. If you want to ingest & parse all data from bucket then leave
          the prefix field empty
        </Text>
        <Box>
          <FastField
            as={FormikTextInput}
            label="S3 Prefix"
            placeholder="Limit logs to objects that start with matching characters"
            name="prefix"
            aria-describedby="prefix-helper"
          />
          <FormHelperText id="prefix-helper" fontSize="small-medium" color="gray-300" mt={1}>
            Leave blank to create a wildcard (*) prefix and allow ingestion of all files in bucket.
          </FormHelperText>
        </Box>
        <Box>
          <FastField
            name="excludedPrefixes"
            label="Exclusion Filters - Optional"
            placeholder="Exclude logs to objects that start with matching characters"
            items={[]}
            as={FormikMultiCombobox}
            aria-describedby="exclusion-prefix-helper-text"
            allowAdditions
            searchable
          />
          <FormHelperText
            id="exclusion-prefix-helper-text"
            fontSize="small-medium"
            fontStyle="italic"
          >
            Indicate which S3 Prefixes to exclude
          </FormHelperText>
        </Box>
        <Flex justify="center" mt={6}>
          <SubmitButton variantColor="blue-300">Apply Changes</SubmitButton>
        </Flex>
      </SimpleGrid>
    </Formik>
  );
};

export default InferSchemaForm;

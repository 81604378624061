/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Button, Flex, Img } from 'pouncejs';
import { useFormikContext } from 'formik';
import SuccessStatus from 'Assets/statuses/illustration-success.svg';
import urls from 'Source/urls';
import AuthorizationStatus from 'Assets/statuses/authorization.svg';
import { useWizardContext, WizardPanel } from 'Components/Wizard';
import { checkIfSourceIsAuthorized, generateIntegrationOAuthLink } from 'Helpers/utils';
import { LogPullerTypeEnum } from 'Generated/schema';
import { SlackLogIntegrationDetails } from 'Source/graphql/fragments/SlackLogIntegrationDetails.generated';
import EventThresholdAlarmConfigurator from 'Components/EventThresholdAlarmConfigurator';
import { FailingPanel, PendingPanel } from 'Components/SourceVerificationPanels';
import FinishSourceSetupActions from 'Pages/Integrations/components/FinishSourceSetupActions';
import { SlackLogsSourceWizardValues } from '../SlackLogSourceWizard';

const ValidationPanel = () => {
  const [errorMessage, setErrorMessage] = React.useState('');
  const result = React.useRef<SlackLogIntegrationDetails | Error>(null);
  const { currentStepStatus, setCurrentStepStatus, setNavigationAvailability } = useWizardContext();
  const { initialValues, submitForm } = useFormikContext<SlackLogsSourceWizardValues>();

  React.useEffect(() => {
    (async () => {
      setNavigationAvailability(false);
      result.current = await (submitForm() as Promise<any>);
      if (result.current instanceof Error) {
        setErrorMessage(result.current.message);
        setCurrentStepStatus('FAILING');
        setNavigationAvailability(true);
      } else {
        setErrorMessage('');
        setCurrentStepStatus('PASSING');
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (currentStepStatus === 'PENDING') {
    return <PendingPanel />;
  }

  if (currentStepStatus === 'FAILING') {
    return <FailingPanel errorMessage={errorMessage} />;
  }

  const slackLogSource = result.current as SlackLogIntegrationDetails;
  const isSourceAuthorized = checkIfSourceIsAuthorized(slackLogSource);
  if (!isSourceAuthorized) {
    return (
      <WizardPanel>
        <Flex align="center" direction="column" mx="auto" width={400}>
          <WizardPanel.Heading
            title="Grant Access to Panther"
            subtitle={`Your source has been ${
              initialValues.integrationId ? 'updated' : 'created'
            }, but we need authorization to generate an access token to access the logs`}
          />
          <Img
            nativeWidth={120}
            nativeHeight={120}
            alt="Shield with checkmark"
            src={AuthorizationStatus}
          />
          <WizardPanel.Actions>
            <Button
              onClick={() => {
                window.location.assign(
                  generateIntegrationOAuthLink(
                    slackLogSource.integrationId,
                    urls.integrations.logSources.authorize(
                      slackLogSource.integrationId,
                      LogPullerTypeEnum.Slack
                    )
                  )
                );
              }}
            >
              Grant Access
            </Button>
          </WizardPanel.Actions>
        </Flex>
      </WizardPanel>
    );
  }

  // Since new slack sources will *have to* go through authorization which takes them away from this
  // wizard, the code below will only be visible for updated sources which don't require a re-authorization
  // (i.e. the changes didn't break authorization)
  return (
    <WizardPanel>
      <Flex align="center" direction="column" mx="auto" width={620}>
        <Box width={400}>
          <WizardPanel.Heading
            title="Everything looks good!"
            subtitle="Your Slack source was successfully updated"
          />
        </Box>
        <Img nativeWidth={157} nativeHeight={118} mb={8} alt="Checkmark" src={SuccessStatus} />
        <EventThresholdAlarmConfigurator logSource={slackLogSource} />
        <WizardPanel.Actions>
          <FinishSourceSetupActions integrationId={slackLogSource.integrationId} />
        </WizardPanel.Actions>
      </Flex>
    </WizardPanel>
  );
};

export default ValidationPanel;

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */
import React from 'react';
import { useFormikContext } from 'formik';
import { Redirect, Route, Switch } from 'react-router';
import { Flex } from 'pouncejs';
import EditSchemaForm from 'Pages/Integrations/LogSources/S3/SchemasManagement/EditSchemaForm';
import TestReport from 'Pages/Integrations/LogSources/S3/SchemasManagement/TestReport';
import urls from 'Source/urls';
import { JobStatus } from 'Generated/schema';
import useRouter from 'Hooks/useRouter';
import { ENABLE_NEW_NAVIGATION } from 'Source/constants';
import ViewRawData from './ViewRawData';
import { S3PrefixLogTypesFormValues } from './SchemasManagement';
import { useSchemasManagementContext } from './SchemasManagementContext';
import S3PrefixLogTypesForm from './S3PrefixLogTypesForm/S3PrefixLogTypesForm';

const SchemasManagementRoutes = ({ savingSchemaName }: { savingSchemaName: string }) => {
  const { status } = useFormikContext<S3PrefixLogTypesFormValues>();
  const { match } = useRouter<{ id: string }>();
  const { schemaTestJob } = useSchemasManagementContext();

  return (
    <Switch>
      <Route
        exact
        path={
          ENABLE_NEW_NAVIGATION
            ? urls.configure.logSources.schemasManagement(':id').testReport(':testReport')
            : urls.integrations.logSources.schemasManagement(':id').testReport(':testReport')
        }
        component={TestReport}
      />
      {/* If a test report is running and the user tries to navigate away */}
      {/* from the test report page we should force a redirection until the test is completed */}
      {schemaTestJob?.status === JobStatus.Running && (
        <Redirect
          to={
            ENABLE_NEW_NAVIGATION
              ? urls.configure.logSources
                  .schemasManagement(match.params.id)
                  .testReport(schemaTestJob.id)
              : urls.integrations.logSources
                  .schemasManagement(match.params.id)
                  .testReport(schemaTestJob.id)
          }
        />
      )}
      <Route
        exact
        path={
          ENABLE_NEW_NAVIGATION
            ? urls.configure.logSources.schemasManagement(':id').draftSchema(':schemaName')
            : urls.integrations.logSources.schemasManagement(':id').draftSchema(':schemaName')
        }
      >
        <EditSchemaForm availableLogTypes={status.availableLogTypes} />;
      </Route>
      <Route
        exact
        path={
          ENABLE_NEW_NAVIGATION
            ? urls.configure.logSources.schemasManagement(':id').home()
            : urls.integrations.logSources.schemasManagement(':id').home()
        }
      >
        <Flex direction="column" spacing={6}>
          <S3PrefixLogTypesForm loadingSchema={savingSchemaName} />
          <ViewRawData />
        </Flex>
      </Route>
      <Redirect
        to={
          ENABLE_NEW_NAVIGATION
            ? urls.configure.logSources.schemasManagement(':id').home()
            : urls.integrations.logSources.schemasManagement(':id').home()
        }
      />
    </Switch>
  );
};

export default SchemasManagementRoutes;

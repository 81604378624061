/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Card, Grid } from 'pouncejs';
import TimeSeriesChart, { TimeSeriesData } from 'Components/charts/TimeSeriesChart';
import { useMetricsFiltersContext } from 'Components/utils/MetricsFiltersContext';
import { SeriesWithBreakdown } from 'Generated/schema';
import VolumeSummary from '../../VolumeSummary';
import ChartTooltip from './ChartTooltip';

interface EventVolumeTabProps {
  bytesProcessedPerSource: SeriesWithBreakdown[];
}

const EventVolumeTab: React.FC<EventVolumeTabProps> = ({ bytesProcessedPerSource }) => {
  const {
    filters: { fromDate, toDate },
    resultProjectionStartDate,
  } = useMetricsFiltersContext();

  const volumeChartData = React.useMemo<TimeSeriesData>(() => {
    // Only one line should be displayed in the bytes processed chart, containing
    // the sum of all log types. When the user hovers over a specific time in the chart
    // he can see a breakdown in the popup tooltip.
    const timestamps = Object.keys(bytesProcessedPerSource[0]?.breakdown ?? []);
    return {
      timestamps,
      // Calculate the sum of all bytes per log type
      series: [
        {
          label: 'Total',
          values: timestamps.map(timestamp =>
            bytesProcessedPerSource.reduce((acc, series) => acc + series.breakdown[timestamp], 0)
          ),
          color: 'red-300' as const,
        },
      ],
      // Store the original bytes per log type in metadata to use them in the tooltip
      metadata: timestamps.map(timestamp =>
        bytesProcessedPerSource
          .map(series => ({ label: series.label, value: series.breakdown[timestamp] }))
          .filter(mappedSeries => !!mappedSeries.value)
      ),
    };
  }, [bytesProcessedPerSource]);

  const totalBytes = React.useMemo(() => {
    return volumeChartData.series[0].values.reduce((prev, current) => prev + current);
  }, [volumeChartData]);

  return (
    <Grid templateColumns="1fr 3fr" gap={4}>
      <VolumeSummary
        title="Total Volume Processed"
        totalBytes={totalBytes}
        color="red-300"
        fromDate={fromDate}
        toDate={toDate}
      />
      <Card variant="dark" height={289} py={5} pl={4}>
        <TimeSeriesChart
          projectionStartDate={resultProjectionStartDate}
          chartId="event-volume"
          data={volumeChartData}
          zoomable
          hideLegend
          units="bytes"
          scaleControls={false}
          tooltipComponent={ChartTooltip}
        />
      </Card>
    </Grid>
  );
};

export default React.memo(EventVolumeTab);

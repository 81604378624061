/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */
import React from 'react';
import { FastField, Formik, useFormikContext } from 'formik';
import { Box, Button, Flex, Card, SimpleGrid, Heading, Text, Alert, IconButton } from 'pouncejs';
import isEmpty from 'lodash/isEmpty';
import Breadcrumbs from 'Components/Breadcrumbs';
import FormikTextArea from 'Components/fields/TextArea';
import FormikTextInput from 'Components/fields/TextInput';
import Panel from 'Components/Panel';
import FormikEditor from 'Components/fields/Editor';
import LoadingOverlay from 'Components/forms/DataSchemaForm/LoadingOverlay';
import { PutUserSchemaInput } from 'Generated/schema';
import { Redirect } from 'react-router';
import urls from 'Source/urls';
import SaveButton from 'Components/buttons/SaveButton';
import {
  appendDataSchemaPrefix,
  DataSchemaErrors,
  dataSchemaValidation,
  DATA_SCHEMA_ID_PREFIX,
  validateDataSchema,
} from 'Helpers/utils';
import useRouter from 'Hooks/useRouter';
import { S3PrefixLogTypesFormValues } from '../SchemasManagement';

interface EditSchemaFormProps {
  availableLogTypes: string[];
}

const EditSchemaForm: React.FC<EditSchemaFormProps> = ({ availableLogTypes }) => {
  const { values: s3PrefixLogTypesFormValues, setFieldValue } = useFormikContext<
    S3PrefixLogTypesFormValues
  >();
  const { match, history } = useRouter<{ id: string; schemaName: string }>();
  const initialValues = s3PrefixLogTypesFormValues.draftSchemas.find(
    s => appendDataSchemaPrefix(s.name) === match.params.schemaName
  );

  const [schemaErrors, setSchemaErrors] = React.useState<DataSchemaErrors>(null);
  const [dismissedValidationSuccess, setDismissedValidationSuccess] = React.useState(true);
  const hasSchemaErrors = schemaErrors && !isEmpty(schemaErrors);

  const handleGoBack = React.useCallback(() => {
    history.replace(urls.integrations.logSources.schemasManagement(match.params.id).home());
  }, [history, match.params.id]);

  const handleSubmit = React.useCallback(
    async (values: PutUserSchemaInput) => {
      const validationErrors = await validateDataSchema(values.spec);
      setSchemaErrors(validationErrors);
      if (!isEmpty(validationErrors)) {
        return;
      }
      const newSchemaName = appendDataSchemaPrefix(values.name);
      setFieldValue(
        's3PrefixLogTypes',
        s3PrefixLogTypesFormValues.s3PrefixLogTypes.map(prefix => ({
          ...prefix,
          logTypes: prefix.logTypes.map(logType =>
            logType === match.params.schemaName ? newSchemaName : logType
          ),
        }))
      );
      setFieldValue(
        'draftSchemas',
        s3PrefixLogTypesFormValues.draftSchemas.map(schema =>
          appendDataSchemaPrefix(schema.name) === match.params.schemaName ? values : schema
        )
      );
      handleGoBack();
    },
    [setFieldValue, s3PrefixLogTypesFormValues, handleGoBack, match]
  );

  const handleValidateClick = React.useCallback(async (spec: string) => {
    setDismissedValidationSuccess(false);
    const validationErrors = await validateDataSchema(spec);
    setSchemaErrors(validationErrors);
  }, []);

  if (!initialValues) {
    return <Redirect to={urls.integrations.logSources.schemasManagement(match.params.id).home()} />;
  }

  return (
    <Formik<PutUserSchemaInput>
      initialValues={initialValues}
      validationSchema={dataSchemaValidation(availableLogTypes)}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <Panel
          title={
            <Flex align="center">
              <IconButton
                variant="solid"
                variantColor="transparent"
                icon="arrow-back"
                onClick={() =>
                  history.replace(
                    urls.integrations.logSources.schemasManagement(match.params.id).home()
                  )
                }
                size="medium"
                aria-label="Back to Schema Management"
              />
              <Heading size="x-small" as="h4">
                Edit Data Schema
              </Heading>
            </Flex>
          }
        >
          <Flex spacing={4} direction="column">
            <Card variant="dark" p={4}>
              <SimpleGrid columns={2} spacing={5} mb={4}>
                <FastField
                  as={FormikTextInput}
                  name="name"
                  label="Schema ID"
                  prefix={DATA_SCHEMA_ID_PREFIX}
                  placeholder={`Must start with \`${DATA_SCHEMA_ID_PREFIX}\` followed by a capital letter`}
                  required
                />
                <FastField
                  as={FormikTextInput}
                  name="referenceURL"
                  label="Reference URL"
                  placeholder="The URL to the log's schema documentation"
                />
              </SimpleGrid>
              <Box mb={6}>
                <FastField
                  as={FormikTextArea}
                  name="description"
                  label="Description"
                  placeholder="A verbose description of what this log type does"
                />
              </Box>
              <Heading size="x-small" mb={5}>
                Event Schema
              </Heading>
              <LoadingOverlay
                loading={false}
                title="Inferring Schema"
                description="We are processing your data, please wait."
              >
                <FastField
                  as={FormikEditor}
                  placeholder="# Write your schema in YAML here..."
                  name="spec"
                  width="100%"
                  minHeight={400}
                  maxHeight={500}
                  language="yaml"
                  aria-labelledby={hasSchemaErrors ? 'schema-errors' : undefined}
                  required
                />
              </LoadingOverlay>
            </Card>
            {hasSchemaErrors && (
              <Box
                mt={4}
                p={4}
                borderRadius="medium"
                backgroundColor="pink-700"
                fontSize="medium"
                data-testid="schema-errors"
                id="schema-errors"
              >
                <Flex direction="column" spacing={2}>
                  {schemaErrors &&
                    Object.keys(schemaErrors).map(fieldName => {
                      return (
                        <Box key={fieldName}>
                          <Box as="b">{fieldName}</Box>
                          {schemaErrors[fieldName].map((errors, errorIndex) => (
                            <Text key={errorIndex} fontStyle="italic" ml={4}>
                              {errors.message}
                            </Text>
                          ))}
                        </Box>
                      );
                    })}
                </Flex>
              </Box>
            )}
            {!dismissedValidationSuccess && !hasSchemaErrors && (
              <Alert
                aria-label="schema syntax success"
                variant="success"
                title="Schema syntax is looking good"
                discardable
                onClose={() => setDismissedValidationSuccess(true)}
              />
            )}
            <Button
              variantColor="teal-500"
              icon="play"
              disabled={!values?.spec}
              onClick={() => handleValidateClick(values?.spec)}
              data-tid="validate-custom-schema"
            >
              Validate Schema
            </Button>
          </Flex>
          <Breadcrumbs.Actions>
            <Flex spacing={4} justify="flex-end">
              <Button
                variantColor="gray-600"
                icon="close-outline"
                aria-label="Cancel editing"
                onClick={handleGoBack}
              >
                Back
              </Button>
              <SaveButton>Save Draft</SaveButton>
            </Flex>
          </Breadcrumbs.Actions>
        </Panel>
      )}
    </Formik>
  );
};

export default EditSchemaForm;

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type SummarizeDataLakeQueryVariables = {
  input: Types.SummarizeDataLakeQueryInput;
};

export type SummarizeDataLakeQuery = {
  summarizeDataLakeQuery: Pick<Types.SummarizeDataLakeQueryJob, 'id' | 'status'>;
};

export const SummarizeDataLakeQueryDocument = gql`
  mutation SummarizeDataLakeQuery($input: SummarizeDataLakeQueryInput!) {
    summarizeDataLakeQuery(input: $input) {
      id
      status
    }
  }
`;
export type SummarizeDataLakeQueryMutationFn = ApolloReactCommon.MutationFunction<
  SummarizeDataLakeQuery,
  SummarizeDataLakeQueryVariables
>;

/**
 * __useSummarizeDataLakeQuery__
 *
 * To run a mutation, you first call `useSummarizeDataLakeQuery` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSummarizeDataLakeQuery` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [summarizeDataLakeQuery, { data, loading, error }] = useSummarizeDataLakeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSummarizeDataLakeQuery(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SummarizeDataLakeQuery,
    SummarizeDataLakeQueryVariables
  >
) {
  return ApolloReactHooks.useMutation<SummarizeDataLakeQuery, SummarizeDataLakeQueryVariables>(
    SummarizeDataLakeQueryDocument,
    baseOptions
  );
}
export type SummarizeDataLakeQueryHookResult = ReturnType<typeof useSummarizeDataLakeQuery>;
export type SummarizeDataLakeQueryMutationResult = ApolloReactCommon.MutationResult<
  SummarizeDataLakeQuery
>;
export type SummarizeDataLakeQueryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SummarizeDataLakeQuery,
  SummarizeDataLakeQueryVariables
>;
export function mockSummarizeDataLakeQuery({
  data,
  variables,
  errors,
}: {
  data: SummarizeDataLakeQuery;
  variables?: SummarizeDataLakeQueryVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: SummarizeDataLakeQueryDocument, variables },
    result: { data, errors },
  };
}

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import useModal from 'Hooks/useModal';
import UserFeedbackModal, { UserFeedbackModalProps } from 'Components/modals/UserFeedbackModal';

const useUserFeedback = ({ featureName }: UserFeedbackModalProps) => {
  const { showModal } = useModal();

  const showUserFeedbackModal = React.useCallback(() => {
    showModal(<UserFeedbackModal featureName={featureName} />, {
      title: 'Send Feedback',
      showCloseButton: true,
    });
  }, [featureName, showModal]);

  return { showUserFeedbackModal };
};

export default useUserFeedback;
